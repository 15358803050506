import React, { Component } from 'react'
import SignupForm from './Signup'
import {userRegister} from '../../redux/actions/signupActions'
import { connect } from "react-redux";
import {apiCall} from "../../utils/utilFunctions";
import Globals from "../../Globals";
import {logout} from "../../redux/actions/queryObjects";

class index extends Component {
    constructor(props){
        super(props);
        this.state = {
          page:''
          };
    }

    componentDidMount(){
      // window.history.pushState(null, document.title, window.location.href);
      //   window.addEventListener('popstate', function (event){
      //       window.history.pushState(null, document.title,  window.location.href);
      //   });
        window.scrollTo(0, 0);
        apiCall(Globals.API_ROOT_URL,logout())
        localStorage.removeItem('token')
        localStorage.removeItem('price')
        localStorage.removeItem('quantity')
    }
    finalSubmit = (values) => {       //Passing values
     this.props.userRegister(values)
    };

    static getDerivedStateFromProps(nextProps){   //Redirecting to payment page
      if (Object.keys(nextProps.signupData.signupDetails.data).length > 0) {
        nextProps.history.push({
          pathname: '/payment',
          state: nextProps.signupData.signupDetails.data
          });
          nextProps.signupData.signupDetails.data={}
        return true;
      }
      else return null;
    }
    render() {
         return( 
        <SignupForm
          mySubmit={this.finalSubmit}
        />
        )
    }
}

const mapStateToProps = (state) => ({
  signupData:state.signup
});
export default connect(mapStateToProps,{userRegister})(index)
