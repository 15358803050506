import React, { Component } from 'react'
import ResetPassword from './ResetPassword'
import {resetpassword} from '../../redux/actions/resetActions'
import { connect } from "react-redux";

class ResetIndex extends Component {
    constructor(props){
        super(props);
        this.state = {
          };
    }

    finalResetSubmit =async (values) =>{    
      this.props.resetpassword(values)            //Passing Values
      }

      componentDidMount(){
        // window.history.pushState(null, document.title, window.location.href);
        //   window.addEventListener('popstate', function (event){
        //       window.history.pushState(null, document.title,  window.location.href);
        //   });
        window.scrollTo(0, 0);
      }

    static getDerivedStateFromProps(nextProps){   //Redirecting to login page after resetting the password
      if (Object.keys(nextProps.resetData.resetDetails.data).length > 0) {
        nextProps.history.push('/login')
        nextProps.resetData.resetDetails.data={}
        return true;
      }
      else return null;
    }

    render() {
        return (
        <ResetPassword
        handleResetSubmit = {this.finalResetSubmit}
        />
        )
    }
}
const mapStateToProps = (state) => ({
  resetData:state.resetpassword
});
export default connect(mapStateToProps,{resetpassword})(ResetIndex)
