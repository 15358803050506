import React from "react";
import { Button, Grid } from "semantic-ui-react";
import SciTechLogoSvgComponent from "../../img/sciTech-logo";
import FacebookIcon from "../../img/Facebook";
import TwitterIcon from "../../img/Twitter"
import LinkedinIcon from "../../img/Linkedin"
import InstagramIcon from "../../img/instagram"
import YoutubeIcon from "../../img/youtube"
import PrashantImage from '../../img/prashant.png'

const Thankyou = (props) => {
    // window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener('popstate', function (event){
    //       window.history.pushState(null, document.title,  window.location.href);
    //   });
      window.scrollTo(0, 0);
  const goToLogin =()=>{
    localStorage.removeItem("token")
    localStorage.removeItem("price")
    localStorage.removeItem("quantity")
    props.history.push('/login');
  }
  const goToPayment =()=>{
    props.history.push('/payment');
  }
  return (
    <main id="Thankyou-page">
      <section id="Thankyou">
        <div className="Thankyou-wrapper container">
          <a className="mill-skitech-logo" onClick = {() => props.history.push("/")}>
            <SciTechLogoSvgComponent />
          </a>
          <div className="Thankyou-inner-content">
            <img className="prashantimage" src={PrashantImage} alt="prashantimg"/>
            <p className="box-content">
              Thank you for subscribing to the associate membership at the mill
              scitech park. We seek the support of all stakeholders to ensure
              this iconic site going back to the domesday has a sustainable
              future. We throw open our arms to everyone to join us on this
              exciting adventure…
            </p>
            <p className="prashant-shah">- Prashant Shah</p>
          </div>

          <div className="email-link-wrapper">
            <div className="email-link">
              <p>
                To make a booking or to take advantage of our weekly on-site
                packages, please reach out to{" "}
                <a className="email-link">prashant@o2h.com</a>
              </p>
            </div>
          </div>
          {/*For desktop ratio */}
          <Grid className="Desktopbuttongroup">
            <Grid.Row>
              <Grid.Column width={8} className="Logoutbtn">
                <div className="Logoutbtn">
                  <Button type="login" onClick={goToLogin}>Log Out</Button>
                </div>
              </Grid.Column>
              <Grid.Column width={8} className="Paymentbtn">
                <div className="Paymentbtn">
                  <Button type="paymentlink" onClick={goToPayment}>Go to Payment Page</Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
           {/*For mobile ratio */}
          <div className="Mobilebuttongroup">
              <div className="Logoutbtn">
                      <Button type="login" onClick={goToLogin}>Log Out</Button>
              </div>
              <div className="Paymentbtn">
                  <Button type="paymentlink" onClick={goToPayment}>Go to Payment Page</Button>
              </div>
          </div>
          <div className="social-media flex_view_xs center middle">
              <p className="social">Follow us</p>
              <div className="social-icons flex_view_xs">
                <a className="facebook-link" 
                  rel="noopener noreferrer" 
                  target="_blank" 
                  href="https://www.facebook.com/o2hlife/">
                  <FacebookIcon/>
                </a>
                <a className="twitter-link" 
                  rel="noopener noreferrer" 
                  target="_blank" 
                  href="https://twitter.com/o2hventures/">
                  <TwitterIcon/>
                </a>
                <a className="linkedin-link" 
                  rel="noopener noreferrer"
                  target="_blank" 
                  href="https://www.linkedin.com/company/o2h-group/">
                  <LinkedinIcon/>
                </a>
                <a className="instagram-link" 
                  rel="noopener noreferrer"
                  target="_blank" 
                  href="https://www.instagram.com/o2hlife/">
                  <InstagramIcon/>
                </a>
                <a className="youtube-link" 
                  rel="noopener noreferrer"
                  target="_blank" 
                  href="https://www.youtube.com/channel/UC7YqoMJ566H7uau0E2WC3Qw">
                  <YoutubeIcon/>
                </a>
              </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Thankyou;
