import React, { useState } from "react";
import { Button, Form, Header, Message } from "semantic-ui-react";
import ResetPasswordIcon from "../../img/Resetpassword";
import { Link } from "react-router-dom";

const ResetPassword = (props) => {
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [error, seterror] = useState({});
  const urlParams = new URLSearchParams(window.location.search);

  const renderFieldError = (errors = []) => {
    //Rendering error message
    if (errors[0] !== null) {
      return <Message style={{ display: "block" }} error list={errors} />;
    } else {
      return null;
    }
  };
  const handleResetSubmit = (e) => {
    e.preventDefault();
    const obj = {
      //Passing password details
      password: password,
      confirmpassword: confirmpassword,
      userId: urlParams.get("userId"),
      token: urlParams.get("token"),
    };
    if (Object.values(error).every((o) => o==="")) {
      props.handleResetSubmit(obj);
    }
  };

  const handleResetChange = (e, name) => {
    if (name === "password") {
      setpassword(e.target.value);
      if (/\s/.test(e.target.value) || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/.test(e.target.value)) {
        seterror({...error,password:"Password must contain atleast 8 characters, 1 number, 1 upperCase, 1 lowerCase and 1 Special character"});
      }
     else if (e.target.value !== confirmpassword && confirmpassword!=='' ){
        seterror({ ...error, password:"",confirmpassword: "Passwords must match" });
      }
      else{
        seterror({...error,password:"",confirmpassword: ""})
      }
    }
    if (name === "confirmpassword") {
      setconfirmpassword(e.target.value);
      if (e.target.value !== password) {
        seterror({ ...error, confirmpassword: "Passwords must match" });
      }
      else{
        seterror({...error,confirmpassword:""})
        }
    }
  };
  return (
    <Form onSubmit={(e) => handleResetSubmit(e)}>
      <main id="Reset-password-page">
        <section id="ResetPassword">
          <div className="ResetPassword-wrapper container">
            <div className="Reset-password-header">
              <Header as="h2">Reset Password</Header>
            </div>
            <div className="toggle-section">
              <div
                className={`fieldrow ${error.password ? "password_has_error" : null}`}
              >
                <Form.Input
                  className="Password fieldrow"
                  required
                  fluid
                  name="password"
                  type="password"
                  value={password}
                  id="form-subcomponent-shorthand-input-password"
                  label="Password"
                  onChange={(e) => handleResetChange(e, "password")}
                  placeholder="Enter password"
                  error={!!error.password}
                />
                <div className="password-validation validation_msg">
                  {renderFieldError([error.password || null])}
                </div>
              </div>
              <div
                className={`fieldrow ${
                  error.confirmpassword ? "has_error" : null
                }`}
              >
                <Form.Input
                  className="Confirm-Password fieldrow"
                  required
                  fluid
                  name="confirmpassword"
                  type="password"
                  value={confirmpassword}
                  id="form-subcomponent-shorthand-input-confirmpassword"
                  label="Confirm Password"
                  onChange={(e) => handleResetChange(e, "confirmpassword")}
                  placeholder="Re-enter password"
                  error={!!error.confirmpassword}
                />
                <div className="confirmpassword-validation validation_msg">
                  {renderFieldError([error.confirmpassword || null])}
                </div>
              </div>
              <div className="Submitbtn">
                <Button disabled = {(password==="") || (confirmpassword==="") || (!(Object.values(error).every((o) => o==="")))} type="submit">Submit</Button>
              </div>
            </div>
            <div className="go-back-link-wrapper">
              <div className="go-back-link">
                <p>
                  Go back to
                  <Link to="/login" className="login-link">
                    Login
                  </Link>
                </p>
              </div>
            </div>
            <div className="ResetpasswordSvg">
              <ResetPasswordIcon />
            </div>
          </div>
        </section>
      </main>
    </Form>
  );
};
export default ResetPassword;
