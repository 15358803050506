function LoginSvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 457.6 214.7"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st5{fill:#fff}.prefix__st15{fill:#706865}.prefix__st16{fill:#292929}.prefix__st17{fill:#6b6b6b}.prefix__st18{fill:#eea886}.prefix__st19{fill:#81807e}.prefix__st20{fill:#353a3d}.prefix__st21{fill:#3b3b40}.prefix__st23{fill:#dbdff0}.prefix__st24{fill:#2d3133}.prefix__st30{fill:#998e89}.prefix__st31{fill:#294c5f}.prefix__st32{fill:#424773}.prefix__st33{fill:#292c47}.prefix__st34{fill:#29384a}.prefix__st35{fill:#3a3f47}.prefix__st39{fill:#fccca2}.prefix__st44{fill:#434343}.prefix__st46{fill:#a7aab8}.prefix__st53{fill:#ffb27d}.prefix__st54{fill:#233862}.prefix__st58{fill:#a5668b}"
        }
      </style>
      <g id="prefix__Group_4452" transform="translate(-10.268 -381.557)">
        <g id="prefix___19876" transform="translate(10.269 381.557)">
          <g id="prefix__Group_642">
            <g id="prefix__Group_638">
              <g id="prefix__Group_637">
                <defs>
                  <path id="prefix__SVGID_1_" d="M-75.8-178.6h-.2l.2-.2z" />
                </defs>
                <clipPath id="prefix__SVGID_2_">
                  <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
                </clipPath>
                <g id="prefix__Group_636" clipPath="url(#prefix__SVGID_2_)">
                  <linearGradient
                    id="prefix__Path_1241_1_"
                    gradientUnits="userSpaceOnUse"
                    x1={-763.762}
                    y1={556.523}
                    x2={-762.884}
                    y2={557.745}
                    gradientTransform="matrix(163.4449 0 0 -229.64 125152.172 127925.508)"
                  >
                    <stop offset={0} stopColor="#fff" />
                    <stop offset={1} />
                  </linearGradient>
                  <path
                    id="prefix__Path_1241"
                    d="M473.8-123.1l8-12.4-22.1-14.2-8 12.4-4.5-2.9-5.6 8.6-4.6-3L323.2 42.2l4.6 3-9.5 14.8 31 20 9.6-14.8 4.6 3 113.8-176.7-4.6-3 5.5-8.6-4.4-3z"
                    fill="url(#prefix__Path_1241_1_)"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_641">
              <g id="prefix__Group_640">
                <defs>
                  <path id="prefix__SVGID_3_" d="M-75.8-178.6h-.2l.2-.2z" />
                </defs>
                <clipPath id="prefix__SVGID_4_">
                  <use xlinkHref="#prefix__SVGID_3_" overflow="visible" />
                </clipPath>
                <g id="prefix__Group_639" clipPath="url(#prefix__SVGID_4_)">
                  <linearGradient
                    id="prefix__Path_1244_1_"
                    gradientUnits="userSpaceOnUse"
                    x1={-763.588}
                    y1={556.729}
                    x2={-762.799}
                    y2={557.942}
                    gradientTransform="matrix(172.5988 0 0 -219.2579 132167.422 122264.281)"
                  >
                    <stop offset={0} stopColor="#fff" />
                    <stop offset={1} />
                  </linearGradient>
                  <path
                    id="prefix__Path_1244"
                    d="M419.3 137l-.7.1c15.1-14 24.4-29.8 27.8-47.3 9.4-49-33.9-93.2-35.7-94.9l-11.4 17.7c.4.4 34.2 34.9 26.8 73.3-4.6 24-25.2 44.6-61.1 61.3l-12.8-6.7 2.5-4.7-48.7-25.4-2.6 4.9 25.2 13.2-11.8 22.6 48 25.2-7.4 22.6H320v15.3h156v-15.3h-56.8l.1-61.9z"
                    fill="url(#prefix__Path_1244_1_)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prefix__Group_647" transform="translate(220.275 233.946)">
            <linearGradient
              id="prefix__Path_1247_1_"
              gradientUnits="userSpaceOnUse"
              x1={-974.889}
              y1={322.269}
              x2={-973.889}
              y2={322.269}
              gradientTransform="matrix(51.6921 0 0 -137.0382 50318.102 44052.945)"
            >
              <stop offset={0} stopColor="#87a878" />
              <stop offset={1} stopColor="#d6d36a" />
            </linearGradient>
            <path
              id="prefix__Path_1247"
              d="M-71.5-178.6c4.5-2.1 8.9 12.1 8.9 12.1 7.6 10 18.2-3.3 17.1 11.6-1.8 26.2 30.1 8.2 15.4 35.6-14.7 27.3 13 15.5 3.8 37.5-9.2 22.1 2.7 48.3-11.1 37.5 0 0-12.1-26.6-24.7-34.8s7.5-18 3.6-29.2c-5.7-16.2-19.4-15.6-9.5-33.1s-18.6-29.9-3.5-37.2z"
              fill="url(#prefix__Path_1247_1_)"
            />
            <g id="prefix__Group_643" transform="translate(17.949 27.766)">
              <path
                id="prefix__Path_1248"
                className="prefix__st5"
                d="M-50.9-82c-6-4.2-13-33.5-13.4-50.9-.2-8.9-2.9-12.7-5.2-15.9-2.2-3.1-4.1-5.9-3-12.1 2.1-11.9-3.5-17-3.6-17.1l.7-.7c.3.2 6.1 5.5 3.9 18-1.1 5.8.7 8.2 2.9 11.3 2.3 3.2 5.1 7.3 5.4 16.5.4 18.7 7.6 46.4 13 50.1l-.7.8z"
              />
            </g>
            <g id="prefix__Group_644" transform="translate(37.942 68.042)">
              <linearGradient
                id="prefix__Path_1249_1_"
                gradientUnits="userSpaceOnUse"
                x1={-1011.415}
                y1={250.891}
                x2={-1010.415}
                y2={250.891}
                gradientTransform="matrix(46.7466 0 0 -58.6458 47204.184 14564.246)"
              >
                <stop offset={0} stopColor="#87a878" />
                <stop offset={1} stopColor="#d6d36a" />
              </linearGradient>
              <path
                id="prefix__Path_1249"
                d="M-68.6-120.1c-.8 0-1.7-.1-2.5-.3-2.1-.3-3.8-1.7-4.5-3.6-1.6-4.6 2.1-12.1 5.2-15.7 3.2-4.3 4.8-9.5 4.6-14.8-.1-1.4-.2-2.8-.4-4-.4-3.4-.7-5.9.8-7.5 1.3-1.4 3.8-2.1 8.3-2.1 7.9 0 11.1-3.3 13.6-6 1-1.1 2.1-2.1 3.3-2.9 4-2.2 8.2-2.5 10-.8.9.8 1.6 2.4.2 5.4-1.1 2.2-2.6 4.2-4.4 5.9-1.8 1.9-3 3.1-2.4 5.6.1.6.3 1.2.5 1.8 1.2 4.2 2.6 8.9-4.6 12.2-1.5.7-2.9 1.2-4.1 1.7-5 2-8 3.2-11.4 10.4-.7 1.4-1.2 2.7-1.8 4-2.8 6-4.9 10.7-10.4 10.7zm28.8-56c-1.2.7-2.2 1.6-3.1 2.7-2.7 2.8-6 6.2-14.3 6.3-4.2 0-6.5.6-7.6 1.8-1.2 1.3-.9 3.6-.6 6.7.1 1.2.3 2.5.4 4 .3 5.6-1.4 11.1-4.8 15.5-3 3.6-6.4 10.7-5 14.7.6 1.6 2 2.8 3.7 3 6.8 1.4 8.6-2.9 11.7-9.9.6-1.3 1.2-2.6 1.8-4 3.6-7.6 6.9-8.9 12-10.8 1.2-.5 2.6-1 4.1-1.7 6.4-2.9 5.2-6.8 4-11l-.5-1.8c-.8-3 .7-4.5 2.7-6.5 1.7-1.6 3.1-3.5 4.2-5.6.7-1.4 1.2-3.2 0-4.3-1.3-1.4-5.1-1-8.7.9z"
                fill="url(#prefix__Path_1249_1_)"
              />
            </g>
            <g id="prefix__Group_645" transform="translate(41.923 75.927)">
              <linearGradient
                id="prefix__Path_1250_1_"
                gradientUnits="userSpaceOnUse"
                x1={-1010.468}
                y1={242.782}
                x2={-1009.468}
                y2={242.782}
                gradientTransform="matrix(35.0719 0 0 -56.469 35363.035 13559.077)"
              >
                <stop offset={0} stopColor="#87a878" />
                <stop offset={1} stopColor="#d6d36a" />
              </linearGradient>
              <path
                id="prefix__Path_1250"
                d="M-75-122.3h-1c0-.9 1.3-22.8 10.1-29.2 9.6-7 12.7-14.6 14.1-19.6 1.7-6 10.4-7.6 10.7-7.6l.2 1c-.1 0-8.4 1.5-10 6.9-1.5 5.2-4.6 12.9-14.5 20.1-8.4 6.1-9.6 28.1-9.6 28.4z"
                fill="url(#prefix__Path_1250_1_)"
              />
            </g>
            <linearGradient
              id="prefix__Path_1251_1_"
              gradientUnits="userSpaceOnUse"
              x1={-971.344}
              y1={321.593}
              x2={-970.344}
              y2={321.593}
              gradientTransform="matrix(40.8693 0 0 -107.8075 39651.352 34567.234)"
            >
              <stop offset={0} stopColor="#87a878" />
              <stop offset={1} stopColor="#d6d36a" />
            </linearGradient>
            <path
              id="prefix__Path_1251"
              d="M-13-156.5c-6.2-2.1-4.9 9.9-7.6 18.8-2.6 8.9-29.3 3.9-18.1 26.2s-14.2 7.4-6.6 26.9.7 40.2 8.9 34.9c8.2-5.3 4.6-29.8 13.2-32.7 8.6-2.9 10-6.3 1.5-14.2S.5-112.4-7-128.3s1.2-25.7-6-28.2z"
              fill="url(#prefix__Path_1251_1_)"
            />
            <g id="prefix__Group_646" transform="translate(39.531 50.134)">
              <path
                id="prefix__Path_1252"
                className="prefix__st5"
                d="M-72.1-100.9c-.8-3.3-7.5-32.6-1.2-36.2 5.5-3.1 5.4-15.2 4.5-26.7-.8-10.1 12.3-14.9 12.8-15.1l.3.9c-.1 0-12.9 4.7-12.2 14.1.9 11.8 1 24.2-5 27.6-4.7 2.7-.4 26.2 1.7 35.1l-.9.3z"
              />
            </g>
            <linearGradient
              id="prefix__Path_1253_1_"
              gradientUnits="userSpaceOnUse"
              x1={-969.929}
              y1={314.642}
              x2={-968.929}
              y2={314.642}
              gradientTransform="matrix(37.7169 0 0 -33.785 36526.598 10585.94)"
            >
              <stop offset={0} stopColor="#292929" />
              <stop offset={1} stopColor="#6b6b6b" />
            </linearGradient>
            <path
              id="prefix__Path_1253"
              d="M-18.4-61.1l-8.9 33.8h-19.9l-8.9-33.8h37.7z"
              fill="url(#prefix__Path_1253_1_)"
            />
            <linearGradient
              id="prefix__Path_1254_1_"
              gradientUnits="userSpaceOnUse"
              x1={-969.929}
              y1={314.642}
              x2={-968.929}
              y2={314.642}
              gradientTransform="matrix(37.7169 0 0 -33.785 36526.598 10585.94)"
            >
              <stop offset={0} stopColor="#292929" />
              <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <path
              id="prefix__Path_1254"
              d="M-18.4-61.1S-38-57.4-38-27.3h-9.2l-8.9-33.8h37.7z"
              fill="url(#prefix__Path_1254_1_)"
            />
          </g>
          <g id="prefix__Group_651">
            <g id="prefix__Group_650">
              <g id="prefix__Group_649">
                <defs>
                  <path id="prefix__SVGID_5_" d="M-75.8-178.6h-.2l.2-.2z" />
                </defs>
                <clipPath id="prefix__SVGID_6_">
                  <use xlinkHref="#prefix__SVGID_5_" overflow="visible" />
                </clipPath>
                <g id="prefix__Group_648" clipPath="url(#prefix__SVGID_6_)">
                  <linearGradient
                    id="prefix__Path_1255_1_"
                    gradientUnits="userSpaceOnUse"
                    x1={-744.04}
                    y1={546.203}
                    x2={-744.673}
                    y2={547.995}
                    gradientTransform="matrix(32.177 0 0 -49.049 24401.242 26971.17)"
                  >
                    <stop offset={0} stopColor="#fff" />
                    <stop offset={1} />
                  </linearGradient>
                  <path
                    id="prefix__Path_1255"
                    d="M382-66.5l-.8-.5 31.4-48.5.8.5L382-66.5z"
                    fill="url(#prefix__Path_1255_1_)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prefix__Group_655">
            <g id="prefix__Group_654">
              <g id="prefix__Group_653">
                <defs>
                  <path id="prefix__SVGID_7_" d="M-75.8-178.6h-.2l.2-.2z" />
                </defs>
                <clipPath id="prefix__SVGID_8_">
                  <use xlinkHref="#prefix__SVGID_7_" overflow="visible" />
                </clipPath>
                <g id="prefix__Group_652" clipPath="url(#prefix__SVGID_8_)">
                  <linearGradient
                    id="prefix__Path_1258_1_"
                    gradientUnits="userSpaceOnUse"
                    x1={-700.142}
                    y1={501.174}
                    x2={-701.93}
                    y2={508.732}
                    gradientTransform="matrix(11.395 0 0 -11.628 8497.613 6009.322)"
                  >
                    <stop offset={0} stopColor="#fff" />
                    <stop offset={1} />
                  </linearGradient>
                  <path
                    id="prefix__Path_1258"
                    d="M439-139.6l-.8-.5 7.2-11.1 4.2 2.7-.5.8-3.4-2.2-6.7 10.3z"
                    fill="url(#prefix__Path_1258_1_)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prefix__Group_656" transform="translate(313.657 243.911)">
            <path
              id="prefix__Rectangle_148"
              className="prefix__st15"
              d="M-6.5-178.8h3.1v139.1h-3.1z"
            />
            <path
              id="prefix__Rectangle_149"
              className="prefix__st15"
              d="M-76-178.8h3.1v139.1H-76z"
            />
          </g>
          <path
            id="prefix__Path_1261"
            className="prefix__st16"
            d="M318.3 181.3h-88.8c-3.3 0-6-2.7-6-5.9V13c0-3.3 2.7-6 5.9-6h88.8c3.3 0 6 2.7 6 5.9v162.3c.1 3.4-2.6 6-5.9 6.1z"
          />
          <path
            id="prefix__Path_1262"
            className="prefix__st5"
            d="M315.6 174.4h-83.4c-3.3 0-5.9-2.6-5.9-5.9V15.7c0-3.3 2.6-5.9 5.9-5.9h83.4c3.3 0 5.9 2.6 5.9 5.9v152.9c0 3.2-2.6 5.8-5.9 5.8z"
          />
          <path
            id="prefix__Rectangle_150"
            className="prefix__st17"
            d="M258.9 5.7h30v6.8h-30z"
          />
          <g id="prefix__Group_657" transform="translate(325.127 198.921)">
            <path
              id="prefix__Path_1263"
              className="prefix__st17"
              d="M-58.2-134.6c-12.1-2.5-19.8-14.3-17.4-26.3 2.1-10.4 11.2-17.8 21.8-17.8v1c-11.8 0-21.3 9.5-21.3 21.3s9.5 21.3 21.3 21.3 21.3-9.5 21.3-21.3c0-1-.1-2.1-.2-3.1l1-.1c.2 1.1.2 2.2.2 3.3 0 12.3-10 22.3-22.3 22.3-1.4-.2-2.9-.3-4.4-.6z"
            />
          </g>
          <g id="prefix__Group_687" transform="translate(267.171 199.344)">
            <g id="prefix__Group_659" transform="translate(13.061 6.144)">
              <g id="prefix__Group_658">
                <path
                  id="prefix__Path_1264"
                  className="prefix__st18"
                  d="M-56.8-160s1.9-3.3-1.1-12.3c-1.4-4.8-6.4-7.5-11.2-6.2-2.1.6-3.9 2-5.1 3.8-3.2 5.5-2.1 9 2.6 15.2 4.8 6.4 12.9 2.4 14.8-.5z"
                />
              </g>
            </g>
            <g id="prefix__Group_661" transform="translate(2.194 178.255)">
              <g id="prefix__Group_660">
                <path
                  id="prefix__Path_1265"
                  className="prefix__st19"
                  d="M-69.4-178.3s-.5 3.7-.6 4.2-5.6-.3-5.6-.3l-.4-4.4s5.9 0 6.6.5z"
                />
              </g>
            </g>
            <g id="prefix__Group_663" transform="translate(1.312 182.22)">
              <g id="prefix__Group_662">
                <path
                  id="prefix__Path_1266"
                  className="prefix__st20"
                  d="M-69.1-178.5c1 1.2 2.1 2.3 3.4 3.2 1.7 1 4.3 1.4 5.3 2.3.7.7 2.6 3.8-4.4 3.8-7-.1-10.4.4-11.1-.8-.8-1.2 1.1-8.8 1.1-8.8 1.9.4 3.9.5 5.7.3z"
                />
              </g>
            </g>
            <g id="prefix__Group_665" transform="translate(40.334 178.255)">
              <g id="prefix__Group_664">
                <path
                  id="prefix__Path_1267"
                  className="prefix__st19"
                  d="M-69.4-178.3s-.5 3.7-.6 4.2-5.6-.3-5.6-.3l-.4-4.4s5.9 0 6.6.5z"
                />
              </g>
            </g>
            <g id="prefix__Group_667" transform="translate(39.452 182.199)">
              <g id="prefix__Group_666">
                <path
                  id="prefix__Path_1268"
                  className="prefix__st20"
                  d="M-69.1-178.5c1 1.2 2.1 2.3 3.4 3.2 1.7 1 7.9 1.8 8.9 2.7.7.7 1.9 3.6-7.9 3.3-7-.2-10.4.4-11.1-.8-.8-1.2 1.1-8.8 1.1-8.8 1.8.4 3.7.5 5.6.4z"
                />
              </g>
            </g>
            <g id="prefix__Group_669" transform="translate(.292 87.315)">
              <g id="prefix__Group_668">
                <path
                  id="prefix__Path_1269"
                  className="prefix__st21"
                  d="M-65.7-178.8s-2.8 8.8-2.5 16.3-1.5 15.8-2.2 23.7c-.8 8.8-4.2 29.7-4.4 34.4S-76-85-76-85l8.7.6s5.8-36.4 7.2-40.2 8.9-30.2 9.1-33.2c.3-3-14.7-21-14.7-21z"
                />
              </g>
            </g>
            <g id="prefix__Group_671" transform="translate(10.63 84.541)">
              <g id="prefix__Group_670">
                <path
                  id="prefix__Path_1270"
                  className="prefix__st21"
                  d="M-47.6-176.9s4.1 23.2 4.7 27.8c.5 4.6.8 22.4 1.1 25.3.3 2.9 1.6 37.9 3 42.2h-9.3s-6.1-25.4-6.1-37.4-5.2-26.2-8.5-34.1c-3.3-7.9-11-8.1-13.1-22.9-.2 0 25.4-5.6 28.2-.9z"
                />
              </g>
            </g>
            <g id="prefix__Group_673" transform="translate(17.378 24.564)">
              <g id="prefix__Group_672">
                <path
                  id="prefix__Path_1271"
                  className="prefix__st18"
                  d="M-63.9-176.1s-.9 2.5 0 4.3-11 .7-11 .7c.3-2.3-.1-4.6-1-6.7-1.5-3 12 1.7 12 1.7z"
                />
              </g>
            </g>
            <g id="prefix__Group_675" transform="translate(10.08 86.427)">
              <g id="prefix__Group_674">
                <path
                  id="prefix__Path_1272"
                  d="M-76-176s4.3 4.4 29.3-.7l-.4-2.1-28.5 1.1-.4 1.7z"
                  fill="#eddad3"
                />
              </g>
            </g>
            <g id="prefix__Group_677" transform="translate(6.507 11.697)">
              <g id="prefix__Group_676">
                <path
                  id="prefix__Path_1273"
                  className="prefix__st23"
                  d="M-13.5-177.4c-.8 2.9-3 9.4-3.7 11.5-.8 2.5-2.8 7.4-3.5 8.1-3.1 2.4-6.5 4.5-10 6.3-1.2.6-2.5 1.1-3.8 1.6-4 1.3-7.8 3.1-11.4 5.3H-76c.5-4.4 1.8-8.6 3.8-12.5 2.3-.1 5.8-.6 7.5-.6 1.5-.1 3-.5 4.5-1h.8l2.3.2 5.9.4s4.4.4 5.6.7 9.7-1.5 11.4-1.7c.3 0 .6-.1.8-.3 2.6-1.1 8.3-5 8.5-5.6.2-.6 3.1-11.6 3.7-13.6.2-.5 7.8.9 7.7 1.2z"
                />
              </g>
            </g>
            <g id="prefix__Group_679" transform="translate(1.349 30.93)">
              <g id="prefix__Group_678">
                <path
                  id="prefix__Path_1274"
                  className="prefix__st23"
                  d="M-71.1-158.9c1.2 5.2 2.1 10.4 2.8 15.6.4 4.7-7.7 70.8-7.7 70.8 3.8 3 45.8 3.8 45.6 2.5-.2-1.2-9.3-72.4-8.6-74.8 1.2-4.6 2-9.2 2.5-13.9.4-4.5 4.3-7.1 4.3-7.1l6.7-5.2c.7-3.3-2.7-7.9-2.7-7.9s-10.2 1.1-11.6 1c-1.1-.1-8.2-1-8.2-1-1.3.4-2.6.7-4 .8-.8.1-1.6 0-2.3-.2-.3-.1-.5-.2-.7-.4 0 0-7.7.3-11.7 1.5-3.5 5.6-5 11.9-4.4 18.3z"
                />
              </g>
            </g>
            <g id="prefix__Group_681" transform="translate(12.47 5.396)">
              <g id="prefix__Group_680">
                <path
                  id="prefix__Path_1275"
                  className="prefix__st24"
                  d="M-71-158.6c1.7 0 3.3-.4 4.9-1 3.3-1.1 3.6-7 4.3-6.3.8.9 2.1.9 3 .2.2-.2.3-.4.5-.6 0 0-1.6-2.1-.9-3.2.6-.7 1.2-1.3 2-1.8 0 0-2.3-11.3-13-6.2-12.6 6.1-.8 18.9-.8 18.9z"
                />
              </g>
            </g>
            <g id="prefix__Group_683" transform="translate(18.199 29.94)">
              <g id="prefix__Group_682">
                <path
                  id="prefix__Path_1276"
                  className="prefix__st23"
                  d="M-64.7-177.1l-.4-1.6c-3.5-.1-7 0-10.5.4l-.4 1.9s4.8 2.1 11.3-.7z"
                />
              </g>
            </g>
            <g id="prefix__Group_685" transform="translate(24.66 15.94)">
              <g id="prefix__Group_684">
                <path
                  id="prefix__Path_1277"
                  className="prefix__st18"
                  d="M-72.8-177.9c-.6-1-2.9-1.7-3.2 1.2-.2 2.3 4.1 4.7 4.1 2.6.1-1.3-.2-2.7-.9-3.8z"
                />
              </g>
            </g>
            <path
              id="prefix__Path_1278"
              className="prefix__st23"
              d="M-65.4-146.1s-3.3 0-10.3 22.4c-2.4 7.7 11.1 13.6 11.1 13.6l-.8-36z"
            />
            <g id="prefix__Group_686" transform="translate(55.907 .765)">
              <path
                id="prefix__Rectangle_151"
                transform="rotate(-55.054 -70.376 -171.14)"
                fill="#4da8f2"
                d="M-79.3-171.8h17.8v1.3h-17.8z"
              />
            </g>
            <path
              id="prefix__Path_1279"
              d="M-5.7-173.7c.1.4.1.8.1 1.2 0 0-.2.7-.4 1.2 0 .1-.1.3-.1.4 0 .8-.2 1.5-.4 2.2-.4.9-2.1 4.1-2.1 4.1l-2.9-1-2.7-.1s-1-6-.7-7c0-.1.1-.2.1-.3.3-.6.6-1.1 1-1.5.1-.1.9-1.5 1.4-1.5.5-.1 1 .1 1.4.5.7-.1 1.4.1 2 .4.7-.1 1.4.2 1.9.7-.1.1 1.1.1 1.4.7z"
              fill="#efaa88"
            />
            <path
              id="prefix__Path_1280"
              d="M-8.9-177.3l.3-1.5-1.4.8 1.1.7z"
              fill="#dfe1e8"
            />
          </g>
          <g id="prefix__Group_827" transform="translate(80.063 226.185)">
            <g id="prefix__Group_741" transform="translate(51.924 68.318)">
              <path
                id="prefix__Rectangle_152"
                fill="#dededf"
                d="M-76-178.8h32v1.9h-32z"
              />
            </g>
            <g id="prefix__Group_742" transform="translate(56.216 75.146)">
              <path
                id="prefix__Rectangle_153"
                className="prefix__st17"
                d="M-76-178.8h2.8v86.3H-76z"
              />
            </g>
            <g id="prefix__Group_743" transform="translate(78.879 67.768)">
              <path
                id="prefix__Rectangle_154"
                fill="#ccbdb7"
                d="M-76-178.8h22.2v2.7H-76z"
              />
            </g>
            <g id="prefix__Group_744" transform="translate(85.772 44.66)">
              <path
                id="prefix__Rectangle_155"
                className="prefix__st30"
                d="M-76-178.8h8.4v25.6H-76z"
              />
            </g>
            <g id="prefix__Group_745" transform="translate(40.444 70.241)">
              <path
                id="prefix__Rectangle_156"
                className="prefix__st16"
                d="M-76-178.8H47.1v4.9H-76z"
              />
            </g>
            <g id="prefix__Group_746" transform="translate(6.838 32.302)">
              <path
                id="prefix__Path_1313"
                className="prefix__st31"
                d="M-66-170.6s-1.4 18.9 2.3 21.1c2.3 1.4 11.5 6.2 11.2 7.1s-5.2 8-5.2 8S-75.4-146-76-149.7c-.5-3.6.9-27.6 3.3-28.8 1.8-.8 2.8-.1 5.1 1.6 3.3 2.4 1.6 6.3 1.6 6.3z"
              />
            </g>
            <g id="prefix__Group_749" transform="translate(31.374 149.153)">
              <g id="prefix__Group_748">
                <g id="prefix__Group_747">
                  <path
                    id="prefix__Path_1314"
                    className="prefix__st32"
                    d="M-68.8-178.3s-.5 3.8-.6 4.4-6.1-.3-6.1-.3l-.5-4.5c0-.1 6.5-.1 7.2.4z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_752" transform="translate(30.436 153.265)">
              <g id="prefix__Group_751">
                <g id="prefix__Group_750">
                  <path
                    id="prefix__Path_1315"
                    className="prefix__st33"
                    d="M-68.4-178.5c1.1 1.3 2.3 2.4 3.8 3.3 1.9 1 4.8 1.4 5.9 2.4.8.7 2.9 4-4.8 3.9s-11.4.5-12.2-.8 1.2-9.1 1.2-9.1 1.7.7 6.1.3z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_755" transform="translate(70.148 149.184)">
              <g id="prefix__Group_754">
                <g id="prefix__Group_753">
                  <path
                    id="prefix__Path_1316"
                    className="prefix__st32"
                    d="M-68.8-178.3s-.5 3.8-.6 4.4-6.1-.3-6.1-.3l-.5-4.5c0-.1 6.5-.1 7.2.4z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_758" transform="translate(69.188 153.328)">
              <g id="prefix__Group_757">
                <g id="prefix__Group_756">
                  <path
                    id="prefix__Path_1317"
                    className="prefix__st33"
                    d="M-68.4-178.5c1.1 1.3 2.3 2.4 3.8 3.3 1.9 1 8.7 1.8 9.8 2.9.8.7 2.1 3.8-8.7 3.5-7.7-.2-11.4.5-12.2-.8s1.2-9.1 1.2-9.1 1.6.6 6.1.2z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_761" transform="translate(13.593 83.666)">
              <g id="prefix__Group_760">
                <g id="prefix__Group_759">
                  <path
                    id="prefix__Path_1318"
                    className="prefix__st34"
                    d="M-70.5-178.8s22 3.7 27 4.5 31.9 7.5 35.3 9.5c2.7 1.6-1.4 40.7-2.4 55.6h-10.6l-.8-44.7c-12.7.3-21.8-3-31.1-1.6s-17 3.4-22.9-4.5c0 .1-.2-17.1 5.5-18.8z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_764" transform="translate(9.26 88.444)">
              <g id="prefix__Group_763">
                <g id="prefix__Group_762">
                  <path
                    id="prefix__Path_1319"
                    className="prefix__st34"
                    d="M-44.5-113.9l-10.3-.6-1-44.1c-3.9.1-7.9-.1-11.8-.7l-8.3-19.4c7.3 1.5 30.8 7.4 33.9 9.3 2.6 1.5-1.5 40.6-2.5 55.5z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_767" transform="translate(23.664 .789)">
              <g id="prefix__Group_766">
                <g id="prefix__Group_765">
                  <path
                    id="prefix__Path_1320"
                    className="prefix__st18"
                    d="M-70.4-178.4s8.1-2.2 11.1 4c3.1 6.3 1.7 18.1-2.4 19.3-4.1 1.1-9.9-.2-11.6-4.1s-3-9.4-2.6-13c.4-3.6 3.3-5.4 5.5-6.2z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_770" transform="translate(25.074 17.708)">
              <g id="prefix__Group_769">
                <g id="prefix__Group_768">
                  <path
                    id="prefix__Path_1321"
                    className="prefix__st18"
                    d="M-65.2-173.3s-.1 4.8.4 5.8-11.2.8-11.2.8 1.9-5.7.8-10.6c-1.1-4.9 10 4 10 4z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_773" transform="translate(23.219)">
              <g id="prefix__Group_772">
                <g id="prefix__Group_771">
                  <path
                    id="prefix__Path_1322"
                    className="prefix__st35"
                    d="M-74-161.4s.6 1.3 3.7 1.1c3-.2 7.1-3.9 8.4-4.2s1.4-1.1 1.4-1.1l.7 1.2 1.3.2s-.9-3.7 1.1-5-.9-11.7-10.8-9.2c0 0-7.6 1.4-7.7 5.6-.2 4.2.4 9.1 1.9 11.4z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_776" transform="translate(25.222 25.73)">
              <g id="prefix__Group_775">
                <g id="prefix__Group_774">
                  <path
                    id="prefix__Path_1323"
                    d="M-64.3-175.6s0-1.9-.9-2.1c-.9-.2-9.2-1.3-10.3-.9l-.5 2.6c0-.1 2.1 1.2 11.7.4z"
                    fill="#e2d2ca"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_779" transform="translate(35.962 10.139)">
              <g id="prefix__Group_778">
                <g id="prefix__Group_777">
                  <path
                    id="prefix__Path_1324"
                    className="prefix__st18"
                    d="M-72.6-176.6c-.2-1.6-2-3.4-3.1-.9-1 2.5.7 6.1 1.9 5.9 1.1-.3 1.5-2.9 1.2-5z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_782" transform="translate(71.945 51.785)">
              <g id="prefix__Group_781">
                <g id="prefix__Group_780">
                  <path
                    id="prefix__Path_1325"
                    d="M-76-178.8s.1 0 0 0z"
                    fill="#323657"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_785" transform="translate(21.925 2.312)">
              <g id="prefix__Group_784">
                <g id="prefix__Group_783">
                  <path
                    id="prefix__Path_1326"
                    className="prefix__st35"
                    d="M-71.1-178.8s-9.8-.5-1.7 14.9l1.7-14.9z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_787" transform="translate(4.947 146.88)">
              <g id="prefix__Group_786">
                <path
                  id="prefix__Path_1327"
                  className="prefix__st16"
                  d="M-76-167.1l.7 2.1 30.9-9.6-1.5-4.2-30.1 11.7z"
                />
              </g>
            </g>
            <g id="prefix__Group_789" transform="translate(34.144 115.95)">
              <g id="prefix__Group_788">
                <path
                  id="prefix__Rectangle_157"
                  className="prefix__st16"
                  d="M-76-178.8h3.5v33.6H-76z"
                />
              </g>
            </g>
            <g id="prefix__Group_791" transform="translate(25.539 114.174)">
              <g id="prefix__Group_790">
                <path
                  id="prefix__Rectangle_158"
                  className="prefix__st17"
                  d="M-76-178.8h20.7v2H-76z"
                />
              </g>
            </g>
            <g id="prefix__Group_793" transform="translate(35.476 146.605)">
              <g id="prefix__Group_792">
                <path
                  id="prefix__Path_1328"
                  className="prefix__st16"
                  d="M-44.4-166.8l-.7 2.1-30.9-9.6 1.4-4.4 30.2 11.9z"
                />
              </g>
            </g>
            <g id="prefix__Group_795" transform="translate(35.476 146.605)">
              <g id="prefix__Group_794">
                <path
                  id="prefix__Path_1329"
                  className="prefix__st16"
                  d="M-66.1-165v2.4l-9.9-11.8 1.4-4.4 8.5 13.8z"
                />
              </g>
            </g>
            <g id="prefix__Group_797" transform="translate(64.207 158.529)">
              <g id="prefix__Group_796">
                <path
                  id="prefix__Path_1330"
                  className="prefix__st17"
                  d="M-70.2-175.9c0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9z"
                />
              </g>
            </g>
            <g id="prefix__Group_799" transform="translate(39.789 158.529)">
              <g id="prefix__Group_798">
                <path
                  id="prefix__Path_1331"
                  className="prefix__st17"
                  d="M-70.2-175.9c0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9z"
                />
              </g>
            </g>
            <g id="prefix__Group_801" transform="translate(2.03 158.529)">
              <g id="prefix__Group_800">
                <path
                  id="prefix__Path_1332"
                  className="prefix__st17"
                  d="M-70.2-175.9c0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9z"
                />
              </g>
            </g>
            <g id="prefix__Group_802" transform="translate(11.48 106.647)">
              <path
                id="prefix__Path_1333"
                className="prefix__st16"
                d="M-29.9-171.3h-43c-1.7 0-3-1.4-3-3v-1.4c0-1.7 1.4-3 3-3h43c1.7 0 3 1.4 3 3v1.4c0 1.7-1.4 3-3 3z"
              />
            </g>
            <g id="prefix__Group_803" transform="translate(144.989 75.146)">
              <path
                id="prefix__Rectangle_159"
                className="prefix__st17"
                d="M-76-178.8h2.8v86.3H-76z"
              />
            </g>
            <g id="prefix__Group_804" transform="translate(53.679 5.738)">
              <path
                id="prefix__Path_1334"
                className="prefix__st16"
                d="M-6.4-127.6H-73c-1.7 0-3-1.4-3-3v-45.2c0-1.7 1.4-3 3-3h66.6c1.7 0 3 1.4 3 3v45.2c0 1.7-1.3 3-3 3z"
              />
            </g>
            <g id="prefix__Group_805" transform="translate(56.512 8.487)">
              <path
                id="prefix__Rectangle_160"
                fill="#f5f7ff"
                d="M-76-178.8h67v40.4h-67z"
              />
            </g>
            <g id="prefix__Group_806" transform="translate(115.074 50.685)">
              <path
                id="prefix__Path_1335"
                className="prefix__st17"
                d="M-70.6-176.7c0 1.1-1.2 2.1-2.7 2.1-1.5 0-2.7-.9-2.7-2.1s1.2-2.1 2.7-2.1c1.5 0 2.7.9 2.7 2.1z"
              />
            </g>
            <g id="prefix__Group_807" transform="translate(85.771 63.73)">
              <path
                id="prefix__Path_1336"
                className="prefix__st30"
                d="M-56.8-172.2v-.9s-1.3-5.7-10.4-5.7-8.9 6.6-8.9 6.6h19.3z"
              />
            </g>
            <g id="prefix__Group_809" transform="translate(83.671 61.037)">
              <g id="prefix__Group_808">
                <path
                  id="prefix__Path_1337"
                  className="prefix__st18"
                  d="M-74-175.3c.9-.1 1.8-.3 2.7-.6.7-.4 5-3.3 6-2.9s7.8 2.5 7.5 3.5c-.2 1-.9.9-1.3.7-1.9-.5-3.9-.9-5.8-1 0 0 4.8 1.8 4.8 3s-1 1.6-1.5 2.1-1 1.7-2.2 1.8c-1.2.1-7.3-2-8.5-2.1s-3.6.1-3.7-.1c.6-1.5 1.2-3 2-4.4z"
                />
              </g>
            </g>
            <g id="prefix__Group_812" transform="translate(8.753 28.381)">
              <g id="prefix__Group_811">
                <g id="prefix__Group_810">
                  <path
                    id="prefix__Path_1338"
                    className="prefix__st31"
                    d="M-34.9-175.6c-6.3-1.1-12.9-2.7-12.9-2.7l-11.7-.5s-10.7 2.4-15 4.3c-.4.2-.4 9.6-1.5 12.1l3.5 7.4s2 14.7 2.5 21.2c.2 3-.5 28.9-.5 28.9s1.1.5 9 1.4c8 .9 27.9-.3 28.1-1.3s-3.9-35.1-4-36.9c-.3-2.7.8-15.3.8-15.3 1.5 2 5.3 11.1 9.3 15.5 2.3 2.6 14 4.1 16.2 4.2 4.8.2 13.5 1.1 13.7.7.2-.3.8-7.7.2-7.9-.5-.2-16.7-1.1-20.8-3.3-1.5-.8-6-10.7-6-10.7s-7.5-15.6-10.9-17.1z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_813" transform="translate(0 54.512)">
              <path
                id="prefix__Path_1339"
                className="prefix__st16"
                d="M-48-131.9h-22.2c-3.2 0-5.8-2.6-5.8-5.8V-173c0-3.2 2.6-5.8 5.8-5.8H-48c3.2 0 5.8 2.6 5.8 5.8v35.2c.1 3.3-2.5 5.9-5.8 5.9z"
              />
            </g>
            <g id="prefix__Group_814" transform="translate(10.216 84.46)">
              <path
                id="prefix__Path_1340"
                className="prefix__st16"
                d="M-58.4-150.9c-5 0-9-1.7-12-5-6.8-7.5-5.6-20.6-5.5-21.2.1-1 1-1.8 2-1.7s1.8 1 1.7 2v.1c0 .1-1.1 12.1 4.5 18.3 2.3 2.5 5.3 3.7 9.2 3.7 1 0 1.9.8 1.9 1.9.1 1-.7 1.9-1.8 1.9z"
              />
            </g>
            <g id="prefix__Group_817" transform="translate(61.226 11.7)">
              <g id="prefix__Group_816">
                <g id="prefix__Group_815">
                  <path
                    id="prefix__Path_1341"
                    className="prefix__st17"
                    d="M-56.8-176.6h-18.8c-.2 0-.4-.2-.4-.4v-1.3c0-.2.2-.4.4-.4h18.8c.2 0 .4.2.4.4v1.3c0 .2-.2.4-.4.4z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_820" transform="translate(61.226 16.267)">
              <g id="prefix__Group_819">
                <g id="prefix__Group_818">
                  <path
                    id="prefix__Path_1342"
                    className="prefix__st17"
                    d="M-56.8-176.6h-18.8c-.2 0-.4-.2-.4-.4v-1.3c0-.2.2-.4.4-.4h18.8c.2 0 .4.2.4.4v1.3c0 .2-.1.4-.4.4z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_823" transform="translate(61.268 21.552)">
              <g id="prefix__Group_822">
                <g id="prefix__Group_821">
                  <path
                    id="prefix__Path_1343"
                    className="prefix__st17"
                    d="M-57.9-154.6h-16.7c-.8 0-1.5-.7-1.5-1.5v-21.2c0-.8.7-1.5 1.5-1.5h16.7c.8 0 1.5.7 1.5 1.5v21.2c0 .8-.7 1.5-1.5 1.5z"
                  />
                </g>
              </g>
            </g>
            <g id="prefix__Group_826" transform="translate(84.841 11.721)">
              <g id="prefix__Group_825">
                <g id="prefix__Group_824">
                  <path
                    id="prefix__Path_1344"
                    className="prefix__st17"
                    d="M-43.4-144.8h-30.3c-1.3 0-2.3-1-2.3-2.3v-29.4c0-1.3 1-2.3 2.3-2.3h30.3c1.3 0 2.3 1 2.3 2.3v29.4c0 1.3-1 2.3-2.3 2.3z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prefix__Group_846" transform="translate(323.568 198.921)">
            <g id="prefix__Group_829" transform="translate(3.671 49.221)">
              <g id="prefix__Group_828">
                <path
                  id="prefix__Path_1345"
                  className="prefix__st39"
                  d="M-65.3-172.7s-1.4-3-1.6-3.4-1.5-1.2-3.1-1.6c-1.5-.4-3.6-1.2-3.8-1-.4.1-.7.4-.8.8-.2.4-.2.9-.2 1.4-.4.4-.6 1-.5 1.5-.4.4-.5 1.1-.3 1.6-.3.3-.5.9-.3 1.3.6.3 1.1.7 1.6 1.1 1.8 1.1 3.7 1.9 5.8 2.5 0-.1 2.8-.1 3.2-4.2z"
                />
              </g>
            </g>
            <g id="prefix__Group_831" transform="translate(11.16 53.975)">
              <g id="prefix__Group_830">
                <path
                  id="prefix__Path_1346"
                  className="prefix__st39"
                  d="M-76-173.3s3.4.6 4.5-.1-.2-4.1-.2-4.1l-2.2-1.3-2.1 5.5z"
                />
              </g>
            </g>
            <g id="prefix__Group_833" transform="translate(7.079 53.827)">
              <g id="prefix__Group_832">
                <path
                  id="prefix__Path_1347"
                  d="M-73.2-178.8c0 .7.1 1.4.3 2.1.3.5-3.1 1.2-3.1 1.2s.8-3.2 2.8-3.3z"
                  fill="#f2b890"
                />
              </g>
            </g>
            <g id="prefix__Group_835" transform="translate(3.651 49.249)">
              <g id="prefix__Group_834">
                <path
                  id="prefix__Path_1348"
                  d="M-73.4-178.7s3.7 1.8 3.5 2.4c-.1.3-.3.6-.7.7l1.2 1.4s.3 1.4-1.3 1.3c0 0 .4 1.3-1.1 1 0 0-.1 1-.9.9-1.1-.3-2.1-.7-3.1-1.3 0 0-.4-.5.2-1.3-.2-.5-.1-1.2.3-1.6-.1-.7.1-1.4.6-1.9 0-.7.4-1.5 1-1.8-.1.1.1.2.3.2z"
                  fill="#f2c195"
                />
              </g>
            </g>
            <g id="prefix__Group_837" transform="translate(7.649 51.755)">
              <g id="prefix__Group_836">
                <path
                  id="prefix__Path_1349"
                  className="prefix__st39"
                  d="M-71-178.8c-1.5 0-3 .3-4.4.8-.3.2-.7.4-.6.8s.6.8 1.8.9c1 .1 1.9.3 2.8.6 0-.1 1.3-.4.4-3.1z"
                />
              </g>
            </g>
            <g id="prefix__Group_838" transform="translate(4.702 49.213)">
              <path
                id="prefix__Path_1350"
                className="prefix__st39"
                d="M-75.9-177c1.5.5 3.1 1.1 4.5 1.8.3.4.8-1.2.6-1.5-1.3-.8-2.6-1.5-3.9-2.1-.8.1-1.3.8-1.2 1.6v.2z"
              />
            </g>
            <g id="prefix__Group_839" transform="translate(0 48.604)">
              <path
                id="prefix__Path_1351"
                className="prefix__st39"
                d="M-69.3-178s-6.6-1.4-6.7-.6.5.9 5.9 2.5c5.3 1.6.8-1.9.8-1.9z"
              />
            </g>
            <g id="prefix__Group_840" transform="translate(41.096 183.856)">
              <path
                id="prefix__Path_1352"
                className="prefix__st20"
                d="M-65.1-178.2s-2.1 3.1-5 3.8c-2.9.7-5.8 1.8-5.9 3.6s8.5 3 11.9 1.1c5-2.8 5.5-1.9 6.1-3.3s-.7-5.1-.6-5.6c0-.6-6.5.4-6.5.4z"
              />
            </g>
            <g id="prefix__Group_841" transform="translate(51.977 181.967)">
              <path
                id="prefix__Path_1353"
                className="prefix__st19"
                d="M-75.7-177.9l-.3 1.5c.1 0 6.6.8 6.4-.4-.2-1-.4-2.1-.4-2.1l-5.7 1z"
              />
            </g>
            <g id="prefix__Group_842" transform="translate(65.176 183.424)">
              <path
                id="prefix__Path_1354"
                className="prefix__st20"
                d="M-67.1-178s-1.5 3-4.1 4c-2.7 1.1-4.9 2.3-4.9 4.2s6.7 3.6 11.5.2c3-2.2 4-2.2 4.5-3.6s-.8-5-.8-5.4c.1-.6-6.2.6-6.2.6z"
              />
            </g>
            <g id="prefix__Group_843" transform="translate(74.035 181.502)">
              <path
                id="prefix__Path_1355"
                className="prefix__st19"
                d="M-76-177.6c0 .5 0 1 .1 1.6.1 0 6.4.6 6.3-.6-.1-.7-.3-1.4-.6-2.1l-5.8 1.1z"
              />
            </g>
            <g id="prefix__Group_844" transform="translate(41.801 83.955)">
              <path
                id="prefix__Path_1356"
                className="prefix__st24"
                d="M-67.1-171.8s-5.9 26-7.3 34c-1.1 6.7-2.1 13.8-1.5 18.6.7 6 9.2 37.3 8.9 39.1-.4 1.8 10.2.7 10.2.7s-7.7-35.3-6.4-41.8c1-4.8 9.4-36.7 10.7-35.1s2.9 30.1 3.8 34.9 3.9 42.1 3.9 42.1 6.6 1 8.3-.5c0 0 .8-31.9.2-40.8-.2-3.4 4-40.6-1.4-55.5-2.9-7.9-29.4 4.3-29.4 4.3z"
              />
            </g>
            <g id="prefix__Group_845" transform="translate(12.175 32.238)">
              <path
                id="prefix__Path_1357"
                className="prefix__st23"
                d="M-76-150.3s20.4 4.5 24.5 3.5c1.9-.5 4.4-3.1 6.7-6.1.8-1 1.5-2.1 2.3-3.1 2.9-4.2 5.2-8.2 5.2-8.2s8.5-10 1.7-14.5c-1.8-.5-3.6.1-4.8 1.5-2.3 2.5-5 7.6-5.6 8.9s-9 13.4-9.4 13.7-4.1-.7-7-.9c-4-.5-8-1.2-12-2.3l-1.6 7.5z"
              />
            </g>
            <path
              id="prefix__Path_1358"
              d="M-17.1-148.6s-8.8 1.8-9.8 2.7c-1.1 1-3.1 10.6-3.3 13.3.2 6.1.9 12.1 2.2 18.1 1.1 4.2.9 24.7.2 30.5 0 0 17.7 5 35.1 2.8 0 0-2.9-21.6-2.2-27.3.9-7.3 3-24.2 3-24.2S6.6-144.5 5-145.8s-12.2-2.7-12.2-2.7l-9.9-.1z"
              fill="#bcc0cf"
            />
            <path
              id="prefix__Path_1359"
              className="prefix__st39"
              d="M-13.4-175.8s-10.6-3.9-9.9 10.1 3.1 15.5 10.1 13.5 7.5-10.8 8.4-15.1c.9-4.2-5.2-7.9-8.6-8.5z"
            />
            <path
              id="prefix__Path_1360"
              className="prefix__st39"
              d="M-18.5-146.7s2.2 1.4 10.4-2.2c-.1-3.7.4-7.4 1.4-10.9 1.6-5.3-11.1 7-11.1 7 .1.1.2.6.1 1.4-.1.7-.2 1.5-.4 2.2-.3.7-.4 1.6-.4 2.5z"
            />
            <path
              id="prefix__Path_1361"
              className="prefix__st24"
              d="M-6.3-160.3s0-3.8-1.5-4.1c-.9-.1-1.6-.7-1.8-1.6l-.9.2s.2-.7-1.3-1.5-10.4-.1-11.5-1.4.5-8.9 2.9-9.6c2.4-.7 8.1-.7 13.2.8 2.5.8 4.1 3 4.2 5.6.3 2.9-2 9.6-3.3 11.6z"
            />
            <path
              id="prefix__Path_1362"
              d="M-18-149.3c8.2-1.4 9.4-6 9.4-6.1-3.2 4.1-9 3.9-9 3.9-.1.8-.2 1.5-.4 2.2z"
              fill="#633029"
            />
            <path
              id="prefix__Path_1363"
              className="prefix__st44"
              d="M-17.7-144.9s-1.5 2.2-2.4 7.8c-.9 5.6-.7 15.4-.7 15.4l2.1.3s3.2-19.9 2.6-23.3l-1.6-.2z"
            />
            <path
              id="prefix__Path_1364"
              className="prefix__st39"
              d="M-9.6-166c.5-1.2 3.1-1.1 2.8.9s-2.5 5-3.3 4.4-.1-3.6.5-5.3z"
            />
            <path
              id="prefix__Path_1365"
              className="prefix__st23"
              d="M-27-145.9s-5 6.6-3.2 23.9c3.3 31.2-5.4 81.4-5.4 81.4l9 2.8s8.6-64.1 6.4-87.4c-1-10.6-4-14.1.7-23 .1.1-4.5.3-7.5 2.3z"
            />
            <path
              id="prefix__Path_1366"
              className="prefix__st23"
              d="M-4.7-148.3s-14.3 14.4-15.4 25.4S-23-37.8-23-37.8l34-2s-3.1-39.1-3.9-44-2.3-33 1.1-46.1c1.9-7-.4-15.9-4.6-16.9-4.2-.9-8.3-1.5-8.3-1.5z"
            />
            <path
              id="prefix__Path_1367"
              className="prefix__st23"
              d="M.1-146.4s-5.5 1.5-2.5 11.6c2.4 7.9 2.7 9.6 2.7 9.6l12.7-3.2s-3.7-17.4-9.8-18.3c-1-.1-2.1 0-3.1.3z"
            />
            <path
              id="prefix__Path_1368"
              className="prefix__st16"
              d="M-41.5-92.9l24.9 25.8L0-83.1l-24.3-26.4-17.2 16.6z"
            />
            <path
              id="prefix__Path_1369"
              className="prefix__st17"
              d="M-39.2-92.4l22.1 22.9 14.7-14.2-21.5-23.4-15.3 14.7z"
            />
            <path
              id="prefix__Path_1370"
              d="M-34.1-96.2l-1.5-1.6 5.9-5.7 1.8 1.4-6.2 5.9z"
              fill="#616161"
            />
            <path
              id="prefix__Path_1371"
              className="prefix__st46"
              d="M-8-150.5c-2.7 1.6-5.6 2.6-8.6 3.2l2.3 3.6 7.2-4.9-.9-1.9z"
            />
            <path
              id="prefix__Path_1372"
              className="prefix__st46"
              d="M-18-149.3c.2.8.7 1.5 1.4 2l-2.1 3 .1-4 .6-1z"
            />
            <path
              id="prefix__Path_1373"
              className="prefix__st44"
              d="M-16.6-147.3l-1.7 2.3s1.1 1.4 3.4.3l-1.7-2.6z"
            />
            <path
              id="prefix__Path_1374"
              className="prefix__st46"
              d="M-32.6-120.6c.8-1 1.5-2.1 2.3-3.1 0 0-.3-7.6 0-9.8-.1 0-2.5 8.2-2.3 12.9z"
            />
            <path
              id="prefix__Path_1375"
              className="prefix__st46"
              d="M2.5-125.7s1.7 10.8 1.5 11.5c-.1.7 7.6-2.2-1.5-11.5z"
            />
            <path
              id="prefix__Rectangle_161"
              fill="#c6cad9"
              d="M-27.5-129.6h4v6.1h-4z"
            />
            <path
              id="prefix__Path_1376"
              className="prefix__st39"
              d="M-9.6-100.2c-.2.4-.6.8-1 1-1.1.4-2.7-.3-3.4 1.3-.7 1.5 3.6 5.1 4.6 5.2s2.5-1.5 3.5-4.9c1-3.3-3.7-2.6-3.7-2.6z"
            />
            <path
              id="prefix__Path_1377"
              className="prefix__st39"
              d="M-13.7-98.4c-1.3 1.2-2.5 2.4-3.6 3.7-1.6 1.8-3.1 4.2-2.8 4.7.6 1 2.1-1.2 3.7-2.9l1.3-1.3s0-.2-1.4 1.9c-1.5 2.2-2.7 4.6-2.4 5.1.2.3.8.9 1.2-.1-.3.5-.3.4-.1.5 1 .8 3.6-2.1 4.7-3.2-.8.9-1.3 1.6-.4 1.7 1 .1 4.6-4.1 6-5.5 1.5-1.7-6.2-4.6-6.2-4.6z"
            />
            <path
              id="prefix__Path_1378"
              className="prefix__st23"
              d="M-.5-145.7c-1.2.4-1.6 2.5-1.1 8.4.2 3.6 1.3 7 3.3 10 .8 1.4 5.1 9.8 4.3 10.9-.8 1.1-8 8.3-8.5 8.9s-8.5 7.9-8.5 7.9l4.1 5.8s17.5-13.7 18.9-15.1c1.4-1.4 5.5-4.2 4-9-1.5-4.9-4.3-16.2-4.8-17.1S7.1-148.7-.5-145.7z"
            />
          </g>
          <g id="prefix__Group_849">
            <g id="prefix__Group_848">
              <defs>
                <path id="prefix__SVGID_9_" d="M-75.8-178.6h-.2l.2-.2z" />
              </defs>
              <clipPath id="prefix__SVGID_10_">
                <use xlinkHref="#prefix__SVGID_9_" overflow="visible" />
              </clipPath>
              <g id="prefix__Group_847" clipPath="url(#prefix__SVGID_10_)">
                <linearGradient
                  id="prefix__Path_1379_1_"
                  gradientUnits="userSpaceOnUse"
                  x1={-726.58}
                  y1={496.684}
                  x2={-726.496}
                  y2={505.079}
                  gradientTransform="matrix(16.828 0 0 -8.161 12550.87 4188.604)"
                >
                  <stop offset={0} stopColor="#fff" />
                  <stop offset={1} />
                </linearGradient>
                <path
                  id="prefix__Path_1379"
                  d="M322.9-25h12.9l-3.9-8.2H319l3.9 8.2z"
                  fill="url(#prefix__Path_1379_1_)"
                />
              </g>
            </g>
          </g>
          <g id="prefix__Group_852">
            <g id="prefix__Group_851">
              <defs>
                <path id="prefix__SVGID_11_" d="M-75.8-178.6h-.2l.2-.2z" />
              </defs>
              <clipPath id="prefix__SVGID_12_">
                <use xlinkHref="#prefix__SVGID_11_" overflow="visible" />
              </clipPath>
              <g id="prefix__Group_850" clipPath="url(#prefix__SVGID_12_)">
                <linearGradient
                  id="prefix__Path_1382_1_"
                  gradientUnits="userSpaceOnUse"
                  x1={-669.395}
                  y1={496.719}
                  x2={-669.193}
                  y2={505.114}
                  gradientTransform="matrix(7.019 0 0 -8.161 5011.553 4188.604)"
                >
                  <stop offset={0} stopColor="#fff" />
                  <stop offset={1} />
                </linearGradient>
                <path
                  id="prefix__Path_1382"
                  d="M316.9-25h3.1l-4-8.2h-3.1l4 8.2z"
                  fill="url(#prefix__Path_1382_1_)"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="prefix__Group_1473" transform="translate(419.012 605.765)">
          <g
            id="prefix__Group_1467"
            transform="translate(47.48 55.28)"
            opacity={0.6}
          >
            <g id="prefix__Group_1464" transform="translate(38.296 55.101)">
              <path
                id="prefix__Path_1938"
                className="prefix__st16"
                d="M-71.7-119.9c.6 0 1-.4 1-.9l-1.3-57.5-3.9-.5 3.3 58c0 .5.4.8.9.9z"
              />
            </g>
            <g id="prefix__Group_1465" transform="translate(61.077 55.101)">
              <path
                id="prefix__Path_1939"
                className="prefix__st16"
                d="M-71.7-119.9c.6 0 1-.4 1-.9l-1.3-57.5-3.9-.5 3.3 58c0 .5.4.8.9.9z"
              />
            </g>
            <g id="prefix__Group_1466" transform="translate(1.554 55.101)">
              <path
                id="prefix__Path_1940"
                className="prefix__st16"
                d="M-75-119.9c-.6 0-1-.4-1-.9l1.3-57.5 3.9-.5-3.3 58c0 .5-.4.9-.9.9z"
              />
            </g>
            <path
              id="prefix__Path_1941"
              className="prefix__st16"
              d="M-8-126.8v-39.4c0-6.9-5.6-12.5-12.5-12.6H-49c-6.9 0-12.5 5.6-12.6 12.5v40.9l53.6-1.4z"
            />
            <path
              id="prefix__Path_1942"
              className="prefix__st16"
              d="M-66.6-120.6h54.8c2.1 0 3.8-1.7 3.8-3.8v-2.5h-58.6v6.3z"
            />
            <path
              id="prefix__Path_1943"
              className="prefix__st16"
              d="M-73.2-120.6h41.5c1.5 0 2.8-1.3 2.8-2.8v-.6c0-1.5-1.3-2.8-2.8-2.8h-41.5c-1.5 0-2.8 1.3-2.8 2.8v.6c0 1.5 1.3 2.8 2.8 2.8z"
            />
          </g>
          <g id="prefix__Group_1472">
            <path
              id="prefix__Path_1944"
              className="prefix__st53"
              d="M-56.8-32.5c-.4 2-3.2 1.6-6.4.9-3.2-.8-2.4.1-6.9-.4s-5.9-2.2-6-2.8c-.1-.6 2.1-.8 4.5-1.1.3 0 .6-.1.9-.1 2.7-.3 4.7-2.1 7.1-3.5 2.3-1.3 5.8-6.8 5.8-6.8l6.2.5s-3.2 4.9-4.5 7.1c-.6 1.3-.8 2.8-.6 4.2.1.6.1 1.3-.1 2z"
            />
            <path
              id="prefix__Path_1945"
              className="prefix__st54"
              d="M-63.1-31.6c-3.2-.8-2.4.1-6.9-.4s-5.9-2.2-6-2.8c-.1-.6 2.1-.8 4.5-1.1 1.9 1.2 4.1 1.9 6.4 2 3 .1 6.3-.8 8.4-.8.1.7.1 1.4 0 2.1-.5 2.1-3.3 1.7-6.4 1z"
            />
            <path
              id="prefix__Path_1946"
              className="prefix__st53"
              d="M-3.8-23.6c1.2 1.6-.9 3.5-3.6 5.4s-1.5 1.9-4.8 4.9-5.5 2.9-6.1 2.6c-.5-.3.8-2.1 2.2-4.1.2-.2.3-.5.5-.7 1.5-2.2 1.5-4.9 2.1-7.6.5-2.6-3.8-12.3-3.8-12.3l6.9-.9c.4 2.8 1 5.5 1.7 8.2.5 1.4 2.4 2.3 3.4 3.1.5.3 1 .8 1.5 1.4z"
            />
            <path
              id="prefix__Path_1947"
              className="prefix__st54"
              d="M-7.4-18.3c-2.7 1.9-1.5 1.9-4.8 4.9s-5.5 2.9-6.1 2.6c-.5-.3.8-2.1 2.2-4.1 2.2-.6 4.2-1.8 5.8-3.5 2.1-2.2 3.6-5.2 5-6.8.6.4 1.1.9 1.6 1.4 1.1 1.8-1 3.7-3.7 5.5z"
            />
            <path
              id="prefix__Path_1948"
              d="M-32-73l6.3 16.9 8.2 22.1c1.5.1 2.9 0 4.4-.4 1.5-.5 2.9-1.2 4.2-2.1 0 0-2.3-23.7-6.5-37.4-1.1-3.5-2.3-6.3-3.7-7.9C-25.7-89.5-32-73-32-73z"
              fill="#263c5c"
            />
            <path
              id="prefix__Path_1949"
              d="M-25.6-99.8c4.1-1.3 16.5-.5 28.4 1.8l1.8-3.2 25.5-2s8.4 18.7 3.8 30.6c-.6 1.8-2.4 3-4.3 2.9H9.1c-12.7 0-26.8-8.7-28.7-9.1-3-.6-4.2 5.4-7.2 11.7S-55-39.3-55-39.3c-1.8 0-3.7-.1-5.5-.6-.9-.3-1.7-.8-2.3-1.5 0 .1 27.9-55.5 37.2-58.4z"
              fill="#32353b"
            />
            <path
              id="prefix__Path_1950"
              className="prefix__st16"
              d="M13.5-178.4c3.6-.9 8.5.1 9.3.2 9.7 1.1 11.9 16.2 11.1 20.4-1.3 7.7 2.4 9.9 6.8 16.1 8.9 12.4 2.7 22.7-4.5 25.2-4.4 1.5-10.3-2.6-10.3-2.6-.1 0-15.4-14.6-23-21.7-12.6-5.2 1.5-35.5 10.6-37.6z"
            />
            <path
              id="prefix__Path_1951"
              className="prefix__st53"
              d="M11.4-143s3.9 2.6 7.2 2.1c3.1-.5 6.3-4.5 6.3-4.5-1.2-.4-2.3-1.8-1.6-9.3h-.6l-9.8.9s-.1 3-.4 5.9c-.1 2.5-.5 4.7-1.1 4.9z"
            />
            <path
              id="prefix__Path_1952"
              d="M13-153.7s-.1 3-.4 5.9c5.4.4 8.7-4.2 10.1-6.8l-9.7.9z"
              fill="#ed975d"
            />
            <path
              id="prefix__Path_1953"
              className="prefix__st53"
              d="M10.9-151.2s12.7 1.5 13.5-6.4 3-13-5.1-14.3c-8.1-1.3-9.9 1.6-10.9 4.2s-.8 15.9 2.5 16.5z"
            />
            <path
              id="prefix__Path_1954"
              className="prefix__st16"
              d="M10-171.8s6.1 8.7 11.3 10.7 7.7.7 7.7.7c-1.6-2.4-2.8-5.2-3.3-8.1-.2-1.4-1.2-2.5-2.5-3-3.8-1.4-11-3.4-13.2-.3z"
            />
            <path
              id="prefix__Path_1955"
              className="prefix__st16"
              d="M15-171.2c-1.7.9-3.2 2.1-4.6 3.5-1.5 2.3-2.3 5-2.4 7.8 0 0-2.1-9 1.2-12.3 3.6-3.4 5.8 1 5.8 1z"
            />
            <path
              id="prefix__Path_1956"
              className="prefix__st58"
              d="M1.7-122c.4 25.6.1 20.2 2.9 22.7.7.6 2.6.9 5.1 1.1 7.8.5 21.6-.8 22.2-2.2 1.8-5 .5-6.6 2-14.2.2-.8.4-1.7.6-2.7 1.3-5.3 2.5-7.1-2-15.5-4-7.7-8.4-13.8-8.8-13.6-10.7 6.3-11.4.9-11.4.9S1.5-134.5 1.7-122z"
            />
            <path
              id="prefix__Path_1957"
              d="M-25.7-56l8.4 23c1.7.5 3.4.8 5.2.7 1.3-.2 2.5-.7 3.5-1.6 0 0-2.6-26.2-6.8-39.8L-25.7-56z"
              fill="#333841"
            />
            <path
              id="prefix__Path_1958"
              className="prefix__st58"
              d="M12.3-145.6S1.3-143.7 0-139s-10.7 30.8-10.7 30.8 7.4 2.7 10 .2 9-24 9-24l4-13.6z"
            />
            <path
              id="prefix__Path_1959"
              d="M9.7-98.2c7.8.5 21.6-.8 22.2-2.2 1.8-5 .5-6.6 2-14.2l-3.7-8.4s.5 9.6-3 12.7c-3.5 3.1-14.3 6-16 8.6-.8 1-1.3 2.2-1.5 3.5z"
              fill="#dfebfd"
            />
            <g id="prefix__Group_1468" transform="translate(79.586 72.684)">
              <path
                id="prefix__Path_1960"
                className="prefix__st53"
                d="M-70.6-176.4c-.9.8-1.9 1.6-3 2.2-.3.1-.7.1-1.1 0l1.7-2 2.4-.2z"
              />
              <path
                id="prefix__Path_1961"
                className="prefix__st53"
                d="M-69.6-178.8l-5.9 1.5c.2.3.5.5.8.5.8 0 1.6 0 2.5-.2l2.6-1.8z"
              />
              <path
                id="prefix__Path_1962"
                className="prefix__st53"
                d="M-59.6-175.5s-8.4-3.8-10-3.3-5.6 2.7-6.3 3.6 3.3-.1 4.6-.2c.8 0 2.7 1.1 3.6 1.6 3.9 2.2 6.6 2 6.6 2l1.5-3.7z"
              />
            </g>
            <path
              id="prefix__Path_1963"
              className="prefix__st58"
              d="M23.7-146.5s12 2.2 14.2 5.5c4.8 7.2 15.1 37.2 9.3 41.2-6.5 4.5-29.5 1.2-29.5 1.2s-.8-3.6.3-5.3 18.4-4.5 19.2-5.3c.8-.8-7.9-12.4-7-14.6 3-7.1-6.5-22.7-6.5-22.7z"
            />
            <g id="prefix__Group_1469" transform="translate(39.831 74.691)">
              <path
                id="prefix__Path_1964"
                className="prefix__st53"
                d="M-63.4-178.8s-8.4 2.2-9.2 3.6c-.9 1.3-2.5 5.3-2.5 6.3.1 1 2.3-2.1 3.2-2.8.6-.5 2.6-.8 3.5-1 1.7-.3 3.3-1 4.6-2.1.4-.2.9-.3 1.4-.3l-1-3.7z"
              />
              <path
                id="prefix__Path_1965"
                className="prefix__st53"
                d="M-71.9-173c-.2 1.1-.5 2.3-.9 3.3-.2.3-.4.5-.7.6v-2.5l1.6-1.4z"
              />
              <path
                id="prefix__Path_1966"
                className="prefix__st53"
                d="M-72.6-175.2l-3.4 4.6c.3.1.6.1.9-.1.6-.5 1.2-1 1.7-1.6l.8-2.9z"
              />
              <path
                id="prefix__Path_1967"
                className="prefix__st53"
                d="M-63.7-174.8c-.5 1.1-1.3 2-2.3 2.6-1.6.7-2.7 1.7-2.9 1.3-.2-.4 1.2-2.3 1.2-2.3l4-1.6z"
              />
            </g>
            <path
              id="prefix__Path_1968"
              className="prefix__st58"
              d="M-8.5-114.4l-17.6 11.1s1.8 2.5 2.5 2.7c3.3 1.2 17.9 0 22.9-7.5l-7.8-6.3z"
            />
          </g>
        </g>
        <g id="prefix__Group_386" transform="translate(443.93 654.672)">
          <path
            id="prefix__Path_531"
            className="prefix__st5"
            d="M-12.9-147h-33.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5H-13c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.4 1.5z"
          />
          <path
            id="prefix__Path_532"
            className="prefix__st16"
            d="M-76.8-177.4h28c2.1 0 3.9 1.2 4.8 3.1l12.9 27.4h-34.8c-1.3 0-2.5-.8-3-2l-10-25.4c-.4-1.2.2-2.5 1.4-2.9.2-.2.5-.2.7-.2z"
          />
          <path
            id="prefix__Path_533"
            className="prefix__st5"
            d="M-57-163.4c.6 1.9.2 3.7-1 4.1-1.2.4-2.6-.9-3.2-2.7-.6-1.9-.2-3.7 1-4.1 1.1-.4 2.5.8 3.2 2.7z"
          />
        </g>
      </g>
    </svg>
  )
}

export default LoginSvgComponent
