import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loginDetails:{loading:false,data:{},status:false}
}

export const loginReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.LOGIN_INIT:
            return{
                ...state,
                loginDetails:{...state.loginDetails , data:{} , status:false , loading:true}
            }
        case actionTypes.LOGIN_SUCCESS:
            return{
                ...state,
                loginDetails:{...state.loginDetails , data:action.response , status:action.response.user.paymentStatus , loading:false}
            }
        default:
            return state
    }
} 