import * as actionTypes from "./actionTypes";
import {invoiceInput} from "./queryObjects";
import {apiCall} from "../../utils/utilFunctions";
import Globals from "../../Globals";
import {toast} from 'react-toastify'

export const dispatchInit = () => {
  return {
    type: actionTypes.MYINVOICE_INIT
  };
};
export const dispatchSuccess = (result) => {
  return {
    type: actionTypes.MYINVOICE_SUCCESS,
    response: result.data.getUserDetail.user
  };
};

export const myInvoice = (props) => async (dispatch) => {
    dispatch(dispatchInit());
    const requestData = invoiceInput()
    const apiResponse = await apiCall(Globals.API_ROOT_URL,requestData)
    if (apiResponse.data.getUserDetail!==null){
      dispatch(dispatchSuccess(apiResponse));  
    }
    else{
      props.history.push('/login')
      toast.error(apiResponse.errors[0].message)
    }
};

