function YoutubeIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44.12"
        height="44.12"
        viewBox="0 0 44.12 44.12"
      >
        <g data-name="Group 6574" transform="translate(-641.368 -812.44)">
          <path
            fill="none"
            stroke="#87a878"
            strokeWidth="1"
            d="M663.428 812.94a21.56 21.56 0 1021.56 21.56 21.582 21.582 0 00-21.56-21.56z"
            data-name="Path 3394"
          ></path>
          <path
            fill="#87a878"
            d="M23.53-3.45a3.01 3.01 0 00-2.117-2.118c-1.88-.514-9.4-.514-9.4-.514s-7.52 0-9.4.495A3.071 3.071 0 00.495-3.45 31.715 31.715 0 000 2.329a31.6 31.6 0 00.495 5.778 3.01 3.01 0 002.118 2.118c1.9.515 9.4.515 9.4.515s7.52 0 9.4-.495a3.01 3.01 0 002.117-2.118 31.725 31.725 0 00.495-5.779 30.1 30.1 0 00-.495-5.798zM9.618 5.93v-7.2l6.253 3.6zm0 0"
            transform="translate(651.766 833.522)"
          ></path>
        </g>
      </svg>
    );
  }
  
  export default YoutubeIcon;
  