function InstagramIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44.12"
        height="44.12"
        viewBox="0 0 44.12 44.12"
      >
        <g data-name="Group 6573" transform="translate(-587.001 -812.44)">
          <path
            fill="none"
            stroke="#87a878"
            strokeWidth="1"
            d="M609.061 812.94a21.56 21.56 0 1021.56 21.56 21.582 21.582 0 00-21.56-21.56z"
            data-name="Path 3393"
          ></path>
          <g fill="#87a878" transform="translate(599.551 825.44)">
            <path
              d="M19.219 5.534a6.877 6.877 0 00-.437-2.283A4.821 4.821 0 0016.028.5a6.9 6.9 0 00-2.283-.441C12.737.011 12.417 0 9.862 0S6.987.011 5.983.055A6.879 6.879 0 003.7.493a4.592 4.592 0 00-1.67 1.088A4.634 4.634 0 00.946 3.247 6.9 6.9 0 00.508 5.53C.46 6.537.449 6.857.449 9.413S.46 12.288.5 13.292a6.877 6.877 0 00.438 2.283A4.821 4.821 0 003.7 18.329a6.9 6.9 0 002.283.437c1 .044 1.324.055 3.879.055s2.875-.011 3.879-.055a6.876 6.876 0 002.283-.437 4.814 4.814 0 002.754-2.754 6.9 6.9 0 00.437-2.283c.044-1 .055-1.324.055-3.879s-.007-2.876-.051-3.879zm-1.695 7.685a5.159 5.159 0 01-.324 1.746 3.122 3.122 0 01-1.787 1.787 5.177 5.177 0 01-1.746.323c-.993.044-1.29.055-3.8.055s-2.813-.011-3.8-.055a5.156 5.156 0 01-1.746-.323 2.9 2.9 0 01-1.081-.7 2.926 2.926 0 01-.7-1.081 5.178 5.178 0 01-.323-1.746c-.044-.993-.055-1.291-.055-3.8s.011-2.813.055-3.8a5.156 5.156 0 01.323-1.746 2.861 2.861 0 01.7-1.088 2.922 2.922 0 011.081-.7 5.181 5.181 0 011.746-.324c.993-.044 1.291-.055 3.8-.055s2.813.011 3.8.055a5.159 5.159 0 011.746.324 2.894 2.894 0 011.081.7 2.926 2.926 0 01.7 1.081 5.18 5.18 0 01.324 1.746c.044.993.055 1.29.055 3.8s-.005 2.807-.049 3.8zm0 0"
              data-name="Path 3378"
            ></path>
            <path
              d="M129.784 124.5a4.835 4.835 0 104.835 4.835 4.836 4.836 0 00-4.835-4.835zm0 7.971a3.136 3.136 0 113.136-3.136 3.137 3.137 0 01-3.136 3.136zm0 0"
              data-name="Path 3379"
              transform="translate(-119.922 -119.922)"
            ></path>
            <path
              d="M364.707 89.73a1.129 1.129 0 11-1.129-1.129 1.129 1.129 0 011.129 1.129zm0 0"
              data-name="Path 3380"
              transform="translate(-348.69 -85.344)"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
  
  export default InstagramIcon;