import React, { useEffect,useState } from "react";
import { Form, Header } from "semantic-ui-react";
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './checkoutForm'
import {CardElement,Elements,useStripe,useElements} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  
const DesktopRightSection = (props) => {
const [cardname, setcardName] = useState("");
const [email, setemail] = useState("");

useEffect(() => {
  if ( props.data !== undefined ){
    setemail(props.data.email)
  }
  else if(props.data !== undefined && props.data.name !== undefined ){
      setemail(props.data.name) 
  }
}, [props.data]);
  
  const handlePaymentChange = (e, name) => {
    if (name === "email") {
      setemail(e.target.value);
    }
    if (name === "cardname") {
      if (/^[A-Z a-z \s]+$/g.test(e.target.value) || e.target.value==="" ){
        setcardName(e.target.value);
      }
    }
  };
  return (
    <Form>
      <div className="right-section">
        <div className="Payment-header">
          <Header as="h2">Payment</Header>
        </div>
        <div className="fieldrow">
          <Form.Input
            className="Inputfield email fieldrow"
            fluid
            disabled
            name="email"
            value = {email}
            onChange={(e) => handlePaymentChange(e, "email")}
            id="form-subcomponent-shorthand-input-email"
            label="Email"
            placeholder="Enter email"
          />
          <Form.Input
            className="Inputfield cardname fieldrow"
            fluid
            name="cardname"
            value = {cardname}
            onChange={(e) => handlePaymentChange(e, "cardname")}
            id="form-subcomponent-shorthand-input-cardname"
            label="Name on Card"
            placeholder="Enter name"
          />

          <Form.Field required>
          <label>Card Information</label>
            <Elements stripe={stripePromise}>
              <CheckoutForm 
              price= {props.price}
              quantity={props.quantity}
              data={props.data}
              />
            </Elements>
          </Form.Field>
        </div>
      </div>
      </Form>
  );
};
export default DesktopRightSection;
