import { Button,Input } from "semantic-ui-react";
const SeatsQuantity = ({ onAdd, onRemove, quantity }) => {
  return (
    <>
      <Button disabled={quantity===1} onClick={onRemove}>–</Button>
      <Input type="text" value={quantity} readOnly />
      <Button disabled={quantity===100} onClick={onAdd}>+</Button>
    </>
  );
};

export default SeatsQuantity;
