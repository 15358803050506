import React, { Component } from 'react'
import ForgotPassword from './ForgotPassword';
import {forgotPassword,dispatchFailure} from '../../redux/actions/forgotActions'
import { connect } from "react-redux";

class ForgotIndex extends Component {
    constructor(props){
        super(props);
        this.state = {
            flag:''
          };
    }
    componentDidMount(){
      // window.history.pushState(null, document.title, window.location.href);
      //   window.addEventListener('popstate', function (event){
      //       window.history.pushState(null, document.title,  window.location.href);
      //   });
      this.props.dispatchFailure()
        window.scrollTo(0, 0);
    }

    finalForgotSubmit =async (values) =>{                   //Passing user data
        this.props.forgotPassword(values)
      }

    render() {
        return (
        <ForgotPassword
        handleForgotSubmit = {this.finalForgotSubmit}
        data = {this.props.forgotData.forgotDetails.data}
        fail = {this.props.dispatchFailure}
        loading = {this.props.forgotData.forgotDetails.loading}
        />
        )
    }
}
const mapStateToProps = (state) => ({
  forgotData:state.forgot
});
export default connect(mapStateToProps,{forgotPassword,dispatchFailure})(ForgotIndex)