import * as actionTypes from '../actions/actionTypes';

const initialState = {
    myInvoiceDetails:{loading:false,data:{}}
}

export const myInvoiceReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.MYINVOICE_INIT:
            return{
                ...state,
                myInvoiceDetails:{...state.myInvoiceDetails,data:{},loading:true}
            }
        case actionTypes.MYINVOICE_SUCCESS:
            return{
                ...state,
                myInvoiceDetails:{...state.myInvoiceDetails,data:action.response,loading:false}
            }
        default:
            return state
    }
} 