import * as actionTypes from "./actionTypes";
import {forgotInput} from "./queryObjects";
import {apiCall} from "../../utils/utilFunctions";
import Globals from "../../Globals";
import {toast} from 'react-toastify'

export const dispatchInit = () => {
  return {
    type: actionTypes.FORGOT_INIT
  };
};
export const dispatchSuccess = (result) => {
  return {
    type: actionTypes.FORGOT_SUCCESS,
    response: result.data.forgotPassword
  };
};

export const dispatchFailure = () => {
  return {
    type: actionTypes.FORGOT_FAILURE
  };
};

export const forgotPassword = (userData) => async (dispatch) => {
    dispatch(dispatchInit());
    const requestData = forgotInput(userData);
    const apiResponse = await apiCall(Globals.API_ROOT_URL, requestData);
    if (apiResponse.data.forgotPassword!==null){
        dispatch(dispatchSuccess(apiResponse));    
      }
      else{
        dispatch(dispatchFailure());
        toast.error(apiResponse.errors[0].message);       //Showing toastify error message
      }
};

