import React, { Component } from 'react'
import Invoice from './invoice'
import {userInvoice} from '../../redux/actions/invoiceActions'
import { connect } from "react-redux";
import {myInvoice} from '../../redux/actions/myInvoiceActions'

class InvoiceIndex extends Component {
    constructor(props){
        super(props);
        this.state = {
            price:100,
            quantity:1
          };
    }
  
    componentDidMount(){
      // window.history.pushState(null, document.title, window.location.href);
      // window.addEventListener('popstate',   function (event) {
      //   window.history.pushState(null, document.title, window.location.href);
      // });
        window.scrollTo(0, 0);
      this.props.myInvoice(this.props)
    }

    finalInvoiceSubmit =async (values) =>{              //Passing values
      this.props.userInvoice(values, this.props)
      }

      static getDerivedStateFromProps(nextProps){
        if (Object.keys(nextProps.invoiceData.invoiceDetails.data).length > 0) {
          nextProps.history.push('/thankyou')
          nextProps.invoiceData.invoiceDetails.data={}
          return true;
        }
        else return null;
      }

    render() {
        return (
        <Invoice
            price={localStorage.getItem('price')}
            quantity={localStorage.getItem('quantity')}
            handleInvoiceSubmit={this.finalInvoiceSubmit}
            data = {this.props.myInvoiceData.myInvoiceDetails.data}
            loading = {this.props.invoiceData.invoiceDetails.loading}
            />
        )
    }
}
const mapStateToProps = (state) => ({
  invoiceData:state.invoice,
  myInvoiceData:state.myInvoice
});
export default connect(mapStateToProps,{userInvoice,myInvoice})(InvoiceIndex)