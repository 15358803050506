import React,{useState} from "react";
import { Button, Form, Header, Dimmer, Loader, Segment,Message } from "semantic-ui-react";
import ForgotpasswordSvgComponent from '../../img/Forgotpassword'
import {Link} from 'react-router-dom'

const ForgotPassword = (props) => {
  const [email, setemail] = useState("");
  const [error, seterror] = useState({});

  const removeData = () =>{
    props.fail()
  }
  const renderFieldError = (errors = []) => {
    //showing error message
    if (errors[0] !== null) {
      return (
        <Message
          style={{ display: "block" }}
          compact
          attached="bottom"
          error
          list={errors}
        />
      );
    } else {
      return null;
    }
  };

  const handleForgotSubmit = (e) => {
    e.preventDefault();
    const obj = {
     email: email,       
    };
    if (Object.values(error).every((o) => o==="")) {
      props.handleForgotSubmit(obj);    //Passing email id
    }   
  };

 const handleForgotChange = (e, name) => {
  if (name === "email") {
    setemail(e.target.value);
    if (email!==null && !/\S+@\S+\.\S+/.test(e.target.value)) {
      seterror({ ...error, email: "Please enter valid email address" });
    }
    else{
      seterror({...error,email:""})
      }
  }  
  };
  return (
    <Segment>
      {props.loading===true?
      <Dimmer active>
        <Loader size='medium'>Loading</Loader>
        {document.body.classList.add("loader-scroll")}
      </Dimmer>
      :(document.body.classList.remove("loader-scroll"))}
      <Form onSubmit={(e)=>handleForgotSubmit(e)}>
      <main id="Forgot-password-page">
        <section id="ForgotPassword">
          <div className="ForgotPassword-wrapper container">
            <div className="forgot-password-header">
              <Header as="h2">Forgotten your password?</Header>
            </div>
            <div className="forgot-password-sub-para">
              <p>
                Please enter the email address you used when creating your
                account
              </p>
            </div>
            {Object.keys(props.data).length===0 && <div id="email-box"className="toggle-section">
                <div className="fieldrow">
                <Form.Input
                    className="Inputfield email"
                    required
                    fluid
                    name="email"
                    value={email}
                    onChange={(e) => handleForgotChange(e, "email")}
                    id="form-subcomponent-shorthand-input-email"
                    label="Email"
                    placeholder="Enter email"
                    error={!!error.email}
                />
                <div className="Email-validation validation_msg">
                  {renderFieldError([error.email || null])}
                </div>
                </div>
                <div className="Submitbtn">
                    <Button disabled = {(email==="") || (!(Object.values(error).every((o) => o==="")))} type="submit">Submit</Button>
                </div>
            </div>}
            {/*Message box starts*/}
            {Object.keys(props.data).length>0 && <div id ="box" class="ui icon message">
            <i class="info circle icon"></i>
                <div class="content">
                    <p className="content-inner">
                    Please check your inbox.
                    </p>
                    <p className="content-inner">We have sent you an email to <span className="span-text">{email}</span> with instructions for resetting your password. For any queries,
                        please reach out to us on <span className="span-text">info@o2h.com</span>
                    </p>
                </div>
            </div>}
            {/*Message box ends*/}

            <div className="go-back-link-wrapper">
              <div className="go-back-link">
                <p>
                  Go back to 
                  <Link to='/login' onClick ={()=>removeData()} className="login-link">
                  Login
                  </Link>
                </p>
              </div>
            </div>
            <div className="ForgotpasswordSvg">
                <ForgotpasswordSvgComponent/>
            </div>
          </div>
        </section>
      </main>
    </Form>
    </Segment>
  )
};
export default ForgotPassword;