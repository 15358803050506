import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch,Redirect} from 'react-router-dom';
import index from '../components/Signup/index';
import Index from '../components/Login/index';
import PaymentIndex from '../components/Payment/index';
import ForgotIndex from '../components/ForgotPassword/index';
import ResetIndex from '../components/ResetPassword/index';
import InvoiceIndex from '../components/Invoice/index';
import Thankyou from '../components/Thankyou/thankyou'
import history from './history'
import {ToastContainer} from 'react-toastify';
import TermsOfService from '../components/TermsOfService/termsofservice'
import 'react-toastify/dist/ReactToastify.css';

class AppRoute extends Component {
    render() {
        const AuthenticatedRoute = ({component:Component, ...rest}) =>(
            <Route {...rest}
            render = {props =>
            localStorage.getItem("token") ? (
                <Component {...props}/>
                ):(
                    <Redirect
                    to={{
                        pathname: "/login",
                        state:{from: props.location}
                    }}
                    />
            )}
            />
        )
        return (
            <Router history={history}>
                <ToastContainer pauseOnFocusLoss={false}/>
                <Switch>
                    <Route path="/" exact component={index} />
                    <Route path="/login" component={Index} />
                    <Route path="/forgotpassword" component={ForgotIndex} />
                    <Route path="/resetpassword" component={ResetIndex} />
                    <AuthenticatedRoute path="/payment" component={PaymentIndex} />       
                    <AuthenticatedRoute path="/invoice" component={InvoiceIndex} />  
                    <AuthenticatedRoute path="/thankyou" component={Thankyou} /> 
                    <Route path='/termsofservice' component={TermsOfService} />                  
                </Switch>
            </Router>
        );
    }
}

export default AppRoute;
