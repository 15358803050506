import React from "react";
import ReactDOM from "react-dom";
import AppRoute from './routes/routes'
import { Provider } from "react-redux";
import store from "./store";
import 'semantic-ui-css/semantic.min.css'
import '../src/scss/main.scss';

ReactDOM.render(
<Provider store={store}>
  <AppRoute />
</Provider>, 
document.getElementById('root')
);
