import React from 'react'
import {
  Grid,
  Responsive,
} from 'semantic-ui-react'
 import LeftSection from '../Signup/LeftSection'
 import DesktopRightSection from './DesktopRightsection'
 import MobileRightSection from './MobileRightSection'

const SignupForm = (props) => {         //middle function for passing data
  const mySubmit = (mydata) => {
    props.mySubmit(mydata)
  }
   return (
        <main id="signuppage">
            <section id="Signup">
                    <div className="signup-wrapper container">
                            {/* Desktop */}
                            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                                <Grid>
                                    <Grid.Row className="signup-section-grid">
                                        <Grid.Column width={7} className="Left-section-wrapper">
                                            <LeftSection/>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <DesktopRightSection
                                            handleSignupSubmit={mySubmit}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Responsive>
                            {/* Mobile */}
                            <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                                <div className="Mobile-section-wrapper">
                                    <div className="Left-section-wrapper">
                                        <LeftSection/>
                                    </div>
                                    <div className="Mobile-section">
                                        <MobileRightSection
                                        handleSignupSubmit={mySubmit}
                                        />
                                    </div>
                                </div>
                            </Responsive>
                    </div>
            </section>
        </main>
  )}
  export default SignupForm
