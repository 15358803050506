import { combineReducers } from 'redux';
import {signupReducer} from './signupReducer'
import {loginReducer} from './loginReducer'
import {forgotReducer} from './forgotReducer'
import {resetpasswordReducer} from './resetReducer'
import {invoiceReducer} from './invoiceReducer'
import {myInvoiceReducer} from './myInvoiceReducer'

export default combineReducers({
    signup: signupReducer,
    login: loginReducer,
    forgot: forgotReducer,
    resetpassword: resetpasswordReducer,
    invoice: invoiceReducer,
    myInvoice: myInvoiceReducer
})