import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Grid,
  Header,
  Message,
  Icon,
} from "semantic-ui-react";
import O2hSkiPark from "../../img/o2hskiparklogo";

const DesktopRightSection = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [password, setpassword] = useState("");
  const [terms, setterms] = useState(false);
  const [error, seterror] = useState({ firstname: "",
  lastname: "",
  email: "",
  company: "",
  contact: "",
  password: ""
});
  const [hidden, sethidden] = useState(true);

  const toggleShow = () => {
    //toggle visiblity of password
    sethidden(!hidden);
  };

  const renderFieldError = (errors = []) => {
    //showing error message
    if (errors.length === 2) {
      if (errors[0] !== null || errors[1] !== null) {
        return (
          <Message
            style={{ display: "block" }}
            compact
            attached="bottom"
            error
            list={errors}
          />
        );
      }
    } else if (errors[0] !== null) {
      return (
        <Message
          style={{ display: "block" }}
          compact
          attached="bottom"
          error
          list={errors}
        />
      );
    } else {
      return null;
    }
  };

  const onlyAlphabets = (value) => {
    if (!/^[a-zA-Z,.'-]+$/i.test(value)){
      return true;
    }
    else{
      return false
    }
  }

  const onlyAlphabetsAndNumbers  = (value) => {
    if (!/^[a-zA-Z0-9 ,.'-]+$/i.test(value)){
      return true;
    }
    else{
      return false
    }
  }

  const handleSignupSubmit = (e) => {
    e.preventDefault();
    const obj = {
      //User details
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      contact: contact,
      company: companyName,
    };
    if (Object.values(error).every((o) => o==="")) {
      props.handleSignupSubmit(obj);
    }
  };

  const handleSignupChange = (e, name) => {
    if (name === "firstName") {
      setFirstName(e.target.value);
      if (firstName!==null && onlyAlphabets(e.target.value)) {
        seterror({ ...error, firstname: "Please enter valid first name" });
      }
      else{
      seterror({ ...error,firstname: "" });
      }
    }
    if (name === "lastName") {
      setlastName(e.target.value);
      if (lastName!==null && onlyAlphabets(e.target.value)) {
        seterror({ ...error, lastname: "Please enter valid last name" });
      }
      else{
      seterror({...error,lastname:""})
      }
    }
    if (name === "email") {
      setemail(e.target.value);
      if (email!==null && !/\S+@\S+\.\S+/.test(e.target.value)) {
        seterror({ ...error, email: "Please enter valid email address" });
      }
      else{
        seterror({...error,email:""})
        }
    }
    if (name === "contact") {
       if (/^[\s\d + () # \s]*$/g.test(e.target.value)){
        setcontact(e.target.value);
        if ( ! ((/^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/.test(e.target.value)) || (/^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(e.target.value))) ) {
          seterror({ ...error, contact: "Please enter valid number" });
        }
        else{
          seterror({...error,contact:""})
        }
      }
    }
    if (name === "company") {
      setcompanyName(e.target.value);
      if (companyName!== null && onlyAlphabetsAndNumbers(e.target.value)) {
        seterror({ ...error, company: "Please enter valid Co. name" });
      }
      else{
        seterror({...error,company:""})
      }
    }
    if (name === "password") {
      setpassword(e.target.value);
      if (/\s/.test(e.target.value) || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/.test(e.target.value)) 
      {
        seterror({...error,password:"Password must contain atleast 8 characters, 1 number, 1 upperCase, 1 lowerCase and 1 Special character"});
      }
      else{
        seterror({...error,password:""})
      }
    }
    if (name === "terms") {
      setterms(!terms);
    }
  };

  return (
    <Form onSubmit={(e) => handleSignupSubmit(e)}>
      <div className="right-section">
        <div className="Sign-up-header">
          <Header as="h2">Sign Up</Header>
        </div>
        <Form.Group
          widths="equal"
          className={`fieldrow ${
            error.firstname || error.lastname ? "has_error" : null
          }`}
        >
          <Form.Input
            className="Inputfield"
            fluid
            required
            value={firstName}
            onChange={(e) => handleSignupChange(e, "firstName")}
            id="form-subcomponent-shorthand-input-first-name"
            label="First Name"
            placeholder="e.g. Peter"
            error={!!error.firstname}
          />
          <div className="firstname-validation validation_msg">
            {renderFieldError([error.firstname || null])}
          </div>

          <Form.Input
            className="Inputfield lastname"
            fluid
            required
            name="lastname"
            value={lastName}
            onChange={(e) => handleSignupChange(e, "lastName")}
            id="form-subcomponent-shorthand-input-last-name"
            label="Last Name"
            error={!!error.lastname}
            placeholder="e.g. Jackson"
          />
          <div className="Lastname-validation validation_msg">
            {renderFieldError([error.lastname || null])}
          </div>
        </Form.Group>

        <Form.Group
          widths="equal"
          className={`fieldrow ${
            error.contact || error.company ? "has_error" : null
          }`}
        >
          <Form.Input
            className="Inputfield"
            fluid
            required
            // type=''
            name="contact"
            value={contact}
            onChange={(e) => handleSignupChange(e, "contact")}
            id="form-subcomponent-shorthand-input-contact"
            label="Contact Number"
            placeholder="Enter contact number"
            error={!!error.contact}
          />
          <div className="Contact-validation validation_msg">
            {renderFieldError([error.contact || null])}
          </div>
          <Form.Input
            className="Inputfield"
            fluid
            required
            name="company"
            value={companyName}
            onChange={(e) => handleSignupChange(e, "company")}
            id="form-subcomponent-shorthand-input-company"
            label="Company Name"
            placeholder="e.g. Verizona"
            error={!!error.company}
          />
          <div className="Company-validation validation_msg">
            {renderFieldError([error.company || null])}
          </div>
        </Form.Group>

        <Form.Field
          required
          className={`Inputfield email fieldrow ${
            error.email ? "has_error" : null
          }`}
        >
          <label>Email</label>
          <Input
            fluid
            required
            className="Inputfield email fieldrow"
            name="email"
            value={email}
            onChange={(e) => handleSignupChange(e, "email")}
            id="form-subcomponent-shorthand-input-email"
            placeholder="e.g. your@email.com"
            error={!!error.email}
          />
          <div className="Email-validation validation_msg">
            {renderFieldError([error.email || null])}
          </div>
        </Form.Field>

        <Form.Field
          required
          className={`Inputfield create-password fieldrow ${
            error.password ? "password_has_error" : null
          }`}
        >
          <label>Create Password</label>
          <Input
            fluid
            required
            type={hidden ? "password" : "text"}
            className="encryptpwd"
            name="password"
            value={password}
            onChange={(e) => handleSignupChange(e, "password")}
            placeholder="Enter Password"
            error={!!error.password}
          />
          <Icon name={hidden?"eye slash":"eye icon"} onClick={() => toggleShow()} />
          <div className="password-validation validation_msg">
            {renderFieldError([error.password || null])}
          </div>
        </Form.Field>
        <div className="termscheckbox flex_view">
          <Form.Checkbox
            inline
            autoFocus
            value={terms}
            onChange={(data) => handleSignupChange(data, "terms")}
            error={!!error.terms}
            label={
              <label>
                Creating an account means you agree with our{" "}
                </label>
            }
          />
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="Terms-service-link"
            href="/termsofservice"
          >
            Terms of Service
          </a>
        </div>
        <Grid>
          <Grid.Row className="create-account-wrapper">
            <Grid.Column width={7}>
              <div className="Createaccountbtn">
                <Button disabled = {(!(terms)) || (firstName==="") || (lastName==="") || (email==="") || (contact==="") || (companyName==="") || (password==="") || (!(Object.values(error).every((o) => o==="")))} type="submit">Create Account</Button>
              </div>
            </Grid.Column>
            <Grid.Column width={3} className="or-text-grid-child">
              <div className="or-text">
                <span>OR</span>
              </div>
            </Grid.Column>
            <Grid.Column width={6}>
              <div className="Already-a-member">
                <p>
                  Already a member?
                  <Link to="/login" className="login-link">
                    Login
                  </Link>
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="powered-by-text">
          <Grid>
            <Grid.Row className="poweredBy-wrapper">
              <Grid.Column width={8}>
                <div className="powered-text">
                  <h3>Powered By </h3>
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div class="skiparklogo">
                  <O2hSkiPark />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </Form>
  );
};
export default DesktopRightSection;
