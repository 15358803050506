export async function readGraphQL(response){
  return await response.json()
}
export async function apiCall(url = "", data = {}) {
  var apiToken = null
  if (localStorage.getItem("token")===undefined || localStorage.getItem("token")===null){
    apiToken=null
  }
  else {
    apiToken = localStorage.getItem("token")
  }

    var apiDetail = {
      method: "POST",
      headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + apiToken
    }
    };
    if (Object.keys(data).length > 0) {
      let objToAdd = {
        body: JSON.stringify({
          query: data
        })
      };
      Object.assign(apiDetail, objToAdd);
    var response = await fetch(url, apiDetail);
    return await response.json()
  }
}