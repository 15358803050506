class Globals {
    constructor (props) {
        if(process.env.REACT_APP_SERVER === 'production'){
            this.API_ROOT_URL  = 'https://api.coworklabs.o2h.com/graphql';
          }
          else{
            this.API_ROOT_URL  = 'https://api.local.cowork.o2h.com/graphql';
          }
    }
  }
  
  export default new Globals()

  
  