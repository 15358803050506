export const registrationInput = (req) => {
return `mutation {
    createUser(input : {
    firstName:"${req["firstname"]}",
    lastName:"${req["lastname"]}",
    email:"${req["email"]}",
    password:"${req["password"]}",
    contactNumber:"${req["contact"]}",
    companyName:"${req["company"]}",
    }){
      user{
        lastName,
        email,
        firstName,
        companyName,
        contactNumber,
        id,
      }
      token
    }
    
    }`;
};

export const loginInput = (req) => {
  return `mutation {
    login(input: {
    email:"${req["email"]}",
    password:"${req["password"]}",
    }){
      message
      user{
        id,
        firstName,
        lastName,
        email,
        contactNumber,
        companyName,
        type,
        paymentStatus
      }
      token 
    }
  }`;
  };

  export const facilityPayment = (req) => {
    return `mutation {
    facilityPayment(input:{
      transactionId:"${req["transactionId"]}"
      status:"succeeded"
      amount:${req["amount"]}
    }){
      message
    }
  }`;
};

  export const forgotInput = (req) => {
    return `{
    forgotPassword(input:{
      email:"${req["email"]}"
    }){
      message
    }
  }`;
  };
  

  export const resetInput = (req) => {
    return `mutation {
    resetPassword(input:{
      password:"${req["password"]}",
      token:"${req["token"]}"
      userId:"${req["userId"]}"
    })
    {
      message
    }
  }`;
};

export const invoiceInput = () => {
  return`{
  getUserDetail{
    message
    user{
      firstName,
      lastName,
      email,
      companyName
    }
  }
}`;
};

export const generateInvoice = (req) => {
  return `{
  generateInvoice(input:{
    email:"${req["email"]}",
    name:"${req["name"]}"
    companyName:"${req["company"]}"
    country:"${req["country"]}"
    address1:"${req["address1"]}"
    address2:"${req["address2"]}"
    city:"${req["town"]}"
    state:"${req["state"]}"
    postalCode:"${req["postalcode"]}"
    noOfFacilities:${req["quantity"]}
    amount:${req["price"]}
  }){
    message
  }
}`;
};

export const logout = () => {
  return`{
  logout{
    message
  }
}`;
};

export const createIntent = (req) => {
  return`{
  createIntent(
    input:{
      amount:${req}
}){
  message
  clientSecret
}
}`;
};


export const facilitiesList = () => {
  return`{
  facilitiesList{
    message
    facilitieList{
     item,
      price
    }
  }
}`;
};