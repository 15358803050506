import React from "react";

function chaiIconsvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="317"
      height="211"
      viewBox="0 0 317 211"
    >
      <defs>
        <filter
          id="Rectangle_337"
          width="317"
          height="72"
          x="0"
          y="139"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="blur" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
        <filter
          id="Cutting_मसाला_चाय"
          width="172"
          height="43"
          x="73"
          y="157"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="6"></feOffset>
          <feGaussianBlur result="blur-2" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur-2" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g data-name="Group 7566" transform="translate(-121.521 -352)">
        <g data-name="Group 7562" transform="translate(141.547 352)">
          <g data-name="Group 7559">
            <g data-name="Group 7561">
              <path
                fill="#caca94"
                d="M0 0H64.481V6.846H0z"
                data-name="Rectangle 328"
                transform="translate(35.414 61.381)"
              ></path>
              <path
                fill="#cacc97"
                d="M0 0H7.577V10.648H0z"
                data-name="Rectangle 329"
                transform="translate(63.89 50.733)"
              ></path>
              <circle
                cx="6.099"
                cy="6.099"
                r="6.099"
                fill="#d6d79e"
                data-name="Ellipse 31"
                transform="translate(61.579 47.32)"
              ></circle>
              <path
                fill="#d6d79f"
                d="M1280.465 756.772h118.645l-26.76-16.483h-64.481z"
                data-name="Path 3810"
                transform="translate(-1272.455 -672.062)"
              ></path>
              <path
                fill="#caca93"
                d="M0 0H118.646V59.797H0z"
                data-name="Rectangle 332"
                transform="translate(8.01 84.71)"
              ></path>
              <rect
                width="8.009"
                height="14.302"
                fill="#88713e"
                data-name="Rectangle 333"
                rx="4"
                transform="translate(0 84.71)"
              ></rect>
              <rect
                width="8.009"
                height="14.302"
                fill="#88713e"
                data-name="Rectangle 334"
                rx="4"
                transform="rotate(-180 67.332 49.506)"
              ></rect>
              <path
                fill="none"
                stroke="#f1f0ec"
                strokeMiterlimit="10"
                strokeWidth="10"
                d="M4.515 84.71V52.688c0-25.3 19.815-45.817 44.257-45.817h37.63c24.442 0 44.257 20.513 44.257 45.817v32.023"
                data-name="Path 3811"
              ></path>
              <rect
                width="61.717"
                height="13.742"
                fill="#88713f"
                data-name="Rectangle 335"
                rx="6.871"
                transform="translate(38.177)"
              ></rect>
              <path
                fill="#d6d79f"
                d="M1729.9 808.859s12.669 5.831 19.061-9.51 14.668-14.668 18.234-14.533l3.566.135v8.478s-8.209-1.077-10.833 8.545-6.771 27.989-30.028 27.215z"
                data-name="Path 3812"
                transform="translate(-1603.244 -704.803)"
              ></path>
              <path
                fill="#babb86"
                d="M1314.959 740.289l-13.633 16.483v59.45h-20.861v-58.311l27.4-17.622z"
                data-name="Path 3813"
                transform="translate(-1272.455 -672.062)"
              ></path>
              <path
                fill="#babb86"
                d="M1397.487 740.289l-6.292 16.483v59.45h-5.243v-59.45l8.914-16.483z"
                data-name="Path 3814"
                transform="translate(-1350.095 -672.062)"
              ></path>
            </g>
            <g data-name="Group 7560" transform="translate(181.536 59.418)">
              <g data-name="Group 7554" transform="translate(0 26.478)">
                <path
                  fill="#d6b26d"
                  d="M1918.006 856.006l29.449-.166-4.159 30.03h-21.54z"
                  data-name="Path 3790"
                  transform="translate(-1916.183 -832.19)"
                ></path>
                <path
                  fill="#a9833d"
                  stroke="#292929"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M0 0H21.027V3.189H0z"
                  data-name="Rectangle 330"
                  transform="translate(5.706 54.173)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M1943.475 873.107h3.51l-.574 23.356z"
                  data-name="Path 3791"
                  transform="translate(-1936.478 -845.949)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M1969.86 873.107h3.191l-1.6 23.356z"
                  data-name="Path 3792"
                  transform="translate(-1957.502 -845.949)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M1994.989 873.107h3.191l-1.6 23.867z"
                  data-name="Path 3793"
                  transform="translate(-1977.526 -845.949)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2019.175 873.107h3.063l-2.106 23.356z"
                  data-name="Path 3794"
                  transform="translate(-1996.798 -845.949)"
                ></path>
                <path
                  fill="none"
                  stroke="#292929"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M0 10.887l5.637 47.22h21.164l6.487-47.22z"
                  data-name="Path 3795"
                ></path>
                <g
                  fill="#c18244"
                  data-name="Group 7553"
                  opacity="0.4"
                  transform="translate(12.708)"
                >
                  <path
                    d="M1974.911 739.433s2.685.965 1.393 4.064-3.309 3.1-2.4 6.451 5.429 1.225 1.263 8.359c0 0 4.816-4.481 1.736-6.581s-1.642-4.775-.674-6.146 2.238-5.538-1.318-6.147z"
                    data-name="Path 3796"
                    transform="translate(-1973.25 -739.433)"
                  ></path>
                  <path
                    d="M1971.605 796.214s.109 1.062.98 1.279a1.773 1.773 0 011.143 2.858c-1.116 1.578-.854 1.66-.854 1.66a3.9 3.9 0 01.418-1.823c.408-.49.708-1.361-.626-2.123s-1.061-1.171-1.061-1.851z"
                    data-name="Path 3797"
                    transform="translate(-1971.583 -784.678)"
                  ></path>
                  <path
                    d="M1991.641 763.527a.738.738 0 01-.191 1.361c-1.2.572-2.177 1.579-1.823 2.694a3.485 3.485 0 001.169 1.688s-1.223-2.123-.57-2.871 3.51-2.055 1.415-2.872z"
                    data-name="Path 3798"
                    transform="translate(-1985.902 -758.632)"
                  ></path>
                </g>
                <path
                  fill="#d6b26d"
                  d="M1918.006 851.623l.223 1.557h28.944l.282-1.557z"
                  data-name="Path 3799"
                  transform="translate(-1916.183 -828.83)"
                ></path>
              </g>
              <g data-name="Group 7556" transform="translate(52.037 25.928)">
                <path
                  fill="#d6b26d"
                  d="M2174.14 856.006l29.449-.166-4.158 30.03h-21.54z"
                  data-name="Path 3800"
                  transform="translate(-2172.317 -831.64)"
                ></path>
                <path
                  fill="#a9833d"
                  stroke="#292929"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M0 0H21.027V3.189H0z"
                  data-name="Rectangle 331"
                  transform="translate(5.706 54.722)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2199.61 873.107h3.51l-.574 23.356z"
                  data-name="Path 3801"
                  transform="translate(-2192.613 -845.4)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2225.995 873.107h3.191l-1.595 23.356z"
                  data-name="Path 3802"
                  transform="translate(-2213.637 -845.4)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2251.123 873.107h3.191l-1.6 23.867z"
                  data-name="Path 3803"
                  transform="translate(-2233.66 -845.4)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2275.309 873.107h3.063l-2.106 23.356z"
                  data-name="Path 3804"
                  transform="translate(-2252.933 -845.4)"
                ></path>
                <path
                  fill="none"
                  stroke="#292929"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M0 11.437l5.637 47.22h21.164l6.487-47.22z"
                  data-name="Path 3805"
                ></path>
                <path
                  fill="#d6b26d"
                  d="M2174.14 851.623l.223 1.557h28.944l.282-1.557z"
                  data-name="Path 3806"
                  transform="translate(-2172.317 -828.28)"
                ></path>
                <g
                  fill="#c18244"
                  data-name="Group 7555"
                  opacity="0.4"
                  transform="translate(15.03)"
                >
                  <path
                    d="M2242.475 736.727s2.685.965 1.393 4.064-3.309 3.1-2.4 6.451 5.428 1.225 1.263 8.359c0 0 4.816-4.481 1.736-6.581s-1.642-4.775-.674-6.146 2.238-5.538-1.318-6.147z"
                    data-name="Path 3807"
                    transform="translate(-2240.814 -736.727)"
                  ></path>
                  <path
                    d="M2239.169 793.508s.109 1.062.98 1.279a1.773 1.773 0 011.143 2.858c-1.116 1.578-.853 1.66-.853 1.66a3.9 3.9 0 01.418-1.823c.408-.49.708-1.361-.626-2.123s-1.062-1.171-1.062-1.851z"
                    data-name="Path 3808"
                    transform="translate(-2239.146 -781.972)"
                  ></path>
                  <path
                    d="M2259.206 760.821a.738.738 0 01-.191 1.361c-1.2.571-2.177 1.579-1.823 2.694a3.489 3.489 0 001.168 1.687s-1.223-2.123-.57-2.871 3.51-2.054 1.416-2.871z"
                    data-name="Path 3809"
                    transform="translate(-2253.467 -755.926)"
                  ></path>
                </g>
              </g>
              <g data-name="Group 7558" transform="translate(21.677)">
                <path
                  fill="#d6b26d"
                  d="M2026.438 825.713l35.146-.2-4.963 36.139h-25.706z"
                  data-name="Path 3815"
                  transform="translate(-2024.263 -781.547)"
                ></path>
                <path
                  fill="#a9833d"
                  stroke="#292929"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M0 0H25.094V3.805H0z"
                  data-name="Rectangle 336"
                  transform="translate(6.81 80.393)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2056.834 846.12h4.188l-.685 27.874z"
                  data-name="Path 3816"
                  transform="translate(-2048.483 -797.967)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2088.322 846.12h3.808l-1.9 27.874z"
                  data-name="Path 3817"
                  transform="translate(-2073.574 -797.967)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2118.311 846.12h3.808l-1.9 28.483z"
                  data-name="Path 3818"
                  transform="translate(-2097.47 -797.967)"
                ></path>
                <path
                  fill="#dddec6"
                  d="M2147.175 846.12h3.656l-2.513 27.874z"
                  data-name="Path 3819"
                  transform="translate(-2120.47 -797.967)"
                ></path>
                <path
                  fill="none"
                  stroke="#292929"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M0 28.735l6.727 56.354h25.258l7.742-56.354z"
                  data-name="Path 3820"
                ></path>
                <path
                  fill="#d6b26d"
                  d="M2026.438 819.684l.265 1.858h34.618l.263-1.858z"
                  data-name="Path 3821"
                  transform="translate(-2024.263 -776.902)"
                ></path>
                <path
                  fill="snow"
                  d="M2019.948 766.292l-1.655-13.816h38.57l-1.77 13.816z"
                  data-name="Path 3822"
                  transform="translate(-2017.773 -723.348)"
                ></path>
                <g
                  fill="#c18244"
                  data-name="Group 7557"
                  opacity="0.4"
                  transform="translate(14.361)"
                >
                  <path
                    d="M2093.206 609.1s5.478 1.969 2.843 8.291-6.752 6.322-4.9 13.162 11.076 2.5 2.577 17.055c0 0 9.826-9.143 3.542-13.427s-3.351-9.742-1.374-12.54 4.567-11.293-2.688-12.541z"
                    data-name="Path 3823"
                    transform="translate(-2089.817 -609.104)"
                  ></path>
                  <path
                    d="M2086.462 724.957s.222 2.166 2 2.61 4.609 2.61 2.332 5.831-1.741 3.387-1.741 3.387.02-2.721.853-3.721 1.444-2.777-1.277-4.331-2.167-2.388-2.167-3.776z"
                    data-name="Path 3824"
                    transform="translate(-2086.415 -701.42)"
                  ></path>
                  <path
                    d="M2127.342 658.264s2.054 1.611-.389 2.776-4.442 3.221-3.72 5.5a7.11 7.11 0 002.384 3.443s-2.5-4.331-1.162-5.859 7.162-4.194 2.887-5.86z"
                    data-name="Path 3825"
                    transform="translate(-2115.633 -648.276)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g filter="url(#Rectangle_337)" transform="translate(121.52 352)">
          <path
            fill="snow"
            d="M0 0H299V54H0z"
            data-name="Rectangle 337"
            transform="translate(9 145)"
          ></path>
        </g>
        <g filter="url(#Cutting_मसाला_चाय)" transform="translate(121.52 352)">
          <text
            fill="#6b6b6b"
            data-name="Cutting मसाला चाय"
            fontFamily="Montserrat-Medium, Montserrat"
            fontSize="18"
            fontWeight="500"
            transform="translate(82 179)"
          >
            <tspan x="0" y="0">
              Cutting{" "}
            </tspan>
            <tspan
              y="0"
              fontFamily="chanakyaregular"
            >
              मसाला
            </tspan>
            <tspan y="0"></tspan>
            <tspan
              y="0"
              x="125"
              fontFamily="chanakyaregular"
            >
              चाय
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default chaiIconsvg;