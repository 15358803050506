import React from 'react'
import {Header} from 'semantic-ui-react'
import SciTechLogoSvgComponent from '../../img/sciTech-logo'
import ChaiIconsvg from '../../img/cuttingchai'
import QuoteopenIcon from '../../img/Quote(open)'
import QuotecloseIcon from '../../img/Quote(close)'


const leftsection = () => {
   return (
    < >
      <div className="left-section-inner">
            <div className="skipark-logo">
                <SciTechLogoSvgComponent/>
            </div>
            <div className="left-section-text">
                <Header as='h3'>Get access to our boutique workspace and
                    our home style cutting chai recipe
                </Header>
            </div>
            <div className="signup-illustration">
                <ChaiIconsvg/>
            </div>
            <div className="Quotebox">
                <p className="quotetext">
                    Originally made popular by the residents of Mumbai looking for
                    their morning refreshment before catching the first local train to
                    work, cutting chai can now be found on road-side tea stalls in
                    every city in India and is now also served fresh in Hauxton House! 
                    If the sharp spices are too much for you we are always happy to
                    serve you a cup of italian artisan coffee...  
                </p>
                <div className="openquote">
                    <QuoteopenIcon/>
                </div>
                <div className="closequote">
                    <QuotecloseIcon/>
                </div>
            </div>
        </div>
    </>
  )}
  export default leftsection
