import * as actionTypes from "./actionTypes";
import {registrationInput} from "./queryObjects";
import {apiCall} from "../../utils/utilFunctions";
import Globals from "../../Globals";
import {toast} from 'react-toastify'

export const dispatchInit = () => {
  return {
    type: actionTypes.SIGNUP_INIT
  };
};
export const dispatchSuccess = (result) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    response: result.data.createUser
  };
};

export const userRegister = (userData) => async (dispatch) => {
    dispatch(dispatchInit());
    const requestData = registrationInput(userData);
    const apiResponse = await apiCall(Globals.API_ROOT_URL, requestData);
    if (apiResponse.data.createUser!==null){
      localStorage.setItem('token',apiResponse.data.createUser.token)     //Setting the token
        dispatch(dispatchSuccess(apiResponse));     
      }
      else{
        toast.error(apiResponse.errors[0].message);       //Showing toastify error message
      }
};

