import React, { useState } from "react";
import { Button, Form, Input, Grid, Header, Icon } from "semantic-ui-react";
import O2hSkiPark from "../../img/o2hskiparklogo";
import { Link } from "react-router-dom";

const DesktopRightSection = (props) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [hidden, sethidden] = useState(true);

  const toggleShow = () => {
    //toggle password visiblity
    sethidden(!hidden);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const obj = {
      //User details (credentials)
      email: email,
      password: password,
    };
    props.handleLoginSubmit(obj);
  };
  const handleLoginChange = (e, name) => {
    if (name === "email") {
      setemail(e.target.value);
    }
    if (name === "password") {
      setpassword(e.target.value);
    }
  };
  return (
    <Form onSubmit={(e) => handleLoginSubmit(e)}>
      <div className="right-section">
        <div className="Login-header">
          <Header as="h2">Login</Header>
        </div>
        <div className="fieldrow">
          <Form.Input
            className="Inputfield email"
            required
            fluid
            name="email"
            value={email}
            onChange={(e) => handleLoginChange(e, "email")}
            id="form-subcomponent-shorthand-input-email"
            label="Email"
            placeholder="Enter email"
          />
          <Form.Field required className="Inputfield password">
            <label>Password</label>
            <Input
              fluid
              required
              className="encryptpwd"
              name="password"
              value={password}
              type={hidden ? "password" : "text"}
              onChange={(e) => handleLoginChange(e, "password")}
              placeholder="Enter password"
            />
            <Icon name={hidden?"eye slash":"eye icon"} onClick={() => toggleShow()} />
          </Form.Field>
        </div>

        <Grid>
          <Grid.Row className="login-account-wrapper">
            <Grid.Column width={7} className="login">
              <div className="Loginaccountbtn">
                <Button disabled = {(password==="") || (email==="")}type="submit">Login</Button>
              </div>
            </Grid.Column>
            <Grid.Column width={3} className="or-text-grid-child">
              <div className="or-text">
                <span>OR</span>
              </div>
            </Grid.Column>
            <Grid.Column width={6} className="notamember">
              <div className="Not-a-member">
                <p>
                  New member?
                  <Link to="/" className="signup-link">
                    Sign Up
                  </Link>
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="Forgotpassword-link">
          <Link to="/forgotpassword" className="forgotpassword">
            Forgot Password?
          </Link>
        </div>
        <div className="powered-by-text">
          <Grid>
            <Grid.Row className="poweredBy-wrapper">
              <Grid.Column width={8}>
                <div className="powered-text">
                  <h3>Powered By </h3>
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div class="skiparklogo">
                  <O2hSkiPark />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </Form>
  );
};
export default DesktopRightSection;
