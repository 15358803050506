import React, { Component } from 'react'
import LoginForm from './LoginForm'
import { connect } from "react-redux";
import {userLogin} from '../../redux/actions/loginActions'
import {apiCall} from "../../utils/utilFunctions";
import Globals from "../../Globals";
import {logout} from "../../redux/actions/queryObjects";


class Index extends Component {
    constructor(props){
        super(props);
        this.state = {
            page:''
          };
    }
    finalLoginSubmit =async (values) =>{                //Passing user data
        this.props.userLogin(values)
      }

      componentDidMount(){
        // window.history.pushState(null, document.title, window.location.href);
        //   window.addEventListener('popstate', function (event){
        //       window.history.pushState(null, document.title,  window.location.href);
        //   });
        window.scrollTo(0, 0);
        apiCall(Globals.API_ROOT_URL,logout())
        localStorage.removeItem('token')
        localStorage.removeItem('price')
        localStorage.removeItem('quantity')
      }

      static getDerivedStateFromProps(nextProps){       //Redirecting to payment page based on response
        if (Object.keys(nextProps.loginData.loginDetails.data).length > 0) {
          if (nextProps.loginData.loginDetails.status===true) {
            nextProps.history.push({
              pathname: '/thankyou'
              });
          }
          else{
          nextProps.history.push({
            pathname: '/payment',
            state: nextProps.loginData.loginDetails.data
            });
          }
         nextProps.loginData.loginDetails.data={}
          return true;
        }
        else return null;
      }

    render() {
        return (
        <LoginForm
            handleSubmit={this.finalLoginSubmit}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    loginData:state.login
  });
export default connect(mapStateToProps,{userLogin})(Index)