import * as actionTypes from '../actions/actionTypes';

const initialState = {
    invoiceDetails:{loading:false,data:{}}
}

export const invoiceReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.INVOICE_INIT:
            return{
                ...state,
                invoiceDetails:{...state.invoiceDetails,data:{},loading:true}
            }
        case actionTypes.INVOICE_SUCCESS:
            return{
                ...state,
                invoiceDetails:{...state.invoiceDetails,data:action.response,loading:false}
            }
        default:
            return state
    }
} 