import React, { Component } from 'react'
import PaymentForm from '../Payment/payment'
import {apiCall} from '../../utils/utilFunctions'
import Globals from '../../Globals'
import {facilitiesList} from '../../redux/actions/queryObjects'
import { connect } from "react-redux";
import getSeatsPrice from './getSeatsPrice'
import {myInvoice} from '../../redux/actions/myInvoiceActions'

class PaymentIndex extends Component {
    constructor(props){
        super(props);
        this.state = {
            numSeats:1,
            price:100
          };
    }

    componentDidMount=async()=>{
      // ------BACK BUTTON DISABLE FUNCTIONALITY------
      // window.history.pushState(null, document.title, window.location.href);
      //   window.addEventListener('popstate', function (event){
      //       window.history.pushState(null, document.title,  window.location.href);
      //   });
      window.scrollTo(0, 0);
      this.props.myInvoice(this.props)
      const requestData = facilitiesList();
      const apiResponse = await apiCall(Globals.API_ROOT_URL, requestData);

      if (apiResponse.data.facilitiesList===null){
        this.props.history.push('/login')
        return true;
      }
      this.setState({price : apiResponse.data.facilitiesList.facilitieList[0].price})
    }

    addSeats = () => this.setState({ numSeats : Math.min(100, this.state.numSeats + 1)})
    remSeats = () => this.setState({ numSeats : Math.max(1 , this.state.numSeats - 1)})

    render() {
        return (
            <>
            <PaymentForm
              onAdd={this.addSeats}
              onRemove={this.remSeats}
              quantity={this.state.numSeats}
              price={getSeatsPrice(this.state.numSeats,this.state.price)}
              data = {this.props.myInvoiceData.myInvoiceDetails.data}
            />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    myInvoiceData:state.myInvoice
  });
export default connect(mapStateToProps,{myInvoice})(PaymentIndex)
