import React,{useState,useEffect} from "react";
import { Button, Form, Grid, Header,Message,Dimmer, Loader, Segment  } from "semantic-ui-react";
import {Link} from 'react-router-dom'

const Invoice = (props) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [addr1, setaddr1] = useState("");
  const [addr2, setaddr2] = useState("");
  const [town, settown] = useState("");
  const [stateid, setstateid] = useState("");
  const [postalcode, setpostalcode] = useState("");
  const [country, setcountry] = useState("");
  const [price, setprice] = useState(0);
  const [quantity, setquantity] = useState(0);
  const [error, seterror] = useState({
    name: "",
    email: "",
    company: "",
    addr1: "",
    addr2: "",
    town: "",
    stateid: "",
    country: "",
    postalcode: ""
  });

  useEffect(() => {
    if ( props.data !== undefined ){
      if (props.data.firstName!==undefined || props.data.lastName!==undefined){
      setname(props.data.firstName+" "+props.data.lastName)
      }
      else{
        setname("")
      }
      setemail(props.data.email || "")
      setcompanyName(props.data.companyName ||"")
      setprice(props.price)
      setquantity(props.quantity)
    }
  }, [props.data]);

  const renderFieldError = (errors = []) => {       //Displaying error message
    if (errors.length===2){
        if (errors[0]!==null || errors[1]!==null) {
          return (
            <Message style={{display:"block"}}
            error
            list={errors}
          />
          );
        }
    }
    else if (errors[0]!==null) {
      return (
        <Message style={{display:"block"}}
        error
        list={errors}
      />
      );
     } 
   else  {
      return null;
     }
  };
  const onlyAlphabets = (value) => {
    if (!/^[a-zA-Z ,.'-]+$/i.test(value)){
      return true;
    }
    else{
      return false
    }
  }

  const onlyAlphabetsAndNumbers  = (value) => {
    if (!/^[a-zA-Z0-9 ,.'-]+$/i.test(value)){
      return true;
    }
    else{
      return false
    }
  }

  const handleInvoiceSubmit = (e) => {
    e.preventDefault();
    const obj = {             //Invoice Details
      name: name,
      email: email,
      company: companyName,
      address1:addr1,
      address2:addr2,
      town:town,
      state:stateid,
      postalcode:postalcode,
      country:country,
      price:price,
      quantity:quantity
    };
    if (Object.values(error).every((o) => o === "")){
      props.handleInvoiceSubmit(obj)
    }
  };

  const handleInvoiceChange = (e, name) => {
    if (name === "name") {
      setname(e.target.value);
      if (name!==null && onlyAlphabets(e.target.value)) {
        seterror({ ...error, name: "Please enter valid name" });
      }
      else{
      seterror({ ...error,name: "" });
      }
    }
    if (name === "email") {
      setemail(e.target.value);
      if (email!==null && !/\S+@\S+\.\S+/.test(e.target.value)) {
        seterror({ ...error, email: "Please enter valid email address" });
      }
      else{
        seterror({...error,email:""})
        }
    }
    if (name === "company") {
      setcompanyName(e.target.value);
      if (companyName !== null && onlyAlphabetsAndNumbers(e.target.value)) {
        seterror({ ...error, company: "Please enter valid Co. name" });
      }
      else{
        seterror({...error,company:""})
      }
    }
    if (name === "Address1") {
      setaddr1(e.target.value);
      if (addr1 !== null && onlyAlphabetsAndNumbers(e.target.value)) {
        seterror({ ...error, addr1: "Please enter valid address line 1" });
      }
      else{
        seterror({...error,addr1:""})
      }
    }
    if (name === "Address2") {
      setaddr2(e.target.value);
      if ( !(/^$/i.test(e.target.value)) && onlyAlphabetsAndNumbers(e.target.value)){
        seterror({...error,addr2:"Please enter valid address line 2"})
      }
      else{
        seterror({...error,addr2:""})
      }
    }
    if (name === "town") {
      settown(e.target.value);
      if ( town !== null && onlyAlphabets(e.target.value)) {
        seterror({ ...error, town: "Please enter valid town" });
      }
      else{
      seterror({ ...error,town: "" });
      }
    }
    if (name === "State") {
      setstateid(e.target.value);
      if (stateid !== null && onlyAlphabets(e.target.value)) {
        seterror({ ...error, stateid: "Please enter valid state" });
      }
      else{
      seterror({ ...error,stateid: "" });
      }
    }
    if (name === "PostalCode") {
      setpostalcode(e.target.value);
      if (postalcode!==null && onlyAlphabetsAndNumbers(e.target.value)) {
        seterror({ ...error, postalcode: "Please enter valid postal code" });
      }
      else{
      seterror({ ...error,postalcode: "" });
      }
    }
    if (name === "Country") {
      setcountry(e.target.value);
      if (country!==null && onlyAlphabets(e.target.value)) {
        seterror({ ...error, country: "Please enter valid country" });
      }
      else{
      seterror({ ...error,country: "" });
      }
    }
  };
  return (
    <Segment>
    {props.loading===true ?
      <Dimmer active>
        <Loader size='medium'>Loading</Loader>
        {document.body.classList.add("loader-scroll")}
      </Dimmer>
      :(document.body.classList.remove("loader-scroll"))}
    <Form onSubmit={(e)=>handleInvoiceSubmit(e)}>
      <main id="Invoice-page">
        <section id="Invoice">
          <div className="container">
            <div className="Invoice-wrapper">
              <div className="Invoice-header">
                <Header as="h2">
                  Please provide the required details and we will send you an invoice
                </Header>
              </div>
              <Form.Group widths="equal" className={`fieldrow ${
            error.name || error.email ? "has_error" : null
          }`}>
                <Form.Input
                  className="Name"
                  required
                  fluid
                  name="Name"
                  type="text"
                  value = {name}
                  onChange={(e) => handleInvoiceChange(e, "name")}
                  id="form-subcomponent-shorthand-input-Name"
                  label="Name"
                  placeholder="e.g. Peter Jackson"
                  error = {!!error.name}
                />
                <div className="firstname-validation validation_msg">
            {renderFieldError([error.name || null])}
          </div>
                <Form.Input
                  className="Email"
                  required
                  fluid
                  name="Email"
                  value={email}
                  type="text"
                  onChange={(e) => handleInvoiceChange(e, "email")}
                  id="form-subcomponent-shorthand-input-email"
                  label="Email"
                  placeholder="e.g. your@email.com"
                  error={!!error.email}
                />
                <div className="Email-validation validation_msg">
            {renderFieldError([error.email || null])}
          </div>
              </Form.Group>
              <div className={`fieldrow ${error.company ? "has_error" : null}`}>
              <Form.Input
                className="CompanyName"
                required
                fluid
                name="Company Name"
                value={companyName}
                onChange={(e) => handleInvoiceChange(e, "company")}
                type="text"
                id="form-subcomponent-shorthand-input-Name"
                label="Company Name"
                placeholder="e.g. Verizona"
                error = {!!error.company}
              />

          <div className="Company-validation validation_msg">
            {renderFieldError([error.company || null])}
          </div>
              </div>
              <div className={`fieldrow ${error.addr1 ? "has_error" : null}`}>
              <Form.Input
                className="Address1"
                required
                fluid
                name="Address1"
                type="text"
                value = {addr1}
                onChange={(e) => handleInvoiceChange(e, "Address1")}
                id="form-subcomponent-shorthand-input-Address1"
                label="Address Line 1"
                placeholder="Enter street address"
                error = {!!error.addr1}
              />
              <div className="Addr1-validation validation_msg">
              {renderFieldError([
                (error.addr1 || null)
              ])}
              </div>
              </div>
              <div className={`fieldrow ${error.addr2 ? "has_error" : null}`}>
                <Form.Input
                  className="Address2"
                  fluid
                  name="Address2"
                  type="text"
                  value = {addr2}
                  onChange={(e) => handleInvoiceChange(e, "Address2")}
                  id="form-subcomponent-shorthand-input-Address2"
                  label="Address Line 2"
                  placeholder="Enter apartment/building/floor"
                  error = {!!error.addr2}
                />
                <div className="Addr2-validation validation_msg">
                {renderFieldError([
                  (error.addr2 || null)
                ])}
                </div>
              </div>
              <Form.Group widths="equal" className={`fieldrow ${error.town || error.stateid ? "has_error" : null}`}>
                <Form.Input
                  className="Town"
                  required
                  fluid
                  name="Town"
                  type="Text"
                  value = {town}
                  onChange={(e) => handleInvoiceChange(e, "town")}
                  id="form-subcomponent-shorthand-input-Town"
                  label="Town/City"
                  placeholder="Enter town/city"
                  error = {!!error.town}
                />
                <div className="town-validation validation_msg">
                {renderFieldError([
                  (error.town || null)
                ])}
                </div>
                <Form.Input
                  className="State"
                  required
                  fluid
                  name="State"
                  type="text"
                  value = {stateid}
                  onChange={(e) => handleInvoiceChange(e, "State")}
                  id="form-subcomponent-shorthand-input-State"
                  label="State/County"
                  placeholder="Enter state"
                  error = {!!error.stateid}
                />
                <div className="state-validation validation_msg">
                {renderFieldError([
                  (error.stateid || null)
                ])}
                </div>
              </Form.Group>
              <Form.Group widths="equal" className={`fieldrow ${error.postalcode || error.country ? "has_error" : null}`}>
                <Form.Input
                  className="PostalCode"
                  required
                  fluid
                  name="PostalCode"
                  type="text"
                  value = {postalcode}
                  onChange={(e) => handleInvoiceChange(e, "PostalCode")}
                  id="form-subcomponent-shorthand-input-PostalCode"
                  label="Postal Code"
                  placeholder="Enter postal code"
                  error = {!!error.postalcode}
                />
                <div className="postalcode-validation validation_msg">
                {renderFieldError([
                  (error.postalcode || null)
                ])}
                </div>
                <Form.Input
                  className="Country"
                  required
                  fluid
                  name="Country"
                  type="text"
                  value = {country}
                  onChange={(e) => handleInvoiceChange(e, "Country")}
                  id="form-subcomponent-shorthand-input-Country"
                  label="Country"
                  placeholder="Enter country"
                  error = {!!error.country}
                />
                <div className="country-validation validation_msg">
                {renderFieldError([
                  (error.country || null)
                ])}
                </div>
              </Form.Group>
              <Grid className="Button-wrapper">
                <Grid.Row>
                  <Grid.Column width={8} className="Button-wrapper-inner">
                    <div className="Submitbtn">
                      <Button disabled = {(name==="") || (email==="") || (addr1==="") || (companyName==="") || (town==="") || (stateid==="") || (postalcode==="") || (country==="") || (!(Object.values(error).every((o) => o==="")))} type="submit">Submit</Button>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} className="Button-wrapper-inner">
                    <div className="go-back-link-wrapper">
                      <div className="go-back-link">
                        <p>
                          Go back to{" "}
                          <Link to ={{pathname:'/payment',state: {name : email}}} className="Payment-link" >
                          Payment Page
                          </Link>
                        </p>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {/*Message box starts*/}
              {/* <div id ="invoice-box" class="ui icon message">
                <i class="check circle icon"></i>
                <div class="content">
                  <p className="content-inner">
                    Thank you. You will recieve an invoice on your email in next
                    few days.
                  </p>
                </div>
              </div> */}
              {/*Message box ends*/}
            </div>
          </div>
        </section>
      </main>
    </Form>
    </Segment>
  );
};
export default Invoice;
