function PaymentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="458.462"
      height="244.595"
      viewBox="0 0 458.462 244.595"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="1"
          x2="0"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#dae3fe"></stop>
          <stop offset="1" stopColor="#e9effd"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x2="1"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#6b6b6b"></stop>
          <stop offset="1" stopColor="#292929"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="-0.001"
          x2="1"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#292929"></stop>
          <stop offset="1" stopColor="#87a878"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="0.475"
          x2="0.841"
          y1="3.837"
          y2="-40.376"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#292929"></stop>
          <stop offset="1" stopColor="#4f52ff"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="0.297"
          x2="0.682"
          y1="12.152"
          y2="-9.695"
          xlinkHref="#linear-gradient-2"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-13"
          x2="1"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff928e"></stop>
          <stop offset="1" stopColor="#feb3b1"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-14"
          x1="0.104"
          x2="0.277"
          y1="-0.501"
          y2="-0.014"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-15"
          x1="0.311"
          x2="0.471"
          y1="2.213"
          y2="0.817"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#09005d"></stop>
          <stop offset="1" stopColor="#12102c"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-16"
          x1="0.364"
          x2="0.985"
          y1="0.364"
          y2="1.814"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#a5668b"></stop>
          <stop offset="1" stopColor="#ff928e"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-17"
          x1="-0.032"
          x2="0.871"
          y1="-0.555"
          y2="1.493"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-18"
          x1="26.133"
          x2="26.299"
          y1="37.065"
          y2="35.63"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-19"
          x1="18.996"
          x2="17.479"
          y1="31.383"
          y2="33.77"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#09005d"></stop>
          <stop offset="1" stopColor="#1a0f91"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-20"
          x1="109.535"
          x2="110.238"
          y1="119.428"
          y2="114.792"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-21"
          x1="0.868"
          x2="0.555"
          y1="-1.854"
          y2="0.3"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-22"
          x1="11.23"
          x2="10.34"
          y1="29.477"
          y2="31.618"
          xlinkHref="#linear-gradient-19"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-23"
          x1="0.567"
          x2="-0.172"
          y1="14.013"
          y2="16.451"
          xlinkHref="#linear-gradient-19"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-24"
          x1="0.733"
          x2="0.333"
          y1="0.134"
          y2="0.8"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#09005d"></stop>
          <stop offset="1" stopColor="#292929"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-25"
          x1="0.428"
          x2="0.572"
          y1="1.94"
          y2="-0.72"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#eae7e7"></stop>
          <stop offset="1" stopColor="#a5668b"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-26"
          x1="0.511"
          x2="0.489"
          y1="1.752"
          y2="-0.794"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-27"
          x1="0.458"
          x2="2.075"
          y1="0.559"
          y2="-1.565"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-28"
          x1="-0.167"
          x2="0.922"
          y1="1.153"
          y2="0.094"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-29"
          x1="0.812"
          x2="-1.442"
          y1="0.711"
          y2="-1.322"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-30"
          x1="1.474"
          x2="-0.045"
          y1="1.117"
          y2="0.104"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-31"
          x1="0.438"
          x2="0.526"
          y1="-0.906"
          y2="0.936"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-32"
          x1="0.135"
          x2="0.632"
          y1="-4.966"
          y2="2.347"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-33"
          x1="0.153"
          x2="0.615"
          y1="-5.059"
          y2="2.558"
          xlinkHref="#linear-gradient-13"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-34"
          x1="0.523"
          x2="0.481"
          y1="1.027"
          y2="-0.017"
          xlinkHref="#linear-gradient-19"
        ></linearGradient>
      </defs>
      <g data-name="Group 6556" transform="translate(14830.272 -4278.255)">
        <g
          data-name="20944993 [Converted]"
          transform="translate(-14831.932 4215.02)"
        >
          <g>
            <g data-name="Group 4531">
              <g data-name="Group 4462" transform="translate(1.659 133.949)">
                <g data-name="Group 4459" transform="translate(0 56.504)">
                  <path
                    fill="#b0bc98"
                    d="M1931 1060.613c-15.608 3-61.7 4.879-78.694-71.619a2.164 2.164 0 013.713-1.93c6.992 7.73 19.427 20.871 29.993 24.913 32.734 12.528 44.583 32.68 48.495 42.12a4.772 4.772 0 01-3.507 6.516z"
                    data-name="Path 2798"
                    transform="translate(-1852.252 -986.342)"
                  ></path>
                  <path
                    fill="url(#linear-gradient)"
                    d="M1926.925 1147.74a.911.911 0 00.254-1.785c-22.747-6.615-39.452-19.333-49.461-28.84a110.4 110.4 0 01-16.457-19.268.911.911 0 10-1.549.958 111.917 111.917 0 0016.707 19.587c10.162 9.66 27.127 22.586 50.25 29.31a.879.879 0 00.256.038z"
                    data-name="Path 2799"
                    transform="translate(-1846.665 -1075.385)"
                  ></path>
                </g>
                <g data-name="Group 4461" transform="translate(41.312)">
                  <path
                    fill="#87a878"
                    d="M1852.432 701.887c-1.775 14.283-10.658 102.355 36.183 130.457a2.224 2.224 0 003.358-1.745c.76-10.5 1.234-43.855-18.032-83.224-13.9-28.406-19-41.211-20.6-45.591a.47.47 0 00-.909.103z"
                    data-name="Path 2800"
                    transform="translate(-1850.603 -701.473)"
                  ></path>
                  <g data-name="Group 4460" transform="translate(6.826 30.297)">
                    <path
                      fill="#fff"
                      d="M1881.916 953.957a.912.912 0 00.8-1.347c-23.538-43.276-33.505-94.784-33.551-97.355a.911.911 0 00-1.771-.4c-.571 1.827 10.2 55.374 33.722 98.626a.915.915 0 00.8.476zm-32.784-98.557z"
                      data-name="Path 2801"
                      transform="translate(-1847.371 -854.218)"
                    ></path>
                  </g>
                </g>
              </g>
              <path
                fill="#bf9f9f"
                d="M1110.181 1334.152H998.1l10.685-12.062h91.8z"
                data-name="Path 2803"
                transform="translate(-810.697 -1056.306)"
              ></path>
              <g data-name="Group 4468" transform="translate(419.084 172.981)">
                <g data-name="Group 4467">
                  <g data-name="Group 4464" transform="translate(29.861 26.15)">
                    <path
                      fill="#87a878"
                      d="M2220.481 1110.658s19.567-45.2 4.752-92.827a.369.369 0 00-.687.074c-3.572 28.804-4.636 59.995-4.065 92.753z"
                      data-name="Path 2806"
                      transform="translate(-2220.307 -1017.627)"
                    ></path>
                    <path
                      fill="#fff"
                      d="M2220.454 1153.138a.242.242 0 01-.068-.009.25.25 0 01-.173-.311c3.457-12.284 4.775-33.2 5.27-48.578.536-16.673.252-30.614.247-30.754a.249.249 0 01.245-.256.254.254 0 01.252.245c0 .138.289 14.094-.25 30.78-.5 15.408-1.817 36.365-5.287 48.7a.25.25 0 01-.236.183z"
                      data-name="Path 2807"
                      transform="translate(-2220.203 -1061.057)"
                    ></path>
                  </g>
                  <g data-name="Group 4465" transform="translate(14.118)">
                    <path
                      fill="#87a878"
                      d="M2159.726 1009.99s12.089-55.463-10.758-111.59a.351.351 0 00-.678.136c1.232 34.764 5.382 72.226 11.436 111.454z"
                      data-name="Path 2808"
                      transform="translate(-2148.289 -898.176)"
                    ></path>
                    <path
                      fill="#fff"
                      d="M2171.48 1060.773h-.024a.25.25 0 01-.226-.273c2.715-28.639-7.627-94.823-7.732-95.487a.251.251 0 01.5-.077c.105.666 10.456 66.913 7.735 95.613a.253.253 0 01-.253.224z"
                      data-name="Path 2809"
                      transform="translate(-2160.167 -950.155)"
                    ></path>
                  </g>
                  <g data-name="Group 4466" transform="translate(0 34.784)">
                    <path
                      fill="#87a878"
                      d="M2107.437 1149.136s5.79-48.386-22.978-91.927c-.188-.285-.712-.11-.655.219 5.066 28.964 13.311 59.742 23.633 91.708z"
                      data-name="Path 2810"
                      transform="translate(-2083.8 -1057.067)"
                    ></path>
                    <path
                      fill="#fff"
                      d="M2124.582 1189.87a.252.252 0 01-.252-.247c-.256-12.759-5.191-33.519-9.287-48.69-4.439-16.445-8.873-30.042-8.919-30.178a.251.251 0 11.477-.155c.044.136 4.483 13.746 8.925 30.2 4.1 15.2 9.046 36 9.3 48.81a.251.251 0 01-.245.256s.003.004.001.004z"
                      data-name="Path 2811"
                      transform="translate(-2101.228 -1098.744)"
                    ></path>
                  </g>
                </g>
                <path
                  fill="#292929"
                  d="M2152.674 1354.23h-26.595a3.322 3.322 0 00-3.111 3.947 76.377 76.377 0 008.148 21.984 4.245 4.245 0 003.77 2.215h8.678a4.271 4.271 0 003.837-2.154c4.173-7.592 6.213-15.187 7.914-22.557a2.805 2.805 0 00-2.641-3.435z"
                  data-name="Path 2812"
                  transform="translate(-2114.349 -1254.391)"
                ></path>
              </g>
              <g data-name="Group 4475" transform="translate(221 104.562)">
                <g data-name="Group 4471" transform="translate(3.616 4.532)">
                  <path
                    fill="url(#linear-gradient-2)"
                    d="M735.245 625.79a18.742 18.742 0 01-18.745 18.741 19.456 19.456 0 01-19.2-19.107c0-10.351 8.853-18.374 19.2-18.374a18.739 18.739 0 0118.745 18.74z"
                    data-name="Path 2813"
                    transform="translate(-697.3 -606.896)"
                  ></path>
                  <g
                    fill="#292929"
                    data-name="Group 4469"
                    transform="translate(1.218 .795)"
                  >
                    <path
                      d="M703.116 662.69a.227.227 0 01-.066-.009.253.253 0 01-.18-.309 17.241 17.241 0 01.771-2.248.251.251 0 01.462.195 16.569 16.569 0 00-.749 2.183.243.243 0 01-.238.188z"
                      data-name="Path 2814"
                      transform="translate(-702.863 -649.027)"
                    ></path>
                    <path
                      d="M716.2 617.785a.26.26 0 01-.151-.05.251.251 0 01-.05-.352 18.909 18.909 0 0115.06-7.4 18 18 0 017.288 1.526.251.251 0 01-.2.46 17.471 17.471 0 00-7.084-1.484 18.4 18.4 0 00-14.657 7.2.254.254 0 01-.206.1z"
                      data-name="Path 2815"
                      transform="translate(-713.085 -609.98)"
                    ></path>
                  </g>
                  <path
                    fill="#d1c9c9"
                    d="M741.795 634.772a16.222 16.222 0 01-16.222 16.222 16.842 16.842 0 01-16.623-16.539c0-8.96 7.664-15.9 16.623-15.9a16.221 16.221 0 0116.222 16.217z"
                    data-name="Path 2816"
                    transform="translate(-706.4 -615.878)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M718.975 684.082a1.249 1.249 0 11-1.173-1.32 1.246 1.246 0 011.173 1.32z"
                    data-name="Path 2819"
                    opacity="0.78"
                    transform="translate(-712.281 -666.031)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M842.235 691.262a1.249 1.249 0 11-1.173-1.32 1.246 1.246 0 011.173 1.32z"
                    data-name="Path 2820"
                    opacity="0.78"
                    transform="translate(-808.557 -671.639)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M768.492 747.1a1.25 1.25 0 11-1.454 1 1.251 1.251 0 011.454-1z"
                    data-name="Path 2821"
                    opacity="0.78"
                    transform="translate(-751.755 -716.269)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M790.672 625.646a1.25 1.25 0 11-1.454 1 1.251 1.251 0 011.454-1z"
                    data-name="Path 2822"
                    opacity="0.78"
                    transform="translate(-769.08 -621.405)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M811.893 748.657c.127.32.094.62-.07.67s-.4-.171-.53-.49-.094-.62.07-.67.403.17.53.49z"
                    data-name="Path 2823"
                    opacity="0.78"
                    transform="translate(-786.277 -717.114)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M762.3 633.457c.127.32.094.62-.07.67s-.4-.171-.53-.49-.094-.62.07-.67.406.17.53.49z"
                    data-name="Path 2824"
                    opacity="0.78"
                    transform="translate(-747.544 -627.134)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M837.525 727.785c.274.208.4.479.287.609s-.433.063-.707-.142-.4-.482-.287-.609.431-.063.707.142z"
                    data-name="Path 2825"
                    opacity="0.78"
                    transform="translate(-806.241 -701.037)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M735.325 655.08c.274.208.4.479.287.609s-.434.064-.707-.142-.4-.479-.287-.609.433-.066.707.142z"
                    data-name="Path 2826"
                    opacity="0.78"
                    transform="translate(-726.415 -644.247)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M841.794 662.939c.311-.149.613-.136.672.026s-.142.414-.453.563-.613.136-.672-.026.142-.416.453-.563z"
                    data-name="Path 2827"
                    opacity="0.78"
                    transform="translate(-809.799 -650.469)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M730.174 720.129c.311-.149.613-.136.672.026s-.142.414-.453.563-.613.136-.672-.026.142-.416.453-.563z"
                    data-name="Path 2828"
                    opacity="0.78"
                    transform="translate(-722.615 -695.139)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M819.238 637.643c.188-.289.453-.436.589-.328s.094.427-.1.716-.453.434-.589.326-.088-.425.1-.714z"
                    data-name="Path 2829"
                    opacity="0.78"
                    transform="translate(-792.407 -630.508)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M753.538 744.48c.188-.289.453-.433.589-.326s.094.427-.1.716-.453.436-.589.328-.088-.429.1-.718z"
                    data-name="Path 2830"
                    opacity="0.78"
                    transform="translate(-741.09 -713.957)"
                  ></path>
                  <path
                    fill="#292929"
                    d="M744.562 624.277l-5.307-9.875a.287.287 0 01.254-.423h1.291a.288.288 0 00.21-.484l-6.6-7.056a.287.287 0 00-.488.265l2.279 9.278a.289.289 0 00.49.127l1.044-1.125a.288.288 0 01.455.044l5.88 9.538a.286.286 0 00.39.1.293.293 0 00.102-.389z"
                    data-name="Path 2831"
                    transform="translate(-725.895 -606.347)"
                  ></path>
                  <path
                    fill="#fff"
                    d="M737.932 617.972a.251.251 0 01-.247-.21c-.037-.184-.935-3.783-1.765-7.1a.369.369 0 01.635-.333l1.359 1.546a.251.251 0 01-.377.333l-1-1.134c1.079 4.313 1.631 6.537 1.642 6.611a.25.25 0 01-.214.282z"
                    data-name="Path 2832"
                    transform="translate(-727.457 -609.361)"
                  ></path>
                  <path
                    fill="#6b6b6b"
                    d="M783.919 679.51l9.805-.32a.287.287 0 01.258.42l-.593 1.147a.288.288 0 00.333.409l9.3-2.618a.287.287 0 00-.011-.556l-9.287-2.244a.286.286 0 00-.337.377l.519 1.445a.286.286 0 01-.25.383l-9.768.983a.286.286 0 00-.265.3.289.289 0 00.296.274z"
                    data-name="Path 2833"
                    transform="translate(-764.729 -660.549)"
                  ></path>
                  <g
                    data-name="Group 4470"
                    transform="translate(29.803 15.692)"
                  >
                    <path
                      fill="#fff"
                      d="M838.819 680.957a.251.251 0 01-.059-.495l1.467-.363c-6.524-1.548-6.592-1.572-6.625-1.585a.252.252 0 01.16-.477c.1.029 1.43.35 7.115 1.7a.369.369 0 010 .718l-2 .5a.337.337 0 01-.058.002zm1.942-.733z"
                      data-name="Path 2834"
                      transform="translate(-833.435 -678.024)"
                    ></path>
                  </g>
                  <path
                    fill="url(#linear-gradient-3)"
                    d="M781.076 685.743a1.333 1.333 0 11-1.333-1.333 1.333 1.333 0 011.333 1.333z"
                    data-name="Path 2835"
                    transform="translate(-760.653 -667.321)"
                  ></path>
                </g>
                <g fill="#fff" data-name="Group 4474">
                  <g data-name="Group 4472" transform="translate(0 25.093)">
                    <path
                      d="M865.721 742.721a.252.252 0 01-.206-.4c.236-.337.464-.685.683-1.038a.251.251 0 11.427.265c-.223.361-.458.718-.7 1.062a.25.25 0 01-.204.111z"
                      data-name="Path 2836"
                      transform="translate(-825.037 -732.215)"
                    ></path>
                    <path
                      d="M704.942 733.426h-.011a22.868 22.868 0 01-2.509-.256.252.252 0 01.079-.5 21.87 21.87 0 002.454.252.252.252 0 01.239.263.255.255 0 01-.252.241zm2.493-.022a.251.251 0 01-.015-.5 22.026 22.026 0 002.45-.3.254.254 0 01.291.2.251.251 0 01-.2.291 22.924 22.924 0 01-2.5.3zm-7.415-.76a.25.25 0 01-.068-.009 22.559 22.559 0 01-2.391-.806.251.251 0 01.186-.466 22.194 22.194 0 002.338.788.251.251 0 01-.066.492zm12.321-.118a.252.252 0 01-.072-.492 22.069 22.069 0 002.32-.836.252.252 0 01.331.134.249.249 0 01-.134.328 22.442 22.442 0 01-2.375.856.2.2 0 01-.069.011zm-16.941-1.735a.234.234 0 01-.118-.031 22.59 22.59 0 01-2.152-1.318.252.252 0 01.287-.414 21.974 21.974 0 002.1 1.287.253.253 0 01-.12.475zm21.533-.212a.252.252 0 01-.125-.471 22.6 22.6 0 002.078-1.333.251.251 0 01.293.407 22.888 22.888 0 01-2.124 1.364.251.251 0 01-.127.032zm-25.633-2.623a.253.253 0 01-.166-.061 22.894 22.894 0 01-1.8-1.765.251.251 0 01.37-.339 22.214 22.214 0 001.762 1.725.251.251 0 01-.162.44zm29.666-.3a.252.252 0 01-.168-.438 22.246 22.246 0 001.734-1.756.251.251 0 11.376.333 22.849 22.849 0 01-1.773 1.8.248.248 0 01-.168.065zm-33.028-3.382a.253.253 0 01-.2-.1 23.05 23.05 0 01-1.373-2.117.252.252 0 01.438-.25 22.429 22.429 0 001.342 2.071.254.254 0 01-.055.352.271.271 0 01-.151.048zm-2.472-4.328a.249.249 0 01-.23-.153 22.73 22.73 0 01-.865-2.371.251.251 0 01.482-.144 22.175 22.175 0 00.847 2.316.252.252 0 01-.131.33.23.23 0 01-.102.026z"
                      data-name="Path 2837"
                      transform="translate(-683.578 -713.416)"
                    ></path>
                    <path
                      d="M681.223 702.012a.253.253 0 01-.247-.206c-.077-.416-.14-.838-.193-1.257a.251.251 0 01.219-.28.248.248 0 01.28.219c.05.407.114.821.188 1.226a.254.254 0 01-.2.293.337.337 0 01-.047.005z"
                      data-name="Path 2838"
                      transform="translate(-680.781 -700.267)"
                    ></path>
                  </g>
                  <g data-name="Group 4473" transform="translate(4.217)">
                    <path
                      d="M700.294 623.808a.236.236 0 01-.149-.05.252.252 0 01-.053-.352c.252-.342.517-.677.786-1a.251.251 0 11.385.322c-.263.315-.521.644-.768.976a.246.246 0 01-.201.104z"
                      data-name="Path 2839"
                      transform="translate(-700.043 -614.289)"
                    ></path>
                    <path
                      d="M748.618 603.031a.252.252 0 01-.243-.191 22.18 22.18 0 00-.718-2.316.253.253 0 01.473-.177 22.83 22.83 0 01.735 2.371.252.252 0 01-.182.3.245.245 0 01-.065.013zm-1.708-4.593a.254.254 0 01-.226-.138 22.558 22.558 0 00-1.211-2.1.252.252 0 01.423-.274 22.825 22.825 0 011.239 2.152.249.249 0 01-.112.337.272.272 0 01-.112.023zm-2.675-4.109a.251.251 0 01-.195-.092 22.3 22.3 0 00-1.638-1.793.25.25 0 11.35-.357 23.367 23.367 0 011.675 1.832.25.25 0 01-.035.352.245.245 0 01-.157.058zm-32.735-1.946a.257.257 0 01-.182-.077.252.252 0 01.007-.355 22.56 22.56 0 011.764-1.528l.118-.092a.251.251 0 01.307.4l-.114.09a22.01 22.01 0 00-1.725 1.493.252.252 0 01-.175.069zm29.235-1.486a.256.256 0 01-.155-.053 22.284 22.284 0 00-1.988-1.39.251.251 0 01.265-.427 22.54 22.54 0 012.034 1.421.251.251 0 01.044.352.254.254 0 01-.198.1zm-25.349-1.5a.246.246 0 01-.215-.12.252.252 0 01.083-.346 22.815 22.815 0 012.189-1.173.252.252 0 01.215.455 21.791 21.791 0 00-2.139 1.147.263.263 0 01-.131.032zm21.183-1.077a.271.271 0 01-.107-.024 22.272 22.272 0 00-2.246-.919.251.251 0 01.164-.475 22.766 22.766 0 012.3.941.25.25 0 01.118.335.247.247 0 01-.227.137zm-16.741-1a.251.251 0 01-.239-.171.254.254 0 01.158-.32 22.137 22.137 0 012.393-.663.252.252 0 11.107.493 22.146 22.146 0 00-2.338.648.271.271 0 01-.079.012zm12.111-.609a.267.267 0 01-.057-.007 21.909 21.909 0 00-2.393-.409.251.251 0 01.057-.5 23.018 23.018 0 012.448.418.252.252 0 01-.055.5zm-7.323-.438a.251.251 0 01-.026-.5 20.2 20.2 0 012.364-.125h.116a.251.251 0 010 .5h-.125a22.408 22.408 0 00-2.3.12.113.113 0 01-.027.005z"
                      data-name="Path 2840"
                      transform="translate(-708.798 -585.645)"
                    ></path>
                    <path
                      d="M883.3 675.6a.251.251 0 01-.25-.232 23.28 23.28 0 00-.131-1.235.252.252 0 01.215-.282.244.244 0 01.282.215c.057.418.1.843.134 1.263a.249.249 0 01-.232.269.043.043 0 01-.018.002z"
                      data-name="Path 2841"
                      transform="translate(-842.885 -654.542)"
                    ></path>
                  </g>
                </g>
              </g>
              <g data-name="Group 4478" transform="translate(53.871 63.235)">
                <path
                  fill="url(#linear-gradient-4)"
                  d="M817.914 1391.874l-4.529-7.178a1.266 1.266 0 00-1.009-.626H493.5a1.267 1.267 0 00-1.132.878l-2.474 7.189a1.241 1.241 0 00.155 1.106 1.21 1.21 0 00.976.512l325.878-.044a1.193 1.193 0 001.009-1.837z"
                  data-name="Path 2842"
                  transform="translate(-467.568 -1167.952)"
                ></path>
                <path
                  fill="url(#linear-gradient-5)"
                  d="M0 0H5.026V182.947H0z"
                  data-name="Rectangle 232"
                  transform="translate(29.523 33.171)"
                ></path>
                <path
                  fill="url(#linear-gradient-5)"
                  d="M0 0H5.026V186.465H0z"
                  data-name="Rectangle 233"
                  transform="translate(131.551 29.653)"
                ></path>
                <path
                  fill="#484848"
                  d="M0 0H5.026V186.465H0z"
                  data-name="Rectangle 234"
                  transform="translate(243.631 29.653)"
                ></path>
                <path
                  fill="url(#linear-gradient-5)"
                  d="M0 0H5.026V184.959H0z"
                  data-name="Rectangle 235"
                  transform="translate(338.623 31.161)"
                ></path>
                <path
                  fill="url(#linear-gradient-5)"
                  d="M419.97 539.21l22.555 28.039v-6.283l-14.262-21.756z"
                  data-name="Path 2843"
                  transform="translate(-413.002 -508.049)"
                ></path>
                <path
                  fill="#474747"
                  d="M1981.323 539.21l-23.433 28.039v-6.283l15.141-21.756z"
                  data-name="Path 2844"
                  transform="translate(-1614.241 -508.049)"
                ></path>
                <g
                  fill="url(#linear-gradient-5)"
                  data-name="Group 4476"
                  transform="translate(223.841 31.161)"
                >
                  <path
                    d="M1542.04 539.21l-20.36 28.039v-6.283l13.17-21.756z"
                    data-name="Path 2845"
                    transform="translate(-1497.367 -539.21)"
                  ></path>
                  <path
                    d="M1410.62 539.21l19.79 28.039v-6.283l-12.6-21.756z"
                    data-name="Path 2846"
                    transform="translate(-1410.62 -539.21)"
                  ></path>
                </g>
                <g
                  fill="#484848"
                  data-name="Group 4477"
                  transform="translate(112.264 31.161)"
                >
                  <path
                    d="M1032.367 539.21l-20.357 28.039v-6.283l13.168-21.756z"
                    data-name="Path 2847"
                    transform="translate(-987.697 -539.21)"
                  ></path>
                  <path
                    d="M900.95 539.21l19.79 28.039v-6.283l-12.6-21.756z"
                    data-name="Path 2848"
                    transform="translate(-900.95 -539.21)"
                  ></path>
                </g>
                <path
                  fill="url(#linear-gradient-5)"
                  d="M841.811 396.87H539.388a5 5 0 00-4.908 4.74v14.86H846.1v-14.86a4.467 4.467 0 00-4.289-4.74z"
                  data-name="Path 2849"
                  transform="translate(-502.444 -396.87)"
                ></path>
                <path
                  fill="url(#linear-gradient-5)"
                  d="M760.575 486.41H389.51a1.5 1.5 0 00-1.1.563 1.138 1.138 0 00-.2 1.106l4.1 11.355a1.368 1.368 0 001.311.845l366.617-2.161c.582 0 .707-.414.707-.924v-9.711c-.002-.515.215-1.073-.37-1.073z"
                  data-name="Path 2850"
                  transform="translate(-388.142 -466.808)"
                ></path>
                <path
                  fill="#292929"
                  d="M1659.322 485.626h-82.929a.753.753 0 010-1.506h82.929a.753.753 0 110 1.506z"
                  data-name="Path 2851"
                  transform="translate(-1315.673 -465.019)"
                ></path>
                <path
                  fill="#292929"
                  d="M1002.4 485.626H864.683a.753.753 0 110-1.506H1002.4a.753.753 0 010 1.506z"
                  data-name="Path 2852"
                  transform="translate(-759.771 -465.019)"
                ></path>
                <path
                  fill="#292929"
                  d="M576.515 485.626h-44.732a.753.753 0 010-1.506h44.732a.753.753 0 110 1.506z"
                  data-name="Path 2853"
                  transform="translate(-499.749 -465.019)"
                ></path>
              </g>
              <g data-name="Group 4514" transform="translate(312.761 195.493)">
                <g
                  fill="#6b6b6b"
                  data-name="Group 4508"
                  transform="translate(65.559 73.142)"
                >
                  <path
                    d="M0 0H2.531V27.372H0z"
                    data-name="Rectangle 237"
                    transform="translate(12.231 6.132)"
                  ></path>
                  <path
                    d="M1953.61 1484.686h-22.17v-.933a.714.714 0 01.714-.714h20.74a.714.714 0 01.714.714v.933z"
                    data-name="Path 2907"
                    transform="translate(-1929.03 -1450.655)"
                  ></path>
                  <path
                    d="M1948.3 1340.292a2.73 2.73 0 01-2.721 2.721h-22.428a2.73 2.73 0 01-2.721-2.721v-2.461a2.728 2.728 0 012.721-2.721h22.428a2.73 2.73 0 012.721 2.721z"
                    data-name="Path 2908"
                    transform="translate(-1920.43 -1335.11)"
                  ></path>
                </g>
                <g data-name="Group 4509" transform="translate(27.679 55.882)">
                  <path
                    fill="url(#linear-gradient-13)"
                    d="M1864.832 1279.75a22.015 22.015 0 00-7.991 3.411 2.105 2.105 0 00-.93 2.014 89.342 89.342 0 009.453 29.672 3.2 3.2 0 01.306 2.189l-1.193 5.55-2.211 2.073h5.7l3.794-10.265-1.622-.512a3.069 3.069 0 01-2.143-2.872l-.195-11.01a12.53 12.53 0 00-3.008-8.291l9.1-.442z"
                    data-name="Path 2909"
                    transform="translate(-1832.143 -1274.61)"
                  ></path>
                  <path
                    fill="#292929"
                    d="M1874.739 1447.357c-.519.679 5.584 1.576 8.667.09 0 0 4.276-7.437 4.276-11.266 0 0-.339-.781-2.848-.6 0 0-1.436 8.805-2.951 9.792s-4.24.1-4.24.1a8.888 8.888 0 00-2.904 1.884z"
                    data-name="Path 2910"
                    transform="translate(-1846.838 -1396.302)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-14)"
                    d="M1797.348 1296.913c-.048-.083-9.8-1.171-9.8-1.171a22.508 22.508 0 01-.679 7.045c-1 4.081-15.309 21.756-15.309 21.756l1.5 4.757a24.141 24.141 0 01-13.7.823l9.3-5.58s8.7-29.661 14.035-34.953l9.77-3.38z"
                    data-name="Path 2911"
                    transform="translate(-1756.749 -1279.656)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-15)"
                    d="M1902.816 1256.27s5.615 8.354 6.077 12.163-1.539 5.359-7.432 5.613-21.625.252-21.625.252l-13.426-11.476s9.786-3.492 12.33-4.479 24.076-2.073 24.076-2.073z"
                    data-name="Path 2912"
                    transform="translate(-1840.356 -1256.27)"
                  ></path>
                  <path
                    fill="#292929"
                    d="M1763.149 1475.72a2.746 2.746 0 01.523 3.306s-12.8 2.268-16.272 1.239c0 0 .723-1.646 4.635-3.522l-.289.221a.467.467 0 00.309.838c2.578-.145 7.915-.617 11.094-2.082z"
                    data-name="Path 2913"
                    transform="translate(-1747.4 -1427.678)"
                  ></path>
                </g>
                <g data-name="Group 4510" transform="translate(54.874)">
                  <path
                    fill="url(#linear-gradient-16)"
                    d="M1921.922 1143.18a128.5 128.5 0 01-9.76-10.353 8.749 8.749 0 01-1.482-7.877 108.932 108.932 0 014.319-10.878c.366-1.224 7.7-1.173 7.7-1.173s14.431 9.365 20.1 25.231l-6.368 1.981a74.407 74.407 0 00-7.492-10.34s6.441 8.509 6.668 11.992z"
                    data-name="Path 2914"
                    transform="translate(-1901.846 -1088.403)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-17)"
                    d="M1927.67 1075.179c.077.254 1.99 6.6 2.141 6.732s4.2.35 6.1-.952l-3.822-9.1z"
                    data-name="Path 2915"
                    transform="translate(-1915.401 -1056.349)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-18)"
                    d="M1889.092 1054.845s-1.095 3.588 1.232 8.59 5.221 5.134 6.876 3.645a10.314 10.314 0 003.487-8.794c-.237-4.741-10.043-7.999-11.595-3.441z"
                    data-name="Path 2916"
                    transform="translate(-1885.068 -1041.35)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-19)"
                    d="M1883.7 1039.319s-3.938-6.684-8.155-1.767l-.16 1.852s-4.632-.771-3.617-5a8.294 8.294 0 0111.165-5.5c5.1 2.093 7.08 5.013 7.24 8.744s-2.756 2.778-2.756 2.778-.841-2.662-2.2-3-1.1 1.721-1.1 1.721z"
                    data-name="Path 2917"
                    transform="translate(-1871.625 -1022.291)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-20)"
                    d="M1928.762 1064.912s-2.045-3.424.234-3.814 1.754 3.325 1.445 4.694z"
                    data-name="Path 2918"
                    transform="translate(-1915.595 -1047.921)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-21)"
                    d="M1902.47 1233.662l4.805-4.632 6.646 2.809s6.616 1.025 10.4 0l3.787-1.025 8.558-2.664 1.344 3.8a2.285 2.285 0 01-.836 2.627 15.345 15.345 0 01-2.161 1.346c-1.127.493-27.781-.342-27.781-.342z"
                    data-name="Path 2919"
                    transform="translate(-1895.717 -1178.424)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-22)"
                    d="M1919.165 1008.53a1.482 1.482 0 01-1.331-2.264c1.979-3.295 6.151-7.351 13.949-4.008 8.783 3.765 13.439 5.329 15.23 4.44a1.488 1.488 0 012.15 1.688 6.324 6.324 0 01-2.434 3.135c-11.329 8.767-20.603-3.543-27.564-2.991z"
                    data-name="Path 2920"
                    transform="translate(-1907.548 -1001.007)"
                  ></path>
                </g>
                <g data-name="Group 4511" transform="translate(24.962 32.114)">
                  <path
                    fill="url(#linear-gradient-23)"
                    d="M1881.422 1254.3a.577.577 0 01-.063 0l-27.873-.031a1.247 1.247 0 110-2.493l27.873.031a1.246 1.246 0 01.059 2.491z"
                    data-name="Path 2921"
                    transform="translate(-1826.572 -1228.994)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-24)"
                    d="M1736.846 1147.7h23.214a4.424 4.424 0 014 2.537l10.731 22.757h-28.847a2.687 2.687 0 01-2.5-1.7l-8.321-21.058a1.85 1.85 0 011.723-2.536z"
                    data-name="Path 2922"
                    transform="translate(-1734.992 -1147.7)"
                  ></path>
                  <path
                    fill="#7d97f4"
                    d="M1804.94 1192.7c.5 1.563.131 3.08-.83 3.391s-2.148-.707-2.651-2.27-.131-3.08.83-3.391 2.15.71 2.651 2.27z"
                    data-name="Path 2923"
                    transform="translate(-1786.733 -1181.035)"
                  ></path>
                </g>
                <g data-name="Group 4513" transform="translate(0 57.156)">
                  <path
                    fill="#292929"
                    d="M1739.736 1263.373a1.026 1.026 0 00-.243-.891 1.184 1.184 0 00-.887-.392H1622.29a1.35 1.35 0 00-1.114.571 1.178 1.178 0 00-.088 1.189l1.261 2.557a1.776 1.776 0 001.6.961h113.858a1.315 1.315 0 001.311-1.016z"
                    data-name="Path 2924"
                    transform="translate(-1620.967 -1262.09)"
                  ></path>
                  <g
                    fill="#e0e0e0"
                    data-name="Group 4512"
                    transform="translate(6.824 2.172)"
                  >
                    <path
                      d="M1653.158 1325.02a1.018 1.018 0 01-.4-.085 1.053 1.053 0 01-.534-1.373l21.408-50.919a1.014 1.014 0 011.342-.547 1.053 1.053 0 01.534 1.373l-21.408 50.919a1.025 1.025 0 01-.942.632z"
                      data-name="Path 2925"
                      transform="translate(-1652.138 -1272.011)"
                    ></path>
                    <path
                      d="M1686.93 1325.02a1.021 1.021 0 01-.939-.633l-21.408-50.919a1.055 1.055 0 01.534-1.373 1.014 1.014 0 011.342.547l21.408 50.919a1.055 1.055 0 01-.534 1.373 1.017 1.017 0 01-.403.086z"
                      data-name="Path 2926"
                      transform="translate(-1661.793 -1272.011)"
                    ></path>
                    <path
                      d="M2038.238 1325.02a1.017 1.017 0 01-.4-.085 1.053 1.053 0 01-.534-1.373l21.408-50.919a1.014 1.014 0 011.342-.547 1.053 1.053 0 01.534 1.373l-21.408 50.919a1.025 1.025 0 01-.942.632z"
                      data-name="Path 2927"
                      transform="translate(-1952.916 -1272.011)"
                    ></path>
                    <path
                      d="M2072.009 1325.02a1.021 1.021 0 01-.939-.633l-21.408-50.919a1.053 1.053 0 01.534-1.373 1.014 1.014 0 011.342.547l21.409 50.919a1.053 1.053 0 01-.534 1.373 1.017 1.017 0 01-.404.086z"
                      data-name="Path 2928"
                      transform="translate(-1962.57 -1272.011)"
                    ></path>
                  </g>
                </g>
              </g>
              <g data-name="Group 4521" transform="translate(195.322 145.111)">
                <g data-name="Group 4515" transform="translate(53.882 16.708)">
                  <path
                    fill="#e0e0e0"
                    d="M1263.479 776.076c-11.2-10.324-29.673-4.042-29.516 7.916s22.9 7.013 11.152 20.635c.002 0 29.562-18.227 18.364-28.551z"
                    data-name="Path 2929"
                    transform="translate(-1233.963 -770.869)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-25)"
                    d="M1296.218 818.834a11.554 11.554 0 00.522-4.209 7.269 7.269 0 00-3.935 1.866 8.411 8.411 0 00-2-3.732 9.915 9.915 0 00-2.187 3.776v.013l-.015-.013a7.261 7.261 0 00-3.979-1.909 11.54 11.54 0 00.541 4.263 7.248 7.248 0 00-4.242.859c3.382 4.975 7.482 4.793 9.483 4.318.008 0 .561-.008.561-.011 1.864.473 6.108.635 9.484-4.333a7.1 7.1 0 00-4.233-.888z"
                    data-name="Path 2930"
                    transform="translate(-1274.63 -807.149)"
                  ></path>
                </g>
                <g data-name="Group 4520" transform="translate(0 49.069)">
                  <path
                    fill="#353684"
                    d="M1102.42 1261.41l19.992 4.915 20.695 1.18 25.924-6.095-33.3 16.846z"
                    data-name="Path 2931"
                    transform="translate(-1087.501 -1203.09)"
                  ></path>
                  <path
                    fill="#d6d36a"
                    d="M1151.017 1104.67a18.217 18.217 0 015.53-2.572c.742-.2 4.753.963 7.231.906 3.4-.079 6.867-1.626 9.672-.928a20.225 20.225 0 016.384 2.537c3.941 2.8 6.255 8.03 7.664 13.208a18.039 18.039 0 01-6.031 3.958l-3.483 22.8c-8.209 4.118-16.476 3.588-24.788-.576l-3.608-22.564s-5.394-2.511-5.788-3.426 2.458-10.072 7.217-13.343z"
                    data-name="Path 2932"
                    transform="translate(-1119.794 -1078.496)"
                  ></path>
                  <g
                    fill="#6b6b6b"
                    data-name="Group 4516"
                    transform="translate(21.939 74.832)"
                  >
                    <path
                      d="M1136.112 1337.167c3.754-.742 12.371-.017 12.371-.017l.326.961.464 4.742a98.214 98.214 0 01-12.3 1.1 3.808 3.808 0 01-1.132-.206 5.436 5.436 0 01-.35-.361 4.449 4.449 0 01.621-6.219z"
                      data-name="Path 2933"
                      transform="translate(-1134.486 -1336.832)"
                    ></path>
                    <path
                      d="M1194.24 1337.42l-1.463.017a2.8 2.8 0 00-2.08 1 3.452 3.452 0 00-.812 2.345 2.74 2.74 0 00.747 1.826 2.232 2.232 0 001.694.7l2.24-.09z"
                      data-name="Path 2934"
                      transform="translate(-1177.756 -1337.291)"
                    ></path>
                  </g>
                  <path
                    fill="#353684"
                    d="M1255.46 1259.626c-1.694-3.9-5.836-4.53-9.361-2.708-.061.081-22.389 11.524-42.079 16.54a21.36 21.36 0 01.917 5.62c.011.42 0 .836-.02 1.246 15.1-.775 31.487-5.812 45.568-10.237a7.784 7.784 0 004.975-10.461z"
                    data-name="Path 2935"
                    transform="translate(-1166.859 -1198.819)"
                  ></path>
                  <g data-name="Group 4518" transform="translate(4.803 56.591)">
                    <g
                      data-name="Group 4517"
                      transform="translate(47.378 18.368)"
                    >
                      <path
                        fill="#6b6b6b"
                        d="M1295.119 1338.583c-3.735-.858-12.367-.4-12.367-.4l-.35.95-.582 4.726s8.22 1.482 12.271 1.478a3.8 3.8 0 001.136-.171c.123-.112.245-.225.359-.35a4.448 4.448 0 00-.467-6.233z"
                        data-name="Path 2936"
                        transform="translate(-1279.808 -1337.92)"
                      ></path>
                      <path
                        fill="#353684"
                        d="M1273.1 1337.41l1.462.061a2.811 2.811 0 012.054 1.062 3.469 3.469 0 01.753 2.369 2.739 2.739 0 01-.791 1.8 2.218 2.218 0 01-1.71.65l-2.235-.16z"
                        data-name="Path 2937"
                        transform="translate(-1272.63 -1337.41)"
                      ></path>
                    </g>
                    <path
                      fill="#353684"
                      d="M1104.166 1277.473a13 13 0 011.27-6.084c.026-.085.057-.169.086-.254a184.396 184.396 0 01-4.486-1.467 215.35 215.35 0 01-34.828-15.178c-3.956-2.161-8.453-.714-9.718 3.967-1.13 4.177 1.351 7.743 5.118 9 11.27 3.75 31.266 9.733 42.582 10.493l-.024-.477z"
                      data-name="Path 2938"
                      transform="translate(-1056.215 -1253.51)"
                    ></path>
                  </g>
                  <path
                    fill="url(#linear-gradient-26)"
                    d="M1202.15 1079.723a8.625 8.625 0 008.805 6.382c7.437-.136 8.1-6.4 8.1-6.4l-4.858-1.04-.405-5.412-7.308.541-.136 5.142z"
                    data-name="Path 2939"
                    transform="translate(-1165.398 -1056.122)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-27)"
                    d="M1046.1 1239.054a5.638 5.638 0 01-2.266-.195c-.825-.357-4.076-.659-4.764-1.629 0 0-.795 1.252.357 1.721s2.912 1.292 1.657 2.393-1.813.18-1.736-1.105-.265-1.2-.889-1.37c0 0-1.408 2.113.348 3.371 0 0-4.812-.849-4.525 1.073l4.514.017s-4.648 1-3.575 2.769l4.044-1.37s-2.487.871-1.955 2.086c0 0 7.708-3.978 9.129-4.291s-.339-3.47-.339-3.47z"
                    data-name="Path 2940"
                    transform="translate(-1034.273 -1184.203)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-28)"
                    d="M1101.194 1173.8s-5.591 12.36-12.884 15.548l.339 3.47s10.819-.4 18.542-15.5c.003.003-2.068-4.756-5.997-3.518z"
                    data-name="Path 2941"
                    transform="translate(-1076.48 -1134.5)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-29)"
                    d="M1396.376 1239.054a5.638 5.638 0 002.266-.195c.825-.357 4.076-.659 4.764-1.629 0 0 .795 1.252-.357 1.721s-2.912 1.292-1.659 2.393 1.813.18 1.736-1.105.265-1.2.889-1.37c0 0 1.408 2.113-.348 3.371 0 0 4.812-.849 4.525 1.073l-4.514.017s4.648 1 3.575 2.769l-4.043-1.37s2.487.871 1.955 2.086c0 0-7.708-3.978-9.129-4.291s.34-3.47.34-3.47z"
                    data-name="Path 2942"
                    transform="translate(-1316.406 -1184.203)"
                  ></path>
                  <path
                    fill="url(#linear-gradient-30)"
                    d="M1319.318 1173.8s5.591 12.36 12.884 15.548l-.339 3.47s-10.819-.4-18.543-15.5c-.002.003 2.067-4.756 5.998-3.518z"
                    data-name="Path 2943"
                    transform="translate(-1252.231 -1134.5)"
                  ></path>
                  <g data-name="Group 4519" transform="translate(37.306)">
                    <path
                      fill="url(#linear-gradient-31)"
                      d="M1213.33 1018.9s-3.209 13.175 4.95 15.248c5.755 1.462 7.822-9.569 6.427-12.305-2.156-4.23-3.13-3.569-3.13-3.569z"
                      data-name="Path 2944"
                      transform="translate(-1210.951 -1013.153)"
                    ></path>
                    <path
                      fill="url(#linear-gradient-32)"
                      d="M1266.47 1044.608s.392-2.575 1.648-2.137.2 3.719-1.508 3.991z"
                      data-name="Path 2945"
                      transform="translate(-1252.944 -1032.042)"
                    ></path>
                    <path
                      fill="url(#linear-gradient-33)"
                      d="M1207.056 1046.539l-.206-1.749s-.512-2.406-1.625-2.067-.525 4.238 1.831 3.816z"
                      data-name="Path 2946"
                      transform="translate(-1204.683 -1032.252)"
                    ></path>
                    <path
                      fill="url(#linear-gradient-34)"
                      d="M1206.327 1006.054a.313.313 0 00.6-.1c.1-2.008.523-5.048 2.22-3.709a5.388 5.388 0 006.244.28 1.506 1.506 0 012.194.644 10.739 10.739 0 01.587 2.835.394.394 0 00.751.125l.265-.58a.094.094 0 00.009-.042v-.781a.1.1 0 01.085-.1c.272-.044 1.068-.261.556-1.272a.1.1 0 01.037-.134c.2-.123.641-.539.167-1.627a.1.1 0 01.031-.12 2.875 2.875 0 001.053-3.753c-1.036-2.064-2.491-1.313-2.859-1.079a.1.1 0 01-.138-.033 1.519 1.519 0 00-2-.784 1.53 1.53 0 01-1.364-.147 1.453 1.453 0 00-1.782-.064 1.481 1.481 0 01-1.791-.022 2.726 2.726 0 00-3.45.033 3.772 3.772 0 00-1.951 4.346.1.1 0 01-.083.125 1.036 1.036 0 00-.95 1.123 7.72 7.72 0 001.066 3.054.063.063 0 01.009.022l.49 1.743.004.017z"
                      data-name="Path 2947"
                      transform="translate(-1204.74 -995.01)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name="4782300" transform="translate(-14792.303 4421.332)">
          <g transform="translate(60.894 17.69)">
            <g data-name="Group 5446">
              <g data-name="Group 5255" transform="translate(1.045 17.494)">
                <g data-name="Group 5254">
                  <g data-name="Group 5253">
                    <g data-name="Group 5252">
                      <path
                        fill="#292929"
                        d="M223.88 258.64l1.3 8.545h7.552l1.755-8.422z"
                        data-name="Path 3150"
                        transform="translate(-223.88 -258.64)"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5311" transform="translate(1.903 22.031)">
                <g data-name="Group 5260" transform="translate(.044 .896)">
                  <g data-name="Group 5259">
                    <g data-name="Group 5258">
                      <g data-name="Group 5257">
                        <g data-name="Group 5256">
                          <path
                            fill="#fafafa"
                            d="M235.608 277.233c0 .023-1.936.044-4.324.044s-4.324-.021-4.324-.044 1.936-.044 4.324-.044 4.324.018 4.324.044z"
                            data-name="Path 3151"
                            transform="translate(-226.96 -277.189)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5265" transform="translate(.015 .475)">
                  <g data-name="Group 5264">
                    <g data-name="Group 5263">
                      <g data-name="Group 5262">
                        <g data-name="Group 5261">
                          <path
                            fill="#fafafa"
                            d="M235.566 275.794c0 .023-1.948.044-4.353.044s-4.353-.021-4.353-.044 1.948-.044 4.353-.044 4.353.021 4.353.044z"
                            data-name="Path 3152"
                            transform="translate(-226.86 -275.75)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5270">
                  <g data-name="Group 5269">
                    <g data-name="Group 5268">
                      <g data-name="Group 5267">
                        <g data-name="Group 5266">
                          <path
                            fill="#fafafa"
                            d="M235.516 274.174c0 .023-1.948.044-4.353.044s-4.353-.021-4.353-.044 1.948-.044 4.353-.044 4.353.02 4.353.044z"
                            data-name="Path 3153"
                            transform="translate(-226.81 -274.13)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5275" transform="translate(.93 1.356)">
                  <g data-name="Group 5274">
                    <g data-name="Group 5273">
                      <g data-name="Group 5272">
                        <g data-name="Group 5271">
                          <path
                            fill="#fafafa"
                            d="M230.363 280.925a6.838 6.838 0 01-.231-1.075 7.087 7.087 0 01-.144-1.09 6.834 6.834 0 01.231 1.075 7 7 0 01.144 1.09z"
                            data-name="Path 3154"
                            transform="translate(-229.984 -278.76)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5280" transform="translate(1.671 1.33)">
                  <g data-name="Group 5279">
                    <g data-name="Group 5278">
                      <g data-name="Group 5277">
                        <g data-name="Group 5276">
                          <path
                            fill="#fafafa"
                            d="M232.665 280.77a8.29 8.29 0 01-.094-1.049 8.985 8.985 0 01-.05-1.052 5.017 5.017 0 01.138 1.046 4.821 4.821 0 01.006 1.055z"
                            data-name="Path 3155"
                            transform="translate(-232.514 -278.67)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5285" transform="translate(2.494 1.26)">
                  <g data-name="Group 5284">
                    <g data-name="Group 5283">
                      <g data-name="Group 5282">
                        <g data-name="Group 5281">
                          <path
                            fill="#fafafa"
                            d="M235.588 280.6a5.318 5.318 0 01-.2-1.075 5.392 5.392 0 01-.059-1.093 30.924 30.924 0 01.255 2.168z"
                            data-name="Path 3156"
                            transform="translate(-235.323 -278.43)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5290" transform="translate(3.306 1.283)">
                  <g data-name="Group 5289">
                    <g data-name="Group 5288">
                      <g data-name="Group 5287">
                        <g data-name="Group 5286">
                          <path
                            fill="#fafafa"
                            d="M238.209 280.7a8.756 8.756 0 01-.073-2.191 9.147 9.147 0 01.056 1.1 9.691 9.691 0 01.017 1.091z"
                            data-name="Path 3157"
                            transform="translate(-238.096 -278.51)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5295" transform="translate(4.49 1.353)">
                  <g data-name="Group 5294">
                    <g data-name="Group 5293">
                      <g data-name="Group 5292">
                        <g data-name="Group 5291">
                          <path
                            fill="#fafafa"
                            d="M242.229 280.827a6.056 6.056 0 01-.079-1.037 6.136 6.136 0 01.009-1.04 6.432 6.432 0 01.079 1.037 6.168 6.168 0 01-.009 1.04z"
                            data-name="Path 3158"
                            transform="translate(-242.137 -278.75)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5300" transform="translate(5.395 1.33)">
                  <g data-name="Group 5299">
                    <g data-name="Group 5298">
                      <g data-name="Group 5297">
                        <g data-name="Group 5296">
                          <path
                            fill="#fafafa"
                            d="M245.262 280.814a6.555 6.555 0 01-.035-1.072 6.854 6.854 0 01.053-1.072 6.574 6.574 0 01.035 1.075 6.826 6.826 0 01-.053 1.069z"
                            data-name="Path 3159"
                            transform="translate(-245.226 -278.67)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5305" transform="translate(6.22 1.125)">
                  <g data-name="Group 5304">
                    <g data-name="Group 5303">
                      <g data-name="Group 5302">
                        <g data-name="Group 5301">
                          <path
                            fill="#fafafa"
                            d="M248.05 280.205a7.507 7.507 0 01.064-1.122 7.073 7.073 0 01.152-1.113 7.509 7.509 0 01-.064 1.122 7.077 7.077 0 01-.152 1.113z"
                            data-name="Path 3160"
                            transform="translate(-248.042 -277.97)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5310" transform="translate(7.25 1.374)">
                  <g data-name="Group 5309">
                    <g data-name="Group 5308">
                      <g data-name="Group 5307">
                        <g data-name="Group 5306">
                          <path
                            fill="#fafafa"
                            d="M251.564 280.695a3.914 3.914 0 01.126-.955 3.614 3.614 0 01.281-.92 8.869 8.869 0 01-.193.94 9.381 9.381 0 01-.214.935z"
                            data-name="Path 3161"
                            transform="translate(-251.557 -278.82)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5353" transform="translate(3.999)">
                <g data-name="Group 5316" transform="translate(2.447 .634)">
                  <g data-name="Group 5315">
                    <g data-name="Group 5314">
                      <g data-name="Group 5313">
                        <g data-name="Group 5312">
                          <path
                            fill="#b0bc98"
                            d="M244.306 201.09l-.009.041a8.603 8.603 0 01-.035.12c-.035.111-.082.267-.144.46s-.141.445-.223.732-.182.609-.272.976-.2.759-.293 1.189-.193.891-.29 1.377c-.182.973-.346 2.051-.463 3.187s-.161 2.226-.176 3.217c0 .5-.006.967.015 1.406s.032.85.053 1.225.053.712.073 1.008.05.551.073.762.038.363.05.48c0 .05.009.091.012.126s0 .044 0 .044-.006-.015-.009-.041-.012-.073-.021-.123c-.015-.117-.038-.275-.067-.478s-.062-.463-.088-.759-.067-.636-.088-1.011-.053-.785-.064-1.227-.021-.914-.021-1.409c.012-.993.059-2.086.17-3.225s.284-2.221.472-3.2c.1-.486.19-.949.3-1.377s.205-.826.3-1.189.2-.685.284-.973.167-.53.237-.727.12-.346.158-.457c.018-.047.032-.088.044-.117s.016-.037.022-.037z"
                            data-name="Path 3162"
                            transform="translate(-242.32 -201.09)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5320" transform="translate(2.931 4.288)">
                  <g data-name="Group 5319">
                    <g data-name="Group 5318">
                      <g data-name="Group 5317">
                        <path
                          fill="#b0bc98"
                          d="M244.108 215.2a2.805 2.805 0 011.175-1.482.665.665 0 01.621-.114c.264.126.284.5.205.779a2.068 2.068 0 01-.855 1.166 1.731 1.731 0 01-1.283.322"
                          data-name="Path 3163"
                          transform="translate(-243.97 -213.563)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5324" transform="translate(2.535 7.075)">
                  <g data-name="Group 5323">
                    <g data-name="Group 5322">
                      <g data-name="Group 5321">
                        <path
                          fill="#b0bc98"
                          d="M242.7 225.5a4.708 4.708 0 011.394-2.241.9.9 0 01.316-.17.372.372 0 01.34.073.52.52 0 01.076.489 2.975 2.975 0 01-2.2 2.218"
                          data-name="Path 3164"
                          transform="translate(-242.62 -223.077)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5328" transform="translate(2.483 10.691)">
                  <g data-name="Group 5327">
                    <g data-name="Group 5326">
                      <g data-name="Group 5325">
                        <path
                          fill="#b0bc98"
                          d="M242.44 237.371a4.329 4.329 0 011.406-1.769.67.67 0 01.492-.176c.267.05.387.375.363.644a2.093 2.093 0 01-.911 1.348 5.632 5.632 0 01-1.3.668"
                          data-name="Path 3165"
                          transform="translate(-242.44 -235.419)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5332" transform="translate(2.556 13.377)">
                  <g data-name="Group 5331">
                    <g data-name="Group 5330">
                      <g data-name="Group 5329">
                        <path
                          fill="#b0bc98"
                          d="M242.69 246.114a4.154 4.154 0 011.2-1.362.872.872 0 01.36-.161.361.361 0 01.352.135c.12.176.006.413-.123.586a3.426 3.426 0 01-1.708 1.254"
                          data-name="Path 3166"
                          transform="translate(-242.69 -244.587)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5336" transform="translate(.285 12.103)">
                  <g data-name="Group 5335">
                    <g data-name="Group 5334">
                      <g data-name="Group 5333">
                        <path
                          fill="#b0bc98"
                          d="M237.147 242.119a13.367 13.367 0 00-1.711-1.743.387.387 0 00-.34-.129.259.259 0 00-.158.231.662.662 0 00.064.287 3.325 3.325 0 002.2 1.854"
                          data-name="Path 3167"
                          transform="translate(-234.938 -240.238)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5340" transform="translate(0 8.905)">
                  <g data-name="Group 5339">
                    <g data-name="Group 5338">
                      <g data-name="Group 5337">
                        <path
                          fill="#b0bc98"
                          d="M236.516 231.459l-1.345-1.641a1.624 1.624 0 00-.436-.413.489.489 0 00-.759.536 1.383 1.383 0 00.264.539 3.988 3.988 0 00.978 1.04 1.633 1.633 0 001.227.378"
                          data-name="Path 3168"
                          transform="translate(-233.966 -229.321)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5344" transform="translate(.367 5.49)">
                  <g data-name="Group 5343">
                    <g data-name="Group 5342">
                      <g data-name="Group 5341">
                        <path
                          fill="#b0bc98"
                          d="M237.59 219.907a4.681 4.681 0 00-1.353-2.03.781.781 0 00-.352-.205.594.594 0 00-.618.434 1.1 1.1 0 00.123.891 2.368 2.368 0 00.633.668 5.442 5.442 0 001.5.809"
                          data-name="Path 3169"
                          transform="translate(-235.22 -217.664)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5348" transform="translate(1.252 1.958)">
                  <g data-name="Group 5347">
                    <g data-name="Group 5346">
                      <g data-name="Group 5345">
                        <path
                          fill="#b0bc98"
                          d="M240.26 208.427a5.477 5.477 0 00-1.2-2.511c-.12-.161-.29-.334-.489-.3a.394.394 0 00-.281.24.925.925 0 00-.053.381 2.936 2.936 0 001.9 2.622"
                          data-name="Path 3170"
                          transform="translate(-238.238 -205.609)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5352" transform="translate(3.584)">
                  <g data-name="Group 5351">
                    <g data-name="Group 5350">
                      <g data-name="Group 5349">
                        <path
                          fill="#b0bc98"
                          d="M246.267 202.2a7.966 7.966 0 01-.05-1.471 2.62 2.62 0 01.486-1.368 1.135 1.135 0 01.489-.39.613.613 0 01.6.079.649.649 0 01.205.527 1.638 1.638 0 01-.152.562 3.869 3.869 0 01-1.576 2.059"
                          data-name="Path 3171"
                          transform="translate(-246.2 -198.924)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5395" transform="translate(0 3.867)">
                <g data-name="Group 5358" transform="translate(1.761 .535)">
                  <g data-name="Group 5357">
                    <g data-name="Group 5356">
                      <g data-name="Group 5355">
                        <g data-name="Group 5354">
                          <path
                            fill="#87a878"
                            d="M226.357 213.95a.635.635 0 01.009.12v.346c0 .146 0 .334.009.545s.009.454.026.721a25.783 25.783 0 001.098 6.371c.202.679.416 1.277.609 1.778.091.252.187.475.267.671s.149.366.211.5.1.237.135.316a.576.576 0 01.041.114.628.628 0 01-.059-.105l-.149-.311c-.064-.135-.144-.3-.226-.5s-.185-.416-.278-.668a23.932 23.932 0 01-1.21-4.007 24.656 24.656 0 01-.504-4.157c-.015-.267-.012-.51-.012-.724s0-.4.006-.545l.012-.346a.809.809 0 01.015-.119z"
                            data-name="Path 3172"
                            transform="translate(-226.326 -213.95)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5362" transform="translate(2.003 2.955)">
                  <g data-name="Group 5361">
                    <g data-name="Group 5360">
                      <g data-name="Group 5359">
                        <path
                          fill="#87a878"
                          d="M227.15 223.686a2.016 2.016 0 01.48-1.263.475.475 0 01.4-.211c.208.029.3.278.308.486a1.484 1.484 0 01-.331.978 1.237 1.237 0 01-.8.5"
                          data-name="Path 3173"
                          transform="translate(-227.15 -222.21)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5366" transform="translate(2.457 4.905)">
                  <g data-name="Group 5365">
                    <g data-name="Group 5364">
                      <g data-name="Group 5363">
                        <path
                          fill="#87a878"
                          d="M228.7 230.926a3.385 3.385 0 01.466-1.828.67.67 0 01.179-.185.264.264 0 01.246-.023.372.372 0 01.158.316 2.124 2.124 0 01-1.022 1.986"
                          data-name="Path 3174"
                          transform="translate(-228.701 -228.869)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5370" transform="translate(3.051 7.397)">
                  <g data-name="Group 5369">
                    <g data-name="Group 5368">
                      <g data-name="Group 5367">
                        <path
                          fill="#87a878"
                          d="M230.73 239.107a3.049 3.049 0 01.577-1.506.49.49 0 01.3-.226c.193-.023.346.17.387.36a1.473 1.473 0 01-.331 1.113 4.024 4.024 0 01-.741.735"
                          data-name="Path 3175"
                          transform="translate(-230.73 -237.373)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5374" transform="translate(3.585 9.249)">
                  <g data-name="Group 5373">
                    <g data-name="Group 5372">
                      <g data-name="Group 5371">
                        <path
                          fill="#87a878"
                          d="M232.55 245.1a2.963 2.963 0 01.521-1.186.617.617 0 01.211-.187.262.262 0 01.27.015c.12.094.094.281.041.425a2.447 2.447 0 01-.894 1.222"
                          data-name="Path 3176"
                          transform="translate(-232.55 -243.697)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5378" transform="translate(1.482 9.181)">
                  <g data-name="Group 5377">
                    <g data-name="Group 5376">
                      <g data-name="Group 5375">
                        <path
                          fill="#87a878"
                          d="M227.237 244.325a9.346 9.346 0 00-1.541-.817.277.277 0 00-.261-.015.185.185 0 00-.059.19.518.518 0 00.105.182 2.368 2.368 0 001.9.788"
                          data-name="Path 3177"
                          transform="translate(-225.372 -243.465)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5382" transform="translate(.642 7.002)">
                  <g data-name="Group 5381">
                    <g data-name="Group 5380">
                      <g data-name="Group 5379">
                        <path
                          fill="#87a878"
                          d="M224.617 237.049l-1.268-.826a1.156 1.156 0 00-.387-.187.39.39 0 00-.393.12.385.385 0 00-.009.407 1.029 1.029 0 00.3.311 2.84 2.84 0 00.891.5 1.172 1.172 0 00.917-.006"
                          data-name="Path 3178"
                          transform="translate(-222.506 -236.025)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5386" transform="translate(.195 4.573)">
                  <g data-name="Group 5385">
                    <g data-name="Group 5384">
                      <g data-name="Group 5383">
                        <path
                          fill="#87a878"
                          d="M222.951 228.892a3.37 3.37 0 00-1.359-1.093.577.577 0 00-.284-.064.429.429 0 00-.328.428.8.8 0 00.275.58 1.674 1.674 0 00.574.319 3.929 3.929 0 001.192.229"
                          data-name="Path 3179"
                          transform="translate(-220.98 -227.733)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5390" transform="translate(0 2.031)">
                  <g data-name="Group 5389">
                    <g data-name="Group 5388">
                      <g data-name="Group 5387">
                        <path
                          fill="#87a878"
                          d="M222.211 220.63a3.9 3.9 0 00-1.356-1.45.416.416 0 00-.4-.1.285.285 0 00-.141.226.617.617 0 00.047.27 2.09 2.09 0 001.86 1.374"
                          data-name="Path 3180"
                          transform="translate(-220.314 -219.055)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5394" transform="translate(1.424)">
                  <g data-name="Group 5393">
                    <g data-name="Group 5392">
                      <g data-name="Group 5391">
                        <path
                          fill="#87a878"
                          d="M225.579 214.621a5.446 5.446 0 01-.349-.99 1.89 1.89 0 01.038-1.037.805.805 0 01.249-.369.448.448 0 01.425-.076.463.463 0 01.252.313 1.167 1.167 0 01.018.416 2.78 2.78 0 01-.633 1.743"
                          data-name="Path 3181"
                          transform="translate(-225.176 -212.124)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5437" transform="translate(8.462 3.785)">
                <g data-name="Group 5400" transform="translate(.472 .535)">
                  <g data-name="Group 5399">
                    <g data-name="Group 5398">
                      <g data-name="Group 5397">
                        <g data-name="Group 5396">
                          <path
                            fill="#b0bc98"
                            d="M253.217 213.67a.772.772 0 01.012.12c0 .088.009.2.012.346s.009.334.006.545 0 .457-.012.724a24.218 24.218 0 01-.504 4.157 24.402 24.402 0 01-1.21 4.007c-.094.252-.2.472-.278.668s-.161.363-.226.5l-.149.311a.5.5 0 01-.059.105.664.664 0 01.041-.114l.135-.316c.056-.129.132-.3.211-.5s.176-.419.267-.671a26.384 26.384 0 001.18-4.007 26.55 26.55 0 00.527-4.142c.018-.267.021-.51.026-.721s.009-.4.009-.545v-.346a.6.6 0 01.012-.121z"
                            data-name="Path 3182"
                            transform="translate(-250.809 -213.67)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5404" transform="translate(1.482 2.955)">
                  <g data-name="Group 5403">
                    <g data-name="Group 5402">
                      <g data-name="Group 5401">
                        <path
                          fill="#b0bc98"
                          d="M255.445 223.406a2.016 2.016 0 00-.48-1.263.475.475 0 00-.4-.211c-.208.029-.3.278-.308.486a1.484 1.484 0 00.331.978 1.237 1.237 0 00.8.5"
                          data-name="Path 3183"
                          transform="translate(-254.257 -221.929)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5408" transform="translate(1.158 4.905)">
                  <g data-name="Group 5407">
                    <g data-name="Group 5406">
                      <g data-name="Group 5405">
                        <path
                          fill="#b0bc98"
                          d="M254.209 230.646a3.386 3.386 0 00-.466-1.828.67.67 0 00-.179-.185.264.264 0 00-.246-.023.372.372 0 00-.158.316 2.124 2.124 0 001.022 1.986"
                          data-name="Path 3184"
                          transform="translate(-253.152 -228.589)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5412" transform="translate(.342 7.397)">
                  <g data-name="Group 5411">
                    <g data-name="Group 5410">
                      <g data-name="Group 5409">
                        <path
                          fill="#b0bc98"
                          d="M251.647 238.827a3.049 3.049 0 00-.577-1.506.49.49 0 00-.3-.226c-.193-.023-.346.17-.387.36a1.473 1.473 0 00.331 1.113 4.023 4.023 0 00.741.735"
                          data-name="Path 3185"
                          transform="translate(-250.366 -237.093)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5416" transform="translate(0 9.249)">
                  <g data-name="Group 5415">
                    <g data-name="Group 5414">
                      <g data-name="Group 5413">
                        <path
                          fill="#b0bc98"
                          d="M250.286 244.821a2.964 2.964 0 00-.521-1.186.618.618 0 00-.211-.187.262.262 0 00-.27.015c-.12.094-.094.281-.041.425a2.447 2.447 0 00.893 1.222"
                          data-name="Path 3186"
                          transform="translate(-249.198 -243.417)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5420" transform="translate(1.184 9.181)">
                  <g data-name="Group 5419">
                    <g data-name="Group 5418">
                      <g data-name="Group 5417">
                        <path
                          fill="#b0bc98"
                          d="M253.384 244.045a9.348 9.348 0 011.541-.817.277.277 0 01.261-.015.185.185 0 01.059.19.477.477 0 01-.105.182 2.368 2.368 0 01-1.9.788"
                          data-name="Path 3187"
                          transform="translate(-253.24 -243.185)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5424" transform="translate(1.875 7.002)">
                  <g data-name="Group 5423">
                    <g data-name="Group 5422">
                      <g data-name="Group 5421">
                        <path
                          fill="#b0bc98"
                          d="M255.647 236.769l1.268-.826a1.155 1.155 0 01.387-.187.39.39 0 01.393.12.385.385 0 01.009.407 1.03 1.03 0 01-.3.311 2.84 2.84 0 01-.891.5 1.171 1.171 0 01-.917-.006"
                          data-name="Path 3188"
                          transform="translate(-255.6 -235.745)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5428" transform="translate(2.438 4.573)">
                  <g data-name="Group 5427">
                    <g data-name="Group 5426">
                      <g data-name="Group 5425">
                        <path
                          fill="#b0bc98"
                          d="M257.59 228.612a3.37 3.37 0 011.359-1.093.577.577 0 01.284-.064.429.429 0 01.328.428.8.8 0 01-.275.58 1.674 1.674 0 01-.574.319 3.93 3.93 0 01-1.192.229"
                          data-name="Path 3189"
                          transform="translate(-257.52 -227.453)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5432" transform="translate(2.766 2.031)">
                  <g data-name="Group 5431">
                    <g data-name="Group 5430">
                      <g data-name="Group 5429">
                        <path
                          fill="#b0bc98"
                          d="M258.652 220.35a3.9 3.9 0 011.356-1.45.416.416 0 01.4-.1.278.278 0 01.141.226.618.618 0 01-.047.27 2.09 2.09 0 01-1.86 1.374"
                          data-name="Path 3190"
                          transform="translate(-258.64 -218.775)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5436" transform="translate(2.202)">
                  <g data-name="Group 5435">
                    <g data-name="Group 5434">
                      <g data-name="Group 5433">
                        <path
                          fill="#b0bc98"
                          d="M257.359 214.341a5.45 5.45 0 00.349-.99 1.89 1.89 0 00-.038-1.037.805.805 0 00-.249-.369.448.448 0 00-.425-.076.462.462 0 00-.252.313 1.168 1.168 0 00-.018.416 2.787 2.787 0 00.633 1.743"
                          data-name="Path 3191"
                          transform="translate(-256.716 -211.844)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5441" transform="translate(.178 15.803)">
                <g data-name="Group 5440">
                  <g data-name="Group 5439">
                    <g data-name="Group 5438">
                      <path
                        fill="#6b6b6b"
                        d="M220.92 252.87v1.6a.629.629 0 00.63.63h10.939a.629.629 0 00.63-.63v-1.6z"
                        data-name="Path 3192"
                        transform="translate(-220.92 -252.87)"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g
                data-name="Group 5445"
                opacity="0.4"
                transform="translate(1.127 18.03)"
              >
                <g data-name="Group 5444">
                  <g data-name="Group 5443">
                    <g data-name="Group 5442">
                      <path
                        d="M224.16 260.47l.091.51 10.092.721.255-1.23z"
                        data-name="Path 3193"
                        transform="translate(-224.16 -260.47)"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(2.499)">
            <g data-name="Group 5953">
              <g data-name="Group 5478" transform="translate(0 34.119)">
                <g data-name="Group 5454" transform="translate(27.692 34.627)">
                  <g data-name="Group 5449" transform="translate(.041 .032)">
                    <g data-name="Group 5448">
                      <g data-name="Group 5447">
                        <path
                          fill="#e0e0e0"
                          d="M143.278 373.32l10.572 32.48h-1.922l-12.388-32.48z"
                          data-name="Path 3194"
                          transform="translate(-139.54 -373.32)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5453">
                    <g data-name="Group 5452">
                      <g data-name="Group 5451">
                        <g data-name="Group 5450">
                          <path
                            fill="#263238"
                            d="M143.179 373.242l-.246.006c-.164 0-.4 0-.718.006l-2.774.015.026-.038c2.112 5.519 6.688 17.483 11.99 31.337.144.381.29.759.431 1.131l-.062-.044h1.925l-.064.088c-2.953-9.1-5.584-17.219-7.479-23.064-.943-2.924-1.7-5.279-2.226-6.908-.261-.811-.46-1.441-.6-1.875-.067-.211-.117-.375-.152-.489l-.05-.164s.021.053.059.158.091.27.161.478l.615 1.854c.533 1.623 1.3 3.969 2.262 6.884l7.54 23.084.029.088h-2.062l-.018-.044c-.144-.375-.287-.753-.431-1.131l-11.92-31.366-.015-.038h.041l2.789.015c.311 0 .548.006.712.006s.237.011.237.011z"
                            data-name="Path 3195"
                            transform="translate(-139.4 -373.21)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5462" transform="translate(0 34.633)">
                  <g data-name="Group 5457" transform="translate(.094 .026)">
                    <g data-name="Group 5456">
                      <g data-name="Group 5455">
                        <path
                          fill="#e0e0e0"
                          d="M55.762 373.32L45.19 405.8h1.925L59.5 373.32z"
                          data-name="Path 3196"
                          transform="translate(-45.19 -373.32)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5461">
                    <g data-name="Group 5460">
                      <g data-name="Group 5459">
                        <g data-name="Group 5458">
                          <path
                            fill="#263238"
                            d="M55.536 373.256s.079 0 .237-.006.4 0 .712-.006l2.789-.015h.041l-.015.038-11.92 31.366c-.144.381-.29.759-.431 1.131l-.018.044H44.87l.029-.088 7.54-23.084 2.262-6.884.615-1.854.161-.477c.038-.111.059-.164.059-.164s-.015.059-.05.17-.088.275-.152.489l-.6 1.875-2.226 6.908c-1.9 5.844-4.526 13.959-7.479 23.064l-.064-.088h1.925l-.062.044c.144-.375.287-.753.431-1.131 5.3-13.856 9.878-25.817 11.99-31.336l.026.038-2.774-.015c-.313 0-.551-.006-.718-.006s-.247-.013-.247-.013z"
                            data-name="Path 3197"
                            transform="translate(-44.87 -373.23)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5466" transform="translate(9.406 35.371)">
                  <g data-name="Group 5465">
                    <g data-name="Group 5464">
                      <g data-name="Group 5463">
                        <path
                          fill="#263238"
                          d="M95.579 375.75a1.467 1.467 0 01-.185.132c-.135.088-.313.208-.542.36l-2 1.312c-1.69 1.11-4.019 2.654-6.583 4.374s-4.872 3.275-6.559 4.412c-.832.56-1.509 1.011-1.989 1.333l-.545.357a1.736 1.736 0 01-.193.117 1.4 1.4 0 01.179-.141l.53-.378c.475-.331 1.142-.8 1.966-1.368 1.664-1.148 3.972-2.724 6.539-4.444s4.9-3.252 6.606-4.341c.852-.542 1.544-.981 2.027-1.277l.557-.34a1.781 1.781 0 01.192-.108z"
                          data-name="Path 3198"
                          transform="translate(-76.98 -375.749)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5469" transform="translate(2.902)">
                  <g data-name="Group 5468">
                    <g data-name="Group 5467">
                      <path
                        fill="#292929"
                        d="M59.6 255.034s-3.313.343-3.8 2.338-2.048 26.673 0 30.162 28.451 3.009 30.294 2.528 2.7-1.67 2.513-3.123-18.171-3.258-18.171-3.258l1.1-25.75s-6.459-3.255-11.936-2.897z"
                        data-name="Path 3199"
                        transform="translate(-54.776 -255.006)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5473" transform="translate(5.792 30.2)">
                  <g data-name="Group 5472">
                    <g data-name="Group 5471">
                      <g data-name="Group 5470">
                        <path
                          fill="#263238"
                          d="M95.6 360.453a.276.276 0 01-.079.009l-.234.009c-.217.006-.519.012-.905.023-.788.018-1.925.029-3.334.026-2.815-.006-6.706-.1-10.988-.4-2.141-.158-4.18-.354-6.029-.565s-3.509-.442-4.9-.659c-.7-.1-1.324-.214-1.875-.3s-1.025-.179-1.412-.252l-.891-.17-.231-.047a.362.362 0 01-.079-.021.322.322 0 01.079.006l.234.035.893.146c.387.067.861.146 1.415.229s1.181.19 1.878.284a151.102 151.102 0 0010.926 1.184c4.28.308 8.167.413 10.98.442 1.406.015 2.546.018 3.331.015h1.139a.288.288 0 01.082.006z"
                          data-name="Path 3200"
                          transform="translate(-64.64 -358.096)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5477" transform="translate(14.261 34.894)">
                  <g data-name="Group 5476">
                    <g data-name="Group 5475">
                      <g data-name="Group 5474">
                        <path
                          fill="#263238"
                          d="M112.164 387.361c-.021.029-4.207-2.909-9.345-6.565s-9.289-6.644-9.269-6.676 4.2 2.909 9.345 6.565 9.289 6.646 9.269 6.676z"
                          data-name="Path 3201"
                          transform="translate(-93.55 -374.119)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5481" transform="translate(26.382 27.435)">
                <g data-name="Group 5480">
                  <g data-name="Group 5479">
                    <path
                      fill="#263238"
                      d="M136.8 232.19a.524.524 0 01.035.146c.021.114.041.252.07.422s.064.407.091.671.067.56.088.893c.056.662.082 1.456.07 2.335a28.454 28.454 0 01-.208 2.853 27.232 27.232 0 01-.53 2.812 23.875 23.875 0 01-.668 2.238c-.1.316-.22.595-.313.841s-.182.457-.261.627-.129.284-.176.39a.621.621 0 01-.07.132.432.432 0 01.044-.144c.041-.108.091-.24.149-.4s.149-.384.234-.633.193-.527.29-.847c.211-.627.425-1.386.633-2.238s.381-1.8.516-2.8.193-1.963.22-2.836 0-1.664-.038-2.326c-.012-.331-.044-.63-.064-.891s-.044-.489-.064-.671-.032-.311-.041-.425a.527.527 0 01-.007-.149z"
                      data-name="Path 3202"
                      transform="translate(-134.926 -232.19)"
                    ></path>
                  </g>
                </g>
              </g>
              <g data-name="Group 5952" transform="translate(8.125)">
                <g data-name="Group 5717" transform="translate(3.036)">
                  <g data-name="Group 5483" transform="translate(1.655)">
                    <g data-name="Group 5482">
                      <path
                        fill="#263238"
                        d="M90.184 141.952a10.217 10.217 0 016.77-3.372 6.587 6.587 0 012.821.343 4.62 4.62 0 012.625 1.734 7.709 7.709 0 011.509 3.521 21.708 21.708 0 01.19 3.969 7.242 7.242 0 01-1.239 3.729l-3.366.586a21.242 21.242 0 01-5.557-.3 7.134 7.134 0 01-4.541-2.985 6.453 6.453 0 01.788-7.225z"
                        data-name="Path 3203"
                        transform="translate(-88.619 -138.538)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 5487"
                    transform="translate(14.764 12.224)"
                  >
                    <g data-name="Group 5486">
                      <g data-name="Group 5485">
                        <g data-name="Group 5484">
                          <path
                            fill="#ff725e"
                            d="M133.369 183.343c-.006.009.056.082.2.176a1.657 1.657 0 00.694.234 1.806 1.806 0 001.069-.217 1.8 1.8 0 00.65-2.4 1.822 1.822 0 00-.814-.727 1.626 1.626 0 00-.718-.146.543.543 0 00-.264.05 2.525 2.525 0 01.917.223 1.738 1.738 0 01.715.688 1.593 1.593 0 01.123 1.184 1.615 1.615 0 01-.7.961 1.726 1.726 0 01-.967.234 2.441 2.441 0 01-.905-.26z"
                            data-name="Path 3204"
                            transform="translate(-133.369 -180.265)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5489" transform="translate(0 10.636)">
                    <g data-name="Group 5488">
                      <path
                        fill="#263238"
                        d="M82.987 177a2.517 2.517 0 011.14-1.9 1.8 1.8 0 012.124.185l.53.63a2.175 2.175 0 011.251 2.191 2.378 2.378 0 01-1.652 1.948 2.635 2.635 0 01-2.511-.606 2.945 2.945 0 01-.882-2.448z"
                        data-name="Path 3205"
                        transform="translate(-82.969 -174.846)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 5492" transform="translate(.381 11.474)">
                    <g data-name="Group 5491">
                      <g data-name="Group 5490">
                        <path
                          fill="#455a64"
                          d="M84.572 181.293a1.137 1.137 0 01-.182-.671 3.049 3.049 0 01.77-1.515 4.568 4.568 0 011.348-1.081c.4-.211.665-.29.656-.316a1.8 1.8 0 00-.709.211A4.078 4.078 0 0085.031 179a4.034 4.034 0 00-.545.829 1.809 1.809 0 00-.217.788.939.939 0 00.152.533c.079.114.145.15.151.143z"
                          data-name="Path 3206"
                          transform="translate(-84.27 -177.707)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5495" transform="translate(2.147 11.82)">
                    <g data-name="Group 5494">
                      <g data-name="Group 5493">
                        <path
                          fill="#455a64"
                          d="M90.462 183.125a5.945 5.945 0 011.107-4.236 1.832 1.832 0 00-.445.5 4.7 4.7 0 00-.674 1.471 4.625 4.625 0 00-.132 1.611 1.866 1.866 0 00.144.654z"
                          data-name="Path 3207"
                          transform="translate(-90.297 -178.888)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5666" transform="translate(5.074 1.21)">
                    <g data-name="Group 5665">
                      <g data-name="Group 5501">
                        <g data-name="Group 5500">
                          <g data-name="Group 5499">
                            <g data-name="Group 5498">
                              <g data-name="Group 5497">
                                <g data-name="Group 5496">
                                  <path
                                    fill="#ffbe9d"
                                    d="M107.745 160.85c-.023-1.181-.05-2.147-.05-2.144s2.745-.3 3.5-3.82a37.8 37.8 0 00.428-7.022c-.018-2.136-1.837-5.047-4.476-5.056l-1.172-.138-4.488 1.784a1.251 1.251 0 00-1.186 1.2l-.015 15.257 4.482 11.179 2.85-5.094z"
                                    data-name="Path 3208"
                                    transform="translate(-100.29 -142.67)"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 5503"
                        transform="translate(5.625 10.745)"
                      >
                        <g data-name="Group 5502">
                          <path
                            fill="#eb996e"
                            d="M119.5 180a.766.766 0 011.186-.516 1.026 1.026 0 01.39.964.932.932 0 01-.668.387.892.892 0 01-.126 0 .845.845 0 01-.565-.223.764.764 0 01-.217-.612z"
                            data-name="Path 3209"
                            transform="translate(-119.492 -179.35)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 5664"
                        transform="translate(2.721 4.191)"
                      >
                        <g data-name="Group 5663">
                          <g data-name="Group 5662">
                            <g
                              data-name="Group 5520"
                              transform="translate(6.673 3.335)"
                            >
                              <g data-name="Group 5519">
                                <g data-name="Group 5518">
                                  <g data-name="Group 5517">
                                    <g data-name="Group 5516">
                                      <g data-name="Group 5515">
                                        <g data-name="Group 5514">
                                          <g data-name="Group 5513">
                                            <g data-name="Group 5512">
                                              <g data-name="Group 5511">
                                                <g data-name="Group 5510">
                                                  <g data-name="Group 5509">
                                                    <g data-name="Group 5508">
                                                      <g data-name="Group 5507">
                                                        <g data-name="Group 5506">
                                                          <g data-name="Group 5505">
                                                            <g data-name="Group 5504">
                                                              <path
                                                                fill="#263238"
                                                                d="M133.268 168.8a.459.459 0 01-.463.436.434.434 0 01-.445-.436.459.459 0 01.463-.436.438.438 0 01.445.436z"
                                                                data-name="Path 3210"
                                                                transform="translate(-132.36 -168.36)"
                                                              ></path>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5538"
                              transform="translate(5.945 1.792)"
                            >
                              <g data-name="Group 5537">
                                <g data-name="Group 5536">
                                  <g data-name="Group 5535">
                                    <g data-name="Group 5534">
                                      <g data-name="Group 5533">
                                        <g data-name="Group 5532">
                                          <g data-name="Group 5531">
                                            <g data-name="Group 5530">
                                              <g data-name="Group 5529">
                                                <g data-name="Group 5528">
                                                  <g data-name="Group 5527">
                                                    <g data-name="Group 5526">
                                                      <g data-name="Group 5525">
                                                        <g data-name="Group 5524">
                                                          <g data-name="Group 5523">
                                                            <g data-name="Group 5522">
                                                              <g data-name="Group 5521">
                                                                <path
                                                                  fill="#263238"
                                                                  d="M131.671 163.618c-.059.056-.39-.217-.882-.243s-.861.2-.911.138c-.023-.029.038-.132.2-.237a1.236 1.236 0 01.735-.182 1.2 1.2 0 01.7.267c.141.122.187.233.158.257z"
                                                                  data-name="Path 3211"
                                                                  transform="translate(-129.874 -163.091)"
                                                                ></path>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5555"
                              transform="translate(4.657 1.938)"
                            >
                              <g data-name="Group 5554">
                                <g data-name="Group 5553">
                                  <g data-name="Group 5552">
                                    <g data-name="Group 5551">
                                      <g data-name="Group 5550">
                                        <g data-name="Group 5549">
                                          <g data-name="Group 5548">
                                            <g data-name="Group 5547">
                                              <g data-name="Group 5546">
                                                <g data-name="Group 5545">
                                                  <g data-name="Group 5544">
                                                    <g data-name="Group 5543">
                                                      <g data-name="Group 5542">
                                                        <g data-name="Group 5541">
                                                          <g data-name="Group 5540">
                                                            <g data-name="Group 5539">
                                                              <path
                                                                fill="#263238"
                                                                d="M125.484 167.682a3.147 3.147 0 01.8-.1c.126-.006.246-.023.272-.108a.634.634 0 00-.064-.375c-.1-.311-.208-.633-.316-.973a16.359 16.359 0 01-.694-2.537 16.414 16.414 0 01.9 2.475l.3.978a.722.722 0 01.038.5.318.318 0 01-.217.173.816.816 0 01-.214.018 3.111 3.111 0 01-.805-.051z"
                                                                data-name="Path 3212"
                                                                transform="translate(-125.478 -163.589)"
                                                              ></path>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5571"
                              transform="translate(0 10.316)"
                            >
                              <g data-name="Group 5570">
                                <g data-name="Group 5569">
                                  <g data-name="Group 5568">
                                    <g data-name="Group 5567">
                                      <g data-name="Group 5566">
                                        <g data-name="Group 5565">
                                          <g data-name="Group 5564">
                                            <g data-name="Group 5563">
                                              <g data-name="Group 5562">
                                                <g data-name="Group 5561">
                                                  <g data-name="Group 5560">
                                                    <g data-name="Group 5559">
                                                      <g data-name="Group 5558">
                                                        <g data-name="Group 5557">
                                                          <g data-name="Group 5556">
                                                            <path
                                                              fill="#eb996e"
                                                              d="M114.264 193.719a9.037 9.037 0 01-4.684-1.529 4.75 4.75 0 004.7 2.393z"
                                                              data-name="Path 3213"
                                                              transform="translate(-109.58 -192.19)"
                                                            ></path>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5588"
                              transform="translate(3.108 6.291)"
                            >
                              <g data-name="Group 5587">
                                <g data-name="Group 5586">
                                  <g data-name="Group 5585">
                                    <g data-name="Group 5584">
                                      <g data-name="Group 5583">
                                        <g data-name="Group 5582">
                                          <g data-name="Group 5581">
                                            <g data-name="Group 5580">
                                              <g data-name="Group 5579">
                                                <g data-name="Group 5578">
                                                  <g data-name="Group 5577">
                                                    <g data-name="Group 5576">
                                                      <g data-name="Group 5575">
                                                        <g data-name="Group 5574">
                                                          <g data-name="Group 5573">
                                                            <g data-name="Group 5572">
                                                              <path
                                                                fill="#263238"
                                                                d="M120.286 178.45c.079 0 .053.53.486.935s1 .375 1 .448c.006.035-.132.1-.372.091a1.339 1.339 0 01-.841-.346 1.175 1.175 0 01-.375-.782c-.001-.223.064-.352.102-.346z"
                                                                data-name="Path 3214"
                                                                transform="translate(-120.189 -178.449)"
                                                              ></path>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5607"
                              transform="translate(5.959)"
                            >
                              <g data-name="Group 5606">
                                <g data-name="Group 5605">
                                  <g data-name="Group 5604">
                                    <g data-name="Group 5603">
                                      <g data-name="Group 5602">
                                        <g data-name="Group 5601">
                                          <g data-name="Group 5600">
                                            <g data-name="Group 5599">
                                              <g data-name="Group 5598">
                                                <g data-name="Group 5597">
                                                  <g data-name="Group 5596">
                                                    <g data-name="Group 5595">
                                                      <g data-name="Group 5594">
                                                        <g data-name="Group 5593">
                                                          <g data-name="Group 5592">
                                                            <g data-name="Group 5591">
                                                              <g data-name="Group 5590">
                                                                <g data-name="Group 5589">
                                                                  <path
                                                                    fill="#263238"
                                                                    d="M131.585 157.533c-.091.105-.425-.026-.829-.056s-.753.032-.826-.088c-.032-.059.029-.167.185-.264a1.154 1.154 0 01.685-.146 1.138 1.138 0 01.65.261c.135.126.176.243.135.293z"
                                                                    data-name="Path 3215"
                                                                    transform="translate(-129.922 -156.975)"
                                                                  ></path>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5624"
                              transform="translate(2.197 3.134)"
                            >
                              <g data-name="Group 5623">
                                <g data-name="Group 5622">
                                  <g data-name="Group 5621">
                                    <g data-name="Group 5620">
                                      <g data-name="Group 5619">
                                        <g data-name="Group 5618">
                                          <g data-name="Group 5617">
                                            <g data-name="Group 5616">
                                              <g data-name="Group 5615">
                                                <g data-name="Group 5614">
                                                  <g data-name="Group 5613">
                                                    <g data-name="Group 5612">
                                                      <g data-name="Group 5611">
                                                        <g data-name="Group 5610">
                                                          <g data-name="Group 5609">
                                                            <g data-name="Group 5608">
                                                              <path
                                                                fill="#263238"
                                                                d="M117.079 168.078a.458.458 0 00.431.469.438.438 0 00.478-.4.458.458 0 00-.431-.469.433.433 0 00-.478.4z"
                                                                data-name="Path 3216"
                                                                transform="translate(-117.078 -167.675)"
                                                              ></path>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5642"
                              transform="translate(1.022 1.68)"
                            >
                              <g data-name="Group 5641">
                                <g data-name="Group 5640">
                                  <g data-name="Group 5639">
                                    <g data-name="Group 5638">
                                      <g data-name="Group 5637">
                                        <g data-name="Group 5636">
                                          <g data-name="Group 5635">
                                            <g data-name="Group 5634">
                                              <g data-name="Group 5633">
                                                <g data-name="Group 5632">
                                                  <g data-name="Group 5631">
                                                    <g data-name="Group 5630">
                                                      <g data-name="Group 5629">
                                                        <g data-name="Group 5628">
                                                          <g data-name="Group 5627">
                                                            <g data-name="Group 5626">
                                                              <g data-name="Group 5625">
                                                                <path
                                                                  fill="#263238"
                                                                  d="M113.074 163.173c.056.062.4-.19.9-.179s.847.261.9.2c.026-.026-.029-.135-.185-.252a1.234 1.234 0 00-.718-.234 1.218 1.218 0 00-.715.217c-.156.116-.208.221-.182.248z"
                                                                  data-name="Path 3217"
                                                                  transform="translate(-113.068 -162.71)"
                                                                ></path>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g
                              data-name="Group 5661"
                              transform="translate(1.369 .681)"
                            >
                              <g data-name="Group 5660">
                                <g data-name="Group 5659">
                                  <g data-name="Group 5658">
                                    <g data-name="Group 5657">
                                      <g data-name="Group 5656">
                                        <g data-name="Group 5655">
                                          <g data-name="Group 5654">
                                            <g data-name="Group 5653">
                                              <g data-name="Group 5652">
                                                <g data-name="Group 5651">
                                                  <g data-name="Group 5650">
                                                    <g data-name="Group 5649">
                                                      <g data-name="Group 5648">
                                                        <g data-name="Group 5647">
                                                          <g data-name="Group 5646">
                                                            <g data-name="Group 5645">
                                                              <g data-name="Group 5644">
                                                                <g data-name="Group 5643">
                                                                  <path
                                                                    fill="#263238"
                                                                    d="M114.264 159.789c.085.114.425 0 .832.006s.75.088.829-.026c.035-.056-.018-.17-.164-.275a1.127 1.127 0 00-.671-.193 1.114 1.114 0 00-.665.214c-.146.104-.196.218-.161.274z"
                                                                    data-name="Path 3218"
                                                                    transform="translate(-114.253 -159.299)"
                                                                  ></path>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5669" transform="translate(3.799 .831)">
                    <g data-name="Group 5668">
                      <g data-name="Group 5667">
                        <path
                          fill="#263238"
                          d="M97.221 149.759l.615.639c2.654-2.54 7.842-8.9 7.552-8.522a7.748 7.748 0 00-3.791-.413c-1.974.337-5.718 1.324-5.66 3.653a12.138 12.138 0 001.284 4.643z"
                          data-name="Path 3219"
                          transform="translate(-95.938 -141.377)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5672" transform="translate(3.269 .5)">
                    <g data-name="Group 5671">
                      <g data-name="Group 5670">
                        <path
                          fill="#455a64"
                          d="M94.167 150.972a5.422 5.422 0 00.023-.562 8.486 8.486 0 01.2-1.509 10.341 10.341 0 012.288-4.418 16.483 16.483 0 013.879-3.184 33.233 33.233 0 011.327-.768 3.883 3.883 0 00.486-.284.445.445 0 00-.138.05 6.24 6.24 0 00-.378.176c-.328.158-.794.4-1.356.724a15.41 15.41 0 00-3.943 3.178 10.027 10.027 0 00-2.276 4.5 7.491 7.491 0 00-.152 1.532c0 .182.015.322.021.419a.473.473 0 00.019.146z"
                          data-name="Path 3220"
                          transform="translate(-94.128 -140.246)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5675" transform="translate(2.686 .357)">
                    <g data-name="Group 5674">
                      <g data-name="Group 5673">
                        <path
                          fill="#455a64"
                          d="M99.446 139.757a.506.506 0 00-.141.047 17.992 17.992 0 00-1.784.867 20.357 20.357 0 00-1.957 1.242 11.993 11.993 0 00-2.139 1.875 5.556 5.556 0 00-.809 1.245 5.128 5.128 0 00-.4 1.324 8.062 8.062 0 00.006 2.323 15.911 15.911 0 00.398 1.945.482.482 0 00.05.141 4.007 4.007 0 00-.091-.565c-.064-.36-.164-.885-.24-1.535a8.243 8.243 0 01.029-2.285 5.1 5.1 0 01.393-1.28 5.39 5.39 0 01.788-1.2 12.237 12.237 0 012.1-1.857 22.125 22.125 0 011.928-1.263c.565-.334 1.037-.58 1.365-.747s.506-.265.504-.277z"
                          data-name="Path 3221"
                          transform="translate(-92.139 -139.756)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5678" transform="translate(5.035 .949)">
                    <g data-name="Group 5677">
                      <g data-name="Group 5676">
                        <path
                          fill="#455a64"
                          d="M100.159 150.1a2.265 2.265 0 00.316-.3l.817-.876c.683-.744 1.62-1.778 2.645-2.927s1.948-2.194 2.61-2.959l.776-.908a2.38 2.38 0 00.27-.349 2.267 2.267 0 00-.316.3c-.214.229-.489.524-.817.873a323.964 323.964 0 00-5.258 5.886c-.311.366-.571.671-.776.908a2.476 2.476 0 00-.267.352z"
                          data-name="Path 3222"
                          transform="translate(-100.158 -141.778)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5712" transform="translate(3.026 9.218)">
                    <g data-name="Group 5694">
                      <g data-name="Group 5693">
                        <g data-name="Group 5692">
                          <g data-name="Group 5691">
                            <g data-name="Group 5690">
                              <g data-name="Group 5689">
                                <g data-name="Group 5688">
                                  <g data-name="Group 5687">
                                    <g data-name="Group 5686">
                                      <g data-name="Group 5685">
                                        <g data-name="Group 5684">
                                          <g data-name="Group 5683">
                                            <g data-name="Group 5682">
                                              <g data-name="Group 5681">
                                                <g data-name="Group 5680">
                                                  <g data-name="Group 5679">
                                                    <path
                                                      fill="#ffbe9d"
                                                      d="M95.879 171.023c.015-.264-.258-.905-.516-.958a1.541 1.541 0 00-2.059 1.611 1.823 1.823 0 002.32 2c.006-.054.208-1.739.255-2.653z"
                                                      data-name="Path 3223"
                                                      transform="translate(-93.298 -170.005)"
                                                    ></path>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 5711" transform="translate(.776 .734)">
                      <g data-name="Group 5710">
                        <g data-name="Group 5709">
                          <g data-name="Group 5708">
                            <g data-name="Group 5707">
                              <g data-name="Group 5706">
                                <g data-name="Group 5705">
                                  <g data-name="Group 5704">
                                    <g data-name="Group 5703">
                                      <g data-name="Group 5702">
                                        <g data-name="Group 5701">
                                          <g data-name="Group 5700">
                                            <g data-name="Group 5699">
                                              <g data-name="Group 5698">
                                                <g data-name="Group 5697">
                                                  <g data-name="Group 5696">
                                                    <g data-name="Group 5695">
                                                      <path
                                                        fill="#eb996e"
                                                        d="M96.974 174.449c-.009-.009-.044.026-.117.056a.441.441 0 01-.316-.012 1.044 1.044 0 01-.425-.964 1.441 1.441 0 01.155-.6.49.49 0 01.337-.3.214.214 0 01.24.132c.029.07.009.117.023.123s.056-.038.041-.138a.266.266 0 00-.085-.158.327.327 0 00-.229-.076.6.6 0 00-.463.349 1.433 1.433 0 00-.187.665 1.1 1.1 0 00.551 1.084.449.449 0 00.39-.041c.079-.062.093-.114.085-.12z"
                                                        data-name="Path 3224"
                                                        transform="translate(-95.948 -172.509)"
                                                      ></path>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5716" transform="translate(2.306 12.429)">
                    <g data-name="Group 5715">
                      <g data-name="Group 5714">
                        <g data-name="Group 5713">
                          <path
                            fill="#ff725e"
                            d="M93.677 184.042c.006.009-.056.082-.2.176a1.657 1.657 0 01-.694.234 1.806 1.806 0 01-1.069-.217 1.8 1.8 0 01-.65-2.4 1.821 1.821 0 01.814-.727 1.626 1.626 0 01.718-.146.543.543 0 01.264.05 2.525 2.525 0 00-.917.223 1.738 1.738 0 00-.715.688 1.593 1.593 0 00-.123 1.184 1.615 1.615 0 00.7.961 1.726 1.726 0 00.967.234 2.565 2.565 0 00.905-.26z"
                            data-name="Path 3225"
                            transform="translate(-90.842 -180.965)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5771" transform="translate(24.383 90.673)">
                  <g data-name="Group 5723">
                    <g data-name="Group 5722">
                      <g data-name="Group 5721">
                        <g data-name="Group 5720">
                          <g data-name="Group 5719">
                            <g data-name="Group 5718">
                              <path
                                fill="#455a64"
                                d="M162.61 448.192l-.363 6.846s6.8 2.525 6.858 3.8l-13.265-.085.129-10.69z"
                                data-name="Path 3226"
                                transform="translate(-155.84 -448.06)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5730" transform="translate(1.994 6.335)">
                    <g data-name="Group 5729">
                      <g data-name="Group 5728">
                        <g data-name="Group 5727">
                          <g data-name="Group 5726">
                            <g data-name="Group 5725">
                              <g data-name="Group 5724">
                                <path
                                  fill="#e0e0e0"
                                  d="M163.046 469.707a.548.548 0 00-.39.618.523.523 0 00.609.39.576.576 0 00.407-.656.547.547 0 00-.68-.337"
                                  data-name="Path 3227"
                                  transform="translate(-162.646 -469.685)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5736" transform="translate(0 9.834)">
                    <g data-name="Group 5735">
                      <g data-name="Group 5734">
                        <g data-name="Group 5733">
                          <g data-name="Group 5732">
                            <g data-name="Group 5731">
                              <path
                                fill="#263238"
                                d="M155.84 482.483l.012-.853 12.872.343a1.013 1.013 0 01.448.668z"
                                data-name="Path 3228"
                                transform="translate(-155.84 -481.63)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5743" transform="translate(5.292 6.959)">
                    <g data-name="Group 5742">
                      <g data-name="Group 5741">
                        <g data-name="Group 5740">
                          <g data-name="Group 5739">
                            <g data-name="Group 5738">
                              <g data-name="Group 5737">
                                <path
                                  fill="#263238"
                                  d="M175.139 471.877c0 .064-.331.091-.656.293s-.5.478-.562.451.044-.4.442-.644.791-.159.776-.1z"
                                  data-name="Path 3229"
                                  transform="translate(-173.904 -471.814)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5750" transform="translate(7.02 7.599)">
                    <g data-name="Group 5749">
                      <g data-name="Group 5748">
                        <g data-name="Group 5747">
                          <g data-name="Group 5746">
                            <g data-name="Group 5745">
                              <g data-name="Group 5744">
                                <path
                                  fill="#263238"
                                  d="M180.7 474.03c.015.064-.272.17-.5.442s-.3.565-.36.56-.1-.375.187-.709.673-.358.673-.293z"
                                  data-name="Path 3230"
                                  transform="translate(-179.802 -474.001)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5757" transform="translate(8.614 8.202)">
                    <g data-name="Group 5756">
                      <g data-name="Group 5755">
                        <g data-name="Group 5754">
                          <g data-name="Group 5753">
                            <g data-name="Group 5752">
                              <g data-name="Group 5751">
                                <path
                                  fill="#263238"
                                  d="M185.32 477.178c-.059.012-.146-.316.029-.671s.483-.492.51-.436-.167.243-.3.536-.174.565-.239.571z"
                                  data-name="Path 3231"
                                  transform="translate(-185.246 -476.059)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5764" transform="translate(5.041 5.196)">
                    <g data-name="Group 5763">
                      <g data-name="Group 5762">
                        <g data-name="Group 5761">
                          <g data-name="Group 5760">
                            <g data-name="Group 5759">
                              <g data-name="Group 5758">
                                <path
                                  fill="#263238"
                                  d="M174.443 466.043c-.029.059-.328-.035-.7-.018s-.659.129-.694.073.249-.284.686-.3.741.192.708.245z"
                                  data-name="Path 3232"
                                  transform="translate(-173.048 -465.795)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5770" transform="translate(4.679 2.862)">
                    <g data-name="Group 5769">
                      <g data-name="Group 5768">
                        <g data-name="Group 5767">
                          <g data-name="Group 5766">
                            <g data-name="Group 5765">
                              <path
                                fill="#263238"
                                d="M173.834 458.937a1.286 1.286 0 01-.671-.015 2.959 2.959 0 01-.7-.229 3.091 3.091 0 01-.381-.211.789.789 0 01-.2-.161.288.288 0 01-.023-.349.356.356 0 01.29-.144.9.9 0 01.252.038 2.492 2.492 0 01.416.149 2.374 2.374 0 01.612.416c.3.293.407.539.384.551s-.179-.187-.489-.431a2.765 2.765 0 00-.592-.349 2.451 2.451 0 00-.384-.126c-.141-.041-.261-.044-.3.021-.015.023-.015.032.015.076a.727.727 0 00.146.117 3.154 3.154 0 00.352.208 3.853 3.853 0 00.645.261 5.922 5.922 0 01.628.178z"
                                data-name="Path 3233"
                                transform="translate(-171.814 -457.829)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5825" transform="translate(37.759 77.406)">
                  <g data-name="Group 5777">
                    <g data-name="Group 5776">
                      <g data-name="Group 5775">
                        <g data-name="Group 5774">
                          <g data-name="Group 5773">
                            <g data-name="Group 5772">
                              <path
                                fill="#455a64"
                                d="M207.737 402.77l2.147 6.509s7.256-.12 7.769 1.046l-12.389 4.74-3.764-10z"
                                data-name="Path 3234"
                                transform="translate(-201.5 -402.77)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5784" transform="translate(4.204 7.299)">
                    <g data-name="Group 5783">
                      <g data-name="Group 5782">
                        <g data-name="Group 5781">
                          <g data-name="Group 5780">
                            <g data-name="Group 5779">
                              <g data-name="Group 5778">
                                <path
                                  fill="#e0e0e0"
                                  d="M216.068 427.776a.547.547 0 00-.138.718.525.525 0 00.709.144.58.58 0 00.144-.759.542.542 0 00-.753-.067"
                                  data-name="Path 3235"
                                  transform="translate(-215.851 -427.687)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5790" transform="translate(3.466 7.139)">
                    <g data-name="Group 5789">
                      <g data-name="Group 5788">
                        <g data-name="Group 5787">
                          <g data-name="Group 5786">
                            <g data-name="Group 5785">
                              <path
                                fill="#263238"
                                d="M213.629 432.3l-.3-.8 12.119-4.359a1.013 1.013 0 01.659.46z"
                                data-name="Path 3236"
                                transform="translate(-213.33 -427.14)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5797" transform="translate(7.603 6.489)">
                    <g data-name="Group 5796">
                      <g data-name="Group 5795">
                        <g data-name="Group 5794">
                          <g data-name="Group 5793">
                            <g data-name="Group 5792">
                              <g data-name="Group 5791">
                                <path
                                  fill="#263238"
                                  d="M228.364 424.941c.02.062-.275.2-.5.513s-.293.627-.36.624-.105-.393.179-.762.672-.437.681-.375z"
                                  data-name="Path 3237"
                                  transform="translate(-227.455 -424.92)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5804" transform="translate(9.458 6.553)">
                    <g data-name="Group 5803">
                      <g data-name="Group 5802">
                        <g data-name="Group 5801">
                          <g data-name="Group 5800">
                            <g data-name="Group 5799">
                              <g data-name="Group 5798">
                                <path
                                  fill="#263238"
                                  d="M234.358 425.152c.035.056-.19.258-.3.595s-.07.633-.132.653-.228-.313-.082-.729.493-.578.514-.519z"
                                  data-name="Path 3238"
                                  transform="translate(-233.785 -425.14)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5811" transform="translate(11.143 6.634)">
                    <g data-name="Group 5810">
                      <g data-name="Group 5809">
                        <g data-name="Group 5808">
                          <g data-name="Group 5807">
                            <g data-name="Group 5806">
                              <g data-name="Group 5805">
                                <path
                                  fill="#263238"
                                  d="M239.758 426.646c-.05.035-.249-.24-.217-.636s.27-.633.316-.592-.067.287-.088.612.048.587-.011.616z"
                                  data-name="Path 3239"
                                  transform="translate(-239.538 -425.414)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5818" transform="translate(6.571 4.993)">
                    <g data-name="Group 5817">
                      <g data-name="Group 5816">
                        <g data-name="Group 5815">
                          <g data-name="Group 5814">
                            <g data-name="Group 5813">
                              <g data-name="Group 5812">
                                <path
                                  fill="#263238"
                                  d="M225.213 419.889c-.006.064-.316.085-.656.237s-.568.36-.618.319.126-.354.527-.53.758-.085.747-.026z"
                                  data-name="Path 3240"
                                  transform="translate(-223.929 -419.814)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5824" transform="translate(5.363 3.109)">
                    <g data-name="Group 5823">
                      <g data-name="Group 5822">
                        <g data-name="Group 5821">
                          <g data-name="Group 5820">
                            <g data-name="Group 5819">
                              <path
                                fill="#263238"
                                d="M222.008 413.878a1.256 1.256 0 01-.63.229 3.012 3.012 0 01-.732.041 2.953 2.953 0 01-.434-.059.891.891 0 01-.246-.076.287.287 0 01-.149-.316.359.359 0 01.217-.24.854.854 0 01.249-.056 2.478 2.478 0 01.439-.012 2.288 2.288 0 01.721.167c.393.161.574.354.56.375s-.234-.108-.612-.223a2.728 2.728 0 00-.677-.108 2.355 2.355 0 00-.4.023c-.146.015-.261.056-.272.126 0 .026 0 .038.041.064a.586.586 0 00.179.056 3.484 3.484 0 00.4.067 3.984 3.984 0 00.694.009 4.274 4.274 0 01.652-.067z"
                                data-name="Path 3241"
                                transform="translate(-219.808 -413.384)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5839" transform="translate(0 50.174)">
                  <g data-name="Group 5833" transform="translate(1.334)">
                    <g data-name="Group 5832">
                      <g data-name="Group 5828">
                        <g data-name="Group 5827">
                          <g data-name="Group 5826">
                            <path
                              fill="#263238"
                              d="M85.377 309.849s19.733-.085 21.294 0c2.783.149 4.646 3.255 5.018 4.866s11 27.215 11 27.215l-8.7 3.527L105 325.173l-4.523-7.869-23.313 3.6z"
                              data-name="Path 3242"
                              transform="translate(-77.16 -309.811)"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g data-name="Group 5831" transform="translate(6 6.133)">
                        <g data-name="Group 5830">
                          <g data-name="Group 5829">
                            <path
                              fill="#263238"
                              d="M116.053 331.38a7.276 7.276 0 015.61 2.915c1.157 1.714.75 4.775.773 7.083.032 3.146.492 27.959.492 27.959-2.323 1.649-6.2 1.43-9.029.322l-2-27.016-.275-4.013-13.707.3a8.807 8.807 0 011.977-8.068c.425-.449 16.159.518 16.159.518z"
                              data-name="Path 3243"
                              transform="translate(-97.641 -330.746)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5835" transform="translate(0 1.13)">
                    <g data-name="Group 5834">
                      <path
                        fill="#263238"
                        d="M73.434 314.569s-2.32 5.358.811 9.94c2.068 3.026 6.038 2.332 6.038 2.332l5.27-6.6-4.2-6.571z"
                        data-name="Path 3244"
                        transform="translate(-72.607 -313.67)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 5838" transform="translate(8.74 7.63)">
                    <g data-name="Group 5837">
                      <g data-name="Group 5836">
                        <path
                          fill="#455a64"
                          d="M125.7 351.491a.631.631 0 01-.006-.1v-.291c0-.255.009-.627.023-1.107.035-.973.088-2.361.152-4.072.023-.858.038-1.8.015-2.812-.009-.5-.038-1.025-.073-1.564s-.05-1.1-.12-1.655a5.046 5.046 0 00-.469-1.649 4.959 4.959 0 00-.463-.759 2.523 2.523 0 00-.627-.633 4.983 4.983 0 00-1.711-.7 5.2 5.2 0 00-1.907-.1 36.26 36.26 0 00-1.925.24 9.568 9.568 0 00-1.816.439c-1.157.442-2.282.844-3.357 1.225l-5.715 1.972c-1.629.56-2.941 1.019-3.849 1.342-.445.161-.8.284-1.043.375-.114.041-.2.07-.272.094a.4.4 0 01-.094.026.39.39 0 01.088-.041c.067-.026.155-.064.267-.108.234-.1.583-.231 1.034-.4.9-.34 2.209-.817 3.835-1.389s3.559-1.248 5.707-2c1.072-.381 2.194-.788 3.351-1.23a9.732 9.732 0 011.848-.451c.633-.1 1.28-.176 1.933-.243a5.269 5.269 0 011.954.105 5.136 5.136 0 011.764.724 2.69 2.69 0 01.659.668 5.348 5.348 0 01.475.782 5.182 5.182 0 01.475 1.7c.067.571.079 1.128.114 1.661s.059 1.063.067 1.57c.018 1.014 0 1.957-.035 2.818-.067 1.72-.155 3.111-.2 4.072l-.053 1.107-.015.287a.494.494 0 01-.011.097z"
                          data-name="Path 3245"
                          transform="translate(-102.44 -335.856)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5888" transform="translate(.346 17.624)">
                  <g data-name="Group 5887">
                    <g data-name="Group 5865">
                      <g data-name="Group 5841" transform="translate(0 2.634)">
                        <g data-name="Group 5840">
                          <path
                            fill="#a5668b"
                            d="M93.919 238.218l-2.156-7.746 2.783-11.167-.987-9.723-4.007-1.028-7.883-.864s-6.99 1.6-7.54 4.292c-.826 4.046 1.931 17.123 1.931 17.386s-2.593 9.037-2.238 11.334c0 0 3.225.58 6.046 1.383 3.811 1.084 9.395-2.218 11.5-2.739 2.463-.615 2.551-1.128 2.551-1.128z"
                            data-name="Path 3246"
                            transform="translate(-73.786 -207.69)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 5843"
                        transform="translate(17.973 4.523)"
                      >
                        <g data-name="Group 5842">
                          <path
                            fill="#a5668b"
                            d="M147.239 221.2c-.12.252-2.569 11.155-3.843 12.362a3.843 3.843 0 01-5.484.056c-1.4-1.474-2.771-6.43-2.771-6.43l1.8-13.051 1.772 1.869 1.764 8.5 1.157-4.564z"
                            data-name="Path 3247"
                            transform="translate(-135.14 -214.14)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 5846"
                        transform="translate(21.37 10.326)"
                      >
                        <g data-name="Group 5845">
                          <g data-name="Group 5844">
                            <path
                              fill="#263238"
                              d="M146.736 244.845a.564.564 0 00.05-.105c.032-.085.073-.185.12-.308.1-.27.243-.659.41-1.142a74.032 74.032 0 002.19-7.71c.111-.5.2-.908.252-1.189l.059-.325c.015-.088.018-.114.012-.114a.511.511 0 00-.038.108l-.082.319-.29 1.181c-.246 1-.595 2.367-1.022 3.873s-.85 2.856-1.163 3.832c-.152.475-.278.864-.372 1.154-.038.126-.07.229-.1.316-.02.065-.031.107-.026.11z"
                              data-name="Path 3248"
                              transform="translate(-146.735 -233.95)"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 5849"
                        transform="translate(21.125 15.107)"
                      >
                        <g data-name="Group 5848">
                          <g data-name="Group 5847">
                            <path
                              fill="#263238"
                              d="M145.9 253.848a5.408 5.408 0 00.2-.586 7.235 7.235 0 011.515-2.537 5.943 5.943 0 00.422-.457 2.183 2.183 0 00-.483.4 5.952 5.952 0 00-1.535 2.569 2.073 2.073 0 00-.119.611z"
                              data-name="Path 3249"
                              transform="translate(-145.897 -250.268)"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g data-name="Group 5864" transform="translate(6.158)">
                        <g data-name="Group 5851">
                          <g data-name="Group 5850">
                            <path
                              fill="#a5668b"
                              d="M104.351 204.067s1.2-2.78-.228-4.057l-7.67-1.31s-2.068 1.916-1.57 4.213 6.984 2.437 6.984 2.437z"
                              data-name="Path 3250"
                              transform="translate(-94.806 -198.7)"
                            ></path>
                          </g>
                        </g>
                        <g
                          data-name="Group 5854"
                          transform="translate(8.783 1.239)"
                        >
                          <g data-name="Group 5853">
                            <g data-name="Group 5852">
                              <path
                                fill="#263238"
                                d="M124.993 206.837c.006.006.05-.038.12-.12a2.513 2.513 0 00.252-.39 2.887 2.887 0 00-.155-2.929 2.332 2.332 0 00-.29-.36c-.076-.076-.126-.114-.132-.108s.152.187.349.516a3.115 3.115 0 01.428 1.4 3.156 3.156 0 01-.278 1.441c-.162.347-.312.535-.294.55z"
                                data-name="Path 3251"
                                transform="translate(-124.787 -202.928)"
                              ></path>
                            </g>
                          </g>
                        </g>
                        <g
                          data-name="Group 5857"
                          transform="translate(6.535 .969)"
                        >
                          <g data-name="Group 5856">
                            <g data-name="Group 5855">
                              <path
                                fill="#263238"
                                d="M117.166 202.008c-.018.015.144.179.287.5a2.769 2.769 0 01.2 1.362 5.446 5.446 0 01-.343 1.356 3.791 3.791 0 00-.193.557 2.322 2.322 0 00.272-.524 4.638 4.638 0 00.384-1.38 2.585 2.585 0 00-.243-1.409 1.7 1.7 0 00-.246-.363c-.068-.069-.112-.107-.118-.099z"
                                data-name="Path 3252"
                                transform="translate(-117.114 -202.008)"
                              ></path>
                            </g>
                          </g>
                        </g>
                        <g
                          data-name="Group 5860"
                          transform="translate(3.116 .609)"
                        >
                          <g data-name="Group 5859">
                            <g data-name="Group 5858">
                              <path
                                fill="#263238"
                                d="M105.446 204.3a1.665 1.665 0 00.331-.437 4.664 4.664 0 00.519-1.222 4.718 4.718 0 00.152-1.318 1.665 1.665 0 00-.073-.545 7.858 7.858 0 01-.2 1.831 7.748 7.748 0 01-.729 1.691z"
                                data-name="Path 3253"
                                transform="translate(-105.444 -200.78)"
                              ></path>
                            </g>
                          </g>
                        </g>
                        <g
                          data-name="Group 5863"
                          transform="translate(1.058 .275)"
                        >
                          <g data-name="Group 5862">
                            <g data-name="Group 5861">
                              <path
                                fill="#263238"
                                d="M98.446 202.814a5.487 5.487 0 011.213-3.176 1.545 1.545 0 00-.36.366 4.167 4.167 0 00-.612 1.081 4.1 4.1 0 00-.264 1.213 1.423 1.423 0 00.023.516z"
                                data-name="Path 3254"
                                transform="translate(-98.416 -199.639)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 5883" transform="translate(.339 4.656)">
                      <g data-name="Group 5867">
                        <g data-name="Group 5866">
                          <path
                            fill="#a5668b"
                            d="M86.909 219.786l-1.881 5.935-3.038-8.34a4.3 4.3 0 00-1.81-2.238 3.84 3.84 0 00-3.9-.032c-3 1.749-.018 8.214-.018 8.214l5.455 11.545a3.549 3.549 0 002.49 2.056 5.133 5.133 0 003.58-.5c3-1.588 4.33-13.979 4.33-13.979z"
                            data-name="Path 3255"
                            transform="translate(-74.943 -214.593)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 5870"
                        transform="translate(1.771 5.175)"
                      >
                        <g data-name="Group 5869">
                          <g data-name="Group 5868">
                            <path
                              fill="#263238"
                              d="M80.99 236.862l.044.123.144.354c.132.313.322.77.565 1.353s.551 1.307.9 2.139l.565 1.336c.2.475.419.973.683 1.476s.551 1.028.852 1.573c.146.272.3.551.451.835s.3.58.478.867a7.329 7.329 0 001.312 1.617 4.792 4.792 0 001.922 1.084 3.73 3.73 0 002.308-.062 3.817 3.817 0 001.825-1.57 10.078 10.078 0 001.031-2.287 50.54 50.54 0 00.75-2.464 54.085 54.085 0 001.632-8.3l.006-.041-.038-.018c-1.887-.932-3.65-1.8-5.258-2.593l-.053-.026-.018.056-2.388 7.52c-.281.9-.5 1.605-.656 2.092-.073.237-.129.422-.17.551s-.053.19-.053.19.026-.061.067-.185.1-.311.182-.545c.158-.483.39-1.186.683-2.083.586-1.8 1.421-4.385 2.431-7.505l-.07.029 5.253 2.607-.032-.059a54.51 54.51 0 01-1.635 8.279q-.352 1.252-.75 2.458a9.866 9.866 0 01-1.014 2.262 3.731 3.731 0 01-1.766 1.526 3.635 3.635 0 01-2.238.062 4.681 4.681 0 01-1.878-1.055 7.292 7.292 0 01-1.3-1.591c-.176-.284-.328-.571-.478-.858s-.3-.562-.457-.835c-.3-.542-.595-1.063-.858-1.567s-.489-.993-.688-1.468-.4-.917-.574-1.33c-.357-.829-.668-1.544-.92-2.13s-.451-1.034-.586-1.345c-.067-.149-.117-.267-.155-.349a1.09 1.09 0 00-.051-.123z"
                              data-name="Path 3256"
                              transform="translate(-80.99 -232.26)"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 5873"
                        transform="translate(7.304 10.802)"
                      >
                        <g data-name="Group 5872">
                          <g data-name="Group 5871">
                            <path
                              fill="#263238"
                              d="M99.878 254.058a2.271 2.271 0 00.422-.4c.246-.261.565-.639.914-1.052.176-.208.346-.4.5-.577a2.531 2.531 0 01.436-.425.334.334 0 01.36-.026c.076.053.091.111.1.105s.009-.067-.073-.144a.374.374 0 00-.434-.012 2.244 2.244 0 00-.478.425 13.73 13.73 0 00-.513.574c-.352.416-.659.8-.888 1.075a3.1 3.1 0 00-.346.457z"
                              data-name="Path 3257"
                              transform="translate(-99.876 -251.468)"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 5876"
                        transform="translate(6.671 2.02)"
                      >
                        <g data-name="Group 5875">
                          <g data-name="Group 5874">
                            <path
                              fill="#263238"
                              d="M97.717 221.49a2.152 2.152 0 00.126.357c.1.246.228.568.384.958.328.809.782 1.925 1.26 3.167s.885 2.376 1.166 3.2c.135.4.246.727.328.978a1.983 1.983 0 00.132.354 2.224 2.224 0 00-.085-.369 22.407 22.407 0 00-.293-.993c-.261-.835-.653-1.974-1.134-3.219s-.946-2.355-1.295-3.155c-.173-.4-.319-.724-.422-.943a2.438 2.438 0 00-.167-.335z"
                              data-name="Path 3258"
                              transform="translate(-97.716 -221.49)"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 5879"
                        transform="translate(.482 .617)"
                      >
                        <g data-name="Group 5878">
                          <g data-name="Group 5877">
                            <path
                              fill="#263238"
                              d="M76.589 217.523c.015.02.264-.158.715-.346a4.407 4.407 0 01.814-.252 5.228 5.228 0 011.037-.1 5.152 5.152 0 011.037.126 4.652 4.652 0 01.809.27c.445.2.691.381.706.36s-.053-.056-.164-.138a1.638 1.638 0 00-.211-.141 2.636 2.636 0 00-.293-.161 4.176 4.176 0 00-.82-.3 4.919 4.919 0 00-1.06-.138 4.656 4.656 0 00-1.063.117 4.362 4.362 0 00-.826.284 2.731 2.731 0 00-.3.155 1.732 1.732 0 00-.214.135.751.751 0 00-.167.129z"
                              data-name="Path 3259"
                              transform="translate(-76.589 -216.7)"
                            ></path>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 5882"
                        opacity="0.3"
                        transform="translate(7.127 10.86)"
                      >
                        <g data-name="Group 5881">
                          <g data-name="Group 5880">
                            <path
                              d="M102.169 251.969c-.492 1.491-.943 2.953-1.435 4.444a1.33 1.33 0 01-.328.439.343.343 0 01-.434.059.462.462 0 01-.138-.308 2.839 2.839 0 01.155-1.447.516.516 0 01-.647-.2c-.135-.208-.047-.311.038-.545a2.279 2.279 0 01.431-.627c.642-.756 1.986-2.672 2.379-1.966"
                              data-name="Path 3260"
                              transform="translate(-99.273 -251.666)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 5886"
                      opacity="0.3"
                      transform="translate(5.856 3.449)"
                    >
                      <g data-name="Group 5885">
                        <g data-name="Group 5884">
                          <path
                            d="M103.295 212.339c-2.5.9-4.983.354-7.611-.961-.442-.223-.595-.439-.911-.571s-.536-.489-.785-.255a.873.873 0 00-.067 1.058 2.209 2.209 0 00.864.729c2.654 1.494 7.271 1.342 8.51 0"
                            data-name="Path 3261"
                            transform="translate(-93.776 -210.474)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 5951" transform="translate(13.403 12.272)">
                  <g data-name="Group 5890" transform="translate(15.712 11.39)">
                    <g data-name="Group 5889">
                      <path
                        fill="#ffbe9d"
                        d="M172.676 219.67a1.8 1.8 0 011.386-.334.858.858 0 01.7.5.753.753 0 01-.413.829 2.2 2.2 0 01-.976.193c-.173.009-.346.015-.519.021a1.046 1.046 0 01-.6-.094.652.652 0 01-.161-.879 1.006 1.006 0 01.855-.419"
                        data-name="Path 3262"
                        transform="translate(-171.994 -219.31)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 5892"
                    transform="translate(11.501 10.121)"
                  >
                    <g data-name="Group 5891">
                      <path
                        fill="#ffbe9d"
                        d="M162.747 215.9l-.533 2.475c-.155.721-.246 1.43-.932 1.7l-.146 1.295-3.515-.77.6-3.085 3.471-2.534z"
                        data-name="Path 3263"
                        transform="translate(-157.62 -214.98)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 5894" transform="translate(11.982 6.599)">
                    <g data-name="Group 5893">
                      <path
                        fill="#ffbe9d"
                        d="M161.735 205.706a3.929 3.929 0 00-.63-1.848 2.143 2.143 0 00-.574-.715 1.025 1.025 0 00-.885-.138.493.493 0 00-.387.507 1.439 1.439 0 00.29.662c.442.759.513 1.2.337 1.544"
                        data-name="Path 3264"
                        transform="translate(-159.26 -202.957)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 5897" transform="translate(7.511 8.837)">
                    <g data-name="Group 5895" transform="translate(.012 .008)">
                      <path
                        fill="#455a64"
                        d="M153.2 211.6a16.47 16.47 0 01-1.538 3.829 5.285 5.285 0 01-1.52 1.57l-3.562-.1a6.673 6.673 0 01-2.543-5.537s.715-.867 4.582-.721a7.8 7.8 0 014.581.959z"
                        data-name="Path 3265"
                        transform="translate(-144.04 -210.623)"
                      ></path>
                    </g>
                    <g data-name="Group 5896">
                      <path
                        fill="#263238"
                        d="M150.12 216.984l-3.568-.1a5.409 5.409 0 01-1.274-1.321 6.818 6.818 0 01-.876-1.723 7.738 7.738 0 01-.4-2.5 1.853 1.853 0 01.844-.419 12.831 12.831 0 013.744-.308h.275a7.541 7.541 0 014.312.964h.006v.006c-.023.053-.07.208-.135.422a13.132 13.132 0 01-1.406 3.407 5.389 5.389 0 01-1.52 1.573zm-3.562-.117l3.559.1a5.411 5.411 0 001.517-1.567 13.229 13.229 0 001.4-3.4c.062-.208.108-.36.132-.419a7.532 7.532 0 00-4.3-.958h-.275a12.788 12.788 0 00-3.738.3 1.863 1.863 0 00-.838.413 7.7 7.7 0 00.4 2.49 6.743 6.743 0 00.873 1.72 5.364 5.364 0 001.269 1.321z"
                        data-name="Path 3266"
                        transform="translate(-144 -210.597)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 5900" transform="translate(15.008 10.23)">
                    <g data-name="Group 5898" transform="translate(.009 .006)">
                      <path
                        fill="#455a64"
                        d="M169.62 218.839a2.883 2.883 0 002.569.19 1.947 1.947 0 00-.99-3.659l-.281 1.069s1.5.311 1.069 1.2-1.679.044-1.679.044z"
                        data-name="Path 3267"
                        transform="translate(-169.62 -215.37)"
                      ></path>
                    </g>
                    <g data-name="Group 5899">
                      <path
                        fill="#263238"
                        d="M171.184 219.249c-.1 0-.2-.006-.3-.015a3.209 3.209 0 01-1.283-.4h-.006v-.006l.691-1.157.006.006a1.88 1.88 0 001.236.337.665.665 0 00.434-.378.6.6 0 00.02-.548c-.229-.463-1.078-.644-1.087-.647h-.009v-.009l.284-1.075h.006a2 2 0 011.353.519 1.98 1.98 0 01.639 1.113 2.038 2.038 0 01-1 2.039 1.992 1.992 0 01-.984.221zm-1.576-.425a3.21 3.21 0 001.271.4 2.193 2.193 0 001.283-.208 2.026 2.026 0 00.993-2.024 2.027 2.027 0 00-1.974-1.623l-.278 1.058c.085.021.87.208 1.09.653a.612.612 0 01-.021.56.692.692 0 01-.442.387.872.872 0 01-.214.026 2.2 2.2 0 01-1.025-.36z"
                        data-name="Path 3268"
                        transform="translate(-169.59 -215.35)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 5911" transform="translate(15.016 7.998)">
                    <g data-name="Group 5902" transform="translate(.852 1.409)">
                      <g data-name="Group 5901">
                        <path
                          fill="#ffbe9d"
                          d="M175 212.54s.873 1.1 0 1.69-2.089.621-2.262.39-.419-.756.144-.841A9.147 9.147 0 00175 212.54z"
                          data-name="Path 3269"
                          transform="translate(-172.529 -212.54)"
                        ></path>
                      </g>
                    </g>
                    <g data-name="Group 5904">
                      <g data-name="Group 5903">
                        <path
                          fill="#ffbe9d"
                          d="M169.62 208.856l.873-.5.811-.431a1.764 1.764 0 011.04-.17 1.256 1.256 0 01.674 1.125c.012.709-2.112 1.828-2.461 1.509a.586.586 0 01.035-.964c.319-.205.8-.5.8-.5l-.583.357z"
                          data-name="Path 3270"
                          transform="translate(-169.62 -207.732)"
                        ></path>
                      </g>
                    </g>
                    <g data-name="Group 5907" transform="translate(.714 .28)">
                      <g data-name="Group 5906">
                        <g data-name="Group 5905">
                          <path
                            fill="#eb996e"
                            d="M172.281 211.023c.006-.009-.029-.035-.07-.1a.5.5 0 01-.073-.3.716.716 0 01.179-.416 5.128 5.128 0 01.419-.419 10.433 10.433 0 001.078-1.107 10.68 10.68 0 00-1.157 1.025 3.923 3.923 0 00-.422.439.762.762 0 00-.176.48.507.507 0 00.117.325c.058.064.102.079.105.073z"
                            data-name="Path 3271"
                            transform="translate(-172.057 -208.687)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 5910" transform="translate(.827 1.379)">
                      <g data-name="Group 5909">
                        <g data-name="Group 5908">
                          <path
                            fill="#eb996e"
                            d="M172.446 213.6c-.006 0 0 .05.062.111a.463.463 0 00.319.126 1.6 1.6 0 00.492-.091 3.013 3.013 0 001.409-.932 1.267 1.267 0 00.246-.381 4.548 4.548 0 01-1.148.978 3.5 3.5 0 01-.536.237 1.745 1.745 0 01-.46.108.47.47 0 01-.29-.079c-.064-.04-.085-.077-.094-.077z"
                            data-name="Path 3272"
                            transform="translate(-172.445 -212.438)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 5914"
                    transform="translate(14.696 12.492)"
                  >
                    <g data-name="Group 5913">
                      <g data-name="Group 5912">
                        <path
                          fill="#263238"
                          d="M168.715 224.2c.015-.012-.038-.088-.07-.229a.667.667 0 01.524-.82c.141-.032.231-.015.237-.032s-.088-.059-.249-.047a.69.69 0 00-.592.923c.059.153.141.217.15.205z"
                          data-name="Path 3273"
                          transform="translate(-168.526 -223.074)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5917" transform="translate(8.422 8.773)">
                    <g data-name="Group 5916">
                      <g data-name="Group 5915">
                        <path
                          fill="#263238"
                          d="M147.11 210.751a1.544 1.544 0 00.3-.035c.193-.029.475-.07.823-.114a16.712 16.712 0 012.73-.094 13.185 13.185 0 012.713.287 8.265 8.265 0 01.794.234 2.172 2.172 0 00.29.1 1.548 1.548 0 00-.272-.141 6.017 6.017 0 00-.788-.272 11.826 11.826 0 00-2.727-.322 14.253 14.253 0 00-2.745.126c-.349.053-.627.108-.82.149a2.111 2.111 0 00-.298.082z"
                          data-name="Path 3274"
                          transform="translate(-147.11 -210.379)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 5920"
                    transform="translate(15.952 10.923)"
                  >
                    <g data-name="Group 5919">
                      <g data-name="Group 5918">
                        <path
                          fill="#eb996e"
                          d="M172.819 218.1c-.006 0-.012.047.023.117a.422.422 0 00.261.193 1.594 1.594 0 00.469.029 3.674 3.674 0 00.565-.059 2.521 2.521 0 00.963-.38 1.069 1.069 0 00.316-.287 3.5 3.5 0 00-.357.22 2.92 2.92 0 01-.934.334 4.972 4.972 0 01-.548.067 1.83 1.83 0 01-.442 0 .409.409 0 01-.243-.141c-.055-.054-.064-.093-.073-.093z"
                          data-name="Path 3275"
                          transform="translate(-172.815 -217.715)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 5923"
                    opacity="0.4"
                    transform="translate(7.716 8.973)"
                  >
                    <g data-name="Group 5922">
                      <g data-name="Group 5921">
                        <path
                          d="M145.165 211.447c-2.344.492 4.772 1.055 7.47.325a.067.067 0 00.006-.129 14.073 14.073 0 00-7.476-.196z"
                          data-name="Path 3276"
                          transform="translate(-144.698 -211.06)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5940" transform="translate(0 7.482)">
                    <g data-name="Group 5925" transform="translate(5.63)">
                      <g data-name="Group 5924">
                        <path
                          fill="#ffbe9d"
                          d="M140.117 208.694a3.08 3.08 0 00.258-1.69 1.341 1.341 0 00-.4-.873.643.643 0 00-.885.029c-.193.246-.111.6-.111.911a2.154 2.154 0 01-.454 1.257 6.75 6.75 0 01-.94.978"
                          data-name="Path 3277"
                          transform="translate(-137.58 -205.97)"
                        ></path>
                      </g>
                    </g>
                    <g data-name="Group 5927" transform="translate(0 2.379)">
                      <g data-name="Group 5926">
                        <path
                          fill="#ffbe9d"
                          d="M118.36 219.82c.062-.155 2.49-3.805 2.49-3.805a27.57 27.57 0 014.9-1.925c.463.038 3.817.448 4.256.56.729.187.533.964.275.94-.568-.047-2.7-.117-2.7-.117l2.927.554.642.144s.346.088.313.4c-.047.445-.293.568-.738.568-.48 0-4.06-.393-4.06-.393l3.917 1.008a.409.409 0 01.311.536c-.114.393-.554.436-1.137.366a.416.416 0 01.05.211.594.594 0 01-.694.507c-.419-.026-5.51-.182-5.51-.182l-.759 2.836z"
                          data-name="Path 3278"
                          transform="translate(-118.36 -214.09)"
                        ></path>
                      </g>
                    </g>
                    <g
                      data-name="Group 5930"
                      transform="translate(7.221 3.255)"
                    >
                      <g data-name="Group 5929">
                        <g data-name="Group 5928">
                          <path
                            fill="#eb996e"
                            d="M143.01 217.083a.849.849 0 00.223.076c.146.041.357.1.618.164.524.132 1.251.308 2.056.486s1.538.325 2.071.419c.267.047.483.079.633.1a1.166 1.166 0 00.234.018.892.892 0 00-.228-.062c-.173-.035-.381-.082-.627-.132-.554-.12-1.271-.278-2.062-.448s-1.506-.331-2.062-.454l-.627-.129a.739.739 0 00-.229-.038z"
                            data-name="Path 3279"
                            transform="translate(-143.01 -217.08)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 5933"
                      transform="translate(7.435 4.793)"
                    >
                      <g data-name="Group 5932">
                        <g data-name="Group 5931">
                          <path
                            fill="#eb996e"
                            d="M143.74 222.334c-.009.029 1.058.334 2.379.68s2.4.6 2.408.571-1.058-.334-2.379-.68-2.399-.6-2.408-.571z"
                            data-name="Path 3280"
                            transform="translate(-143.74 -222.332)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 5936"
                      transform="translate(7.719 6.354)"
                    >
                      <g data-name="Group 5935">
                        <g data-name="Group 5934">
                          <path
                            fill="#eb996e"
                            d="M144.71 227.667a16.361 16.361 0 001.907.363 17.174 17.174 0 001.928.252 16.342 16.342 0 00-1.91-.363 15.6 15.6 0 00-1.925-.252z"
                            data-name="Path 3281"
                            transform="translate(-144.71 -227.661)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 5939"
                      transform="translate(4.379 2.329)"
                    >
                      <g data-name="Group 5938">
                        <g data-name="Group 5937">
                          <path
                            fill="#eb996e"
                            d="M139.312 214.336a.952.952 0 00-.223-.05c-.17-.026-.375-.056-.615-.094-.521-.073-1.245-.17-2.054-.269h-.024c-.053.018-.111.035-.167.056-.811.27-1.547.521-2.074.706-.246.088-.454.164-.624.226a1.09 1.09 0 00-.223.1.861.861 0 00.237-.056l.636-.193c.536-.167 1.271-.4 2.086-.677l.167-.056h-.023c.809.1 1.535.182 2.059.234.243.023.451.041.621.059a.888.888 0 00.221.014z"
                            data-name="Path 3282"
                            transform="translate(-133.31 -213.92)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5943" transform="translate(8.413 9.624)">
                    <g data-name="Group 5942">
                      <g data-name="Group 5941">
                        <path
                          fill="#263238"
                          d="M147.08 213.419a1.459 1.459 0 00.281.082c.185.047.451.1.785.158a14.6 14.6 0 005.258-.1c.331-.067.6-.132.779-.187a1.723 1.723 0 00.278-.091 1.22 1.22 0 00-.29.05 15.27 15.27 0 01-.782.149 17.328 17.328 0 01-2.613.252 17.556 17.556 0 01-2.619-.155c-.334-.047-.6-.088-.788-.12a1.807 1.807 0 00-.289-.038z"
                          data-name="Path 3283"
                          transform="translate(-147.08 -213.282)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 5950" transform="translate(9.894)">
                    <g data-name="Group 5946" transform="translate(1.055)">
                      <g data-name="Group 5945">
                        <g data-name="Group 5944">
                          <path
                            fill="#263238"
                            d="M156.294 188.421a.861.861 0 01-.152-.3 1.325 1.325 0 01.182-.888c.094-.173.217-.357.322-.571a1.278 1.278 0 00.158-.753c-.094-.586-.718-.893-.885-1.462a1.43 1.43 0 01-.012-.844 2.117 2.117 0 01.407-.694 2.2 2.2 0 00.4-.65 1.28 1.28 0 00.032-.668 2.7 2.7 0 00-.384-.876 1.647 1.647 0 00-.2-.284 2.8 2.8 0 00.164.308 2.946 2.946 0 01.343.867 1.211 1.211 0 01-.044.624 2.276 2.276 0 01-.393.618 2.132 2.132 0 00-.434.727 1.558 1.558 0 00.009.911 2.152 2.152 0 00.492.762 1.438 1.438 0 01.4.683 1.169 1.169 0 01-.141.694c-.1.208-.217.4-.308.574a1.331 1.331 0 00-.152.937.411.411 0 00.2.287z"
                            data-name="Path 3284"
                            transform="translate(-155.732 -180.43)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 5949">
                      <g data-name="Group 5948">
                        <g data-name="Group 5947">
                          <path
                            fill="#263238"
                            d="M152.694 188.421a.86.86 0 01-.152-.3 1.325 1.325 0 01.182-.888c.094-.173.217-.357.322-.571a1.278 1.278 0 00.158-.753c-.094-.586-.718-.893-.885-1.462a1.43 1.43 0 01-.012-.844 2.118 2.118 0 01.407-.694 2.2 2.2 0 00.4-.65 1.28 1.28 0 00.032-.668 2.7 2.7 0 00-.384-.876 1.649 1.649 0 00-.2-.284 2.788 2.788 0 00.164.308 2.946 2.946 0 01.343.867 1.211 1.211 0 01-.044.624 2.207 2.207 0 01-.393.618 2.132 2.132 0 00-.434.727 1.559 1.559 0 00.009.911 2.112 2.112 0 00.492.762 1.438 1.438 0 01.4.683 1.169 1.169 0 01-.141.694c-.1.208-.217.4-.308.574a1.331 1.331 0 00-.152.937.6.6 0 00.126.231c.044.033.067.057.07.054z"
                            data-name="Path 3285"
                            transform="translate(-152.132 -180.43)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(13.347 43.655)">
            <g data-name="Group 5975">
              <g data-name="Group 5959" transform="translate(72.475 3.249)">
                <g data-name="Group 5955" transform="translate(.032 .026)">
                  <g data-name="Group 5954">
                    <path
                      fill="#e0e0e0"
                      d="M333.561 298.74l5.9 54.4h-2.314l-7.737-54.4z"
                      data-name="Path 3286"
                      transform="translate(-329.41 -298.74)"
                    ></path>
                  </g>
                </g>
                <g data-name="Group 5958">
                  <g data-name="Group 5957">
                    <g data-name="Group 5956">
                      <path
                        fill="#263238"
                        d="M333.483 298.676l-.27.006c-.182 0-.445 0-.794.006l-3.085.015.026-.032 7.64 53.366.147 1.025-.076-.064h2.311l-.076.085c-1.635-15.175-3.1-28.767-4.151-38.566l-1.233-11.592-.331-3.146-.082-.82-.026-.281s.015.091.035.272.053.457.094.809c.079.718.2 1.769.352 3.129.3 2.727.738 6.676 1.277 11.571 1.072 9.811 2.557 23.418 4.218 38.61l.009.085H337l-.009-.064c-.047-.322-.1-.674-.146-1.025l-7.543-53.381-.006-.032h.032l3.1.015c.346 0 .606.006.788.006s.267.003.267.003z"
                        data-name="Path 3287"
                        transform="translate(-329.3 -298.65)"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5965" transform="translate(8.334 3.249)">
                <g data-name="Group 5961" transform="translate(.085 .026)">
                  <g data-name="Group 5960">
                    <path
                      fill="#e0e0e0"
                      d="M116.54 298.74l-5.9 54.4h2.311l7.737-54.4z"
                      data-name="Path 3288"
                      transform="translate(-110.64 -298.74)"
                    ></path>
                  </g>
                </g>
                <g data-name="Group 5964">
                  <g data-name="Group 5963">
                    <g data-name="Group 5962">
                      <path
                        fill="#263238"
                        d="M116.335 298.676l.261-.006c.179 0 .442 0 .788-.006l3.1-.015h.032l-.006.032c-1.175 8.323-4.11 29.093-7.543 53.381l-.146 1.025-.009.064h-2.462l.009-.085 4.218-38.61c.542-4.9.976-8.841 1.277-11.571.152-1.356.27-2.411.352-3.129l.094-.809c.021-.185.035-.272.035-.272s-.006.1-.026.284-.047.463-.082.82l-.331 3.146-1.233 11.592c-1.055 9.8-2.519 23.389-4.151 38.566l-.076-.085h2.311l-.076.064c.047-.322.1-.674.146-1.025a294407.6 294407.6 0 017.64-53.366l.026.032-3.085-.015c-.349 0-.612-.006-.794-.006z"
                        data-name="Path 3289"
                        transform="translate(-110.35 -298.65)"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 5974">
                <g data-name="Group 5973">
                  <g data-name="Group 5972">
                    <g data-name="Group 5971">
                      <g
                        data-name="Group 5967"
                        transform="translate(.073 .073)"
                      >
                        <g data-name="Group 5966">
                          <path
                            fill="#455a64"
                            d="M0 0H88.06V3.202H0z"
                            data-name="Rectangle 289"
                          ></path>
                        </g>
                      </g>
                      <g data-name="Group 5970">
                        <g data-name="Group 5969">
                          <g data-name="Group 5968">
                            <path
                              fill="#263238"
                              d="M170.033 290.835s-.006-.255-.009-.782l-.012-2.42.021.021c-9.855.006-45.562.029-88.054.059l.076-.076v3.2l-.076-.076 62.363.032 18.8.023 5.106.012c.583 0 1.028 0 1.33.006s.457 0 .457 0l-.445.006c-.3 0-.741 0-1.318.006l-5.088.012c-4.429.006-10.842.015-18.784.023l-62.421.032H81.9v-3.353h.079c42.492.026 78.2.05 88.054.059h.02v.021l-.012 2.431c-.003.531-.008.764-.008.764z"
                              data-name="Path 3290"
                              transform="translate(-81.9 -287.56)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(0 101.257)">
            <g data-name="Group 5978">
              <g data-name="Group 5977">
                <g data-name="Group 5976">
                  <path
                    fill="#263238"
                    d="M161.833 484.266c0 .041-28.094.076-62.743.076s-62.749-.035-62.749-.076 28.088-.076 62.749-.076 62.743.035 62.743.076z"
                    data-name="Path 3291"
                    transform="translate(-36.34 -484.19)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(82.944 -5.069)">
            <g data-name="Group 6016">
              <g data-name="Group 5993">
                <g data-name="Group 5992">
                  <g data-name="Group 5991">
                    <g data-name="Group 5990">
                      <g
                        data-name="Group 5983"
                        transform="translate(.045 .045)"
                      >
                        <g data-name="Group 5982">
                          <g data-name="Group 5981">
                            <g data-name="Group 5980">
                              <g data-name="Group 5979">
                                <path
                                  fill="#fafafa"
                                  d="M336.53 161.868l-1.737-3.061a8.191 8.191 0 10-1.963 2.185z"
                                  data-name="Path 3292"
                                  transform="translate(-319.63 -146.33)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g data-name="Group 5989">
                        <g data-name="Group 5988">
                          <g data-name="Group 5987">
                            <g data-name="Group 5986">
                              <g data-name="Group 5985">
                                <g data-name="Group 5984">
                                  <path
                                    fill="#263238"
                                    d="M336.422 161.76l-3.709-.847-.038-.009.023-.032.035.035a8.328 8.328 0 01-1.684 1.016 8.2 8.2 0 01-2.247.642 7.258 7.258 0 01-1.3.064c-.223-.012-.451-.018-.68-.038l-.691-.105a8.259 8.259 0 01-5.118-3.287 8.189 8.189 0 01-1.386-3.164 8.339 8.339 0 01.1-3.644 8.068 8.068 0 011.755-3.369l.334-.369c.111-.123.24-.226.36-.34s.237-.229.372-.325l.393-.293a3.5 3.5 0 01.407-.27c.138-.085.278-.167.416-.252l.434-.211a4.206 4.206 0 01.439-.2 8.364 8.364 0 013.671-.565 8.1 8.1 0 013.369 1 8.3 8.3 0 013.89 4.784 8.8 8.8 0 01.354 2.663 8.124 8.124 0 01-.4 2.3 8.369 8.369 0 01-.82 1.764v-.023c1.1 1.969 1.635 2.929 1.72 3.079-.085-.149-.636-1.1-1.758-3.05l-.006-.012.009-.012a8.351 8.351 0 00.806-1.755 8.041 8.041 0 00.387-2.282 8.833 8.833 0 00-.36-2.639 8.235 8.235 0 00-3.858-4.725 7.974 7.974 0 00-3.331-.981 8.294 8.294 0 00-3.627.56 3.369 3.369 0 00-.434.193l-.431.208c-.138.082-.272.167-.41.249a3.293 3.293 0 00-.4.267l-.39.29c-.132.094-.243.214-.366.319s-.246.214-.354.334l-.331.363a7.961 7.961 0 00-1.734 3.328 8.275 8.275 0 00-.1 3.6 8.064 8.064 0 001.365 3.129 8.2 8.2 0 005.059 3.263l.686.105c.226.021.451.026.674.038a7.143 7.143 0 001.286-.062 8.1 8.1 0 002.229-.627 8.414 8.414 0 001.679-1l.152-.117-.117.152-.015-.041c2.346.575 3.515.857 3.691.901z"
                                    data-name="Path 3293"
                                    transform="translate(-319.477 -146.177)"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 6015" transform="translate(4.26 7.398)">
                <g data-name="Group 6000">
                  <g data-name="Group 5999">
                    <g data-name="Group 5998">
                      <g data-name="Group 5997">
                        <g data-name="Group 5996">
                          <g data-name="Group 5995">
                            <g data-name="Group 5994">
                              <path
                                fill="#263238"
                                d="M335.684 172.262a.832.832 0 11-.832-.832.831.831 0 01.832.832z"
                                data-name="Path 3294"
                                transform="translate(-334.02 -171.43)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6007" transform="translate(3.328)">
                  <g data-name="Group 6006">
                    <g data-name="Group 6005">
                      <g data-name="Group 6004">
                        <g data-name="Group 6003">
                          <g data-name="Group 6002">
                            <g data-name="Group 6001">
                              <path
                                fill="#263238"
                                d="M347.044 172.262a.832.832 0 11-.832-.832.831.831 0 01.832.832z"
                                data-name="Path 3295"
                                transform="translate(-345.38 -171.43)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6014" transform="translate(6.761 .001)">
                  <g data-name="Group 6013">
                    <g data-name="Group 6012">
                      <g data-name="Group 6011">
                        <g data-name="Group 6010">
                          <g data-name="Group 6009">
                            <g data-name="Group 6008">
                              <path
                                fill="#263238"
                                d="M358.653 171.848a.832.832 0 11-1.137-.3.833.833 0 011.137.3z"
                                data-name="Path 3296"
                                transform="translate(-357.101 -171.433)"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(51.384 3.16)">
            <g data-name="Group 6555">
              <g data-name="Group 6055" transform="translate(36.648 34.732)">
                <g data-name="Group 6048">
                  <g data-name="Group 6024" transform="translate(0 34.187)">
                    <g data-name="Group 6019" transform="translate(.094)">
                      <g data-name="Group 6018">
                        <g data-name="Group 6017">
                          <path
                            fill="#e0e0e0"
                            d="M325.312 384.59l-11.642 29.122 1.922.07 13.455-29.057z"
                            data-name="Path 3297"
                            transform="translate(-313.67 -384.59)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6023">
                      <g data-name="Group 6022">
                        <g data-name="Group 6021">
                          <g data-name="Group 6020">
                            <path
                              fill="#263238"
                              d="M325.086 384.59l.246.015c.164.009.4.018.715.032l2.774.114-.026-.035-13.022 28.032-.469 1.014.064-.038-1.922-.07.062.082c3.252-8.161 6.152-15.438 8.238-20.679 1.04-2.622 1.875-4.734 2.455-6.193.287-.729.51-1.292.662-1.682.073-.19.129-.337.17-.439s.053-.152.053-.152-.023.047-.062.144l-.176.428c-.155.384-.384.943-.677 1.664l-2.487 6.169c-2.1 5.244-5.024 12.526-8.3 20.7l-.032.079h.091l1.922.07h.047l.018-.038.469-1.014 12.951-28.058.018-.035h-.041l-2.789-.088c-.311-.009-.548-.015-.712-.021z"
                              data-name="Path 3298"
                              transform="translate(-313.35 -384.59)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 6032"
                    transform="translate(28.753 34.779)"
                  >
                    <g data-name="Group 6027" transform="translate(.038 .029)">
                      <g data-name="Group 6026">
                        <g data-name="Group 6025">
                          <path
                            fill="#e0e0e0"
                            d="M415.33 386.845l9.439 28.439-1.9-.07-11.234-28.5z"
                            data-name="Path 3299"
                            transform="translate(-411.63 -386.71)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6031">
                      <g data-name="Group 6030">
                        <g data-name="Group 6029">
                          <g data-name="Group 6028">
                            <path
                              fill="#263238"
                              d="M415.238 386.774s-.076-.006-.234-.015-.4-.018-.7-.032l-2.76-.114h-.041l.015.035c1.9 4.848 6.029 15.353 10.81 27.522.132.334.261.665.392.993l.015.038h.047l1.9.07h.091l-.026-.076c-2.66-7.977-5.03-15.09-6.735-20.21l-2.021-6.026c-.237-.7-.422-1.251-.551-1.626l-.144-.419c-.033-.097-.059-.144-.059-.144l.044.149c.029.096.076.243.138.428l.533 1.641 1.986 6.046c1.69 5.115 4.04 12.222 6.676 20.19l.064-.073-1.9-.07.062.038-.393-.993-10.88-27.5-.029.032 2.748.088c.311.009.545.015.709.021s.243.007.243.007z"
                              data-name="Path 3300"
                              transform="translate(-411.5 -386.61)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 6036"
                    transform="translate(15.172 35.028)"
                  >
                    <g data-name="Group 6035">
                      <g data-name="Group 6034">
                        <g data-name="Group 6033">
                          <path
                            fill="#263238"
                            d="M365.14 387.46a1.392 1.392 0 00.179.141c.132.094.308.22.53.381l1.954 1.386c1.649 1.172 3.92 2.8 6.418 4.611s4.749 3.451 6.4 4.646l1.939 1.406c.223.158.4.284.53.378a1.876 1.876 0 00.19.126 1.854 1.854 0 00-.176-.146l-.516-.4-1.916-1.441c-1.623-1.21-3.873-2.868-6.372-4.678s-4.781-3.428-6.442-4.582a152.853 152.853 0 00-1.98-1.35q-.343-.224-.545-.36a1.536 1.536 0 00-.193-.118z"
                            data-name="Path 3301"
                            transform="translate(-365.14 -387.459)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6039" transform="translate(6.533)">
                    <g data-name="Group 6038">
                      <g data-name="Group 6037">
                        <path
                          fill="#292929"
                          d="M367.762 267.944s4.819-.056 4.772 3.2c-.029 2.051-1.91 26.389-4.081 29.8s-28.542 1.969-30.367 1.421-2.637-1.767-2.4-3.211 18.28-2.593 18.28-2.593l6.375-25.419s1.959-3.754 7.421-3.198z"
                          data-name="Path 3302"
                          transform="translate(-335.649 -267.888)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6043" transform="translate(6.527 32.132)">
                    <g data-name="Group 6042">
                      <g data-name="Group 6041">
                        <g data-name="Group 6040">
                          <path
                            fill="#263238"
                            d="M335.63 378.035a.335.335 0 00.079.012l.234.023c.217.018.519.044.9.079a161.995 161.995 0 0014.319.501c2.147-.026 4.2-.1 6.052-.2s3.53-.226 4.93-.36c.7-.059 1.333-.132 1.889-.19s1.034-.114 1.424-.164.685-.088.9-.117c.1-.015.173-.026.234-.032l.079-.015a.242.242 0 00-.082 0l-.234.02-.9.091c-.384.038-.87.094-1.427.141s-1.189.117-1.889.17a151.225 151.225 0 01-10.976.516c-4.292.044-8.176-.088-10.986-.229-1.406-.07-2.543-.138-3.328-.187l-.905-.053c-.1 0-.176-.009-.234-.012z"
                            data-name="Path 3303"
                            transform="translate(-335.63 -377.573)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6047" transform="translate(9.843 35.054)">
                    <g data-name="Group 6046">
                      <g data-name="Group 6045">
                        <g data-name="Group 6044">
                          <path
                            fill="#263238"
                            d="M346.95 400.1c.021.032 4.309-2.754 9.579-6.219s9.527-6.3 9.506-6.333-4.309 2.754-9.579 6.222-9.527 6.3-9.506 6.33z"
                            data-name="Path 3304"
                            transform="translate(-346.95 -387.549)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6051" transform="translate(37.119 1.049)">
                  <g data-name="Group 6050">
                    <g data-name="Group 6049">
                      <path
                        fill="#263238"
                        d="M445.242 271.47a.356.356 0 010 .082c0 .064-.006.144-.009.243l-.05.934c-.047.811-.123 1.986-.234 3.433-.22 2.9-.586 6.9-1.107 11.3-.258 2.2-.527 4.3-.788 6.2-.243 1.9-.422 3.63-.724 5.056a7.835 7.835 0 01-1.233 3.2 2.027 2.027 0 01-.727.592 1.061 1.061 0 01-.231.082.291.291 0 01-.082.018c0-.012.111-.029.3-.126a2.069 2.069 0 00.694-.6 7.9 7.9 0 001.175-3.187c.29-1.421.454-3.143.691-5.053s.516-4 .776-6.2c.518-4.4.905-8.4 1.151-11.3.12-1.438.217-2.607.284-3.43.032-.4.059-.709.079-.932.009-.1.018-.179.023-.243a.2.2 0 01.012-.069z"
                        data-name="Path 3305"
                        transform="translate(-440.06 -271.47)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6054" transform="translate(30.792 29.536)">
                  <g data-name="Group 6053">
                    <g data-name="Group 6052">
                      <path
                        fill="#263238"
                        d="M425.01 370.864c-.012.041-1.491-.407-3.3-1s-3.263-1.107-3.252-1.148 1.491.407 3.3 1 3.264 1.107 3.252 1.148z"
                        data-name="Path 3306"
                        transform="translate(-418.46 -368.711)"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 6058" transform="translate(45.688 27.292)">
                <g data-name="Group 6057">
                  <g data-name="Group 6056">
                    <path
                      fill="#87a878"
                      d="M359.654 255.166l-12.831-12.676-2.613 4.382 3.439 5.071a74.758 74.758 0 005.1 7.031c1.717 1.822 1.828 1.415 1.828 1.415z"
                      data-name="Path 3307"
                      transform="translate(-344.21 -242.49)"
                    ></path>
                  </g>
                </g>
              </g>
              <g data-name="Group 6554">
                <g data-name="Group 6253" transform="translate(61.092)">
                  <g data-name="Group 6232">
                    <g data-name="Group 6231">
                      <g data-name="Group 6230">
                        <g data-name="Group 6229">
                          <g data-name="Group 6228">
                            <g data-name="Group 6227">
                              <g
                                data-name="Group 6067"
                                transform="translate(11.713 2.954)"
                              >
                                <g data-name="Group 6066">
                                  <g data-name="Group 6065">
                                    <g data-name="Group 6064">
                                      <g data-name="Group 6063">
                                        <g data-name="Group 6062">
                                          <g data-name="Group 6061">
                                            <g data-name="Group 6060">
                                              <g data-name="Group 6059">
                                                <path
                                                  fill="#263238"
                                                  d="M437.5 169.853a26.049 26.049 0 001.318-4.312 9.135 9.135 0 00-.17-4.465c-.07-.217-.688-1.679-.914-1.667-.164.009.2 1.365.111 1.5a7.763 7.763 0 00-1.061 4.473 20.3 20.3 0 00.715 4.467"
                                                  data-name="Path 3308"
                                                  transform="translate(-436.775 -159.41)"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6074"
                                transform="translate(0 .426)"
                              >
                                <g data-name="Group 6073">
                                  <g data-name="Group 6072">
                                    <g data-name="Group 6071">
                                      <g data-name="Group 6070">
                                        <g data-name="Group 6069">
                                          <g data-name="Group 6068">
                                            <path
                                              fill="#aa8776"
                                              d="M400.465 171.734c.032-1.063.056-1.9.05-1.9a4.35 4.35 0 01-3.717-4.142c-.041-1.749.164-5.358.39-8.621a6.751 6.751 0 016.656-6.287h.349c3.77.176 5.727 3.477 5.449 7.242l-.562 13.2-2.686 2.944a3.412 3.412 0 01-5.926-2.431z"
                                              data-name="Path 3309"
                                              transform="translate(-396.792 -150.78)"
                                            ></path>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6085"
                                transform="translate(3.696 18.026)"
                              >
                                <g data-name="Group 6084">
                                  <g data-name="Group 6083">
                                    <g data-name="Group 6082">
                                      <g data-name="Group 6081">
                                        <g data-name="Group 6080">
                                          <g data-name="Group 6079">
                                            <g data-name="Group 6078">
                                              <g data-name="Group 6077">
                                                <g data-name="Group 6076">
                                                  <g data-name="Group 6075">
                                                    <path
                                                      fill="#7f5d55"
                                                      d="M409.413 212.31a8.731 8.731 0 004.7-1.45 4.463 4.463 0 01-4.7 2.49z"
                                                      data-name="Path 3310"
                                                      transform="translate(-409.41 -210.86)"
                                                    ></path>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6102"
                                transform="translate(1.268 8.971)"
                              >
                                <g data-name="Group 6101">
                                  <g data-name="Group 6100">
                                    <g data-name="Group 6099">
                                      <g data-name="Group 6098">
                                        <g data-name="Group 6097">
                                          <g data-name="Group 6096">
                                            <g data-name="Group 6095">
                                              <g data-name="Group 6094">
                                                <g data-name="Group 6093">
                                                  <g data-name="Group 6092">
                                                    <g data-name="Group 6091">
                                                      <g data-name="Group 6090">
                                                        <g data-name="Group 6089">
                                                          <g data-name="Group 6088">
                                                            <g data-name="Group 6087">
                                                              <g data-name="Group 6086">
                                                                <path
                                                                  fill="#263238"
                                                                  d="M401.12 180.421a.518.518 0 00.5.516.494.494 0 00.527-.472.518.518 0 00-.5-.516.494.494 0 00-.527.472z"
                                                                  data-name="Path 3311"
                                                                  transform="translate(-401.12 -179.948)"
                                                                ></path>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6120"
                                transform="translate(1.157 7.65)"
                              >
                                <g data-name="Group 6119">
                                  <g data-name="Group 6118">
                                    <g data-name="Group 6117">
                                      <g data-name="Group 6116">
                                        <g data-name="Group 6115">
                                          <g data-name="Group 6114">
                                            <g data-name="Group 6113">
                                              <g data-name="Group 6112">
                                                <g data-name="Group 6111">
                                                  <g data-name="Group 6110">
                                                    <g data-name="Group 6109">
                                                      <g data-name="Group 6108">
                                                        <g data-name="Group 6107">
                                                          <g data-name="Group 6106">
                                                            <g data-name="Group 6105">
                                                              <g data-name="Group 6104">
                                                                <g data-name="Group 6103">
                                                                  <path
                                                                    fill="#263238"
                                                                    d="M400.749 175.993c.064.067.454-.229 1.011-.231s.967.27 1.028.2c.029-.029-.038-.152-.217-.278a1.423 1.423 0 00-.823-.243 1.372 1.372 0 00-.8.27c-.176.13-.231.253-.199.282z"
                                                                    data-name="Path 3312"
                                                                    transform="translate(-400.74 -175.439)"
                                                                  ></path>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6137"
                                transform="translate(6.096 9.264)"
                              >
                                <g data-name="Group 6136">
                                  <g data-name="Group 6135">
                                    <g data-name="Group 6134">
                                      <g data-name="Group 6133">
                                        <g data-name="Group 6132">
                                          <g data-name="Group 6131">
                                            <g data-name="Group 6130">
                                              <g data-name="Group 6129">
                                                <g data-name="Group 6128">
                                                  <g data-name="Group 6127">
                                                    <g data-name="Group 6126">
                                                      <g data-name="Group 6125">
                                                        <g data-name="Group 6124">
                                                          <g data-name="Group 6123">
                                                            <g data-name="Group 6122">
                                                              <g data-name="Group 6121">
                                                                <path
                                                                  fill="#263238"
                                                                  d="M417.6 181.421a.518.518 0 00.5.516.494.494 0 00.527-.472.518.518 0 00-.5-.516.494.494 0 00-.527.472z"
                                                                  data-name="Path 3313"
                                                                  transform="translate(-417.6 -180.948)"
                                                                ></path>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6155"
                                transform="translate(6.611 7.811)"
                              >
                                <g data-name="Group 6154">
                                  <g data-name="Group 6153">
                                    <g data-name="Group 6152">
                                      <g data-name="Group 6151">
                                        <g data-name="Group 6150">
                                          <g data-name="Group 6149">
                                            <g data-name="Group 6148">
                                              <g data-name="Group 6147">
                                                <g data-name="Group 6146">
                                                  <g data-name="Group 6145">
                                                    <g data-name="Group 6144">
                                                      <g data-name="Group 6143">
                                                        <g data-name="Group 6142">
                                                          <g data-name="Group 6141">
                                                            <g data-name="Group 6140">
                                                              <g data-name="Group 6139">
                                                                <g data-name="Group 6138">
                                                                  <path
                                                                    fill="#263238"
                                                                    d="M419.369 176.543c.064.067.454-.229 1.011-.231s.967.27 1.028.2c.029-.029-.038-.152-.217-.278a1.423 1.423 0 00-.823-.243 1.372 1.372 0 00-.8.27c-.175.13-.231.253-.199.282z"
                                                                    data-name="Path 3314"
                                                                    transform="translate(-419.36 -175.989)"
                                                                  ></path>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6172"
                                transform="translate(3.251 8.025)"
                              >
                                <g data-name="Group 6171">
                                  <g data-name="Group 6170">
                                    <g data-name="Group 6169">
                                      <g data-name="Group 6168">
                                        <g data-name="Group 6167">
                                          <g data-name="Group 6166">
                                            <g data-name="Group 6165">
                                              <g data-name="Group 6164">
                                                <g data-name="Group 6163">
                                                  <g data-name="Group 6162">
                                                    <g data-name="Group 6161">
                                                      <g data-name="Group 6160">
                                                        <g data-name="Group 6159">
                                                          <g data-name="Group 6158">
                                                            <g data-name="Group 6157">
                                                              <g data-name="Group 6156">
                                                                <path
                                                                  fill="#263238"
                                                                  d="M409.311 181.359a3.625 3.625 0 00-.905-.152c-.144-.012-.278-.041-.3-.138a.718.718 0 01.091-.422c.129-.346.267-.709.407-1.087a18.71 18.71 0 00.911-2.842 18.634 18.634 0 00-1.137 2.762c-.135.381-.267.744-.39 1.093a.812.812 0 00-.067.56.351.351 0 00.237.205.959.959 0 00.243.029 3.426 3.426 0 00.91-.008z"
                                                                  data-name="Path 3315"
                                                                  transform="translate(-407.89 -176.719)"
                                                                ></path>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6185"
                                transform="translate(6.462 6.06)"
                              >
                                <g data-name="Group 6184">
                                  <g data-name="Group 6183">
                                    <g data-name="Group 6182">
                                      <g data-name="Group 6181">
                                        <g data-name="Group 6180">
                                          <g data-name="Group 6179">
                                            <g data-name="Group 6178">
                                              <g data-name="Group 6177">
                                                <g data-name="Group 6176">
                                                  <g data-name="Group 6175">
                                                    <g data-name="Group 6174">
                                                      <g data-name="Group 6173">
                                                        <path
                                                          fill="#263238"
                                                          d="M418.853 170.439c.056.149.612.073 1.266.146s1.184.246 1.268.108c.038-.064-.056-.208-.269-.352a2.215 2.215 0 00-1.93-.193c-.238.098-.358.218-.335.291z"
                                                          data-name="Path 3316"
                                                          transform="translate(-418.85 -170.012)"
                                                        ></path>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6198"
                                transform="translate(1.353 5.763)"
                              >
                                <g data-name="Group 6197">
                                  <g data-name="Group 6196">
                                    <g data-name="Group 6195">
                                      <g data-name="Group 6194">
                                        <g data-name="Group 6193">
                                          <g data-name="Group 6192">
                                            <g data-name="Group 6191">
                                              <g data-name="Group 6190">
                                                <g data-name="Group 6189">
                                                  <g data-name="Group 6188">
                                                    <g data-name="Group 6187">
                                                      <g data-name="Group 6186">
                                                        <path
                                                          fill="#263238"
                                                          d="M401.425 169.568c.1.129.48 0 .943 0 .46-.018.85.094.94-.038.041-.064-.021-.19-.19-.313a1.317 1.317 0 00-.765-.217 1.3 1.3 0 00-.756.246c-.16.128-.216.254-.172.322z"
                                                          data-name="Path 3317"
                                                          transform="translate(-401.411 -168.999)"
                                                        ></path>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6207"
                                transform="translate(12.458 10.228)"
                              >
                                <g data-name="Group 6206">
                                  <g data-name="Group 6205">
                                    <g data-name="Group 6204">
                                      <g data-name="Group 6203">
                                        <g data-name="Group 6202">
                                          <g data-name="Group 6201">
                                            <g data-name="Group 6200">
                                              <g data-name="Group 6199">
                                                <path
                                                  fill="#aa8776"
                                                  d="M439.423 184.363c.062-.026 2.481-.765 2.428 1.711a1.864 1.864 0 01-2.531 1.846c0-.071.103-3.557.103-3.557z"
                                                  data-name="Path 3318"
                                                  transform="translate(-439.32 -184.24)"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6217"
                                transform="translate(13.235 11.039)"
                              >
                                <g data-name="Group 6216">
                                  <g data-name="Group 6215">
                                    <g data-name="Group 6214">
                                      <g data-name="Group 6213">
                                        <g data-name="Group 6212">
                                          <g data-name="Group 6211">
                                            <g data-name="Group 6210">
                                              <g data-name="Group 6209">
                                                <g data-name="Group 6208">
                                                  <path
                                                    fill="#7f5d55"
                                                    d="M441.974 188.955c.012-.009.044.032.114.067a.434.434 0 00.322.015 1.066 1.066 0 00.513-.949 1.416 1.416 0 00-.108-.621.5.5 0 00-.319-.337.22.22 0 00-.258.114c-.035.067-.02.117-.032.123s-.053-.044-.029-.144a.276.276 0 01.1-.152.33.33 0 01.24-.059.6.6 0 01.442.393 1.48 1.48 0 01.135.694 1.122 1.122 0 01-.65 1.058.467.467 0 01-.393-.073c-.074-.07-.086-.126-.077-.129z"
                                                    data-name="Path 3319"
                                                    transform="translate(-441.971 -187.01)"
                                                  ></path>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g
                                data-name="Group 6226"
                                transform="translate(2.019)"
                              >
                                <g data-name="Group 6225">
                                  <g data-name="Group 6224">
                                    <g data-name="Group 6223">
                                      <g data-name="Group 6222">
                                        <g data-name="Group 6221">
                                          <g data-name="Group 6220">
                                            <g data-name="Group 6219">
                                              <g data-name="Group 6218">
                                                <path
                                                  fill="#263238"
                                                  d="M403.758 151.981c-.4-.231.847-1.5 1.846-1.913a8.812 8.812 0 015.156-.554 5.971 5.971 0 013.773 2.906 9.772 9.772 0 01.949 5.006 2.945 2.945 0 01-.393 1.5c-.363.536-1.037.864-1.028 1.62a1.048 1.048 0 01-.278.75c-.466.469-1.008 0-1.251-.463a10.692 10.692 0 01-.334-5.607c.132-.41.34-.794.448-1.21a1.24 1.24 0 00-.337-1.45 2.586 2.586 0 00-2.549-.562c-1.409.454-3.811 1.119-4.421.63a3.772 3.772 0 01-1.075-1.157z"
                                                  data-name="Path 3320"
                                                  transform="translate(-403.683 -149.326)"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6252" transform="translate(3.846 13.466)">
                    <g data-name="Group 6241" transform="translate(.223 .109)">
                      <g data-name="Group 6240">
                        <g data-name="Group 6239">
                          <g data-name="Group 6238">
                            <g data-name="Group 6237">
                              <g data-name="Group 6236">
                                <g data-name="Group 6235">
                                  <g data-name="Group 6234">
                                    <g data-name="Group 6233">
                                      <path
                                        fill="#7f5d55"
                                        d="M410.747 196.189a2.422 2.422 0 00.272.609.788.788 0 00.545.352.71.71 0 00.615-.3 1.13 1.13 0 00.2-.671c0-.214-.085-.475-.3-.513a.54.54 0 00-.261.044l-1.143.4"
                                        data-name="Path 3321"
                                        transform="translate(-410.68 -195.667)"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6251">
                      <g data-name="Group 6250">
                        <g data-name="Group 6249">
                          <g data-name="Group 6248">
                            <g data-name="Group 6247">
                              <g data-name="Group 6246">
                                <g data-name="Group 6245">
                                  <g data-name="Group 6244">
                                    <g data-name="Group 6243">
                                      <g data-name="Group 6242">
                                        <path
                                          fill="#263238"
                                          d="M410.611 196.872a1.66 1.66 0 00.6-.044.856.856 0 00.472-.39.9.9 0 00.05-.732.747.747 0 00-.091-.173c-.041-.05-.035-.047-.082-.041a1.664 1.664 0 00-.182.062c-.064.023-.126.044-.187.067-.246.085-.466.158-.653.22a2.151 2.151 0 01-.618.155 2 2 0 01.571-.287c.182-.076.4-.164.639-.261.062-.023.123-.047.185-.073a2.068 2.068 0 01.208-.073.281.281 0 01.167.012.329.329 0 01.12.1.92.92 0 01.114.22 1.054 1.054 0 01-.079.9.966.966 0 01-.592.434.883.883 0 01-.486-.006c-.109-.04-.167-.081-.156-.09z"
                                          data-name="Path 3322"
                                          transform="translate(-409.919 -195.295)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6352" transform="translate(0 73.15)">
                  <g data-name="Group 6351">
                    <g data-name="Group 6263" transform="translate(.012 .536)">
                      <g data-name="Group 6262">
                        <g data-name="Group 6261">
                          <g data-name="Group 6260">
                            <g data-name="Group 6259">
                              <g data-name="Group 6258">
                                <g data-name="Group 6257">
                                  <g data-name="Group 6256">
                                    <g data-name="Group 6255">
                                      <g data-name="Group 6254">
                                        <path
                                          fill="#142745"
                                          d="M205.454 403l-2.929 9.582-14.235-5.182c.577-1.33 8.938-1.377 8.938-1.377l1.673-5.165z"
                                          data-name="Path 3323"
                                          transform="translate(-188.29 -400.86)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 6274"
                      transform="translate(12.673 6.587)"
                    >
                      <g data-name="Group 6273">
                        <g data-name="Group 6272">
                          <g data-name="Group 6271">
                            <g data-name="Group 6270">
                              <g data-name="Group 6269">
                                <g data-name="Group 6268">
                                  <g data-name="Group 6267">
                                    <g data-name="Group 6266">
                                      <g data-name="Group 6265">
                                        <g data-name="Group 6264">
                                          <path
                                            fill="#e0e0e0"
                                            d="M232.412 421.617a.618.618 0 01.161.809.6.6 0 01-.8.167.65.65 0 01-.164-.858.615.615 0 01.852-.079"
                                            data-name="Path 3324"
                                            transform="translate(-231.509 -421.517)"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6284" transform="translate(0 6.603)">
                      <g data-name="Group 6283">
                        <g data-name="Group 6282">
                          <g data-name="Group 6281">
                            <g data-name="Group 6280">
                              <g data-name="Group 6279">
                                <g data-name="Group 6278">
                                  <g data-name="Group 6277">
                                    <g data-name="Group 6276">
                                      <g data-name="Group 6275">
                                        <path
                                          fill="#263238"
                                          d="M202.5 427.23l.451-1.2-13.881-4.46s-.735.05-.82.521z"
                                          data-name="Path 3325"
                                          transform="translate(-188.25 -421.57)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 6295"
                      transform="translate(8.743 5.522)"
                    >
                      <g data-name="Group 6294">
                        <g data-name="Group 6293">
                          <g data-name="Group 6292">
                            <g data-name="Group 6291">
                              <g data-name="Group 6290">
                                <g data-name="Group 6289">
                                  <g data-name="Group 6288">
                                    <g data-name="Group 6287">
                                      <g data-name="Group 6286">
                                        <g data-name="Group 6285">
                                          <path
                                            fill="#263238"
                                            d="M218.1 417.9c-.023.07.313.229.574.577s.337.706.41.7.117-.442-.205-.858-.773-.487-.779-.419z"
                                            data-name="Path 3326"
                                            transform="translate(-218.096 -417.879)"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 6306"
                      transform="translate(7.023 5.605)"
                    >
                      <g data-name="Group 6305">
                        <g data-name="Group 6304">
                          <g data-name="Group 6303">
                            <g data-name="Group 6302">
                              <g data-name="Group 6301">
                                <g data-name="Group 6300">
                                  <g data-name="Group 6299">
                                    <g data-name="Group 6298">
                                      <g data-name="Group 6297">
                                        <g data-name="Group 6296">
                                          <path
                                            fill="#263238"
                                            d="M212.229 418.177c-.041.062.217.29.346.668s.085.715.155.738.258-.354.088-.823-.565-.65-.589-.583z"
                                            data-name="Path 3327"
                                            transform="translate(-212.225 -418.163)"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 6317"
                      transform="translate(5.392 5.701)"
                    >
                      <g data-name="Group 6316">
                        <g data-name="Group 6315">
                          <g data-name="Group 6314">
                            <g data-name="Group 6313">
                              <g data-name="Group 6312">
                                <g data-name="Group 6311">
                                  <g data-name="Group 6310">
                                    <g data-name="Group 6309">
                                      <g data-name="Group 6308">
                                        <g data-name="Group 6307">
                                          <path
                                            fill="#263238"
                                            d="M206.792 419.886c.059.038.281-.275.24-.721s-.311-.715-.36-.668.076.325.1.691-.047.669.02.698z"
                                            data-name="Path 3328"
                                            transform="translate(-206.657 -418.492)"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6328" transform="translate(9.48 3.826)">
                      <g data-name="Group 6327">
                        <g data-name="Group 6326">
                          <g data-name="Group 6325">
                            <g data-name="Group 6324">
                              <g data-name="Group 6323">
                                <g data-name="Group 6322">
                                  <g data-name="Group 6321">
                                    <g data-name="Group 6320">
                                      <g data-name="Group 6319">
                                        <g data-name="Group 6318">
                                          <path
                                            fill="#263238"
                                            d="M220.609 412.178c.006.073.36.094.744.264s.645.4.7.357-.146-.4-.6-.595-.853-.093-.844-.026z"
                                            data-name="Path 3329"
                                            transform="translate(-220.609 -412.091)"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 6339"
                      transform="translate(10.434 1.785)"
                    >
                      <g data-name="Group 6338">
                        <g data-name="Group 6337">
                          <g data-name="Group 6336">
                            <g data-name="Group 6335">
                              <g data-name="Group 6334">
                                <g data-name="Group 6333">
                                  <g data-name="Group 6332">
                                    <g data-name="Group 6331">
                                      <g data-name="Group 6330">
                                        <g data-name="Group 6329">
                                          <path
                                            fill="#263238"
                                            d="M223.869 405.685a1.4 1.4 0 00.715.255 3.491 3.491 0 00.829.044 3.769 3.769 0 00.489-.067.926.926 0 00.275-.088.325.325 0 00.167-.357.4.4 0 00-.246-.27 1 1 0 00-.281-.062 2.671 2.671 0 00-1.312.185c-.442.185-.65.4-.63.425s.264-.126.691-.255a2.974 2.974 0 01.768-.126 3.005 3.005 0 01.454.023c.164.015.293.061.308.144.006.032 0 .041-.044.073a.71.71 0 01-.2.064 4.472 4.472 0 01-.454.079 4.205 4.205 0 01-.785.012 5.138 5.138 0 00-.744-.079z"
                                            data-name="Path 3330"
                                            transform="translate(-223.869 -405.123)"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6350" transform="translate(10.025)">
                      <g data-name="Group 6349">
                        <g data-name="Group 6348">
                          <g data-name="Group 6347">
                            <g data-name="Group 6346">
                              <g data-name="Group 6345">
                                <g data-name="Group 6344">
                                  <g data-name="Group 6343">
                                    <g data-name="Group 6342">
                                      <g data-name="Group 6341">
                                        <g data-name="Group 6340">
                                          <path
                                            fill="#263238"
                                            d="M222.936 401.511c.021.021.249-.179.439-.621a2.434 2.434 0 00.188-.817 2.4 2.4 0 00-.023-.5.626.626 0 00-.322-.524.356.356 0 00-.369.091.944.944 0 00-.167.234 1.679 1.679 0 00-.176.48 1.784 1.784 0 00.035.847c.144.472.4.659.419.639a5.794 5.794 0 01-.24-.68 1.781 1.781 0 01.018-.753 1.6 1.6 0 01.164-.407c.076-.144.179-.243.229-.208.07.023.149.182.164.325a2.4 2.4 0 01.035.451 2.824 2.824 0 01-.12.762c-.139.424-.31.655-.274.681z"
                                            data-name="Path 3331"
                                            transform="translate(-222.471 -399.03)"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6408" transform="translate(32.327 14.314)">
                  <g data-name="Group 6355" transform="translate(6.143 9.58)">
                    <g data-name="Group 6354">
                      <g data-name="Group 6353">
                        <path
                          fill="#aa8776"
                          d="M325 231.245c-.038-.023-2.01-.258-2.792-.349a.835.835 0 00-.574.146l-1.154.814-.908 2.074.293.185-.243 1.857a.587.587 0 00.935-.094 7.927 7.927 0 00.814-1.412l1.274-.059-.053.12-1.3.369-.4 1.125.422 1.137.9.779.378-.026.726.41s.706-.656.076-1.254c0 0 .606.565.926.328l2.692 1.134 2.033-2.074.524-1.623s-3.422-3.173-3.627-3.322a3.324 3.324 0 00-.942-.265z"
                          data-name="Path 3332"
                          transform="translate(-319.57 -230.891)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6359" transform="translate(9.294 13.135)">
                    <g data-name="Group 6358">
                      <g data-name="Group 6357">
                        <g data-name="Group 6356">
                          <path
                            fill="#7f5d55"
                            d="M331.012 245.606c-.026-.006.006-.161-.035-.4a1.245 1.245 0 00-.466-.782.639.639 0 01-.047-.035l-.05-.035.015-.056a1.718 1.718 0 00.009-.9c-.059-.229-.132-.357-.108-.372s.126.1.22.337a1.555 1.555 0 01.041.978l-.035-.091a.673.673 0 01.056.041 1.218 1.218 0 01.483.9c.013.268-.066.424-.083.415z"
                            data-name="Path 3333"
                            transform="translate(-330.325 -243.027)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6363" transform="translate(8.299 13.866)">
                    <g data-name="Group 6362">
                      <g data-name="Group 6361">
                        <g data-name="Group 6360">
                          <path
                            fill="#7f5d55"
                            d="M327.691 248.252a1.232 1.232 0 00.22-.393 1.178 1.178 0 00-.308-.993c-.073-.1-.152-.187-.226-.275l-.029-.035.015-.041a.767.767 0 00-.161-.768c-.146-.155-.278-.2-.272-.22s.155-.009.343.144a.842.842 0 01.243.893l-.015-.079c.076.085.158.179.234.278a1.665 1.665 0 01.3.583.918.918 0 01-.015.545c-.115.309-.329.381-.329.361z"
                            data-name="Path 3334"
                            transform="translate(-326.929 -245.522)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6367" transform="translate(8.785 14.463)">
                    <g data-name="Group 6366">
                      <g data-name="Group 6365">
                        <g data-name="Group 6364">
                          <path
                            fill="#7f5d55"
                            d="M329.186 247.572c.026.038-.088.158-.252.267s-.319.17-.343.132.088-.158.252-.267.319-.17.343-.132z"
                            data-name="Path 3335"
                            transform="translate(-328.587 -247.56)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6371" transform="translate(9.139 15.079)">
                    <g data-name="Group 6370">
                      <g data-name="Group 6369">
                        <g data-name="Group 6368">
                          <path
                            fill="#7f5d55"
                            d="M330.5 250.491c-.047.009-.123-.223-.316-.439s-.407-.331-.39-.375.293.009.516.267.235.544.19.547z"
                            data-name="Path 3336"
                            transform="translate(-329.797 -249.663)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 6375"
                    transform="translate(10.657 13.083)"
                  >
                    <g data-name="Group 6374">
                      <g data-name="Group 6373">
                        <g data-name="Group 6372">
                          <path
                            fill="#7f5d55"
                            d="M334.986 245.653c-.047-.018.2-.612.27-1.389s-.044-1.406.006-1.415a2.733 2.733 0 01.158 1.43 2.774 2.774 0 01-.434 1.374z"
                            data-name="Path 3337"
                            transform="translate(-334.98 -242.849)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6379" transform="translate(6.45 11.427)">
                    <g data-name="Group 6378">
                      <g data-name="Group 6377">
                        <g data-name="Group 6376">
                          <path
                            fill="#7f5d55"
                            d="M322 237.2c.02.009 0 .123-.059.3a2.043 2.043 0 01-.354.659 1.13 1.13 0 01-.653.393c-.2.029-.319-.026-.313-.044s.12-.015.287-.07a1.252 1.252 0 00.554-.384 3.2 3.2 0 00.372-.6c.086-.161.145-.264.166-.254z"
                            data-name="Path 3338"
                            transform="translate(-320.619 -237.198)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6383" transform="translate(9.256 12.391)">
                    <g data-name="Group 6382">
                      <g data-name="Group 6381">
                        <g data-name="Group 6380">
                          <path
                            fill="#7f5d55"
                            d="M330.2 240.955c-.029-.035.035-.135.1-.252s.123-.223.17-.214.059.149-.026.3-.212.201-.244.166z"
                            data-name="Path 3339"
                            transform="translate(-330.195 -240.488)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6386" transform="translate(0 9.076)">
                    <g data-name="Group 6385">
                      <g data-name="Group 6384">
                        <path
                          fill="#d6d36a"
                          d="M298.6 229.425l.19 5.484c.064 1.843 7.47 1.579 7.327-.255-.009-.108-.19-5.484-.19-5.484z"
                          data-name="Path 3340"
                          transform="translate(-298.6 -229.17)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6389" transform="translate(7.174 9.973)">
                    <g data-name="Group 6388">
                      <g data-name="Group 6387">
                        <path
                          fill="#292929"
                          d="M323.09 232.3a4.08 4.08 0 011.321-.023 1.264 1.264 0 01.85.4 1.443 1.443 0 01.22.709 2.829 2.829 0 01-.1 1.427 1.817 1.817 0 01-.934.911 2.947 2.947 0 01-1.292.226l-.018-.838a3.651 3.651 0 00.779-.067 1.215 1.215 0 00.671-.384 1.66 1.66 0 00.249-1.239.9.9 0 00-.149-.48.785.785 0 00-.644-.223 2.321 2.321 0 00-.9.208"
                          data-name="Path 3341"
                          transform="translate(-323.09 -232.234)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6391" transform="translate(7.588 10.64)">
                    <g data-name="Group 6390">
                      <path
                        fill="#aa8776"
                        d="M324.651 234.7l2.522-.19 2.894.735 2.988 2.141s-2 2.924-2.912 2.059a15.228 15.228 0 01-2-2.8l-1.031-.4s-3.346.093-2.461-1.545z"
                        data-name="Path 3342"
                        transform="translate(-324.502 -234.51)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 6394" transform="translate(6.978 10.573)">
                    <g data-name="Group 6393">
                      <g data-name="Group 6392">
                        <path
                          fill="#7f5d55"
                          d="M330.03 236.676a.62.62 0 01-.146-.1l-.4-.3-1.459-1.142.023.012-2.9-.718h.026c-.782.062-1.635.126-2.522.193l.067-.044-.018.038a.694.694 0 00.015.794 1.531 1.531 0 00.741.41 6.316 6.316 0 001.655.2h.018l.012.006 1.028.407.023.009.012.021a20.789 20.789 0 001.348 2.1c.179.24.331.419.434.542a.123.123 0 11-.029.029 6.928 6.928 0 01-.466-.521 17.206 17.206 0 01-1.406-2.083l.035.029c-.325-.126-.671-.258-1.034-.4l.029.006a6.317 6.317 0 01-1.7-.187 1.672 1.672 0 01-.823-.46.718.718 0 01-.17-.483 1.162 1.162 0 01.135-.489c.006-.015.015-.029.021-.044l.018-.038h.047c.888-.064 1.74-.129 2.522-.185h.024c1.09.284 2.074.542 2.891.753h.015l.009.009 1.427 1.186c.155.132.281.24.381.328a.582.582 0 01.117.122z"
                          data-name="Path 3343"
                          transform="translate(-322.419 -234.28)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6397" transform="translate(.222 14.785)">
                    <g data-name="Group 6396">
                      <g data-name="Group 6395">
                        <path
                          fill="#263238"
                          d="M306.592 248.663c.006.012-.079.07-.243.173a2.745 2.745 0 01-.3.167 3.668 3.668 0 01-.422.188 7.381 7.381 0 01-2.616.5 8.24 8.24 0 01-2.637-.381 5.3 5.3 0 01-.753-.3c-.173-.085-.264-.135-.258-.146.015-.029.4.138 1.049.313a9.412 9.412 0 002.6.319 8.434 8.434 0 002.572-.437c.627-.211.991-.425 1.008-.396z"
                          data-name="Path 3344"
                          transform="translate(-299.359 -248.661)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6400" transform="translate(7.285 9.073)">
                    <g data-name="Group 6399">
                      <g data-name="Group 6398">
                        <path
                          fill="#263238"
                          d="M323.689 234.826a42.21 42.21 0 01-.179-5.666 42.21 42.21 0 01.179 5.666z"
                          data-name="Path 3345"
                          transform="translate(-323.469 -229.16)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6407" transform="translate(2.687)">
                    <g data-name="Group 6403" transform="translate(1.058)">
                      <g data-name="Group 6402">
                        <g data-name="Group 6401">
                          <path
                            fill="#263238"
                            d="M311.944 206.181a.86.86 0 01-.152-.3 1.325 1.325 0 01.182-.888c.094-.173.217-.357.322-.571a1.278 1.278 0 00.158-.753c-.094-.586-.718-.893-.885-1.462a1.43 1.43 0 01-.012-.844 2.118 2.118 0 01.407-.694 2.2 2.2 0 00.4-.65 1.28 1.28 0 00.032-.668 2.7 2.7 0 00-.384-.876 1.648 1.648 0 00-.2-.284 2.8 2.8 0 00.164.308 2.943 2.943 0 01.343.867 1.211 1.211 0 01-.044.624 2.206 2.206 0 01-.393.618 2.131 2.131 0 00-.434.727 1.558 1.558 0 00.009.911 2.151 2.151 0 00.492.762 1.437 1.437 0 01.4.683 1.169 1.169 0 01-.141.694c-.1.208-.217.4-.308.574a1.331 1.331 0 00-.152.937.6.6 0 00.126.231c.041.037.067.057.07.054z"
                            data-name="Path 3346"
                            transform="translate(-311.382 -198.19)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6406">
                      <g data-name="Group 6405">
                        <g data-name="Group 6404">
                          <path
                            fill="#263238"
                            d="M308.334 206.181a.86.86 0 01-.152-.3 1.325 1.325 0 01.182-.888c.094-.173.217-.357.322-.571a1.278 1.278 0 00.158-.753c-.094-.586-.718-.893-.885-1.462a1.43 1.43 0 01-.012-.844 2.117 2.117 0 01.407-.694 2.2 2.2 0 00.4-.65 1.28 1.28 0 00.032-.668 2.7 2.7 0 00-.384-.876 1.64 1.64 0 00-.2-.284 2.807 2.807 0 00.164.308 2.947 2.947 0 01.343.867 1.212 1.212 0 01-.044.624 2.276 2.276 0 01-.393.618 2.132 2.132 0 00-.434.727 1.559 1.559 0 00.009.911 2.152 2.152 0 00.492.762 1.437 1.437 0 01.4.683 1.169 1.169 0 01-.141.694c-.1.208-.217.4-.308.574a1.331 1.331 0 00-.152.937.594.594 0 00.126.231.587.587 0 00.07.054z"
                            data-name="Path 3347"
                            transform="translate(-307.772 -198.19)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6506" transform="translate(1.008 83.157)">
                  <g data-name="Group 6418" transform="translate(.085)">
                    <g data-name="Group 6417">
                      <g data-name="Group 6416">
                        <g data-name="Group 6415">
                          <g data-name="Group 6414">
                            <g data-name="Group 6413">
                              <g data-name="Group 6412">
                                <g data-name="Group 6411">
                                  <g data-name="Group 6410">
                                    <g data-name="Group 6409">
                                      <path
                                        fill="#142745"
                                        d="M211.276 436.111l-4.687 11.853-14.609-6.158c.674-1.359 9.412-.976 9.412-.976l2.9-7.64z"
                                        data-name="Path 3348"
                                        transform="translate(-191.98 -433.19)"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6429" transform="translate(13.244 8.788)">
                    <g data-name="Group 6428">
                      <g data-name="Group 6427">
                        <g data-name="Group 6426">
                          <g data-name="Group 6425">
                            <g data-name="Group 6424">
                              <g data-name="Group 6423">
                                <g data-name="Group 6422">
                                  <g data-name="Group 6421">
                                    <g data-name="Group 6420">
                                      <g data-name="Group 6419">
                                        <path
                                          fill="#e0e0e0"
                                          d="M237.869 463.31a.651.651 0 01.126.855.622.622 0 01-.847.132.681.681 0 01-.129-.905.649.649 0 01.9-.041"
                                          data-name="Path 3349"
                                          transform="translate(-236.899 -463.189)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6439" transform="translate(0 8.159)">
                    <g data-name="Group 6438">
                      <g data-name="Group 6437">
                        <g data-name="Group 6436">
                          <g data-name="Group 6435">
                            <g data-name="Group 6434">
                              <g data-name="Group 6433">
                                <g data-name="Group 6432">
                                  <g data-name="Group 6431">
                                    <g data-name="Group 6430">
                                      <path
                                        fill="#263238"
                                        d="M206.317 467.652l.533-1.227-14.275-5.384s-.77.015-.885.5z"
                                        data-name="Path 3350"
                                        transform="translate(-191.69 -461.04)"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6450" transform="translate(9.221 7.442)">
                    <g data-name="Group 6449">
                      <g data-name="Group 6448">
                        <g data-name="Group 6447">
                          <g data-name="Group 6446">
                            <g data-name="Group 6445">
                              <g data-name="Group 6444">
                                <g data-name="Group 6443">
                                  <g data-name="Group 6442">
                                    <g data-name="Group 6441">
                                      <g data-name="Group 6440">
                                        <path
                                          fill="#263238"
                                          d="M223.167 458.617c-.026.073.316.255.568.633s.313.756.393.756.144-.457-.17-.908-.777-.554-.791-.481z"
                                          data-name="Path 3351"
                                          transform="translate(-223.165 -458.595)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6461" transform="translate(7.418 7.44)">
                    <g data-name="Group 6460">
                      <g data-name="Group 6459">
                        <g data-name="Group 6458">
                          <g data-name="Group 6457">
                            <g data-name="Group 6456">
                              <g data-name="Group 6455">
                                <g data-name="Group 6454">
                                  <g data-name="Group 6453">
                                    <g data-name="Group 6452">
                                      <g data-name="Group 6451">
                                        <path
                                          fill="#263238"
                                          d="M217.016 458.6c-.047.062.211.313.328.718s.05.753.123.779.287-.357.135-.858-.553-.706-.586-.639z"
                                          data-name="Path 3352"
                                          transform="translate(-217.011 -458.588)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6472" transform="translate(5.709 7.456)">
                    <g data-name="Group 6471">
                      <g data-name="Group 6470">
                        <g data-name="Group 6469">
                          <g data-name="Group 6468">
                            <g data-name="Group 6467">
                              <g data-name="Group 6466">
                                <g data-name="Group 6465">
                                  <g data-name="Group 6464">
                                    <g data-name="Group 6463">
                                      <g data-name="Group 6462">
                                        <path
                                          fill="#263238"
                                          d="M211.249 460.107c.059.041.308-.272.29-.741s-.287-.765-.343-.718.064.343.073.727-.087.694-.02.732z"
                                          data-name="Path 3353"
                                          transform="translate(-211.179 -458.643)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6483" transform="translate(10.077 5.72)">
                    <g data-name="Group 6482">
                      <g data-name="Group 6481">
                        <g data-name="Group 6480">
                          <g data-name="Group 6479">
                            <g data-name="Group 6478">
                              <g data-name="Group 6477">
                                <g data-name="Group 6476">
                                  <g data-name="Group 6475">
                                    <g data-name="Group 6474">
                                      <g data-name="Group 6473">
                                        <path
                                          fill="#263238"
                                          d="M226.089 452.794c0 .076.372.117.762.313s.653.457.715.41-.132-.428-.6-.653-.888-.14-.877-.07z"
                                          data-name="Path 3354"
                                          transform="translate(-226.089 -452.717)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6494" transform="translate(11.155 3.699)">
                    <g data-name="Group 6493">
                      <g data-name="Group 6492">
                        <g data-name="Group 6491">
                          <g data-name="Group 6490">
                            <g data-name="Group 6489">
                              <g data-name="Group 6488">
                                <g data-name="Group 6487">
                                  <g data-name="Group 6486">
                                    <g data-name="Group 6485">
                                      <g data-name="Group 6484">
                                        <path
                                          fill="#263238"
                                          d="M229.769 446.328a1.457 1.457 0 00.732.3 3.619 3.619 0 001.38.041 1 1 0 00.293-.079.338.338 0 00.193-.366.428.428 0 00-.243-.3 1.029 1.029 0 00-.29-.079 2.91 2.91 0 00-.521-.035 2.753 2.753 0 00-.861.158c-.472.17-.7.39-.68.413s.281-.117.735-.231a3.262 3.262 0 01.809-.094 2.991 2.991 0 01.475.047c.173.023.3.079.313.164 0 .032 0 .044-.05.076a.943.943 0 01-.217.059 4.768 4.768 0 01-.48.059 4.288 4.288 0 01-.82-.026 6.228 6.228 0 00-.768-.107z"
                                          data-name="Path 3355"
                                          transform="translate(-229.768 -445.818)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6505" transform="translate(10.778 1.768)">
                    <g data-name="Group 6504">
                      <g data-name="Group 6503">
                        <g data-name="Group 6502">
                          <g data-name="Group 6501">
                            <g data-name="Group 6500">
                              <g data-name="Group 6499">
                                <g data-name="Group 6498">
                                  <g data-name="Group 6497">
                                    <g data-name="Group 6496">
                                      <g data-name="Group 6495">
                                        <path
                                          fill="#263238"
                                          d="M228.909 441.806c.021.021.27-.176.492-.624a2.57 2.57 0 00.237-.844 2.385 2.385 0 000-.521.647.647 0 00-.311-.565.371.371 0 00-.393.076 1.022 1.022 0 00-.187.237 1.7 1.7 0 00-.208.492 1.818 1.818 0 00-.006.888c.126.5.387.709.4.688a6.287 6.287 0 01-.217-.724 1.8 1.8 0 01.059-.785 1.712 1.712 0 01.19-.416c.088-.146.2-.246.252-.205.073.029.146.2.155.349a2.569 2.569 0 01.015.472 2.828 2.828 0 01-.167.791c-.155.431-.346.665-.311.691z"
                                          data-name="Path 3356"
                                          transform="translate(-228.482 -439.224)"
                                        ></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6528" transform="translate(8.747 44.534)">
                  <g data-name="Group 6510">
                    <g data-name="Group 6509">
                      <g data-name="Group 6508">
                        <g data-name="Group 6507">
                          <path
                            fill="#455a64"
                            d="M218.11 330.045l9.122-25.006a6.285 6.285 0 017.581-3.468l26.635 5.124 16.426 10.991s-.958 4.5-3.143 5.3c-.595.217-36.038.712-36.038.712l-9.3 21.382-7.64-3.463 3.65-9.163z"
                            data-name="Path 3357"
                            transform="translate(-218.11 -301.347)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 6515"
                    transform="translate(17.199 18.001)"
                  >
                    <g data-name="Group 6514">
                      <g data-name="Group 6513">
                        <g data-name="Group 6512">
                          <g data-name="Group 6511">
                            <path
                              fill="#263238"
                              d="M276.822 362.8a15.36 15.36 0 011.573 2.112 14.992 14.992 0 011.383 2.241 15.133 15.133 0 01-1.573-2.112 14.82 14.82 0 01-1.383-2.241z"
                              data-name="Path 3358"
                              transform="translate(-276.819 -362.797)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 6520"
                    transform="translate(20.546 15.788)"
                  >
                    <g data-name="Group 6519">
                      <g data-name="Group 6518">
                        <g data-name="Group 6517">
                          <g data-name="Group 6516">
                            <path
                              fill="#263238"
                              d="M288.261 355.24a3.55 3.55 0 01.193.949 18.772 18.772 0 01.015 4.658 3.574 3.574 0 01-.187.949c-.067-.009.1-1.471.094-3.278s-.185-3.269-.115-3.278z"
                              data-name="Path 3359"
                              transform="translate(-288.245 -355.24)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 6524"
                    transform="translate(11.281 16.948)"
                  >
                    <g data-name="Group 6523">
                      <g data-name="Group 6522">
                        <g data-name="Group 6521">
                          <path
                            fill="#263238"
                            d="M256.619 385.978a.523.523 0 01.035-.108l.126-.311c.12-.284.287-.685.5-1.2.451-1.055 1.1-2.569 1.907-4.465l6.632-15.345.029-.064h.07l8.715-.278c8.838-.267 16.841-.507 22.648-.68 1.447-.038 2.757-.076 3.911-.105a2.665 2.665 0 001.5-.407 3.643 3.643 0 00.99-.876 5.781 5.781 0 00.864-1.629c.059-.193.105-.349.141-.463a.747.747 0 01.056-.155s.363-1.658-.032.161l-.12.472a5.645 5.645 0 01-.838 1.67 3.7 3.7 0 01-1 .917 2.8 2.8 0 01-1.55.442l-3.911.141c-5.806.19-13.807.457-22.645.747l-8.715.27.1-.067c-2.7 6.169-5.039 11.5-6.709 15.312-.835 1.887-1.5 3.392-1.966 4.438l-.536 1.184c-.059.126-.105.226-.144.3a.863.863 0 01-.058.099z"
                            data-name="Path 3360"
                            transform="translate(-256.618 -359.2)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6527" transform="translate(3.923 9.106)">
                    <g data-name="Group 6526">
                      <g data-name="Group 6525">
                        <path
                          fill="#263238"
                          d="M231.5 363.112s.012-.05.044-.141l.144-.41c.129-.36.319-.891.574-1.567.5-1.359 1.26-3.316 2.218-5.727a516.248 516.248 0 017.869-18.667 10.1 10.1 0 011.427-2.569 3.723 3.723 0 011.148-.891 4.845 4.845 0 011.359-.437 13.663 13.663 0 012.754-.126q1.345.031 2.631.026c3.427-.012 6.512-.082 9.1-.12s4.69-.059 6.14-.05c.724.006 1.289.015 1.67.029l.434.015a1.139 1.139 0 01.146.012l-.149.006h-2.1c-1.45.009-3.548.044-6.137.1s-5.677.135-9.1.152q-1.287.009-2.634-.021a13.5 13.5 0 00-2.721.126 4.816 4.816 0 00-1.318.425 3.628 3.628 0 00-1.1.855 10.031 10.031 0 00-1.4 2.534 600.866 600.866 0 00-7.907 18.643c-.97 2.4-1.74 4.353-2.262 5.707-.258.671-.46 1.2-.6 1.558l-.158.4c-.049.091-.072.138-.072.138z"
                          data-name="Path 3361"
                          transform="translate(-231.5 -332.432)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 6553" transform="translate(39.618 19.872)">
                  <g data-name="Group 6531" transform="translate(9.175)">
                    <g data-name="Group 6530">
                      <g data-name="Group 6529">
                        <path
                          fill="#87a878"
                          d="M379.9 217.16l-9.79 1.468v1.567a96.045 96.045 0 00-5.153 8.624c-.785 1.866-2.5 10.622-1.89 10.622l-8.232 6.65-.029.035 4.194 5.968a10.494 10.494 0 004.268 3.53c3.044 1.374 6.949 3 8.276 2.965a7.634 7.634 0 002.461-.577l.02-.009.009-.021c.1-.205 8.135-21.833 8.475-28.076s-2.552-12.67-2.609-12.746z"
                          data-name="Path 3362"
                          transform="translate(-354.81 -217.16)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6538" transform="translate(9.187 5.223)">
                    <g data-name="Group 6534" transform="translate(.064 .061)">
                      <g data-name="Group 6533">
                        <g data-name="Group 6532">
                          <path
                            fill="#87a878"
                            d="M359.455 265.134c1.55 1.216 8.932 1.227 12.415.428s2.678-1.878 3.48-4.218 2.795-11.029 2.639-16.566a12.377 12.377 0 00-5.51-9.509c-.917-.489-3.02 1.629-4.207 3.594s-1.365 17.817-1.365 17.817l-11.838 2.534 1.055 5.209z"
                            data-name="Path 3363"
                            transform="translate(-355.07 -235.197)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 6537">
                      <g data-name="Group 6536">
                        <g data-name="Group 6535">
                          <path
                            fill="#87a878"
                            d="M366.682 266c-3.126.088-6.392-.182-7.406-.961l-3.351-.715-.009-.038-1.066-5.267.056-.012 11.791-2.525c.015-1.122.214-15.878 1.374-17.8 1.125-1.866 3.3-4.142 4.283-3.618a12.321 12.321 0 015.54 9.559c.149 5.42-1.784 14.082-2.642 16.587a11.058 11.058 0 00-.375 1.456c-.281 1.359-.451 2.182-3.146 2.8a27.861 27.861 0 01-5.049.534zm-10.657-1.772l3.313.712c1.488 1.166 8.75 1.248 12.368.416 2.622-.6 2.777-1.35 3.058-2.71a10.97 10.97 0 01.381-1.471c.855-2.5 2.786-11.141 2.637-16.546a12.2 12.2 0 00-5.478-9.459c-.844-.448-2.909 1.553-4.131 3.574-1.163 1.931-1.353 17.63-1.356 17.788v.047l-.047.009-11.782 2.522z"
                            data-name="Path 3364"
                            transform="translate(-354.85 -234.989)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6543" transform="translate(9.125 10.089)">
                    <g data-name="Group 6542">
                      <g data-name="Group 6541">
                        <g data-name="Group 6540">
                          <g data-name="Group 6539">
                            <path
                              fill="#455a64"
                              d="M367.459 253.182s.006.094 0 .272-.018.454-.029.794c-.035.709-.082 1.731-.144 3.035-.138 2.637-.337 6.41-.58 11v.073l-.073.015c-3.468.75-7.485 1.614-11.832 2.555l.088-.138c.34 1.664.694 3.416 1.06 5.209l-.091-.094c1.635.375 3.351.77 5.115 1.072a26.584 26.584 0 007.546.372 12.811 12.811 0 003.477-.823 4.548 4.548 0 002.452-2.226 14.608 14.608 0 00.891-3.143q.334-1.569.644-3.047c.4-1.969.741-3.808 1-5.5a51.234 51.234 0 00.647-8.05c-.006-.955-.035-1.7-.07-2.2-.015-.243-.023-.431-.032-.571a1.406 1.406 0 010-.2 1.1 1.1 0 01.026.193c.015.141.032.328.056.571.053.5.094 1.242.114 2.2a48.829 48.829 0 01-.571 8.077 101.662 101.662 0 01-.97 5.51q-.308 1.476-.633 3.053a14.6 14.6 0 01-.9 3.2 4.378 4.378 0 01-1.043 1.4 5.565 5.565 0 01-1.515.94 12.931 12.931 0 01-3.542.847 26.8 26.8 0 01-7.614-.369c-1.772-.3-3.492-.7-5.127-1.078l-.076-.018-.015-.076c-.363-1.793-.715-3.545-1.052-5.212l-.023-.111.111-.023 11.841-2.516-.076.088c.275-4.593.5-8.364.662-11 .085-1.3.152-2.323.2-3.032l.059-.794a2.33 2.33 0 01.019-.255z"
                              data-name="Path 3365"
                              transform="translate(-354.64 -251.6)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6548" transform="translate(22.983 6.119)">
                    <g data-name="Group 6547">
                      <g data-name="Group 6546">
                        <g data-name="Group 6545">
                          <g data-name="Group 6544">
                            <path
                              fill="#455a64"
                              d="M409.725 239.766c-.029.032-.372-.311-1.037-.688A5.774 5.774 0 00403 239c-.674.363-1.025.694-1.055.662-.012-.012.067-.105.226-.255a4.706 4.706 0 01.744-.557 5.516 5.516 0 015.859.073 4.507 4.507 0 01.729.574c.158.16.231.257.222.269z"
                              data-name="Path 3366"
                              transform="translate(-401.946 -238.049)"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 6552" transform="translate(0 26.989)">
                    <g data-name="Group 6551">
                      <g data-name="Group 6550">
                        <g data-name="Group 6549">
                          <path
                            fill="#aa8776"
                            d="M332.9 311.847l-3.533-2.557-5.469 1.479s.091.48.806.7l1.488-.278-2.7 1.966a1.514 1.514 0 001.09 1.031 2.753 2.753 0 011.4.946l1.459 1.456 3.776-.105 2.555-.328-.873-4.306z"
                            data-name="Path 3367"
                            transform="translate(-323.49 -309.29)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PaymentIcon;
