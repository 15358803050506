import * as actionTypes from '../actions/actionTypes';

const initialState = {
    forgotDetails:{loading:false,data:{}}
}

export const forgotReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FORGOT_INIT:
            return{
                ...state,
                forgotDetails:{...state.forgotDetails,data:{},loading:true}
            }
        case actionTypes.FORGOT_SUCCESS:
            return{
                ...state,
                forgotDetails:{...state.forgotDetails,data:action.response,loading:false}
            }

        case actionTypes.FORGOT_FAILURE:
            return{
                ...state,
                forgotDetails:{...state.forgotDetails,data:{},loading:false}
            }
        default:
            return state
    }
} 