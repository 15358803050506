function ResetPasswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="530.654"
      height="318.805"
      viewBox="0 0 530.654 318.805"
    >
      <g data-name="Group 3601" transform="translate(-362 -562.126)">
        <g data-name="Group 3600" transform="translate(362 562.126)">
          <g data-name="4860635">
            <g transform="translate(0 318.049)">
              <g data-name="Group 3173">
                <g data-name="Group 3172">
                  <path
                    fill="#263238"
                    d="M472.548 465.7c0 .143-100.288.265-223.964.265-123.727 0-223.984-.123-223.984-.265s100.268-.265 223.984-.265c123.676-.005 223.964.118 223.964.265z"
                    data-name="Path 2511"
                    transform="translate(-24.6 -465.43)"
                  ></path>
                </g>
              </g>
            </g>
            <g transform="translate(10.937 147.095)">
              <g data-name="Group 3273">
                <g data-name="Group 3265">
                  <g data-name="Group 3234" transform="translate(0 30.341)">
                    <g data-name="Group 3233">
                      <path
                        fill="#87a878"
                        d="M78.951 417.382S51.98 386.338 41.8 355.294s-5.339-27.481-5.339-27.481 13.739 30.155 24.176 44.723S89.65 408.48 89.65 408.48z"
                        data-name="Path 2542"
                        transform="translate(-35.314 -327.688)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3236"
                    transform="translate(46.058 34.491)"
                  >
                    <g data-name="Group 3235">
                      <path
                        fill="#87a878"
                        d="M83.38 332.556a1.575 1.575 0 00-2.93 1c1.644 10.9 5.2 36.5 5.063 53.094-.184 21.56.01 29.89.01 29.89h11.23s-1.991-44.57-4.533-58.158c-1.776-9.523-5.553-20.099-8.84-25.826z"
                        data-name="Path 2543"
                        transform="translate(-80.431 -331.754)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3238"
                    transform="translate(55.132 30.652)"
                  >
                    <g data-name="Group 3237">
                      <path
                        fill="#87a878"
                        d="M89.32 413a118.874 118.874 0 009.239-33.514c.929-7.932-.725-18.661-3.175-32.973a88.984 88.984 0 01-1.419-15.864 2.68 2.68 0 015.035-1.26c1.358 2.419 5.278 10.678 6.268 15.854 2.715 14.129 5.8 21.2 3.44 34.607S97.65 413.915 97.65 413.915z"
                        data-name="Path 2544"
                        transform="translate(-89.32 -327.993)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3240"
                    transform="translate(65.228 13.469)"
                  >
                    <g data-name="Group 3239">
                      <path
                        fill="#87a878"
                        d="M99.22 414.255s29.615-63.64 31.667-73.082 9.035-31.2 9.851-29.962 2.052 12.73-3.287 25.858-28.124 79.126-28.124 79.126H99.21"
                        data-name="Path 2545"
                        transform="translate(-99.21 -311.161)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 3243" transform="translate(.347 31.18)">
                    <g data-name="Group 3242">
                      <g data-name="Group 3241">
                        <path
                          fill="#263238"
                          d="M87.193 411.046a6.41 6.41 0 01-.827-.572c-.521-.4-1.327-.96-2.3-1.756a105.819 105.819 0 01-7.963-7.023 133.99 133.99 0 01-21.489-27.573 180.88 180.88 0 01-14.67-31.769 200.894 200.894 0 01-3.277-10.086c-.357-1.194-.613-2.144-.786-2.777a4.311 4.311 0 01-.225-.98 5.4 5.4 0 01.347.939c.225.684.521 1.593.9 2.736a292.642 292.642 0 003.471 10.015 191.377 191.377 0 0014.792 31.6 139.215 139.215 0 0021.254 27.583c3.165 3.154 5.88 5.553 7.8 7.156.949.817 1.715 1.409 2.215 1.837a6.232 6.232 0 01.758.67z"
                          data-name="Path 2546"
                          transform="translate(-35.654 -328.51)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 3246"
                    transform="translate(47.529 35.947)"
                  >
                    <g data-name="Group 3245">
                      <g data-name="Group 3244">
                        <path
                          fill="#263238"
                          d="M89.475 380.415a11.671 11.671 0 01-.265-1.868c-.143-1.317-.327-3.032-.541-5.1-.47-4.308-1.2-10.239-2.246-16.762s-2.215-12.393-3.124-16.63c-.439-2.032-.806-3.716-1.082-5.012a11.2 11.2 0 01-.337-1.858 10.944 10.944 0 01.572 1.8c.327 1.164.766 2.869 1.276 4.972 1.021 4.216 2.256 10.086 3.308 16.63s1.715 12.5 2.072 16.824c.184 2.164.3 3.91.347 5.125a11.606 11.606 0 01.02 1.879z"
                          data-name="Path 2547"
                          transform="translate(-81.872 -333.18)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 3249"
                    transform="translate(59.029 31.547)"
                  >
                    <g data-name="Group 3248">
                      <g data-name="Group 3247">
                        <path
                          fill="#263238"
                          d="M96.172 328.87a4.36 4.36 0 01.347.806l.888 2.368a43.09 43.09 0 011.266 3.787c.439 1.49 1.011 3.175 1.439 5.094l.735 2.991c.255 1.041.429 2.144.653 3.277.48 2.256.786 4.706 1.143 7.279a117.229 117.229 0 01.7 16.864 120.2 120.2 0 01-1.909 16.772c-.541 2.542-1.021 4.961-1.664 7.187-.306 1.113-.561 2.195-.888 3.216s-.643 2-.949 2.93c-.561 1.878-1.256 3.522-1.8 4.982s-1.072 2.7-1.541 3.685c-.439.949-.786 1.715-1.051 2.3a4.963 4.963 0 01-.408.786 4.862 4.862 0 01.286-.837c.235-.592.551-1.368.939-2.338.429-1.011.929-2.246 1.439-3.716s1.174-3.114 1.7-4.992c.286-.939.592-1.909.9-2.93s.551-2.1.847-3.216c.623-2.215 1.072-4.624 1.593-7.156a124.239 124.239 0 001.858-16.691 124.14 124.14 0 00-.653-16.773c-.337-2.562-.623-5-1.082-7.258-.214-1.133-.378-2.225-.613-3.267s-.47-2.031-.694-2.991c-.4-1.919-.939-3.6-1.348-5.1s-.806-2.777-1.164-3.808c-.316-.99-.582-1.8-.776-2.4a6.042 6.042 0 01-.193-.851z"
                          data-name="Path 2548"
                          transform="translate(-93.137 -328.87)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 3252"
                    transform="translate(70.076 14.795)"
                  >
                    <g data-name="Group 3251">
                      <g data-name="Group 3250">
                        <path
                          fill="#263238"
                          d="M140.3 312.46a1.2 1.2 0 01-.02.286c-.031.225-.082.5-.133.827-.133.725-.3 1.8-.582 3.165-.572 2.736-1.521 6.676-3 11.454s-3.5 10.382-5.9 16.548-5.165 12.873-7.8 20.019c-2.675 7.136-4.727 14.1-6.656 20.417s-3.736 11.995-5.492 16.681a104.514 104.514 0 01-4.675 10.882c-.6 1.266-1.154 2.205-1.5 2.848-.163.3-.306.531-.419.725-.092.163-.153.245-.163.235a1.185 1.185 0 01.1-.265c.1-.2.214-.449.357-.755.316-.664.827-1.623 1.4-2.9 1.194-2.522 2.8-6.227 4.482-10.933s3.471-10.372 5.349-16.7c1.9-6.319 3.951-13.312 6.625-20.468 2.634-7.166 5.431-13.873 7.85-20.019s4.492-11.719 6.013-16.466 2.552-8.657 3.2-11.372c.327-1.358.531-2.419.694-3.134.082-.327.143-.592.194-.817a.92.92 0 01.076-.258z"
                          data-name="Path 2549"
                          transform="translate(-103.958 -312.46)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 3264" transform="translate(10.787)">
                    <g data-name="Group 3254">
                      <g data-name="Group 3253">
                        <path
                          fill="#87a878"
                          d="M50.2 298.705a2.548 2.548 0 00-4.2 2.6 72.279 72.279 0 003.726 9.565c4.073 8.647 16.027 39.691 21.632 56.739s13.488 48.861 13.488 48.861l11.954-1.94s-10.933-52.257-21.622-71.092c-8.595-15.128-17.191-36.995-24.978-44.733z"
                          data-name="Path 2550"
                          transform="translate(-45.88 -297.967)"
                        ></path>
                      </g>
                    </g>
                    <g
                      data-name="Group 3257"
                      transform="translate(28.73 80.313)"
                    >
                      <g data-name="Group 3256">
                        <g data-name="Group 3255">
                          <path
                            fill="#263238"
                            d="M74.03 376.64a7.9 7.9 0 01.613 1.429c.357.939.837 2.307 1.378 4.012 1.1 3.41 2.45 8.177 3.808 13.465s2.481 10.106 3.267 13.6c.357 1.654.664 3.042.9 4.145a7 7 0 01.245 1.542 7.659 7.659 0 01-.49-1.48c-.3-1.092-.653-2.46-1.092-4.094-.919-3.593-2.113-8.32-3.45-13.547-1.358-5.288-2.634-10.055-3.624-13.5-.47-1.623-.868-2.981-1.184-4.063a8.5 8.5 0 01-.371-1.509z"
                            data-name="Path 2551"
                            transform="translate(-74.023 -376.64)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g data-name="Group 3260" transform="translate(1.386 .534)">
                      <g data-name="Group 3259">
                        <g data-name="Group 3258">
                          <path
                            fill="#263238"
                            d="M90.81 415.489a1.523 1.523 0 01-.071-.316c-.041-.245-.082-.561-.133-.939-.122-.827-.235-2.052-.49-3.624-.439-3.144-1.225-7.667-2.5-13.189a188.551 188.551 0 00-5.594-19.1c-2.45-7.085-5.533-14.731-9.014-22.653-7.034-15.823-14.057-29.85-18.671-40.232-2.338-5.176-4.145-9.4-5.329-12.342-.6-1.47-1.041-2.624-1.348-3.4-.133-.367-.245-.653-.327-.888a1.158 1.158 0 01-.092-.316 1.373 1.373 0 01.153.286c.1.225.235.51.388.868.347.806.837 1.929 1.45 3.348a526.262 526.262 0 005.513 12.26c4.716 10.321 11.8 24.317 18.845 40.16 3.491 7.932 6.554 15.609 8.983 22.724a180.679 180.679 0 015.482 19.192 134.025 134.025 0 012.317 13.251c.214 1.572.286 2.807.368 3.634.031.388.051.694.071.949a1.326 1.326 0 01-.001.327z"
                            data-name="Path 2552"
                            transform="translate(-47.238 -298.49)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3263"
                      transform="translate(37.827 66.337)"
                    >
                      <g data-name="Group 3262">
                        <g data-name="Group 3261">
                          <path
                            fill="#263238"
                            d="M96.017 413.176a12.916 12.916 0 01-.4-2.011c-.235-1.409-.551-3.257-.929-5.492-.827-4.635-2.083-11.015-3.889-17.957-1.786-6.952-3.808-13.128-5.359-17.569-.766-2.144-1.388-3.91-1.868-5.247a11.9 11.9 0 01-.633-1.95 12.234 12.234 0 01.868 1.858c.51 1.215 1.235 2.981 2.062 5.186a173.582 173.582 0 015.553 17.569 182.274 182.274 0 013.717 18.037c.337 2.338.6 4.226.735 5.533a14.974 14.974 0 01.143 2.043z"
                            data-name="Path 2553"
                            transform="translate(-82.935 -362.95)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 3272" transform="translate(29.948 114.848)">
                  <g data-name="Group 3267" transform="translate(9.627)">
                    <g data-name="Group 3266">
                      <path
                        fill="#292929"
                        d="M0 0H46.663V56.851H0z"
                        data-name="Rectangle 199"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3269"
                    opacity="0.3"
                    transform="translate(9.627)"
                  >
                    <g data-name="Group 3268">
                      <path
                        d="M0 0H46.663V56.851H0z"
                        data-name="Rectangle 200"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 3271">
                    <g data-name="Group 3270">
                      <path
                        fill="#6b6b6b"
                        d="M0 0H41.395V56.851H0z"
                        data-name="Rectangle 201"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(120.992)">
              <g data-name="Group 3599">
                <g data-name="Group 3381" transform="translate(87.772 131.908)">
                  <g data-name="Group 3275" transform="translate(73.726)">
                    <g data-name="Group 3274">
                      <path
                        fill="#ffbe9d"
                        d="M388.694 286.643l-24.531-3.553-49.072 7.258-13.771 4.869 2.44 30.115 47.184 8.687 31.83-9.433 12.036-17.712z"
                        data-name="Path 2554"
                        transform="translate(-301.32 -283.09)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 3311" transform="translate(0 1.593)">
                    <g data-name="Group 3277" transform="translate(51.792)">
                      <g data-name="Group 3276">
                        <path
                          fill="#a5668b"
                          d="M404.608 289.489l-20.529-4.839s-14.516 44.866-50.338 42.937c-29.2-1.572-29.533-34.168-29.533-34.168l-21.04 6.738s-8.044 53.972.858 92.2c2.083 8.932 4.869 20.978 4.869 20.978l-2.848 55.871 155.618.245-37.057-179.962z"
                          data-name="Path 2555"
                          transform="translate(-279.834 -284.65)"
                        ></path>
                      </g>
                    </g>
                    <g
                      data-name="Group 3279"
                      opacity="0.3"
                      transform="translate(115.56 160.661)"
                    >
                      <g data-name="Group 3278">
                        <path
                          d="M342.3 455.1c7.575-.184 14.557.449 22.132.265 9.086-.225 18.243-.449 27.216 1.031 6.727 1.113 13.261 3.154 20 4.216 9.167 1.45 18.967.929 27.134-3.491l-1.317-7.626-31-7.462z"
                          data-name="Path 2556"
                          transform="translate(-342.3 -442.03)"
                        ></path>
                      </g>
                    </g>
                    <g data-name="Group 3281" transform="translate(0 15.078)">
                      <g data-name="Group 3280">
                        <path
                          fill="#a5668b"
                          d="M266.657 312.436c7.115-9.2 18.947-13.016 18.947-13.016l7.963 69.316-15.078 100.91-49.389-.235s27.236-143.653 37.557-156.975z"
                          data-name="Path 2557"
                          transform="translate(-229.1 -299.42)"
                        ></path>
                      </g>
                    </g>
                    <g
                      data-name="Group 3283"
                      transform="translate(165.477 4.328)"
                    >
                      <g data-name="Group 3282">
                        <path
                          fill="#a5668b"
                          d="M399.887 288.89s33.239 2.664 45.162 57.116c5.39 24.633 12.209 53.5 14.18 72.9 1.429 14.067-6.227 36.107-20.254 37.9l-17.64 2.368a28.922 28.922 0 01-10.392-.388c-11.137-2.562-12.271-10.2-14.241-18.324l-5.145-16.252c-2.818-15.456 11.587-18.334 19.539-18.171l-5.931-70.539z"
                          data-name="Path 2558"
                          transform="translate(-391.197 -288.89)"
                        ></path>
                      </g>
                    </g>
                    <g
                      data-name="Group 3286"
                      transform="translate(50.905 53.615)"
                    >
                      <g data-name="Group 3285">
                        <g data-name="Group 3284">
                          <path
                            fill="#263238"
                            d="M280.45 337.17a3.844 3.844 0 00-.153.755c-.082.551-.194 1.286-.337 2.195-.071.48-.143 1.011-.225 1.592-.061.592-.122 1.225-.194 1.9-.133 1.368-.306 2.92-.378 4.635a114.381 114.381 0 00-.082 12.117 148.1 148.1 0 001.47 14.761c.766 5.206 1.725 10.137 2.7 14.588s1.94 8.442 2.766 11.781a592.898 592.898 0 002.052 7.891c.245.888.449 1.6.592 2.134a3.606 3.606 0 00.245.735 3.951 3.951 0 00-.143-.766 174.64 174.64 0 00-.5-2.164c-.459-1.929-1.1-4.624-1.889-7.932-.776-3.348-1.725-7.34-2.654-11.8-.949-4.451-1.889-9.361-2.654-14.557s-1.286-10.168-1.511-14.71c-.194-4.543-.235-8.636-.031-12.066.051-1.715.184-3.267.3-4.635q.077-1.011.153-1.9.092-.857.184-1.592c.1-.919.174-1.654.235-2.205a3.494 3.494 0 00.054-.757z"
                            data-name="Path 2559"
                            transform="translate(-278.965 -337.17)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3289"
                      transform="translate(185.192 121.281)"
                    >
                      <g data-name="Group 3288">
                        <g data-name="Group 3287">
                          <path
                            fill="#263238"
                            d="M436.766 422.764a5.744 5.744 0 00-.265-1.368 21.63 21.63 0 00-1.286-3.583 24.433 24.433 0 00-19.508-14.2 21.387 21.387 0 00-3.808-.122 6.223 6.223 0 00-1.388.174 50.959 50.959 0 015.135.327 25.169 25.169 0 0119.212 13.986 45.519 45.519 0 011.908 4.786z"
                            data-name="Path 2560"
                            transform="translate(-410.51 -403.454)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3292"
                      transform="translate(185.384 113.379)"
                    >
                      <g data-name="Group 3291">
                        <g data-name="Group 3290">
                          <path
                            fill="#263238"
                            d="M429.086 395.792a2.228 2.228 0 00-.806-.071 16.945 16.945 0 00-2.195.1 25.33 25.33 0 00-13.24 5.441 16.453 16.453 0 00-1.623 1.47c-.357.378-.541.592-.521.623.061.061.9-.715 2.368-1.8a29.174 29.174 0 0113.057-5.38c1.816-.251 2.96-.291 2.96-.383z"
                            data-name="Path 2561"
                            transform="translate(-410.699 -395.714)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3295"
                      transform="translate(169.185 7.839)"
                    >
                      <g data-name="Group 3294">
                        <g data-name="Group 3293">
                          <path
                            fill="#263238"
                            d="M395 327.437a7.543 7.543 0 00.082-1.46c.01-.939.061-2.287.174-3.951a64.652 64.652 0 012.215-12.832 65.524 65.524 0 014.788-12.107 69.617 69.617 0 011.909-3.471 7.542 7.542 0 00.684-1.286 7.7 7.7 0 00-.868 1.174c-.541.776-1.245 1.929-2.062 3.4a57.138 57.138 0 00-7.044 25.082c-.071 1.674-.061 3.042-.01 3.981a7.445 7.445 0 00.132 1.47z"
                            data-name="Path 2562"
                            transform="translate(-394.83 -292.329)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3298"
                      transform="translate(105.694 92.386)"
                    >
                      <g data-name="Group 3297">
                        <g data-name="Group 3296">
                          <path
                            fill="#263238"
                            d="M332.64 375.15a1.973 1.973 0 00.153.572c.122.367.276.929.551 1.6a33.635 33.635 0 002.767 5.6 35.992 35.992 0 0035.28 16.956 34.387 34.387 0 006.1-1.337c.7-.2 1.235-.439 1.6-.572a2.257 2.257 0 00.541-.245c-.02-.041-.786.214-2.195.623a37.774 37.774 0 01-6.084 1.164 36.2 36.2 0 01-34.923-16.762 38.162 38.162 0 01-2.9-5.482c-.573-1.362-.849-2.137-.89-2.117z"
                            data-name="Path 2563"
                            transform="translate(-332.636 -375.15)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3301"
                      transform="translate(175.747 155.679)"
                    >
                      <g data-name="Group 3300">
                        <g data-name="Group 3299">
                          <path
                            fill="#263238"
                            d="M401.26 450.472c.051-.051.582.694 1.817 1.715a16.7 16.7 0 006.084 2.9 32.559 32.559 0 0010.015.878c3.8-.276 7.983-.715 12.342-1.368a63.285 63.285 0 0012.046-2.777 15.566 15.566 0 004.42-2.95 23.4 23.4 0 003.093-3.512 33.3 33.3 0 003.4-5.9c.317-.745.561-1.317.715-1.715a2.322 2.322 0 01.265-.592 2.822 2.822 0 01-.163.633c-.112.408-.337 1-.623 1.756a30.354 30.354 0 01-3.277 6.033 22.807 22.807 0 01-3.1 3.614 15.711 15.711 0 01-4.533 3.083 61 61 0 01-12.158 2.848 148.649 148.649 0 01-12.393 1.327 31.571 31.571 0 01-10.137-1.011 16.159 16.159 0 01-6.115-3.1 11.208 11.208 0 01-1.3-1.348 2.364 2.364 0 01-.398-.514z"
                            data-name="Path 2564"
                            transform="translate(-401.258 -437.15)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3304"
                      transform="translate(170.263 60.301)"
                    >
                      <g data-name="Group 3303">
                        <g data-name="Group 3302">
                          <path
                            fill="#263238"
                            d="M395.89 343.72c.143-.031 3.644 13.577 7.83 30.391s7.452 30.482 7.309 30.523-3.644-13.567-7.83-30.391-7.452-30.492-7.309-30.523z"
                            data-name="Path 2565"
                            transform="translate(-395.886 -343.72)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3307"
                      transform="translate(165.749 121.219)"
                    >
                      <g data-name="Group 3306">
                        <g data-name="Group 3305">
                          <path
                            fill="#263238"
                            d="M391.746 419.274a4.748 4.748 0 01-.174-1.123 18.776 18.776 0 01-.071-3.093 8.945 8.945 0 011.49-4.359 13.082 13.082 0 011.7-2.144 11.618 11.618 0 012.358-1.756 29.64 29.64 0 019.678-3.124 27.672 27.672 0 013.073-.276 4.31 4.31 0 011.133.041c0 .092-1.6.153-4.145.6a32.606 32.606 0 00-9.473 3.216 11.843 11.843 0 00-2.266 1.674 12.286 12.286 0 00-1.654 2.042 8.9 8.9 0 00-1.531 4.124 26.1 26.1 0 00-.092 3.042 4.76 4.76 0 01-.026 1.136z"
                            data-name="Path 2566"
                            transform="translate(-391.464 -403.394)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3310"
                      opacity="0.3"
                      transform="translate(47.323 51.369)"
                    >
                      <g data-name="Group 3309">
                        <g data-name="Group 3308">
                          <path
                            d="M282.055 382.94c-8.483-14.363-8.657-33.024-1.266-47.97-2.205 15.915-2.031 32.238 1.511 47.908"
                            data-name="Path 2567"
                            transform="translate(-275.457 -334.97)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 3353"
                    transform="translate(66.066 134.047)"
                  >
                    <g data-name="Group 3328">
                      <g
                        data-name="Group 3313"
                        transform="translate(32.742 2.736)"
                      >
                        <g data-name="Group 3312">
                          <path
                            fill="#ffbe9d"
                            d="M325.89 417.08l.061.031z"
                            data-name="Path 2568"
                            transform="translate(-325.89 -417.08)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 3315"
                        transform="translate(33.109 2.909)"
                      >
                        <g data-name="Group 3314">
                          <path
                            fill="#ffbe9d"
                            d="M326.25 417.25c.051.02.092.041.133.061-.041-.02-.083-.041-.133-.061z"
                            data-name="Path 2569"
                            transform="translate(-326.25 -417.25)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 3317"
                        transform="translate(32.803 2.766)"
                      >
                        <g data-name="Group 3316">
                          <path
                            fill="#ffbe9d"
                            d="M325.95 417.11c.123.061.225.1.306.143-.112-.053-.214-.102-.306-.143z"
                            data-name="Path 2570"
                            transform="translate(-325.95 -417.11)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 3319"
                        transform="translate(32.609 2.675)"
                      >
                        <g data-name="Group 3318">
                          <path
                            fill="#ffbe9d"
                            d="M325.76 417.02c.051.02.092.041.133.061-.082-.041-.133-.061-.133-.061z"
                            data-name="Path 2571"
                            transform="translate(-325.76 -417.02)"
                          ></path>
                        </g>
                      </g>
                      <g data-name="Group 3321">
                        <g data-name="Group 3320">
                          <path
                            fill="#ffbe9d"
                            d="M295.2 445.75c2.664-5.043 23.52-18.355 23.52-18.355l-5.645-1.307-6.227 2.634a15.266 15.266 0 01-2.685 5.839c-2.3 3.267-6.186-.112-6.186-.112l2.634-12.628 12.332-7.422s11.607 1.776 13.475 2.664l4.951 4.522c2.174 1.7 8.442 8.412 10.147 8.616s55.544-11.9 55.544-11.9c7.381 5.839 11.27 17.456 12.444 25.827 0 0 1.317 4.114-.112 6.35l-66.079 5.186-14.013 3.868s-7.748 3.165-11.5 3.951c-4.655.98-7.8-.521-7.922-3.1s3.491-2.858 3.491-2.858a70.408 70.408 0 009-3.961 15.887 15.887 0 005.084-5.574l-4.992.592s-10.617 7.371-14.6 9.524-4.778-1.807-5.094-3.011 11.933-13.183 11.933-13.183-11.638 7.177-13.73 8.983-5.2 2.348-5.86 1.133c-.688-1.194-2.576-1.235.09-6.278z"
                            data-name="Path 2572"
                            transform="translate(-293.817 -414.4)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 3323"
                        transform="translate(33.242 2.971)"
                      >
                        <g data-name="Group 3322">
                          <path
                            fill="#ffbe9d"
                            d="M326.38 417.31c.051.02.092.041.112.051a1 1 0 01-.112-.051z"
                            data-name="Path 2573"
                            transform="translate(-326.38 -417.31)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 3327"
                        transform="translate(33.354 3.032)"
                      >
                        <g data-name="Group 3326">
                          <path
                            fill="#ffbe9d"
                            d="M326.49 417.37a.165.165 0 01.061.031.166.166 0 00-.061-.031z"
                            data-name="Path 2575"
                            transform="translate(-326.49 -417.37)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3331"
                      transform="translate(47.716 15.81)"
                    >
                      <g data-name="Group 3330">
                        <g data-name="Group 3329">
                          <path
                            fill="#eb996e"
                            d="M351.657 446.591a2.872 2.872 0 00.061-.817 4.735 4.735 0 00-.061-.96 8.208 8.208 0 00-.225-1.256 17.926 17.926 0 00-3.379-6.523 37.176 37.176 0 00-5.094-5.288 14.664 14.664 0 00-2.4-1.858 23.93 23.93 0 002.165 2.111 48.625 48.625 0 014.941 5.339 19.358 19.358 0 013.44 6.309c.501 1.779.45 2.952.552 2.943z"
                            data-name="Path 2576"
                            transform="translate(-340.558 -429.887)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3334"
                      transform="translate(25.392 10.381)"
                    >
                      <g data-name="Group 3333">
                        <g data-name="Group 3332">
                          <path
                            fill="#eb996e"
                            d="M318.69 426.785a7.7 7.7 0 006.595-2.205c-.092-.122-1.368.827-3.185 1.429s-3.41.633-3.41.776z"
                            data-name="Path 2577"
                            transform="translate(-318.69 -424.569)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3337"
                      transform="translate(21.92 22.12)"
                    >
                      <g data-name="Group 3336">
                        <g data-name="Group 3335">
                          <path
                            fill="#eb996e"
                            d="M315.29 440.368a65.649 65.649 0 0010.433-4.288 65.673 65.673 0 00-10.433 4.288z"
                            data-name="Path 2578"
                            transform="translate(-315.289 -436.069)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3340"
                      transform="translate(33.107 31.348)"
                    >
                      <g data-name="Group 3339">
                        <g data-name="Group 3338">
                          <path
                            fill="#eb996e"
                            d="M326.25 447.244a12.7 12.7 0 002.726-.96 12.3 12.3 0 002.654-1.143 6.807 6.807 0 00-2.828.684 6.616 6.616 0 00-2.552 1.419z"
                            data-name="Path 2579"
                            transform="translate(-326.248 -445.108)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3343"
                      transform="translate(23.645 27.315)"
                    >
                      <g data-name="Group 3342">
                        <g data-name="Group 3341">
                          <path
                            fill="#eb996e"
                            d="M316.98 441.18a11.408 11.408 0 012.062 1.8 11.617 11.617 0 011.184 2.46c.061 0 .122-.327.041-.857a4.414 4.414 0 00-2.45-3.205c-.49-.228-.827-.249-.837-.198z"
                            data-name="Path 2580"
                            transform="translate(-316.978 -441.157)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3346"
                      transform="translate(18.743 19.604)"
                    >
                      <g data-name="Group 3345">
                        <g data-name="Group 3344">
                          <path
                            fill="#eb996e"
                            d="M312.18 433.631c-.061.133.847.623 1.45 1.684a13.954 13.954 0 01.755 2.093 2.484 2.484 0 00-.327-2.338c-.704-1.225-1.858-1.57-1.878-1.439z"
                            data-name="Path 2581"
                            transform="translate(-312.177 -433.604)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3349"
                      transform="translate(19.301 1.329)"
                    >
                      <g data-name="Group 3348">
                        <g data-name="Group 3347">
                          <path
                            fill="#eb996e"
                            d="M313.822 420.222c.051.031.245-.2.388-.653a3.517 3.517 0 00.041-1.837 3.442 3.442 0 00-.878-1.613c-.337-.337-.612-.449-.643-.408a9.426 9.426 0 011.051 2.134 8.8 8.8 0 01.041 2.377z"
                            data-name="Path 2582"
                            transform="translate(-312.723 -415.702)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3352"
                      transform="translate(102.565 3.102)"
                    >
                      <g data-name="Group 3351">
                        <g data-name="Group 3350">
                          <path
                            fill="#263238"
                            d="M407.878 452.312a7.506 7.506 0 01-.092-1.521c-.01-.49-.02-1.082-.072-1.776s-.061-1.48-.163-2.348a52.476 52.476 0 00-2.889-13.24 47.562 47.562 0 00-3.134-6.778c-.6-.97-1.143-1.929-1.746-2.756-.3-.419-.572-.817-.847-1.215-.3-.368-.572-.725-.847-1.072-2.195-2.705-3.879-4.083-3.8-4.165a9.152 9.152 0 011.184.949 13.536 13.536 0 011.3 1.225 21.7 21.7 0 011.6 1.746c.286.337.582.694.888 1.062s.582.786.888 1.2c.633.817 1.184 1.776 1.807 2.756a44.335 44.335 0 013.205 6.84 48.247 48.247 0 012.756 13.393c.082.878.071 1.664.082 2.368s0 1.3-.02 1.786a7.078 7.078 0 01-.1 1.546z"
                            data-name="Path 2583"
                            transform="translate(-394.287 -417.438)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 3355"
                    opacity="0.3"
                    transform="translate(185.314 122.296)"
                  >
                    <g data-name="Group 3354">
                      <path
                        d="M430.21 405.4a33.2 33.2 0 00-19.58-1.776 27.057 27.057 0 0126.419 17.089c.48 1.266 1.419 2.766 2.756 2.573 1.245-.184 1.715-1.746 1.715-3.012.021-6.614-5.22-12.331-11.31-14.874z"
                        data-name="Path 2584"
                        transform="translate(-410.63 -402.889)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3357"
                    transform="translate(33.195 36.636)"
                  >
                    <g data-name="Group 3356">
                      <path
                        fill="#fff"
                        d="M280.414 332.874a3.209 3.209 0 01-.878-.3c-.561-.214-1.348-.572-2.307-1.051a48.327 48.327 0 01-7.115-4.379 48.919 48.919 0 01-6.268-5.523 32.933 32.933 0 01-1.684-1.889 3.142 3.142 0 01-.541-.755c.061-.051.96.909 2.491 2.379a62.245 62.245 0 0013.291 9.831c1.857 1.033 3.041 1.613 3.011 1.687z"
                        data-name="Path 2585"
                        transform="translate(-261.617 -318.978)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3359"
                    transform="translate(52.369 54.379)"
                  >
                    <g data-name="Group 3358">
                      <path
                        fill="#fff"
                        d="M397.869 336.36a1.14 1.14 0 01-.255.174c-.2.122-.459.276-.786.47-.684.419-1.725.99-3.073 1.695a85.939 85.939 0 01-11.893 5 112.392 112.392 0 01-18.61 4.471 180.6 180.6 0 01-23.357 1.94c-8.32.194-16.242.041-23.439-.122a156.756 156.756 0 01-19.09-1.439 73.914 73.914 0 01-12.567-2.92c-1.45-.49-2.552-.9-3.287-1.215-.347-.143-.623-.265-.837-.347a2.166 2.166 0 01-.276-.133 1.361 1.361 0 01.3.082c.225.082.5.174.857.306.745.286 1.868.664 3.318 1.123a79.58 79.58 0 0012.556 2.746 164.191 164.191 0 0019.049 1.327c7.2.133 15.109.276 23.408.082a185.409 185.409 0 0023.3-1.889 115.273 115.273 0 0018.569-4.349 92.624 92.624 0 0011.923-4.829c1.358-.684 2.409-1.215 3.114-1.6.337-.174.6-.316.806-.419.168-.123.26-.164.27-.154z"
                        data-name="Path 2586"
                        transform="translate(-280.399 -336.358)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3361"
                    transform="translate(20.876 81.295)"
                  >
                    <g data-name="Group 3360">
                      <path
                        fill="#fff"
                        d="M281.023 372.153a6.171 6.171 0 01-1.266-.276c-.909-.245-2.042-.562-3.4-.929-2.858-.806-6.809-1.95-11.148-3.256s-8.269-2.5-11.107-3.4c-1.337-.439-2.45-.8-3.348-1.092a6.113 6.113 0 01-1.2-.47 6.168 6.168 0 011.266.276c.909.245 2.042.561 3.4.929 2.858.806 6.809 1.95 11.148 3.257s8.269 2.5 11.107 3.4c1.337.439 2.45.8 3.348 1.092a6.108 6.108 0 011.2.469z"
                        data-name="Path 2587"
                        transform="translate(-249.55 -362.724)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3363"
                    transform="translate(9.943 133.604)"
                  >
                    <g data-name="Group 3362">
                      <path
                        fill="#fff"
                        d="M284.217 427.547c-.041.143-10.239-2.787-22.765-6.533s-22.653-6.9-22.612-7.044 10.229 2.787 22.765 6.533 22.653 6.897 22.612 7.044z"
                        data-name="Path 2588"
                        transform="translate(-238.84 -413.965)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3365"
                    transform="translate(170.369 25.11)"
                  >
                    <g data-name="Group 3364">
                      <path
                        fill="#fff"
                        d="M428.484 307.69a7.066 7.066 0 01-1.123.888c-.745.551-1.838 1.3-3.216 2.195a109.564 109.564 0 01-23.061 11.321c-1.552.541-2.818.949-3.706 1.2a8.147 8.147 0 01-1.388.347 7.9 7.9 0 011.327-.541c.96-.357 2.174-.817 3.644-1.358 3.063-1.174 7.258-2.9 11.76-5.1s8.432-4.471 11.229-6.176c1.327-.827 2.44-1.511 3.308-2.052a6.741 6.741 0 011.226-.724z"
                        data-name="Path 2589"
                        transform="translate(-395.99 -307.688)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3367"
                    transform="translate(177.842 83.112)"
                  >
                    <g data-name="Group 3366">
                      <path
                        fill="#fff"
                        d="M448.666 364.51a11.873 11.873 0 01-1.654.786c-1.082.48-2.664 1.113-4.645 1.848a109.2 109.2 0 01-15.874 4.441 107.9 107.9 0 01-16.364 1.97c-2.113.082-3.818.1-4.992.071a10.709 10.709 0 01-1.827-.092 11.962 11.962 0 011.827-.112c1.174-.031 2.879-.112 4.982-.245a126.979 126.979 0 0016.283-2.113 125.559 125.559 0 0015.854-4.277c1.991-.684 3.593-1.256 4.7-1.684a10.374 10.374 0 011.71-.593z"
                        data-name="Path 2590"
                        transform="translate(-403.31 -364.505)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3369"
                    transform="translate(181.118 123.328)"
                  >
                    <g data-name="Group 3368">
                      <path
                        fill="#fff"
                        d="M419.209 456.464a2.187 2.187 0 01.051-.572c.071-.429.153-.96.255-1.613.122-.694.265-1.552.357-2.542s.276-2.123.327-3.389c.041-.633.082-1.286.122-1.97s.02-1.409.031-2.144c.051-1.48-.061-3.063-.143-4.727a70.971 70.971 0 00-1.644-10.7 69.962 69.962 0 00-3.42-10.27c-.694-1.511-1.317-2.971-2.031-4.267-.347-.653-.653-1.3-1-1.9s-.684-1.164-1.011-1.7c-.612-1.1-1.286-2.031-1.837-2.869s-1.051-1.542-1.48-2.1c-.388-.531-.7-.97-.96-1.317a2.745 2.745 0 01-.306-.48 2.831 2.831 0 01.388.419c.276.337.623.745 1.041 1.256a24.817 24.817 0 011.562 2.062c.572.827 1.266 1.746 1.909 2.838.337.541.684 1.113 1.041 1.695s.674 1.235 1.041 1.889c.735 1.3 1.388 2.756 2.093 4.277a61.657 61.657 0 015.1 21.132c.061 1.674.153 3.267.092 4.767-.02.745-.01 1.47-.061 2.154s-.1 1.348-.153 1.991c-.071 1.266-.276 2.4-.408 3.4s-.3 1.848-.449 2.542c-.143.643-.255 1.174-.347 1.592a3.229 3.229 0 01-.16.546z"
                        data-name="Path 2591"
                        transform="translate(-406.519 -403.9)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3371"
                    transform="translate(186.111 120.84)"
                  >
                    <g data-name="Group 3370">
                      <path
                        fill="#fff"
                        d="M458.543 425.225c-.061 0-.082-.827-.316-2.307a19.971 19.971 0 00-2-5.952 25.029 25.029 0 00-5.768-7.268 30.31 30.31 0 00-9.81-5.737 38.542 38.542 0 00-11.24-1.98 73.049 73.049 0 00-9.371.347c-2.664.255-4.818.521-6.309.684q-1.041.107-1.715.184a2.239 2.239 0 01-.6.031 2.7 2.7 0 01.592-.133 93.483 93.483 0 001.7-.286 145.38 145.38 0 016.3-.857 68.55 68.55 0 019.433-.47 38.112 38.112 0 0111.4 1.97 30.024 30.024 0 019.953 5.87 24.778 24.778 0 015.778 7.462 18.7 18.7 0 011.868 6.084 12.718 12.718 0 01.123 1.725 2.991 2.991 0 01-.018.633z"
                        data-name="Path 2592"
                        transform="translate(-411.41 -401.462)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3373"
                    transform="translate(66.151 115.029)"
                  >
                    <g data-name="Group 3372">
                      <path
                        fill="#fff"
                        d="M351.17 395.79a2.4 2.4 0 01-.582.1c-.439.041-.99.1-1.664.174-1.521.122-3.593.306-6.125.51-5.176.4-12.322.888-20.233 1.3s-15.078.664-20.264.715c-2.593.02-4.7.031-6.145-.02-.674-.02-1.225-.041-1.674-.051a3.818 3.818 0 01-.582-.051 2.036 2.036 0 01.582-.051c.439-.01.99-.031 1.674-.051 1.521-.041 3.6-.092 6.135-.153 5.186-.153 12.342-.459 20.243-.878 7.871-.449 14.986-.847 20.233-1.143 2.532-.143 4.614-.255 6.135-.347.674-.031 1.225-.051 1.674-.071a2.64 2.64 0 01.593.017z"
                        data-name="Path 2593"
                        transform="translate(-293.9 -395.77)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3376"
                    opacity="0.3"
                    transform="translate(111.967 105.382)"
                  >
                    <g data-name="Group 3375">
                      <g data-name="Group 3374">
                        <path
                          d="M356.165 398.111c6.431 1.889 15.343 2.358 21.611-.031-4.91 6.962-14.374 9.494-22.581 7.217s-15.782-10.484-16.415-18.978c3.981 5.401 10.954 9.903 17.385 11.792z"
                          data-name="Path 2594"
                          transform="translate(-338.78 -386.32)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 3378"
                    transform="translate(129.821 110.435)"
                  >
                    <g data-name="Group 3377">
                      <path
                        fill="#fff"
                        d="M407.956 391.28a1.746 1.746 0 01-.51.143c-.4.082-.888.184-1.49.316-1.3.265-3.165.612-5.492 1.021-4.645.817-11.086 1.817-18.222 2.7s-13.638 1.45-18.345 1.776c-2.348.163-4.257.276-5.574.327-.613.02-1.113.041-1.521.051a1.645 1.645 0 01-.531-.02 1.889 1.889 0 01.521-.092l1.511-.153c1.388-.122 3.267-.3 5.553-.5 4.7-.419 11.168-1.062 18.3-1.929s13.577-1.817 18.232-2.532c2.266-.347 4.134-.643 5.513-.858q.9-.138 1.5-.214a1.885 1.885 0 01.555-.036z"
                        data-name="Path 2595"
                        transform="translate(-356.27 -391.27)"
                      ></path>
                    </g>
                  </g>
                  <g
                    data-name="Group 3380"
                    opacity="0.3"
                    transform="translate(170.501 64.997)"
                  >
                    <g data-name="Group 3379">
                      <path
                        d="M396.426 346.76c-1.613 18.477 3.42 36.812 8.412 54.677a7.875 7.875 0 001.368 3.083 2.652 2.652 0 003.042.847c1.562-.786 1.347-3.042.909-4.727l-13.873-53.125"
                        data-name="Path 2596"
                        transform="translate(-396.12 -346.76)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 3393" transform="translate(0 210.482)">
                  <g data-name="Group 3387">
                    <g data-name="Group 3383" transform="translate(.019 .061)">
                      <g data-name="Group 3382">
                        <path
                          fill="#e0e0e0"
                          d="M143.222 364.887l20.978 103h182.109a3.636 3.636 0 003.634-3.634 3.636 3.636 0 00-3.634-3.634H309.65l-19.457-95.8a3.983 3.983 0 00-3.859-3.185l-139.192-1.514a3.969 3.969 0 00-3.92 4.767z"
                          data-name="Path 2597"
                          transform="translate(-143.139 -360.12)"
                        ></path>
                      </g>
                    </g>
                    <g data-name="Group 3386">
                      <g data-name="Group 3385">
                        <g data-name="Group 3384">
                          <path
                            fill="#263238"
                            d="M143.222 364.889l-.061-.306a3.77 3.77 0 01-.02-.939 3.987 3.987 0 011.929-3.012 4.246 4.246 0 011.2-.48 6.56 6.56 0 011.429-.092c1 .01 2.093.02 3.277.02l8.2.071c12.352.112 30.289.286 52.625.5l36.689.367q9.923.107 20.55.214c3.542.041 7.136.072 10.78.112l5.513.061c.47.01.909-.01 1.409.031a4.213 4.213 0 013.41 2.5 6.635 6.635 0 01.378 1.4l.286 1.388c.184.929.378 1.858.572 2.787.378 1.858.766 3.736 1.143 5.625q1.148 5.666 2.328 11.454 2.358 11.592 4.829 23.714c3.287 16.15 6.7 32.953 10.219 50.256l-.245-.2h36.056a6.816 6.816 0 011.419.082 3.886 3.886 0 01-.674 7.687l-1.684.01H299c-17.007 0-33.494-.01-49.307-.01-31.616-.02-60.516-.031-85.506-.041h-.153l-.031-.153c-6.411-31.656-11.627-57.412-15.241-75.267-1.8-8.922-3.185-15.854-4.134-20.58-.47-2.348-.827-4.145-1.062-5.37-.245-1.2-.347-1.827-.347-1.827s.143.613.4 1.827.633 3.011 1.123 5.359c.97 4.716 2.4 11.648 4.236 20.56 3.655 17.844 8.932 43.58 15.415 75.226l-.184-.153c24.99-.01 53.891-.031 85.506-.041 15.8 0 32.289-.01 49.307-.01H344.8l1.664-.01a3.451 3.451 0 001.49-.4 3.4 3.4 0 00-.909-6.3 6.11 6.11 0 00-1.317-.072h-36.26l-.041-.2c-3.512-17.3-6.921-34.107-10.2-50.256q-2.45-12.112-4.808-23.724-1.179-5.8-2.317-11.464c-.378-1.889-.766-3.757-1.143-5.625-.184-.929-.378-1.858-.561-2.787l-.276-1.388a5.944 5.944 0 00-.357-1.317 3.766 3.766 0 00-1.786-1.878 3.942 3.942 0 00-1.256-.367c-.429-.041-.909-.02-1.368-.031l-5.513-.061-10.78-.122-20.55-.235c-13.23-.153-25.511-.3-36.689-.429-22.346-.276-40.272-.5-52.625-.653q-4.624-.061-8.2-.112c-1.184-.02-2.277-.031-3.277-.051a6.329 6.329 0 00-1.409.071 3.86 3.86 0 00-1.174.459 4 4 0 00-1.929 2.94 4.426 4.426 0 00.013 1.242z"
                            data-name="Path 2598"
                            transform="translate(-143.121 -360.06)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 3389"
                    transform="translate(71.989 45.959)"
                  >
                    <g data-name="Group 3388">
                      <path
                        fill="#fff"
                        d="M225.666 411.093a6.013 6.013 0 11-6.013-6.013 6.017 6.017 0 016.013 6.013z"
                        data-name="Path 2599"
                        transform="translate(-213.64 -405.08)"
                      ></path>
                    </g>
                  </g>
                  <g data-name="Group 3392" transform="translate(.265 5.635)">
                    <g data-name="Group 3391">
                      <g data-name="Group 3390">
                        <path
                          fill="#263238"
                          d="M143.38 365.611s.214-.02.623-.02c.429 0 1.041-.01 1.838-.01 1.633 0 4.022 0 7.085.01 6.166.031 15.057.071 26.042.123 22 .153 52.369.367 85.9.6q6.294.046 12.424.1c1.021.01 2.042.02 3.052.02a8.265 8.265 0 011.562.092 4.359 4.359 0 013.328 3.114c.225 1.011.4 1.96.6 2.94 1.541 7.758 3.022 15.241 4.441 22.377 2.818 14.261 5.359 27.1 7.493 37.894 2.113 10.78 3.818 19.5 5 25.552.582 3 1.031 5.349 1.337 6.952q.214 1.179.337 1.807c.071.408.1.623.1.623s-.061-.2-.143-.612-.214-1.021-.388-1.8c-.327-1.6-.806-3.93-1.429-6.932-1.225-6.043-2.981-14.762-5.165-25.521-2.164-10.78-4.737-23.622-7.6-37.873-1.419-7.126-2.92-14.608-4.471-22.367-.194-.96-.378-1.96-.592-2.9a3.851 3.851 0 00-2.94-2.746 7.549 7.549 0 00-1.46-.082c-1.011-.01-2.031-.02-3.052-.02q-6.125-.046-12.424-.1c-33.535-.3-63.905-.561-85.9-.755-10.984-.123-19.876-.214-26.042-.286-3.063-.041-5.441-.082-7.074-.1-.8-.02-1.409-.031-1.838-.041s-.644-.039-.644-.039z"
                          data-name="Path 2600"
                          transform="translate(-143.38 -365.58)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 3596" transform="translate(141.727)">
                  <g data-name="Group 3585">
                    <g
                      data-name="Group 3395"
                      transform="translate(70.619 59.714)"
                    >
                      <g data-name="Group 3394">
                        <path
                          fill="#263238"
                          d="M382.266 212.37a67.443 67.443 0 01-21.519 50.91l-9.616-27.869"
                          data-name="Path 2601"
                          transform="translate(-351.13 -212.37)"
                        ></path>
                      </g>
                    </g>
                    <g data-name="Group 3576">
                      <g data-name="Group 3397" transform="translate(13.727)">
                        <g data-name="Group 3396">
                          <path
                            fill="#263238"
                            d="M310.907 158.157c8.259-4.512 18.232-5.165 27.461-3.318s17.824 6.023 25.97 10.739c4.573 2.644 9.126 5.553 12.414 9.688 5.992 7.544 11.923 19.937 12.5 29.554.47 7.779-2.511 27.246-5.8 34.311-3.012 6.482-17.14 11.413-24.1 13.016L295.4 177.778a32.4 32.4 0 0115.507-19.621z"
                            data-name="Path 2602"
                            transform="translate(-295.4 -153.876)"
                          ></path>
                        </g>
                      </g>
                      <g data-name="Group 3399" transform="translate(0 11.56)">
                        <g data-name="Group 3398">
                          <path
                            fill="#263238"
                            d="M300.162 167.946l15.333-2.746c-8.381 19.958-10.647 65.589-15.445 86.7-5.645-8.2-18.773-33.167-18.069-43.1s-.031-42.538 18.181-40.854z"
                            data-name="Path 2603"
                            transform="translate(-281.953 -165.2)"
                          ></path>
                        </g>
                      </g>
                      <g
                        data-name="Group 3400"
                        transform="translate(8.376 14.308)"
                      >
                        <path
                          fill="#ffbe9d"
                          d="M372.675 201.108c-.827-11.658-8.371-21.223-19.7-24.112l-35.3-9c-15.251-1.644-25.44 17.12-26.44 32.432-1.113 17.007-1.8 34.964.194 47.663 4 25.531 23.551 28.829 23.551 28.829 0-.041 0 12.148.02 19.6l11.811 48.9 38.067-52.584 7.881-91.417c.008-.433-.043.271-.084-.311z"
                          data-name="Path 2604"
                          transform="translate(-290.158 -167.891)"
                        ></path>
                      </g>
                      <g
                        data-name="Group 3416"
                        transform="translate(17.114 63.188)"
                      >
                        <g data-name="Group 3415">
                          <g data-name="Group 3414">
                            <g data-name="Group 3413">
                              <g data-name="Group 3412">
                                <g data-name="Group 3411">
                                  <g data-name="Group 3410">
                                    <g data-name="Group 3409">
                                      <g data-name="Group 3408">
                                        <g data-name="Group 3407">
                                          <g data-name="Group 3406">
                                            <g data-name="Group 3405">
                                              <g data-name="Group 3404">
                                                <g data-name="Group 3403">
                                                  <g data-name="Group 3402">
                                                    <g data-name="Group 3401">
                                                      <path
                                                        fill="#263238"
                                                        d="M298.72 218.72a3.28 3.28 0 003.154 3.308 3.128 3.128 0 003.379-2.94 3.271 3.271 0 00-3.144-3.308 3.118 3.118 0 00-3.389 2.94z"
                                                        data-name="Path 2605"
                                                        transform="translate(-298.718 -215.773)"
                                                      ></path>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3433"
                        transform="translate(13.228 53.415)"
                      >
                        <g data-name="Group 3432">
                          <g data-name="Group 3431">
                            <g data-name="Group 3430">
                              <g data-name="Group 3429">
                                <g data-name="Group 3428">
                                  <g data-name="Group 3427">
                                    <g data-name="Group 3426">
                                      <g data-name="Group 3425">
                                        <g data-name="Group 3424">
                                          <g data-name="Group 3423">
                                            <g data-name="Group 3422">
                                              <g data-name="Group 3421">
                                                <g data-name="Group 3420">
                                                  <g data-name="Group 3419">
                                                    <g data-name="Group 3418">
                                                      <g data-name="Group 3417">
                                                        <path
                                                          fill="#263238"
                                                          d="M294.961 209.63c.408.429 2.889-1.4 6.411-1.388 3.522-.031 6.1 1.786 6.482 1.358.184-.194-.225-.96-1.348-1.786a9.007 9.007 0 00-5.186-1.613 8.625 8.625 0 00-5.1 1.633c-1.085.836-1.453 1.602-1.259 1.796z"
                                                          data-name="Path 2606"
                                                          transform="translate(-294.912 -206.2)"
                                                        ></path>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3449"
                        transform="translate(47.821 63.188)"
                      >
                        <g data-name="Group 3448">
                          <g data-name="Group 3447">
                            <g data-name="Group 3446">
                              <g data-name="Group 3445">
                                <g data-name="Group 3444">
                                  <g data-name="Group 3443">
                                    <g data-name="Group 3442">
                                      <g data-name="Group 3441">
                                        <g data-name="Group 3440">
                                          <g data-name="Group 3439">
                                            <g data-name="Group 3438">
                                              <g data-name="Group 3437">
                                                <g data-name="Group 3436">
                                                  <g data-name="Group 3435">
                                                    <g data-name="Group 3434">
                                                      <path
                                                        fill="#263238"
                                                        d="M328.8 218.72a3.28 3.28 0 003.154 3.308 3.127 3.127 0 003.379-2.94 3.271 3.271 0 00-3.144-3.308 3.118 3.118 0 00-3.389 2.94z"
                                                        data-name="Path 2607"
                                                        transform="translate(-328.798 -215.773)"
                                                      ></path>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3466"
                        transform="translate(46.682 53.415)"
                      >
                        <g data-name="Group 3465">
                          <g data-name="Group 3464">
                            <g data-name="Group 3463">
                              <g data-name="Group 3462">
                                <g data-name="Group 3461">
                                  <g data-name="Group 3460">
                                    <g data-name="Group 3459">
                                      <g data-name="Group 3458">
                                        <g data-name="Group 3457">
                                          <g data-name="Group 3456">
                                            <g data-name="Group 3455">
                                              <g data-name="Group 3454">
                                                <g data-name="Group 3453">
                                                  <g data-name="Group 3452">
                                                    <g data-name="Group 3451">
                                                      <g data-name="Group 3450">
                                                        <path
                                                          fill="#263238"
                                                          d="M327.731 209.63c.408.429 2.889-1.4 6.411-1.388 3.522-.031 6.1 1.786 6.482 1.358.184-.194-.225-.96-1.348-1.786a9.007 9.007 0 00-5.186-1.613 8.625 8.625 0 00-5.1 1.633c-1.09.836-1.453 1.602-1.259 1.796z"
                                                          data-name="Path 2608"
                                                          transform="translate(-327.682 -206.2)"
                                                        ></path>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3483"
                        transform="translate(26.757 55.169)"
                      >
                        <g data-name="Group 3482">
                          <g data-name="Group 3481">
                            <g data-name="Group 3480">
                              <g data-name="Group 3479">
                                <g data-name="Group 3478">
                                  <g data-name="Group 3477">
                                    <g data-name="Group 3476">
                                      <g data-name="Group 3475">
                                        <g data-name="Group 3474">
                                          <g data-name="Group 3473">
                                            <g data-name="Group 3472">
                                              <g data-name="Group 3471">
                                                <g data-name="Group 3470">
                                                  <g data-name="Group 3469">
                                                    <g data-name="Group 3468">
                                                      <g data-name="Group 3467">
                                                        <path
                                                          fill="#263238"
                                                          d="M317.133 237.259c.02-.2-2.174-.582-5.717-1.041-.9-.092-1.746-.276-1.9-.888a4.524 4.524 0 01.6-2.664c.847-2.174 1.746-4.451 2.675-6.84 3.716-9.749 6.411-17.763 6.013-17.906s-3.726 7.636-7.442 17.375c-.9 2.4-1.746 4.7-2.562 6.881a5.171 5.171 0 00-.47 3.532 2.247 2.247 0 001.48 1.317 6.392 6.392 0 001.531.214c3.559.204 5.772.224 5.792.02z"
                                                          data-name="Path 2609"
                                                          transform="translate(-308.164 -207.918)"
                                                        ></path>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3488"
                        transform="translate(33.195 114.247)"
                      >
                        <g data-name="Group 3487">
                          <g data-name="Group 3486">
                            <g data-name="Group 3485">
                              <g data-name="Group 3484">
                                <path
                                  fill="#eb996e"
                                  d="M348.332 265.79s-8.31 17.538-33.851 15.088l-.01-6a64.341 64.341 0 0033.861-9.088z"
                                  data-name="Path 2610"
                                  transform="translate(-314.47 -265.79)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3501"
                        transform="translate(47.825 41.493)"
                      >
                        <g data-name="Group 3500">
                          <g data-name="Group 3499">
                            <g data-name="Group 3498">
                              <g data-name="Group 3497">
                                <g data-name="Group 3496">
                                  <g data-name="Group 3495">
                                    <g data-name="Group 3494">
                                      <g data-name="Group 3493">
                                        <g data-name="Group 3492">
                                          <g data-name="Group 3491">
                                            <g data-name="Group 3490">
                                              <g data-name="Group 3489">
                                                <path
                                                  fill="#263238"
                                                  d="M328.82 197.144c.347.96 3.859.51 7.993 1.021 4.145.429 7.473 1.644 8.024.786.245-.408-.337-1.317-1.674-2.246a14 14 0 00-12.2-1.368c-1.53.602-2.296 1.347-2.143 1.807z"
                                                  data-name="Path 2611"
                                                  transform="translate(-328.801 -194.522)"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3514"
                        transform="translate(14.339 42.418)"
                      >
                        <g data-name="Group 3513">
                          <g data-name="Group 3512">
                            <g data-name="Group 3511">
                              <g data-name="Group 3510">
                                <g data-name="Group 3509">
                                  <g data-name="Group 3508">
                                    <g data-name="Group 3507">
                                      <g data-name="Group 3506">
                                        <g data-name="Group 3505">
                                          <g data-name="Group 3504">
                                            <g data-name="Group 3503">
                                              <g data-name="Group 3502">
                                                <path
                                                  fill="#263238"
                                                  d="M296.092 199.074c.623.8 3.042-.02 5.962-.061 2.92-.133 5.39.551 5.962-.286.255-.408-.133-1.2-1.215-1.97a8.3 8.3 0 00-4.849-1.327 8.176 8.176 0 00-4.757 1.6c-1.042.83-1.379 1.657-1.103 2.044z"
                                                  data-name="Path 2612"
                                                  transform="translate(-296 -195.427)"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3519"
                        transform="translate(15.92 4.403)"
                      >
                        <g data-name="Group 3518">
                          <g data-name="Group 3517">
                            <g data-name="Group 3516">
                              <g data-name="Group 3515">
                                <path
                                  fill="#263238"
                                  d="M298.009 177.311c1.562-3.838 5.778-6.146 9.912-6.36s8.187 1.358 11.77 3.43c15.2 8.789 23.02 30.013 38.037 39.109 3.7 2.236 8.5.184 11.648 3.154s5.176 7.7 3.44 11.648a35.686 35.686 0 005.216-8.82c1.143-3.226 1.3-6.983-.419-9.953-1.3-2.256-3.593-3.971-4.226-6.493-.551-2.215.316-4.543.225-6.829a14.98 14.98 0 00-2.338-6.513c-5.88-10.4-13.975-19.815-24.4-25.674a43.17 43.17 0 00-22.408-5.8 33.03 33.03 0 00-21.315 8.463c-3.359 3.154-6.891 6.39-5.145 10.637"
                                  data-name="Path 2613"
                                  transform="translate(-297.548 -158.189)"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3557"
                        transform="translate(87.391 64.65)"
                      >
                        <g data-name="Group 3537">
                          <g data-name="Group 3536">
                            <g data-name="Group 3535">
                              <g data-name="Group 3534">
                                <g data-name="Group 3533">
                                  <g data-name="Group 3532">
                                    <g data-name="Group 3531">
                                      <g data-name="Group 3530">
                                        <g data-name="Group 3529">
                                          <g data-name="Group 3528">
                                            <g data-name="Group 3527">
                                              <g data-name="Group 3526">
                                                <g data-name="Group 3525">
                                                  <g data-name="Group 3524">
                                                    <g data-name="Group 3523">
                                                      <g data-name="Group 3522">
                                                        <g data-name="Group 3521">
                                                          <g data-name="Group 3520">
                                                            <path
                                                              fill="#ffbe9d"
                                                              d="M367.56 218.578c.265-.735 13.587-5.533 13.761 8.616.174 13.751-13.7 11.046-13.73 10.647s-.031-19.263-.031-19.263z"
                                                              data-name="Path 2614"
                                                              transform="translate(-367.56 -217.206)"
                                                            ></path>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g
                          data-name="Group 3556"
                          transform="translate(4.212 4.506)"
                        >
                          <g data-name="Group 3555">
                            <g data-name="Group 3554">
                              <g data-name="Group 3553">
                                <g data-name="Group 3552">
                                  <g data-name="Group 3551">
                                    <g data-name="Group 3550">
                                      <g data-name="Group 3549">
                                        <g data-name="Group 3548">
                                          <g data-name="Group 3547">
                                            <g data-name="Group 3546">
                                              <g data-name="Group 3545">
                                                <g data-name="Group 3544">
                                                  <g data-name="Group 3543">
                                                    <g data-name="Group 3542">
                                                      <g data-name="Group 3541">
                                                        <g data-name="Group 3540">
                                                          <g data-name="Group 3539">
                                                            <g data-name="Group 3538">
                                                              <path
                                                                fill="#eb996e"
                                                                d="M371.7 232.5c.061-.041.245.163.643.347a2.4 2.4 0 001.786.02c1.45-.551 2.644-2.869 2.7-5.339a7.821 7.821 0 00-.7-3.42 2.753 2.753 0 00-1.817-1.817 1.224 1.224 0 00-1.409.674c-.184.388-.092.664-.163.684-.041.031-.3-.225-.194-.8a1.519 1.519 0 01.531-.868 1.772 1.772 0 011.317-.357 3.363 3.363 0 012.521 2.113 8.263 8.263 0 01.868 3.828c-.061 2.766-1.46 5.38-3.44 5.972a2.528 2.528 0 01-2.195-.347c-.427-.351-.499-.657-.448-.69z"
                                                                data-name="Path 2615"
                                                                transform="translate(-371.686 -221.619)"
                                                              ></path>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3563"
                        transform="translate(18.882 12.61)"
                      >
                        <g data-name="Group 3562">
                          <g data-name="Group 3561">
                            <g data-name="Group 3560">
                              <g data-name="Group 3559">
                                <g data-name="Group 3558">
                                  <path
                                    fill="#455a64"
                                    d="M374.431 212.577a5.447 5.447 0 01-.827-.459c-.531-.337-1.337-.776-2.307-1.47a47.525 47.525 0 01-7.422-6.758 131.291 131.291 0 01-9.075-11.79 107.731 107.731 0 00-11.352-14.129 43.907 43.907 0 00-7.319-5.788 36.135 36.135 0 00-7.912-3.593 39.177 39.177 0 00-14.567-1.79 37.705 37.705 0 00-9.759 1.909c-1.133.347-1.97.725-2.542.939a5.608 5.608 0 01-.9.316 5.791 5.791 0 01.847-.429 25.486 25.486 0 012.522-1.062 35.546 35.546 0 019.81-2.123 38.791 38.791 0 0114.772 1.684 36.02 36.02 0 018.055 3.614 43.063 43.063 0 017.442 5.86 106.265 106.265 0 0111.382 14.241 141.624 141.624 0 008.943 11.832 50.573 50.573 0 007.238 6.85c.939.715 1.725 1.184 2.225 1.552a7.365 7.365 0 01.746.594z"
                                    data-name="Path 2616"
                                    transform="translate(-300.45 -166.229)"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g
                        data-name="Group 3575"
                        transform="translate(90.733 82.305)"
                      >
                        <g
                          data-name="Group 3568"
                          transform="translate(.25 .125)"
                        >
                          <g data-name="Group 3567">
                            <g data-name="Group 3566">
                              <g data-name="Group 3565">
                                <g data-name="Group 3564">
                                  <path
                                    fill="#fff"
                                    d="M373.275 234.661a2.67 2.67 0 11-.643.194"
                                    data-name="Path 2617"
                                    transform="translate(-371.079 -234.622)"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g data-name="Group 3574">
                          <g data-name="Group 3573">
                            <g data-name="Group 3572">
                              <g data-name="Group 3571">
                                <g data-name="Group 3570">
                                  <g data-name="Group 3569">
                                    <path
                                      fill="#263238"
                                      d="M372.637 234.868a3.723 3.723 0 00-1.235 1.848 2.306 2.306 0 00.541 2.154 2.4 2.4 0 004.226-1.348 2.345 2.345 0 00-.807-2.072 3.755 3.755 0 00-2.082-.8c-.01-.031.214-.143.643-.153a2.808 2.808 0 011.715.6 2.758 2.758 0 011.1 2.481 2.954 2.954 0 01-5.237 1.664 2.872 2.872 0 01.521-4.145c.36-.219.605-.26.615-.229z"
                                      data-name="Path 2618"
                                      transform="translate(-370.834 -234.5)"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3578"
                      transform="translate(71.487 43.309)"
                    >
                      <g data-name="Group 3577">
                        <path
                          fill="#263238"
                          d="M369.9 215.839c.214 3.757.388 7.718-1.347 11.056s-6.115 5.6-9.382 3.736c-2.664-1.521-3.461-4.931-3.91-7.973-1.307-8.759-1.9-17.61-3.277-26.358z"
                          data-name="Path 2619"
                          transform="translate(-351.98 -196.3)"
                        ></path>
                      </g>
                    </g>
                    <g
                      data-name="Group 3581"
                      transform="translate(19.77 16.356)"
                    >
                      <g data-name="Group 3580">
                        <g data-name="Group 3579">
                          <path
                            fill="#455a64"
                            d="M301.32 170.533c.01.082 1.215-.225 3.42-.3a23.5 23.5 0 019.1 1.664 29.441 29.441 0 015.7 2.94 60.505 60.505 0 015.88 4.492 70.576 70.576 0 0111.515 12.414c1.746 2.45 3.3 4.972 4.972 7.309a35.274 35.274 0 005.513 6.2 61.16 61.16 0 0011.617 7.769c3.522 1.858 6.462 3.165 8.483 4.063.96.408 1.735.735 2.348 1a3 3 0 00.847.3 4.193 4.193 0 00-.776-.439c-.6-.3-1.358-.674-2.287-1.133-1.991-.98-4.89-2.358-8.351-4.267a63.574 63.574 0 01-11.413-7.83 34.777 34.777 0 01-5.37-6.1c-1.644-2.307-3.185-4.839-4.951-7.319a69.424 69.424 0 00-11.678-12.505 58.537 58.537 0 00-5.992-4.482 30.028 30.028 0 00-5.86-2.909 22.615 22.615 0 00-9.3-1.45 15.8 15.8 0 00-2.542.357 3.094 3.094 0 00-.875.226z"
                            data-name="Path 2620"
                            transform="translate(-301.32 -169.898)"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      data-name="Group 3584"
                      transform="translate(89.779 36.724)"
                    >
                      <g data-name="Group 3583">
                        <g data-name="Group 3582">
                          <path
                            fill="#455a64"
                            d="M369.9 207.48a6.236 6.236 0 001.215.817c.939.551 2.1 1.235 3.481 2.042.378.214.766.439 1.174.674a3.836 3.836 0 001.5.5 1.437 1.437 0 00.929-.214 1.19 1.19 0 00.51-.909 2.991 2.991 0 00-.653-1.735c-1.235-1.98-2.6-4.165-4.043-6.472l-.541.51a14.411 14.411 0 009.341 2.787 4.146 4.146 0 001.245-.214 1.729 1.729 0 001.072-.929 1.486 1.486 0 00-.235-1.388 5.941 5.941 0 00-.786-.858 29.365 29.365 0 01-4.686-5.911 49.468 49.468 0 01-2.981-6.329 7.3 7.3 0 00.429 1.848 25.637 25.637 0 002.072 4.757 27.856 27.856 0 004.657 6.144c.51.49 1.021 1.041.827 1.48-.163.429-.949.643-1.664.653a13.8 13.8 0 01-4.655-.561 13.6 13.6 0 01-4.185-2.072l-1.644-1.2 1.092 1.7c1.47 2.3 2.858 4.451 4.124 6.421a2.624 2.624 0 01.582 1.337.652.652 0 01-.8.582 6.973 6.973 0 01-2.47-1.021c-1.419-.735-2.624-1.348-3.593-1.848a5.845 5.845 0 00-1.314-.591z"
                            data-name="Path 2621"
                            transform="translate(-369.899 -189.849)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 3586" transform="translate(40.1 87.502)">
                    <path
                      fill="#eb996e"
                      d="M330.254 243.1a4.6 4.6 0 00-4.747-3.491 4.812 4.812 0 00-3.7 2.624 5.84 5.84 0 00-.2 4.594l.071.327a5.232 5.232 0 005.89 1.256 4.871 4.871 0 002.686-5.31z"
                      data-name="Path 2622"
                      transform="translate(-321.235 -239.591)"
                    ></path>
                  </g>
                  <g
                    data-name="Group 3595"
                    transform="translate(37.982 85.121)"
                  >
                    <g data-name="Group 3594">
                      <g data-name="Group 3593">
                        <g data-name="Group 3592">
                          <g data-name="Group 3591">
                            <g data-name="Group 3590">
                              <g data-name="Group 3589">
                                <g data-name="Group 3588">
                                  <g data-name="Group 3587">
                                    <path
                                      fill="#263238"
                                      d="M319.16 247.285c0-.531 3.563-.368 6.237-3.287s2.481-6.738 2.981-6.738c.225-.041.653.878.612 2.481a8.846 8.846 0 01-2.287 5.635 7.745 7.745 0 01-5.217 2.532c-1.486.061-2.357-.378-2.326-.623z"
                                      data-name="Path 2623"
                                      transform="translate(-319.159 -237.259)"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g
                  data-name="Group 3598"
                  transform="translate(205.843 311.506)"
                >
                  <g data-name="Group 3597">
                    <path
                      fill="#fff"
                      d="M438.637 459.03a.933.933 0 01-.245.041c-.194.02-.429.041-.7.071-.664.051-1.582.133-2.726.225-2.368.184-5.8.419-10.045.674-8.483.5-20.223 1.011-33.188 1.235s-24.715.092-33.218-.122c-4.247-.112-7.687-.225-10.055-.337-1.154-.061-2.062-.1-2.736-.133-.286-.02-.521-.031-.715-.051a1.52 1.52 0 01-.245-.031.649.649 0 01.245-.02h.715c.674.01 1.582.02 2.736.031 2.419.041 5.849.092 10.066.163 8.493.122 20.233.184 33.2-.031s24.684-.674 33.178-1.082c4.206-.214 7.626-.378 10.045-.5l2.736-.122c.286-.01.521-.01.715-.02a.893.893 0 01.237.009z"
                      data-name="Path 2624"
                      transform="translate(-344.76 -459.02)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(471.046 196.761)">
            <g data-name="Group 2398">
              <g data-name="Group 2276">
                <g data-name="Group 2251">
                  <g
                    data-name="Group 2232"
                    transform="translate(35.974 47.679)"
                  >
                    <g data-name="Group 2231">
                      <g data-name="Group 2230">
                        <path
                          fill="#87a878"
                          d="M391.04 405.117a19.447 19.447 0 0112.056-1.46c2.185.357 4.471 1.011 5.952 2.654s1.766 4.533.061 5.941a5.7 5.7 0 01-3.757.949c-3.185-.041-6.717-.6-9.269 1.317-1.45 1.092-2.419 2.909-4.155 3.451a4.1 4.1 0 01-4.512-2.011 7.03 7.03 0 01-.51-5.176 7.911 7.911 0 014.134-5.665z"
                          data-name="Path 2220"
                          transform="translate(-386.645 -403.316)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 2235" transform="translate(29.843)">
                    <g data-name="Group 2234">
                      <g data-name="Group 2233">
                        <path
                          fill="#87a878"
                          d="M382.763 389.879c2.246 3.205 6.595 2.144 8.616.184s2.879-4.788 3.655-7.493c1.776-6.176 3.563-12.648 2.246-18.937a10.556 10.556 0 00-2.419-5.145 5.482 5.482 0 00-5.206-1.735c-2.195.582-3.563 2.736-4.553 4.778a45.114 45.114 0 00-4.462 19.369c0 3.134.368 6.4 2.123 8.983"
                          data-name="Path 2221"
                          transform="translate(-380.64 -356.611)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g data-name="Group 2238" transform="translate(0 10.327)">
                    <g data-name="Group 2237">
                      <g data-name="Group 2236">
                        <path
                          fill="#87a878"
                          d="M377.217 394.262a11.666 11.666 0 001.817-10.719 20.626 20.626 0 00-6.35-9.208 34.185 34.185 0 00-11.372-6.707 12.1 12.1 0 00-5.114-.868 5.352 5.352 0 00-4.288 2.593c-1.194 2.287-.051 5.053 1.194 7.309a85.623 85.623 0 007.473 11.372c2.236 2.889 4.808 5.737 8.218 7.034s6.952 1.154 8.769-1.215"
                          data-name="Path 2222"
                          transform="translate(-351.406 -366.727)"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 2242"
                    transform="translate(31.781 52.116)"
                  >
                    <g data-name="Group 2241">
                      <g data-name="Group 2240">
                        <g data-name="Group 2239">
                          <path
                            fill="#263238"
                            d="M382.581 437.111a7.059 7.059 0 00.2-1.46c.1-1.062.235-2.389.388-3.971a55.973 55.973 0 01.9-5.829 44.316 44.316 0 012.113-6.87 25.059 25.059 0 013.512-6.217 12.97 12.97 0 014.441-3.685 12.352 12.352 0 013.736-1.164 6.523 6.523 0 001.45-.214 6.546 6.546 0 00-1.47-.02 11.456 11.456 0 00-3.9 1.011 12.988 12.988 0 00-4.686 3.726 24.776 24.776 0 00-3.634 6.35 41.542 41.542 0 00-2.1 6.983 49.867 49.867 0 00-.776 5.9 79.38 79.38 0 00-.194 3.992 5.845 5.845 0 00.02 1.468z"
                            data-name="Path 2223"
                            transform="translate(-382.538 -407.663)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 2246"
                    transform="translate(31.765 12.882)"
                  >
                    <g data-name="Group 2245">
                      <g data-name="Group 2244">
                        <g data-name="Group 2243">
                          <path
                            fill="#263238"
                            d="M382.847 434.86a3.174 3.174 0 00.041-.674c0-.51 0-1.143-.01-1.929 0-1.674 0-4.1.082-7.095.153-5.992.664-12.434 1.664-21.519.939-8.544 2.205-18.488 3.542-24.95.286-1.47.572-2.8.847-3.951.255-1.164.48-2.164.694-2.971.184-.766.337-1.388.449-1.878a3.546 3.546 0 00.123-.664 2.644 2.644 0 00-.235.633c-.143.49-.337 1.1-.561 1.848-.245.806-.5 1.8-.8 2.95s-.623 2.47-.939 3.94a219.213 219.213 0 00-3.726 23.153c-1 9.106-1.45 17.4-1.49 23.4-.031 3 .031 5.431.1 7.105.051.786.092 1.419.122 1.929a4.08 4.08 0 00.097.673z"
                            data-name="Path 2224"
                            transform="translate(-382.522 -369.23)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g
                    data-name="Group 2250"
                    transform="translate(10.761 17.779)"
                  >
                    <g data-name="Group 2249">
                      <g data-name="Group 2248">
                        <g data-name="Group 2247">
                          <path
                            fill="#263238"
                            d="M383.316 418.774a13.9 13.9 0 00-.01-2.021c-.071-1.3-.255-3.165-.612-5.462a72.719 72.719 0 00-1.776-7.963 65.793 65.793 0 00-3.328-9.422 49.979 49.979 0 00-9.78-15.221 24.872 24.872 0 00-2.3-2.134c-.357-.3-.664-.592-.98-.827s-.613-.429-.888-.612a11.711 11.711 0 00-1.695-1.082 59.209 59.209 0 015.564 4.961 53.2 53.2 0 019.535 15.17 73.933 73.933 0 013.348 9.331 80.091 80.091 0 011.889 7.881c.419 2.277.653 4.124.806 5.41a12.59 12.59 0 00.227 1.991z"
                            data-name="Path 2225"
                            transform="translate(-361.948 -374.027)"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2254" transform="translate(16.674 90.732)">
                  <g data-name="Group 2253">
                    <g data-name="Group 2252">
                      <path
                        fill="#263238"
                        d="M367.74 445.49l1.6 21.948h28.206l1.6-21.948z"
                        data-name="Path 2226"
                        transform="translate(-367.74 -445.49)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2257" transform="translate(13.959 82.075)">
                  <g data-name="Group 2256">
                    <g data-name="Group 2255">
                      <path
                        fill="#263238"
                        d="M0 0H37.496V8.657H0z"
                        data-name="Rectangle 186"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2260" transform="translate(13.959 111.833)">
                  <g data-name="Group 2259">
                    <g data-name="Group 2258">
                      <path
                        fill="#455a64"
                        d="M365.08 466.16v2.4h36.863v-2.4z"
                        data-name="Path 2227"
                        transform="translate(-365.08 -466.16)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2263" transform="translate(15.715 114.232)">
                  <g data-name="Group 2262">
                    <g data-name="Group 2261">
                      <path
                        fill="#455a64"
                        d="M368.525 472.736l-1.725-4.226h33.77l-2.011 4.226z"
                        data-name="Path 2228"
                        transform="translate(-366.8 -468.51)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2267" transform="translate(17.154 94.826)">
                  <g data-name="Group 2266">
                    <g data-name="Group 2265">
                      <g data-name="Group 2264">
                        <path
                          fill="#e0e0e0"
                          d="M398.611 452.573a1.1 1.1 0 01-.133.2c-.112.153-.255.337-.419.561-.4.51-.949 1.215-1.644 2.1l-.071.092-.071-.1a9288.313 9288.313 0 00-4.032-5.584h.235c-1.2 1.7-2.542 3.593-3.971 5.625l-.122.174-.143-.163c-1.47-1.746-3.062-3.634-4.716-5.6h.276c-.082.1-.174.214-.255.327l-4.216 5.278-.143.174-.133-.184c-1.429-2.021-2.766-3.92-3.971-5.625l.235.01c-1.96 2.236-3.624 4.124-4.9 5.574l-.092.1-.061-.112c-.643-1.082-1.164-1.95-1.531-2.572-.163-.276-.286-.51-.4-.684-.082-.153-.122-.245-.122-.245a1.4 1.4 0 01.163.214c.112.174.255.388.439.664.388.613.929 1.46 1.613 2.532l-.153-.01c1.256-1.47 2.889-3.389 4.818-5.645l.122-.143.112.153c1.215 1.7 2.573 3.583 4.012 5.6l-.265-.01c1.337-1.684 2.746-3.45 4.206-5.288.092-.112.174-.225.255-.327l.133-.163.143.163c1.654 1.97 3.236 3.869 4.7 5.615l-.265.01c1.45-2.021 2.8-3.91 4.012-5.594l.122-.163.112.163c1.593 2.266 2.94 4.2 3.951 5.645h-.143c.725-.868 1.3-1.552 1.7-2.052.184-.214.337-.388.459-.531a1.886 1.886 0 01.154-.179z"
                          data-name="Path 2229"
                          transform="translate(-368.21 -449.5)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2271" transform="translate(16.909 90.405)">
                  <g data-name="Group 2270">
                    <g data-name="Group 2269">
                      <g data-name="Group 2268">
                        <path
                          fill="#455a64"
                          d="M399.535 445.344c0 .1-7.064.174-15.782.174s-15.782-.082-15.782-.174 7.064-.174 15.782-.174 15.782.082 15.782.174z"
                          data-name="Path 2230"
                          transform="translate(-367.97 -445.17)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2275" transform="translate(15.603 114.302)">
                  <g data-name="Group 2274">
                    <g data-name="Group 2273">
                      <g data-name="Group 2272">
                        <path
                          fill="#263238"
                          d="M400.99 468.754c0 .1-7.677.174-17.15.174s-17.15-.082-17.15-.174 7.677-.174 17.15-.174 17.15.071 17.15.174z"
                          data-name="Path 2231"
                          transform="translate(-366.69 -468.579)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name="4529819" transform="translate(502.024 634.676)">
          <g>
            <g data-name="Group 2756">
              <g data-name="Group 2749">
                <g data-name="Group 2748">
                  <g data-name="Group 2747">
                    <g data-name="Group 2744" transform="translate(.399 .309)">
                      <path
                        fill="#fafafa"
                        d="M382.439 179.489l-21.879 2.417a51.283 51.283 0 107.7 16.689l.016.031z"
                        data-name="Path 2395"
                        transform="translate(-267.43 -160.148)"
                      ></path>
                    </g>
                    <g data-name="Group 2746">
                      <g data-name="Group 2745">
                        <path
                          fill="#263238"
                          d="M382.839 179.6c-.852 1.147-5.284 7-14.333 19.013l.341.015-.015-.031a.2.2 0 00-.372.155 50.935 50.935 0 01-.341 26.451 46.05 46.05 0 01-2.836 7.531c-.62 1.24-1.209 2.51-1.89 3.765-.759 1.209-1.519 2.433-2.293 3.672a51.171 51.171 0 01-31.208 20.95 50.173 50.173 0 01-21.322.093 51.648 51.648 0 01-20.407-9.452 49.778 49.778 0 01-14.8-18.161 50.891 50.891 0 01-5.082-23.383 51.725 51.725 0 015.733-22.189 49.9 49.9 0 0113.853-16.7 51.422 51.422 0 0136.647-10.475 55.477 55.477 0 0116.038 4.432 51.017 51.017 0 0120.248 16.808.178.178 0 00.186.077c13.837-1.611 20.5-2.4 21.848-2.572-1.364.139-8.027.806-21.91 2.216l.186.077a51.118 51.118 0 00-20.33-17.107 54.969 54.969 0 00-16.208-4.556 52.117 52.117 0 00-37.172 10.522 50.863 50.863 0 00-14.085 16.921 52.273 52.273 0 00-5.857 22.53 51.679 51.679 0 005.145 23.754 50.54 50.54 0 0015.046 18.455 52.048 52.048 0 0020.733 9.576 51.176 51.176 0 0021.647-.139 51.692 51.692 0 0031.58-21.322c.775-1.255 1.55-2.495 2.309-3.719.682-1.271 1.271-2.557 1.891-3.812a46.989 46.989 0 002.836-7.624 51.244 51.244 0 00.186-26.714l-.372.155.016.031a.2.2 0 00.279.077.121.121 0 00.062-.062C377.679 186.7 382 180.745 382.839 179.6z"
                          data-name="Path 2396"
                          transform="translate(-267.43 -159.949)"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g data-name="Group 2755" transform="translate(33.552 32.945)">
                <g data-name="Group 2752">
                  <g data-name="Group 2751">
                    <g data-name="Group 2750">
                      <path
                        fill="#263238"
                        d="M333.147 181.566a4.528 4.528 0 00-.047.713c-.016.511-.031 1.162-.046 1.983-.016 1.782-.047 4.292-.093 7.453-.047 6.431-.108 15.48-.17 26.327l.356-.356c-10.614-.016-23.119-.031-36.461-.062l.4.4v-9.84q0-4.835-.016-9.421c-.016-6.121-.031-11.9-.047-17.2l-.356.356c10.847-.077 19.9-.139 26.327-.17 3.161-.046 5.656-.062 7.453-.093.821-.016 1.472-.031 1.983-.046a4.79 4.79 0 00.713-.047 2.989 2.989 0 00-.635-.046c-.5-.016-1.131-.031-1.921-.047-1.767-.015-4.246-.046-7.376-.093-6.477-.046-15.619-.108-26.544-.17h-.341v.356c-.016 5.3-.031 11.079-.047 17.2q0 4.6-.016 9.421v9.84l.4.4c13.341-.015 25.846-.031 36.461-.062h.356v-.356a12546.2 12546.2 0 01-.17-26.544c-.031-3.115-.062-5.594-.093-7.376-.016-.79-.031-1.426-.047-1.921a2.561 2.561 0 00-.023-.599z"
                        data-name="Path 2397"
                        transform="translate(-296.18 -181.21)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g data-name="Group 2754" transform="translate(4.402 6.366)">
                  <g data-name="Group 2753">
                    <path
                      fill="#87a878"
                      d="M326.471 185.336c.527.418-4.385 7.484-10.955 15.774a302.007 302.007 0 01-3.781 4.68l-.775.945-.868-.79c-5.7-5.129-9.561-9.065-9.2-9.468s4.8 2.851 10.707 7.732l-1.658.155c1.131-1.488 2.371-3.1 3.688-4.757 6.571-8.289 12.331-14.673 12.842-14.271z"
                      data-name="Path 2398"
                      transform="translate(-300.865 -185.318)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ResetPasswordIcon;
