function TwitterIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44.12"
        height="44.12"
        viewBox="0 0 44.12 44.12"
      >
        <g data-name="Group 6571" transform="translate(-479.001 -812.44)">
          <path
            fill="none"
            stroke="#87a878"
            strokeWidth="1"
            d="M501.061 812.94a21.56 21.56 0 1021.56 21.56 21.582 21.582 0 00-21.56-21.56z"
            data-name="Path 3391"
          ></path>
          <g transform="translate(490 778.44)">
            <g data-name="Group 6562" transform="translate(0 48)">
              <path
                fill="#87a878"
                d="M21 50.019a8.974 8.974 0 01-2.48.68 4.28 4.28 0 001.894-2.379 8.6 8.6 0 01-2.729 1.042 4.3 4.3 0 00-7.445 2.943 4.432 4.432 0 00.1.982 12.183 12.183 0 01-8.872-4.5 4.305 4.305 0 001.323 5.753 4.251 4.251 0 01-1.945-.53v.047a4.324 4.324 0 003.448 4.229 4.3 4.3 0 01-1.129.142 3.806 3.806 0 01-.815-.073 4.345 4.345 0 004.022 3 8.649 8.649 0 01-5.342 1.831A8.062 8.062 0 010 63.127a12.118 12.118 0 006.6 1.932 12.166 12.166 0 0012.254-12.248c0-.19-.007-.374-.016-.556A8.587 8.587 0 0021 50.019z"
                data-name="Path 3384"
                transform="translate(0 -48)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
  
  export default TwitterIcon;