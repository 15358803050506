import * as actionTypes from "./actionTypes";
import {loginInput} from "./queryObjects";
import {apiCall} from "../../utils/utilFunctions";
import Globals from "../../Globals";
import {toast} from 'react-toastify'

export const dispatchInit = () => {
  return {
    type: actionTypes.LOGIN_INIT
  };
};
export const dispatchSuccess = (result) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    response: result.data.login
  };
};

export const userLogin = (userData) => async (dispatch) => {
    dispatch(dispatchInit());
    const requestData = loginInput(userData);
    const apiResponse = await apiCall(Globals.API_ROOT_URL, requestData);
    if (apiResponse.data.login!==null){
        localStorage.setItem('token',apiResponse.data.login.token)
        dispatch(dispatchSuccess(apiResponse));   
      }
      else{
        toast.error(apiResponse.errors[0].message);       //Showing toastify error message
      }
};

