import React from "react";
import { Grid, Responsive } from "semantic-ui-react";
import LeftSection from "./LeftSection";
import DesktopRightSection from "./DesktopRightSection";
import MobileRightSection from "./MobileRightSection";

const PaymentForm = (props) => {
  return (
      <main id="Paymentpage">
        <section id="Payment">
          <div className="Payment-wrapper container">
            {/* Desktop */}
            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              <Grid>
                <Grid.Row className="Payment-section-grid">
                  <Grid.Column width={7} className="Left-section-wrapper">
                    <LeftSection 
                      onAdd={props.onAdd}
                      onRemove={props.onRemove}
                      quantity={props.quantity}
                    />
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <DesktopRightSection 
                      quantity={props.quantity}
                      price={props.price}
                      data = {props.data}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Responsive>

            {/* Mobile */}
            <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
              <div className="Mobile-section-wrapper">
                <div className="Left-section-wrapper">
                  <LeftSection
                    onAdd={props.onAdd}
                    onRemove={props.onRemove}
                    quantity={props.quantity}/>
                </div>
                <div className="Mobile-section">
                  <MobileRightSection  
                    quantity={props.quantity}
                    price={props.price}
                    data = {props.data}
                  />
                </div>
              </div>
            </Responsive>
          </div>
        </section>
      </main>
  );
};
export default PaymentForm;
