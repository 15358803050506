import React from 'react'
import {Header,Grid} from 'semantic-ui-react'
import PaymentIcon from '../../img/Payment'
import SciTechLogoSvgComponent from '../../img/sciTech-logo'
import SeatsQuantity from './seatsQuantity'
import { useHistory } from 'react-router-dom';

const Leftsection = (props) => {
   const history = useHistory();
   return (
         <div className="left-section-inner">
            <a className="skipark-logo" onClick={() => history.push('/')}>
                <SciTechLogoSvgComponent/>
            </a>
            <div className="left-section-text">
                <Header as='h2'>Order Details</Header>
                <Grid>
                    <Grid.Row className="Parent-wrapper">
                        <Grid.Column width={12} className="associatemember">
                            <Header as='h3'>Associate Membership
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4} className="price-wrapper">
                            <span className="price">£100/yearly
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                   
                </Grid>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12} className="access-wrapper">
                            <Header as ="h4">Access to the workspace, meeting room or breakout room space and year round coffee</Header>
                        </Grid.Column>
                        <Grid.Column width={4} className="numberpicker-wrapper">
                        <SeatsQuantity
                        onAdd={props.onAdd}
                        onRemove={props.onRemove}
                        quantity={props.quantity}
                        />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </div>
            <div className="Payment-illustration">
                <PaymentIcon/>
            </div>
        </div>
  )}
  export default Leftsection