import React from 'react'
import {
  Grid,
  Responsive,
} from 'semantic-ui-react'
 import LeftSection from './LeftSection'
 import DesktopRightSection from './DesktopRightsection'
 import MobileRightSection from './MobileRightSection'

const LoginForm = props => {        //Middle function for passing data
    const myLoginSubmit = (mydata) => {
        props.handleSubmit(mydata)
    }
    
   return (
        <main id="Loginpage">
            <section id="Login">
                    <div className="login-wrapper container">
                            {/* Desktop */}
                            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                                <Grid>
                                    <Grid.Row className="login-section-grid">
                                        <Grid.Column width={7} className="Left-section-wrapper">
                                            <LeftSection/>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <DesktopRightSection
                                            handleLoginSubmit={myLoginSubmit}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Responsive>
                            {/* Mobile */}
                            <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                                <div className="Mobile-section-wrapper">
                                    <div className="Left-section-wrapper">
                                        <LeftSection/>
                                    </div>
                                    <div className="Mobile-section">
                                        <MobileRightSection
                                        handleLoginSubmit = {myLoginSubmit}
                                        />
                                    </div>
                                </div>
                            </Responsive>
                    </div>
            </section>
        </main>
  )}
  export default LoginForm
