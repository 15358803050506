import React from 'react'
import {Header} from 'semantic-ui-react'
import LoginSvgComponent from '../../img/login'
import SciTechLogoSvgComponent from '../../img/sciTech-logo'
import { useHistory } from 'react-router-dom';

const LeftSection = (props) => {
   const history = useHistory();

   return (
         <div className="left-section-inner">
            <div className="skipark-logo" onClick={() => history.push('/')}>
                <SciTechLogoSvgComponent/>
            </div>
            <div className="left-section-text">
                <Header as='h3'>a lively community of creators
                </Header>
                <Header as ="h4">Shaping one of the region's most exciting community of entrepreneurs...</Header>
                </div>
            <div className="login-illustration">
                <LoginSvgComponent />
            </div>
        </div>
  )}
  export default LeftSection
