export const SIGNUP_INIT= "SIGNUP_INIT";
export const SIGNUP_SUCCESS= "SIGNUP_SUCCESS";

export const LOGIN_INIT= "LOGIN_INIT";
export const LOGIN_SUCCESS= "LOGIN_SUCCESS";

export const FORGOT_INIT= "FORGOT_INIT";
export const FORGOT_SUCCESS= "FORGOT_SUCCESS";
export const FORGOT_FAILURE= "FORGOT_FAILURE";


export const RESET_INIT= "RESET_INIT";
export const RESET_SUCCESS= "RESET_SUCCESS";

export const INVOICE_INIT= "INVOICE_INIT";
export const INVOICE_SUCCESS= "INVOICE_SUCCESS";

export const MYINVOICE_INIT= "MYINVOICE_INIT";
export const MYINVOICE_SUCCESS= "MYINVOICE_SUCCESS";