import React from "react";
import {Button,Grid,Form} from 'semantic-ui-react'
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import {createIntent, facilityPayment} from '../../redux/actions/queryObjects'
import {apiCall} from "../../utils/utilFunctions";
import Globals from "../../Globals";
import CardSection from "./cardSection";
import {useHistory} from 'react-router-dom'
import { toast } from "react-toastify";
import {Link} from 'react-router-dom'
class CheckoutForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        flag:false
      };
}
  handleSubmit = async event => {
    event.preventDefault();
        this.setState({flag:true})
        const {stripe, elements} = this.props;
        const card = elements.getElement(CardElement);

        const obj={
          name:this.props.data.firstName+" "+this.props.data.lastName,
          email:this.props.data.email
        }
        
        const requestData = createIntent(this.props.price);
        const apiResponse = await apiCall(Globals.API_ROOT_URL, requestData);

    if (apiResponse.data.createIntent===null){
        this.props.history.push('/login')
        toast.error(apiResponse.errors[0].message);       //Showing toastify error message
        return true;
    }
        var clientSecret = apiResponse.data.createIntent.clientSecret;
        const paymentMethodReq = await stripe.createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            name: obj.name,
            email: obj.email
          },
        });
        if (paymentMethodReq.error==null){
            await stripe.confirmCardPayment(clientSecret, {
              payment_method: paymentMethodReq.paymentMethod.id
            })
            .then(async(result) => {
              if (result.error===undefined){
              if (result.paymentIntent.status==="succeeded") {

                var obj = {
                  transactionId: result.paymentIntent.id,
                  amount: result.paymentIntent.amount
                }

                const requestData = facilityPayment(obj);
                const apiResponse = await apiCall(Globals.API_ROOT_URL, requestData);

                if (apiResponse.data.facilityPayment!==null){
                this.props.history.push('/thankyou')
                }
              }
            }
            else{
            this.setState({flag:false})
              toast.error(result.error.message)
            }
            })
        }
      else {
        this.setState({flag:false})
        toast.error(paymentMethodReq.error.message)
      }
};

  render() {
    localStorage.setItem('price',this.props.price)
    localStorage.setItem('quantity',this.props.quantity)
    return (
      <div>
        <Form >
          <CardSection />
        <Grid>
          <Grid.Row className="payment-wrapper">
            <Grid.Column width={5} className="payment">
              <div className="paymentbtn">
                <Button onClick={this.handleSubmit} loading={this.state.flag} className="btn-pay">
                  Pay £{this.props.price} Now
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column width={2} className="ortext">
              <div className="or-text">
                <span>OR</span>
              </div>
            </Grid.Column>
            <Grid.Column width={9} className="generate-invoice-wrapper">
              <div className="Generate-invoicebtn">
                <Link to ={{pathname:'/invoice',state: {price : this.props.price, quantity : this.props.quantity}}} >
                <Button >Generate Invoice & Pay Later</Button>
                </Link>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  const history = useHistory()
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} history={history} data={props.data} price={props.price} quantity={props.quantity} elements={elements} />
      )}
    </ElementsConsumer>
  );
}