function QuoteopenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="138"
      height="365"
      viewBox="0 0 138 365"
    >
      <text
        fill="#bf9f9f"
        data-name="“"
        fontFamily="Montserrat-SemiBold, Montserrat"
        fontSize="300"
        fontWeight="600"
        transform="translate(69 290)"
      >
        <tspan x="-68.4" y="0">
          “
        </tspan>
      </text>
    </svg>
  );
}

export default QuoteopenIcon;
