
function SkiParkSvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={67.355}
      height={40.5}
      viewBox="0 0 67.355 40.5"
      {...props}
    >
      <g data-name="Group 556">
        <g data-name="Group 555">
          <g data-name="Group 554">
            <path
              data-name="Path 1125"
              d="M11.659 31.57A11.268 11.268 0 010 20.217C0 12.925 5.043 7.838 12.248 7.838l21.571.044c3.908 0 8.078 2.423 8.078 6.921 0 3.253-1.092 4.476-5.065 7.27l-3.6 2.511a10.288 10.288 0 00-1.532 1.332l-.655.72h15.874V0h1.637c1.725 0 3.777.568 3.777 3.253v9.716S54.1 9.3 60.281 9.3c3.188 0 6.615 2.358 6.615 7.532v14.193h-2.052c-2.969 0-3.537-1.223-3.537-3.471v-9.629a4.092 4.092 0 00-4.236-4.279c-2.86 0-4.585 1.637-4.585 4.41v13.012l-24.671-.087a2.447 2.447 0 01-2.685-2.314c0-1.812 1.223-3.581 3.974-5.742l5.396-4.214a3.974 3.974 0 001.7-3.188c0-2.205-3.275-3.231-5.5-3.231h-9.129l.458.677a11.624 11.624 0 011.9 6.463c-.022 7.597-4.607 12.138-12.27 12.138zm.022-19.344c-3.122 0-5.655 3.144-5.655 7.03 0 6.725 3.362 8.144 6.179 8.144 3.319 0 5.655-2.9 5.655-7.074-.001-5.087-2.315-8.1-6.179-8.1z"
              fill="#87a878"
            />
          </g>
        </g>
      </g>
      <g data-name="Group 557" fill="#6b6b6b">
        <path
          data-name="Path 1126"
          d="M22.422 38.033a1.1 1.1 0 00-.961-.371 1.005 1.005 0 00.022 2.009 1.228 1.228 0 00.939-.327h.022v.415c0 .437-.415.742-1.048.742a1.784 1.784 0 01-1.812-1.768 1.816 1.816 0 011.9-1.879.861.861 0 01.961.764v.415h-.022z"
        />
        <path
          data-name="Path 1127"
          d="M25.457 36.81a1.663 1.663 0 011.659 1.79 1.715 1.715 0 01-1.747 1.9 1.663 1.663 0 01-1.659-1.79 1.726 1.726 0 011.747-1.9zm-.065.786c-.5 0-.764.415-.764 1 0 .7.262 1.113.83 1.113.5 0 .764-.415.764-1-.001-.699-.263-1.113-.831-1.113z"
        />
        <path
          data-name="Path 1128"
          d="M30.369 38.448c0 .24-.153.349-.437.349h-1.506v-.327a.368.368 0 01.415-.371h1.528z"
        />
        <path
          data-name="Path 1129"
          d="M36.199 38.732a7.483 7.483 0 01-.83 1.725h-.153c-.284 0-.48-.262-.611-.48a6.21 6.21 0 01-.589-1.594 7.1 7.1 0 01-.83 2.052h-.109c-.262 0-.5-.262-.633-.48a8.5 8.5 0 01-1-3.1h.48a.484.484 0 01.5.415 7.622 7.622 0 00.568 1.921 11.191 11.191 0 00.677-2.205h.284a.6.6 0 01.633.5 9.166 9.166 0 00.568 1.7 9.689 9.689 0 00.655-2.358h.371c.24 0 .437.109.437.371a7.366 7.366 0 01-.418 1.533z"
        />
        <path
          data-name="Path 1130"
          d="M39.518 36.81a1.663 1.663 0 011.659 1.79 1.715 1.715 0 01-1.747 1.9 1.663 1.663 0 01-1.659-1.79 1.7 1.7 0 011.747-1.9zm-.087.786c-.5 0-.764.415-.764 1 0 .7.262 1.113.83 1.113.5 0 .764-.415.764-1-.001-.699-.263-1.113-.831-1.113z"
        />
        <path
          data-name="Path 1131"
          d="M44.648 37.754a1.432 1.432 0 00-.5-.109c-.393 0-.611.218-.611.742v2.03h-.24c-.393 0-.633-.2-.633-.568v-1.616a1.251 1.251 0 011.307-1.379c.546 0 .742.306.742.568v.306h-.066z"
        />
        <path
          data-name="Path 1132"
          d="M46.88 36.286v2.118a15.217 15.217 0 011.07-1.288.9.9 0 01.7-.218h.5v.022c-.24.218-1.07 1.135-1.441 1.594a12.772 12.772 0 00.873 1.026 1.4 1.4 0 00.677.458.539.539 0 01-.589.437.832.832 0 01-.568-.24 11.524 11.524 0 01-1.223-1.506v1.725h-.284c-.415 0-.611-.218-.611-.633v-4.084h.284c.432.022.612.262.612.589z"
        />
        <path
          data-name="Path 1133"
          d="M53.883 40.457a1 1 0 01-1.113-1.092v-3.646h.24a.563.563 0 01.633.589v2.969c0 .349.175.458.589.458h.24v.2c-.002.369-.196.522-.589.522z"
        />
        <path
          data-name="Path 1134"
          d="M57.093 40.478h-.175c-.83 0-1.419-.48-1.419-1.113s.458-1.113 1.747-1.113h.306v-.042c0-.5-.24-.655-.764-.655a1.368 1.368 0 00-1.113.415h-.022v-.284c0-.393.349-.873 1.288-.873.786 0 1.485.371 1.485 1.594v.808a1.233 1.233 0 01-1.333 1.263zm.48-1.594h-.306c-.677 0-.851.153-.851.437s.218.437.611.437c.371 0 .568-.175.568-.546v-.327z"
        />
        <path
          data-name="Path 1135"
          d="M61.59 40.5a1.705 1.705 0 01-1.659-1.79v-2.991h.306c.349 0 .568.2.568.611v1.026a1.137 1.137 0 01.961-.546 1.648 1.648 0 011.594 1.768 1.8 1.8 0 01-1.77 1.922zm0-2.9a.9.9 0 00-.786.982c0 .808.349 1.135.873 1.135.437 0 .742-.415.742-.982.023-.855-.305-1.138-.829-1.138z"
        />
        <path
          data-name="Path 1136"
          d="M65.891 40.501c-.655 0-1.135-.327-1.135-.7v-.393h.022a1.779 1.779 0 001.07.327c.437 0 .633-.131.633-.393 0-.2-.131-.306-.742-.393-.742-.109-1.113-.393-1.113-.939a1.348 1.348 0 011.507-1.223c.808 0 1.135.415 1.135.742v.327h-.043a1.569 1.569 0 00-1-.327c-.546 0-.7.175-.7.393 0 .175.109.24.7.349.742.109 1.135.437 1.135 1 .016.793-.508 1.23-1.469 1.23z"
        />
      </g>
    </svg>
  )
}

export default SkiParkSvgComponent
