function LinkedinIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44.12"
        height="44.12"
        viewBox="0 0 44.12 44.12"
      >
        <g data-name="Group 6572" transform="translate(-534.001 -812.44)">
          <path
            fill="none"
            stroke="#87a878"
            strokeWidth="1"
            d="M556.061 812.94a21.56 21.56 0 1021.56 21.56 21.582 21.582 0 00-21.56-21.56z"
            data-name="Path 3392"
          ></path>
          <g fill="#87a878" transform="translate(548 826.44)">
            <path
              d="M19.117 18.833V12.8c0-2.952-.635-5.225-4.086-5.225a3.582 3.582 0 00-3.226 1.773h-.048V7.85H8.489v10.983H11.9v-5.439c0-1.432.271-2.817 2.045-2.817 1.747 0 1.773 1.634 1.773 2.908v5.347z"
              data-name="Path 3381"
              transform="translate(-2.67 -2.382)"
            ></path>
            <path
              d="M.4 7.977h3.407V18.96H.4z"
              data-name="Path 3382"
              transform="translate(-.125 -2.509)"
            ></path>
            <path
              d="M1.976 0a1.985 1.985 0 101.975 1.976A1.976 1.976 0 001.976 0z"
              data-name="Path 3383"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
  
  export default LinkedinIcon;