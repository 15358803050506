function FacebookIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44.12"
        height="44.12"
        viewBox="0 0 44.12 44.12"
      >
        <g data-name="Group 6570" transform="translate(-423.651 -812.44)">
          <path
            fill="none"
            stroke="#87a878"
            strokeWidth="1"
            d="M445.711 812.94a21.56 21.56 0 1021.56 21.56 21.582 21.582 0 00-21.56-21.56z"
            data-name="Path 3369"
          ></path>
          <path
            fill="#87a878"
            d="M14.386 3.331h1.831V.141A23.645 23.645 0 0013.549 0C10.909 0 9.1 1.661 9.1 4.713v2.809H6.187v3.565H9.1v8.971h3.572v-8.97h2.8l.444-3.565h-3.24V5.066c0-1.03.278-1.736 1.714-1.736z"
            transform="translate(434.51 824.471)"
          ></path>
        </g>
      </svg>
    );
  }
  
  export default FacebookIcon;
  